import { NgModule } from '@angular/core';
import { TulUploadComponent } from './components/tul-upload/tul-upload.component';
import { SharedModule } from '../shared/shared.module';
import { TulTooltipModule } from '../tul-tooltip/tul-tooltip.module';

@NgModule({
  declarations: [TulUploadComponent],
  imports: [SharedModule, TulTooltipModule],
  exports: [TulUploadComponent],
})
export class TulUploadModule {}
