export enum TypeOption {
  EDIT = 'edit',
  DELETE = 'delete',
  DETAILS = 'details',
  CHECKSTATUS = 'check-status',
  SELECTSTATUS = 'select-status',
  CUSTOM = 'custom',
  EXPAND = 'expand',
}

export enum TypeHeaderOptions {
  ADD = 'add',
  EXPORT = 'export',
  REFRESH = 'refresh',
  CUSTOM = 'custom',
  MULTIEDIT = 'multi-edit',
  IMPORT = 'import',
}

export enum TypesMultiEdit {
  OPENCHECKS = 'open-checks',
  CLOSECHECKS = 'close-checks',
  CLEANCHECKS = 'clean-checks',
  DATACHECKS = 'data-checks',
}

export enum TypeSort {
  ASCEND = 'asc',
  DESCEND = 'desc',
}

export enum ConditionOptions {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  TOPLEFT = 'topLeft',
  TOPRIGHT = 'topRight',
  BOTTOMLEFT = 'bottomLeft',
  BOTTOMRIGHT = 'bottomRight',
  LEFTTOP = 'leftTop',
  LEFTBOTTOM = 'leftBottom',
  RIGHTTOP = 'rightTop',
  RIGHTBOTTOM = 'rightBottom',
}

export enum TypeSortDefault {
  asc = 'ascend',
  desc = 'descend',
}
