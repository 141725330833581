import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_FALLBACK_STRATEGY,
  TRANSLOCO_INTERCEPTOR,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { CustomHandler } from './customHandler';
import { CustomLoader } from './customLoader';
import { CustomInterceptor } from './customInterceptor';
import { CustomStrategy } from './customStrategy';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: CustomHandler },
    { provide: TRANSLOCO_LOADER, useClass: CustomLoader },
    { provide: TRANSLOCO_INTERCEPTOR, useClass: CustomInterceptor },
    { provide: TRANSLOCO_FALLBACK_STRATEGY, useClass: CustomStrategy },
  ],
})
export class TranslocoRootModule {
  public static forRoot(environment: any): ModuleWithProviders<TranslocoRootModule> {
    return {
      ngModule: TranslocoRootModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            availableLangs: [
              environment.country.language,
              `${environment.country.language}_${environment.country.code}`,
            ],
            defaultLang: environment.country.language,
            fallbackLang: `${environment.country.language}_${environment.country.code}`,
            prodMode: environment.production,
            missingHandler: {
              useFallbackTranslation: true,
            },
          }),
        },
        {
          provide: 'ENVIRONMENT',
          useValue: { ...environment },
        },
      ],
    };
  }
}
