<div class="overlay">
  <tul-static-alert
    [message]="data.message"
    [description]="data.description ?? null"
    [tulType]="data.tulType"
    [tulColor]="data.tulColor"
    [isClosable]="data.isClosable"
    [withIcon]="data.withIcon"
    [withButton]="!!data.button"
    [buttonText]="data.button?.text ?? ''"
    (buttonPressed)="buttonPressed()"
  ></tul-static-alert>
</div>
