export enum TulButtonCardTypeCondencedEnum {
  CONDENCED = 'condenced',
  CONDENCEDICON = 'condenced-icon',
}

export enum TulButtonCardTypeLargeEnum {
  LARGE = 'large',
  LARGEICON = 'large-icon',
  LARGEARROW = 'large-arrow',
  LARGEICONARROW = 'large-icon-arrow',
}
