<div class="tul-modal-container" [@enterAnimation] *ngIf="tulVisible">
  <div class="tul-modal-container__block" [ngClass]="'tul-modal-container--' + tulModalSize">
    <div class="tul-modal-container__close-icon-container" *ngIf="tulIsClosable">
      <i
        class="soytul soytul-close_outline tul-modal-container__close-icon"
        (click)="closeModal()"
      ></i>
    </div>
    <div class="tul-modal-container__content">
      <ng-content select="tul-modal-header"></ng-content>
      <ng-content select="tul-modal-body"></ng-content>
      <ng-content select="tul-modal-footer"></ng-content>
    </div>
  </div>
</div>
