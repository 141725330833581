import { Component, Input } from '@angular/core';
import { TypeTextsTable } from './../../../../interfaces/columns/text';
import { SubTypeTextColumnTable } from '../../../../enums/columns/SubTypeTextColumnTable.enum';

/**
 * Body option text
 */
@Component({
  selector: '[text]',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent {
  /**
   * subType Column with default
   */
  subType!: string;

  /**
   * subType Column
   */
  @Input('subType')

  /**
   * Set default column
   */
  set SubType(subType: string) {
    this.subType = subType || SubTypeTextColumnTable.TEXT;
  }

  /**
   * Actual Column
   */
  @Input() column!: TypeTextsTable;
  /**
   * Data row
   */
  @Input() data: any;

  /**
   * Get name to text editor column
   * @param column actual column
   * @return string title
   */
  getNameTextEditor(column: any) {
    return typeof column.textEditor.name === 'string'
      ? column.textEditor.name
      : column.textEditor.name({ data: this.data, column: this.column });
  }
}
