import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ColumnTable } from '../interfaces/columns/column';
import { TableService } from './table.service';
import { TypeQueryFilter } from '../enums/typeQueryFilter.enum';
import { Table, TableMs } from '../interfaces/table';

/**
 * Table micro service
 */
@Injectable({
  providedIn: 'root',
})
export class TableMSService {
  /**
   * environment
   */
  environment: any;

  /**
   * constructor
   * @param http Performs HTTP requests
   * @param environment environment
   * @param tableServices general table services
   */
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') environment: any,
    private tableServices: TableService
  ) {
    this.environment = environment;
  }

  /**
   * get data
   * @param url url to get data
   * @param columns añl columns
   * @param page actual page
   * @param size size page
   * @param filters array filters
   * @param defaultFilters array default filters
   * @param typeQueryFilter type query filter spring-search or rsql
   * @param sort object sort
   * @return Observable
   */
  get(
    url: any,
    columns: Array<ColumnTable>,
    page: any,
    size: any,
    filters = new Map(),
    defaultFilters = [],
    typeQueryFilter: TypeQueryFilter,
    sort?: any,
    table?: TableMs
  ): Observable<any> {
    let auxUrl = (typeof url === 'string' ? url : url()) + '?';
    // add pagination attr
    auxUrl += new URLSearchParams({ page: JSON.stringify(page - 1), size }).toString();
    // add sort attr
    auxUrl += this.getSort(sort);
    // add filters attr
    if (filters.size || defaultFilters.length) {
      auxUrl += '&search=';
      auxUrl += this.getFilters(filters, columns, typeQueryFilter);
    }
    return this.http.get(
      `${table?.alternativeHost ? table?.alternativeHost : this.environment.url}${auxUrl}`
    );
  }

  /**
   * get filters
   * @param filters array filters
   * @param columns all columns
   * @param typeQueryFilter type query filter spring-search or rsql
   * @return string filters
   */
  getFilters(filters: any, columns: Array<ColumnTable>, typeQueryFilter: TypeQueryFilter) {
    return this.tableServices.generateFilter(columns, filters, true, typeQueryFilter);
  }

  /**
   * get default filters
   * @param filters array filters
   * @return string default filters
   */
  getDefaultFilters(filters: any) {
    return this.tableServices.generateDefaultFilters(filters);
  }

  /**
   * get sort
   * @param sort object sort
   * @return string sort
   */
  getSort(sort: any) {
    return sort.type
      ? '&sort=' +
          encodeURIComponent(`${sort.attribute},${sort.type === 'ascend' ? 'asc' : 'desc'}`)
      : '';
  }

  /**
   * edit request
   * @param params object params
   * @param id id to edit
   * @param url url to edit
   * @return observable
   */
  edit(params: any, id: any, url: any): Observable<any> {
    return this.http.patch(this.environment.url + url + '/' + id, params);
  }

  /**
   * delete request
   * @param id id to delete
   * @param url ult to delete
   * @return observable
   */
  delete(id: any, url: any): Observable<any> {
    return this.http.delete(this.environment.url + url + '/' + id, {});
  }
}
