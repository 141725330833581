import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  TulButtonQuickActionsType,
  TulButtonQuickActionsTypeEnum,
} from '../../types/btn-quick-action-type.type';
import { TulButtonAction, TulButtonActionEnum } from '../../types/btn-action.type';
import { TulButtonIconTypeEnum } from '../../types/btn-icon-type.type';
import { TulButtonIconGeneral } from '../../models/btn-icon-general.model';

@Component({
  selector: 'tul-btn-quick-actions',
  templateUrl: './tul-btn-quick-actions.component.html',
  styleUrls: ['./tul-btn-quick-actions.component.scss'],
})
export class TulBtnQuickActionsComponent {
  /**
   * Environments for static style url
   */
  environment: any;

  /**
   * Enum ('primary-a', 'primary-b', 'secondary', 'tertiary')
   */
  @Input() tulType?: TulButtonQuickActionsType = TulButtonQuickActionsTypeEnum.PRIMARYA;

  /**
   * Interface TulButtonIcon
   */
  @Input() tulIcon: TulButtonIconGeneral = {
    name: 'chamo',
    type: 'svg',
  };

  /**
   * Text on button
   */
  @Input() tulText: string = '';

  /**
   * Emit change for tul text
   */
  @Output() tulTextChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Button disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Emit change for tul disabled
   */
  @Output() tulDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Button is select
   */
  @Input() tulSelect: boolean = false;

  /**
   * Emit change for tul select
   */
  @Output() tulSelectChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Emit call back when button has any action
   */
  @Output() callBack: EventEmitter<TulButtonAction> = new EventEmitter<TulButtonAction>();

  /**
   * Default img if icon daesn't exists
   */
  defaultImage: string = '';

  /**
   * Constructor
   * @param ENVIRONMENT
   */
  constructor(@Inject('ENVIRONMENT') ENVIRONMENT: any) {
    this.environment = ENVIRONMENT;
    this.defaultImage = `${this.environment.staticFilesUrl}icons/svg/chamo.svg`;
  }

  /**
   * Emit when there's any event on button
   * @param tulButtonAction
   */
  emitAction(tulButtonAction: TulButtonAction) {
    if (tulButtonAction === TulButtonActionEnum.PRESS) {
      this.tulSelect = !this.tulSelect;
    }
    this.tulTextChange.emit(this.tulText);
    this.tulDisabledChange.emit(this.tulDisabled);
    this.callBack.emit(tulButtonAction);
  }

  get hasShadow(): boolean {
    return (
      this.tulType === TulButtonQuickActionsTypeEnum.PRIMARYA ||
      this.tulType === TulButtonQuickActionsTypeEnum.SECONDARY
    );
  }

  get isDisabled(): boolean {
    return this.tulDisabled;
  }

  get isSelected(): boolean {
    return this.tulSelect;
  }

  get isPrimary(): boolean {
    return (
      this.tulType === TulButtonQuickActionsTypeEnum.PRIMARYA ||
      this.tulType === TulButtonQuickActionsTypeEnum.PRIMARYB
    );
  }

  get isSecondary(): boolean {
    return (
      this.tulType === TulButtonQuickActionsTypeEnum.SECONDARY ||
      this.tulType === TulButtonQuickActionsTypeEnum.TERTIARY
    );
  }

  get isSvg(): boolean {
    return this.tulIcon?.type === TulButtonIconTypeEnum.SVG;
  }

  get isFont(): boolean {
    return this.tulIcon?.type === TulButtonIconTypeEnum.FONT;
  }
}
