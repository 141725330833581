import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TulSwitchTitlePipe } from './tul-switch-title.pipe';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [TulSwitchTitlePipe],
  exports: [TulSwitchTitlePipe],
})
export class TulSwitchTitlePipeModule {}
