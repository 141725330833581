<div class="modification" *ngFor="let group of groups">
  <div class="modification-feedback" *ngIf="group.feedbackOptions">
    <header-feedback [options]="group.feedbackOptions"></header-feedback>
  </div>
  <div class="modification-body">
    <header-group *ngIf="group.headerOptions" [options]="group.headerOptions"> </header-group>
    <header-sub-group
      *ngIf="group.subHeaderOptions"
      [options]="group.subHeaderOptions"
    ></header-sub-group>
    <!-- NEW STRUCTURE-->
    <div *ngFor="let section of group.sections">
      <header-section *ngIf="section.header" [options]="section.header"></header-section>
      <div
        class="section-container"
        [ngStyle]="{
          'grid-template-columns':
            'repeat(' + (section.numberColumns ? section.numberColumns : 1) + ', 1fr)'
        }"
      >
        <ng-container *ngFor="let column of section.columns">
          <div class="column-form-item" *ngIf="validateVisible(column?.visible)">
            <div class="column-form-control" [ngSwitch]="column.type">
              <!-- Draw by default if visible object is not defined-->

              <!-- SELECT-->
              <ng-container *ngSwitchCase="'select'">
                <select-controller
                  [data]="data"
                  [column]="castSelectColumnForms(column)"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></select-controller>
              </ng-container>
              <!-- TEXT -->
              <ng-container *ngSwitchCase="'text'">
                <text-controller
                  [data]="data[column.attribute]"
                  [column]="column"
                  [validateFieldFlag]="validatingFlag"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></text-controller>
              </ng-container>
              <!-- MAP -->
              <ng-container *ngSwitchCase="'map'">
                <map-controller [data]="data" [column]="column"></map-controller>
              </ng-container>
              <!-- SELECTORS -->
              <ng-container *ngSwitchCase="'selectors'">
                <selectors-controller
                  [data]="data[column.attribute]"
                  [column]="column"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></selectors-controller>
              </ng-container>
              <!-- CUSTOM -->
              <ng-container *ngSwitchCase="'custom'">
                <custom-controller
                  [data]="data"
                  [column]="castCustomColumnForms(column)"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></custom-controller>
              </ng-container>
              <!-- COLOR -->
              <ng-container *ngSwitchCase="'color'">
                <color-controller
                  [data]="data[column.attribute]"
                  [column]="column"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></color-controller>
              </ng-container>
              <!-- DOCUMENT -->
              <ng-container *ngSwitchCase="'document'">
                <document-controller
                  [data]="data"
                  [column]="castDocumentColumnForms(column)"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></document-controller>
              </ng-container>
              <!-- FILE -->
              <ng-container *ngSwitchCase="'files'">
                <files-controller
                  [data]="data"
                  [column]="column"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></files-controller>
              </ng-container>
              <!-- NUMERIC -->
              <ng-container *ngSwitchCase="'numeric'">
                <numeric-controller
                  [data]="data[column.attribute]"
                  [column]="column"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></numeric-controller>
              </ng-container>
              <!-- DATE -->
              <ng-container *ngSwitchCase="'date'">
                <date-controller
                  [data]="data"
                  [column]="column"
                  [validateFieldFlag]="validatingFlag"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></date-controller>
              </ng-container>
              <!--DEFAULT -->
              <ng-container *ngSwitchDefault>
                <text-controller
                  [data]="data[column.attribute]"
                  [column]="column"
                  [validateFieldFlag]="validatingFlag"
                  (onChange)="data[column.attribute] = $event; changeField($event, column)"
                ></text-controller>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modification-footer">
  <tul-btn
    [tulText]="'saveForm' | transloco"
    (click)="onClickSaveChanges()"
    [tulDisabled]="loading"
  ></tul-btn>
</div>
