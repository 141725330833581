<ng-container *ngIf="column.httpSubscribe; else imageTemplate">
  <nz-spin [nzSpinning]="loading">
    <ng-container *ngIf="loading; else imageTemplate">
      <div>...{{ "tulTable.content.document.loading" | transloco }}</div>
    </ng-container>
  </nz-spin>
</ng-container>

<ng-template #imageTemplate>
  <ng-container *ngIf="hasError; else elseTemplate">
    <svg-not-found></svg-not-found>
  </ng-container>
  <ng-template #elseTemplate>
    <img
      class="image"
      (click)="isVisible = true"
      [src]="(data | tulDataTablePipe : column.attribute)?.url"
      [alt]="(data | tulDataTablePipe : column.attribute)?.file_name"
      (error)="hasError = true"
    />
  </ng-template>
</ng-template>

<nz-modal
  [(nzVisible)]="isVisible"
  [nzFooter]="null"
  (nzOnCancel)="isVisible = false"
  (nzOnOk)="isVisible = false"
>
  <ng-container *nzModalContent>
    <div class="content-modal">
      <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
    </div>
  </ng-container>
</nz-modal>
