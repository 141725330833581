import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SubHeaderGroupOptions } from '../../../../interfaces/group';

/**
 * Component for custom chip
 */
@Component({
  selector: 'header-sub-group',
  templateUrl: './header-sub-group.component.html',
  styleUrls: ['./header-sub-group.component.scss'],
})
export class TulHeaderSubGroupComponent {
  @Input() options!: SubHeaderGroupOptions;
}
