import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportComponent } from './export.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SvgExportModule } from '../../../svg/svg-export/svg-export.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { TranslocoModule } from '@ngneat/transloco';
import { TulToAsyncPipeModule } from '../../../../pipes';

@NgModule({
  exports: [ExportComponent],
  imports: [
    CommonModule,
    FormsModule,
    SvgExportModule,
    NzButtonModule,
    RouterModule,
    NzToolTipModule,
    NzDropDownModule,
    NzMenuModule,
    TranslocoModule,
    TulToAsyncPipeModule,
  ],
  declarations: [ExportComponent],
})
export class ExportModule {}
