import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgShrinkComponent } from './svg-shrink.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  exports: [SvgShrinkComponent],
  imports: [CommonModule, FormsModule],
  declarations: [SvgShrinkComponent],
})
export class SvgShrinkModule {}
