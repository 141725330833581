import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomColumnForms } from '../../../../interfaces/columns/custom';

/**
 *
 */
@Component({
  selector: 'custom-controller',
  templateUrl: './custom-controller.component.html',
})
export class CustomControllerComponent {
  /** */
  @Input() data!: any;

  /** */
  @Input() column!: CustomColumnForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
}
