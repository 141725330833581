import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TypeColumnTable } from '../enums/columns/typeColumnTable.enum';
import {
  SubTypeDateColumnTable,
  SubTypeSelectColumnTable,
  SubTypeTextColumnTable,
  TypeFilter,
  TypeQueryFilter,
} from '../enums/export';

/**
 * Table services
 */
@Injectable({
  providedIn: 'root',
})
export class TableService {
  /**
   * environment inject
   */
  environment: any;

  /**
   * constructor
   * @param http Performs HTTP requests
   * @param environment environment
   */
  constructor(private http: HttpClient, @Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }

  /**
   * format date
   * @param date date to convert
   * @param format convert format to date
   * @return date string
   */
  formatDate = (date: any, format: any) => formatDate(date, format, 'es_ES');

  /**
   * get request
   * @param url url to get data
   * @return observable
   */
  get(url: any): Observable<any> {
    return this.http.get(this.environment.url + url, {});
  }
  /**
   * Generate filters
   * @param columns array of columns
   * @param filter actual filter
   * @param encode encode flag
   * @return string filter
   */
  generateFilter(columns: any, filter: any, encode = true, typeQueryFilter?: TypeQueryFilter) {
    let auxStringfilters = '';
    const auxFilter = Object.entries(Object.fromEntries(filter));
    for (const [key, { value, type: typeFilter }] of auxFilter) {
      const col = columns.find((element: any) => element.attribute === key);
      if (col) {
        const type = col?.type ?? TypeColumnTable.TEXT;
        const subtype = col?.subType ?? SubTypeTextColumnTable.TEXT;
        switch (type) {
          case TypeColumnTable.DATE:
            const [initDate, lastDate] = value as any;
            switch (subtype) {
              case SubTypeDateColumnTable.DATE:
                const init = new Date(initDate.getTime() - 60 * 60 * 24 * 1 * 1000);
                const last = new Date(lastDate.getTime() + 60 * 60 * 24 * 1 * 1000);
                auxStringfilters += `${key}>'${this.formatDate(
                  init,
                  'yyyy-MM-dd'
                )}' ${this.operatorTransform('AND', typeQueryFilter)} ${key}<'${this.formatDate(
                  last,
                  'yyyy-MM-dd'
                )}'`;
                break;
              default:
                auxStringfilters += `${key}>'${this.formatDate(
                  initDate,
                  'yyyy-MM-dd'
                )}T00:00' ${this.operatorTransform(
                  'AND',
                  typeQueryFilter
                )} ${key}<'${this.formatDate(lastDate, 'yyyy-MM-dd')}T23:59'`;
                break;
            }
            break;
          case TypeColumnTable.SELECT:
            switch (subtype) {
              case SubTypeSelectColumnTable.ENTITY:
                break;
              default:
                if (typeof value === 'string') {
                  const auxFilter = `${key}${this.operatorTransform(
                    ':',
                    typeQueryFilter
                  )}'${value}'`;
                  auxStringfilters += encode ? encodeURIComponent(auxFilter) : auxFilter;
                } else {
                  auxStringfilters += '(';
                  value.forEach((secondValues: any, secondIndex: any) => {
                    const auxFilter =
                      `${key}${this.operatorTransform(':', typeQueryFilter)}'${secondValues}'` +
                      (secondIndex < value.length - 1
                        ? ` ${this.operatorTransform('OR', typeQueryFilter)} `
                        : '');
                    auxStringfilters += encode ? encodeURIComponent(auxFilter) : auxFilter;
                  });
                  auxStringfilters += ')';
                }
                break;
            }
            break;
          case TypeColumnTable.NUMERIC:
          case TypeColumnTable.SELECTORS:
            const auxValNSS = `${key}${this.operatorTransform(':', typeQueryFilter)}${value}`;
            auxStringfilters += encode ? encodeURIComponent(auxValNSS) : auxValNSS;
            break;
          default:
            const auxSplit = key.split('.');
            let auxKey = auxSplit[auxSplit.length - 1];
            let auxValDefault: string = `${
              col.filter?.additionalSearchFilter ? auxKey : key
            }${this.operatorTransform(':', typeQueryFilter)}'*${value}*'`;
            auxStringfilters += encode ? encodeURIComponent(auxValDefault) : auxValDefault;

            break;
        }
      } else {
        let connector = ` ${this.operatorTransform('AND', typeQueryFilter)} `;
        let operator = `${this.operatorTransform(':', typeQueryFilter)}`;
        let flag = false;
        switch (typeFilter) {
          case TypeFilter.RANGE:
            const auxFilter = `${key}>'${value[0]}' ${this.operatorTransform(
              'AND',
              typeQueryFilter
            )} ${key}<'${value[1]}'`;
            auxStringfilters += encode ? encodeURIComponent(auxFilter) : auxFilter;
            flag = true;
            break;
          case TypeFilter.NOT:
            operator = `${this.operatorTransform('!', typeQueryFilter)}`;
            break;
          case TypeFilter.MINOR:
            operator = '<';
            break;
          case TypeFilter.MAYOR:
            operator = '>';
            break;
          case TypeFilter.AND:
          case TypeFilter.OR:
          default:
            connector = ` ${this.operatorTransform('OR', typeQueryFilter)} `;
            break;
        }
        if (!flag) {
          if (typeof value === 'string') {
            const auxFilter = `${key}${operator}'*${value}*'`;
            auxStringfilters += encode ? encodeURIComponent(auxFilter) : auxFilter;
          } else {
            auxStringfilters += '(';
            value.forEach((secondValues: any, secondIndex: any) => {
              const auxFilter =
                `${key}${operator}'${secondValues}'` +
                (secondIndex < value.length - 1 ? connector : '');
              auxStringfilters += encode ? encodeURIComponent(auxFilter) : auxFilter;
            });
            auxStringfilters += ')';
          }
        }
      }
      if (key !== auxFilter[auxFilter.length - 1][0]) {
        auxStringfilters += ` ${this.operatorTransform('AND', typeQueryFilter)} `;
      }
    }
    return auxStringfilters;
  }

  /**
   * generate default filter
   * @param filter filter
   * @param encode encode flag
   * @return string
   */
  generateDefaultFilters(filter: any, encode = true, typeQueryFilter?: TypeQueryFilter) {
    let auxStringfilters = '';
    filter.forEach(({ attribute, value }: { attribute: any; value: any }, index: any) => {
      if (typeof value === 'string') {
        const auxFilter =
          `${attribute}${this.operatorTransform(':', typeQueryFilter)}'${value}'` +
          (index < filter.length - 1 ? ` ${this.operatorTransform('AND', typeQueryFilter)} ` : '');
        auxStringfilters += encode ? encodeURIComponent(auxFilter) : auxFilter;
      } else {
        auxStringfilters += '(';
        value.forEach((secondValues: any, secondIndex: any) => {
          const auxFilter =
            `${attribute}${this.operatorTransform(':', typeQueryFilter)}'${secondValues}'` +
            (secondIndex < value.length - 1
              ? ` ${this.operatorTransform('OR', typeQueryFilter)} `
              : '');
          auxStringfilters += encode ? encodeURIComponent(auxFilter) : auxFilter;
        });
        auxStringfilters +=
          ')' +
          (index < filter.length - 1 ? ` ${this.operatorTransform('AND', typeQueryFilter)} ` : '');
      }
    });
    return auxStringfilters.length === 0 ? '' : auxStringfilters;
  }

  /**
   * export request
   * @param url url to get data
   * @return observable
   */
  export(url: any): Observable<any> {
    const urlToSend = this.environment.url + url;
    let header = {};
    header = {
      observe: 'response',
      responseType: 'blob' as 'json',
    };
    return this.http.get(urlToSend, header);
  }

  /**
   * Transform operator according to @param typeQueryFilter
   * @param operator operator
   * @param typeQueryFilter rsql or spring-search
   * @returns operator
   */
  operatorTransform(operator: string, typeQueryFilter?: TypeQueryFilter): string {
    switch (typeQueryFilter) {
      case TypeQueryFilter.RSQL:
        switch (operator) {
          case 'AND':
            return 'and';
          case 'OR':
            return ',';
          case ':':
            return '==';
          case '!':
            return '!=';
          default:
            break;
        }
        break;
    }
    return operator;
  }
}
