import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location, LocationStrategy } from '@angular/common';
import { TypeColumnForms } from '../../enums/columns/typeColumnForms.enum';
import { ColumnForms, ValidField } from '../../interfaces/columns/column';
import { Data } from '../../interfaces/data';
import { Group } from '../../interfaces/group';
import { TypeState } from '../../enums';
import { NumericValidations } from '../../validations/numericValidations';
import { TextValidations } from '../../validations/textValidations';

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class FormsService {
  /** */
  environment: any;

  /** */
  validateFields: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   *
   * @param environment
   * @param router
   * @param http
   * @param numericValidations
   * @param textValidations
   * @param location
   */
  constructor(
    @Inject('ENVIRONMENT') environment: any,
    private router: Router,
    private http: HttpClient,
    private numericValidations: NumericValidations,
    private textValidations: TextValidations,
    @Inject(Location) private readonly location: Location,
    private locationStrategy: LocationStrategy
  ) {
    this.environment = environment;
  }

  /**
   *
   * @param url
   * @returns
   */
  get(url: string | (() => string)): Observable<any> {
    const auxUrl = typeof url === 'string' ? url : url();
    return this.http.get(this.environment.url + auxUrl);
  }

  /**
   *
   * @param url
   * @param data
   * @returns
   */
  save(url: string | (() => string), data: Array<any>) {
    const auxUrl = typeof url === 'string' ? url : url();
    const dataForm = this.ArrayToObject(data);
    return this.http.post(this.environment.url + auxUrl, dataForm, {});
  }

  /**
   *
   * @param url
   * @param data
   * @returns
   */
  edit(url: string | (() => string), data: Array<any>) {
    const auxUrl = typeof url === 'string' ? url : url();
    const dataForm = this.ArrayToObject(data);
    return this.http.patch(this.environment.url + auxUrl, dataForm, {});
  }

  /**
   *
   * @param data
   * @returns
   */
  ArrayToObject(data: Array<any>) {
    return data.reduce((result, item) => {
      result[item.label] = item.value;
      return result;
    }, {});
  }

  /**
   *
   * @param mapa
   * @returns
   */
  mapToObject(mapa: Map<string, string>) {
    const aux: Data = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of mapa) {
      aux[key] = value;
    }
    return aux;
  }

  /**
   *
   * @param groups
   * @param mapData
   * @returns
   */
  createFormData(groups: Array<Group>, mapData: Map<string, any>) {
    return this.mapToObject(this.changeAttributeToSecondAttribute(groups, mapData));
  }

  /**
   *
   * @param groups
   * @param mapTable
   * @returns
   */
  changeAttributeToSecondAttribute(groups: Array<Group>, mapTable: Map<string, any>) {
    const columns = this.getColumnsFromGroups(groups);
    const newMap = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of mapTable.entries()) {
      const auxCol = columns.find((element) => element?.attribute === key);
      if (auxCol) {
        const auxAttribute = auxCol.secondAttribute ?? auxCol.attribute;
        switch (auxCol.type) {
          default:
            newMap.set(auxAttribute, value);
            break;
        }
      }
    }
    return newMap;
  }

  /**
   *
   * @param groups
   * @returns
   */
  getColumnsFromGroups(groups: Array<Group>) {
    let columns: Array<ColumnForms> = [];
    groups.forEach((group) => {
      group.sections.forEach((section) => {
        columns = [...columns, ...section.columns];
      });
    });
    return columns;
  }

  getActiveRoute() {
    const route = this.router.url.split('?')[0];
    let activeRoute = TypeState.DETAILS;
    if (route.match(/(show)/)) {
      activeRoute = TypeState.DETAILS;
    }
    if (route.match(/(edit)/)) {
      activeRoute = TypeState.EDIT;
    }
    if (route.match(/(add)/)) {
      activeRoute = TypeState.ADD;
    }
    return activeRoute;
  }

  validateField(data: any, col: any): ValidField {
    const response: ValidField = {
      invalidField: false,
      errors: [],
    };
    const { type } = col;
    // handle all text field validations
    if (type === TypeColumnForms.TEXT) {
      return this.textValidations.validateTextFields(data, col, response);
    }

    if (type === TypeColumnForms.NUMERIC) {
      return this.numericValidations.validateNumericFields(data, col, response);
    }

    return response;
  }

  /**
   * getBasePath method
   * @param url
   * @returns string
   */
  getBasePath(url: string): string {
    const tempUrlRoute = url.split('?')[0];
    if (tempUrlRoute.match(/(\/show\/|\/edit\/|\/import\/|\/add\b)/)) {
      const urlRouteClean = tempUrlRoute.split('/');
      // Remove uuid
      urlRouteClean.pop();
      // Remove show/edit/import from route
      if (tempUrlRoute.match(/(\/show\/|\/edit\/|\/import\/)/)) {
        urlRouteClean.pop();
      }
      // add host and pr-id if exists and remove extra slash
      const fullUrl =
        window.location.origin +
        this.locationStrategy.getBaseHref().slice(0, -1) +
        urlRouteClean.join('/');

      return fullUrl;
    }
    return tempUrlRoute;
  }

  generateParamsGraphQl(
    { page, pageSize, input, labels }: any,
    aditionalSearch: { attribute: any; value: any }[]
  ) {
    let auxStringfilters = '';
    if ((input && labels) || aditionalSearch.length > 0) {
      auxStringfilters = ', search: "';
      if (input && labels) {
        labels.forEach((element: any, index: number) => {
          const auxFilter = `${element}:'*${input}*'` + (index < labels.length - 1 ? ' OR ' : '');
          auxStringfilters += auxFilter;
        });
        auxStringfilters += aditionalSearch.length > 0 ? ' AND ' : '"';
      }
      aditionalSearch.forEach((element: { attribute: any; value: any }, index: number) => {
        const auxFilter =
          `${element.attribute}:'${element.value}'` +
          (index < aditionalSearch.length - 1 ? ' AND ' : '"');
        auxStringfilters += auxFilter;
      });
    }
    return `page: ${page - 1}, size: ${pageSize} ${auxStringfilters}`;
  }

  /**
   * Get http request
   * @param url
   * @returns
   */
  getServiceMs(url: string): Observable<any> {
    return this.http.get(url);
  }
}
