import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomComponent } from './custom.component';
import { FormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TulToAsyncPipeModule } from '../../../../pipes';

@NgModule({
  exports: [CustomComponent],
  imports: [CommonModule, FormsModule, NzToolTipModule, TulToAsyncPipeModule],
  declarations: [CustomComponent],
})
export class CustomModule {}
