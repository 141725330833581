<tul-file-upload
  tulListType="picture-card"
  [tulLabel]="(column.required ? '* ' : '') + column.header"
  [tulAdditionalInfo]="column.error ?? ''"
  [tulState]="column.error ? 'error' : 'default'"
  [tulTooltipconfig]="column.tooltip"
  [(tulFileList)]="fileList"
  [tulShowButton]="fileList.length < (column.max ?? 1)"
  [tulPreview]="handlePreview"
  [tulBeforeUpload]="beforeUpload"
  [tulDisabled]="column.disabled ?? false"
  [tulMultiple]="column.multiple ?? false"
  (tulChange)="change($event)"
  [tulAccept]="column.types"
>
</tul-file-upload>
