import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  NzUploadChangeParam,
  NzUploadComponent,
  NzUploadFile,
  NzUploadTransformFileType,
  NzUploadXHRArgs,
  UploadFilter,
  ZipButtonOptions,
} from 'ng-zorro-antd/upload';
import { Observable, Subscription } from 'rxjs';
import { State, TulState } from '../../../shared/types/state.type';

/**
 * Component for custom chip
 */
@Component({
  selector: 'tul-file-upload',
  templateUrl: './tul-file-upload.component.html',
  styleUrls: ['./tul-file-upload.component.scss'],
})
export class TulFileUploadComponent extends NzUploadComponent {
  /**
   * Text in label
   */
  @Input() tulLabel: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Enum State for design
   */
  @Input() tulState: TulState = State.DEFAULT;

  /** */
  @Input() tulTooltipconfig!: any;

  /**
   * Text under dropdown
   */
  @Input() tulAdditionalInfo: string = '';
  /**
    * 
  /** _btnOptions default override */
  override _btnOptions?: ZipButtonOptions | undefined;
  /** fileDrop default override */
  override fileDrop(e: DragEvent): void {}
  /** classList default override */
  override classList: string[] = [];
  /** ngOnInit default override */
  override ngOnInit(): void {}
  /** ngAfterViewInit default override */
  override ngAfterViewInit(): void {}
  /** ngOnChanges default override */
  override ngOnChanges(): void {}
  /** ngOnDestroy default override */
  override ngOnDestroy(): void {}
  /** tulAccept => nzAccept input  */
  @Input('tulAccept') override nzAccept?: string | string[];
  /** tulDirectory => nzDirectory input  */
  @Input('tulDirectory') override nzDirectory: boolean = false;
  /** tulBeforeUpload => nzBeforeUpload input  */
  @Input('tulBeforeUpload') override nzBeforeUpload?: any;
  /** tulCustomRequest => nzCustomRequest input  */
  @Input('tulCustomRequest') override nzCustomRequest?: (item: NzUploadXHRArgs) => Subscription;
  /** tulData => nzData input  */
  @Input('tulData') override nzData?: Object | ((file: NzUploadFile) => Object | Observable<{}>);
  /** tulDisabled => nzDisabled input  */
  @Input('tulDisabled') override nzDisabled: boolean = false;
  /** tulFileList => nzFileList input  */
  @Input('tulFileList') override nzFileList: NzUploadFile[] = [];

  /** tulFileListChange => nzFileListChange input  */
  @Output('tulFileListChange') override nzFileListChange: EventEmitter<NzUploadFile[]> =
    new EventEmitter();
  /** tulLimit => nzLimit input  */
  @Input('tulLimit') override nzLimit: number = 0;
  /** tulSize => nzSize input  */
  @Input('tulSize') override nzSize: number = 0;
  /** tulFileType => nzFileType input  */
  @Input('tulFileType') override nzFileType?: string;
  /** tulFilter => nzFilter input  */
  @Input('tulFilter') override nzFilter: UploadFilter[] = [];
  /** tulHeaders => nzHeaders input  */
  @Input('tulHeaders') override nzHeaders?:
    | Object
    | ((file: NzUploadFile) => Object | Observable<{}>);
  /** tulListType => nzListType input  */
  @Input('tulListType') override nzListType: 'text' | 'picture' | 'picture-card' = 'text';
  /** tulMultiple => nzMultiple input  */
  @Input('tulMultiple') override nzMultiple: boolean = false;
  /** tulName => nzName input  */
  @Input('tulName') override nzName: string = 'file';
  /** tulShowButton => nzShowButton input  */
  @Input('tulShowButton') override nzShowButton: boolean = true;
  /** tulWithCredentials => nzWithCredentials input  */
  @Input('tulWithCredentials') override nzWithCredentials: boolean = false;
  /** tulOpenFileDialogOnClick => nzOpenFileDialogOnClick input  */
  @Input('tulOpenFileDialogOnClick') override nzOpenFileDialogOnClick: boolean = true;
  /** tulRemove => nzRemove input  */
  @Input('tulRemove') override nzRemove?: (file: NzUploadFile) => boolean | Observable<boolean>;
  /** tulPreview => nzPreview input  */
  @Input('tulPreview') override nzPreview?: (file: NzUploadFile) => void;
  /** tulPreviewFile => nzPreviewFile input  */
  @Input('tulPreviewFile') override nzPreviewFile?: (file: NzUploadFile) => Observable<string>;
  /** tulPreviewIsImage => nzPreviewIsImage input  */
  @Input('tulPreviewIsImage') override nzPreviewIsImage?: (file: NzUploadFile) => boolean;
  /** tulTransformFile => nzTransformFile input  */
  @Input('tulTransformFile') override nzTransformFile?: (
    file: NzUploadFile
  ) => NzUploadTransformFileType;

  /** tulDownload => nzDownload input  */
  @Input('tulDownload') override nzDownload?: (file: NzUploadFile) => void;

  /** tulChange => nzChange output  */
  @Output('tulChange') override nzChange: EventEmitter<NzUploadChangeParam> = new EventEmitter();

  nzFileCharged(event: any) {
    this.nzChange.emit(event);
    this.nzFileListChange.emit(event.fileList);
  }
}
