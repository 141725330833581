import { ModuleWithProviders, NgModule } from '@angular/core';
import { TulCurrencyPipe } from './tul-currency.pipe';

@NgModule({
  exports: [TulCurrencyPipe],
  imports: [],
  declarations: [TulCurrencyPipe],
})
export class TulCurrencyPipeModule {
  public static forRoot(environment: any): ModuleWithProviders<TulCurrencyPipeModule> {
    return {
      ngModule: TulCurrencyPipeModule,
      providers: [
        {
          provide: 'ENVIRONMENT',
          useValue: environment,
        },
      ],
    };
  }
}
