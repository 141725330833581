import { Component } from '@angular/core';

/**
 *
 */
@Component({
  selector: 'map-default-controller',
  templateUrl: './map-default-controller.component.html',
})
export class MapDefaultControllerComponent {}
