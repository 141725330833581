import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
  ComponentRef,
} from '@angular/core';
import { AlertConfig } from './alert-config';
import { AlertRef } from './alert-ref';
import { TulAlertComponent } from '../components/tul-alert/tul-alert.component';
import { AlertInjector } from './alert-injector';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  dialogComponentRef!: ComponentRef<TulAlertComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  /**
   * open alert on screen
   * @param config config for alert
   * @returns
   */
  public open(config: AlertConfig): AlertRef {
    const alertRef = this.appendDialogComponentToBody(config);
    return alertRef;
  }

  private appendDialogComponentToBody(config: AlertConfig): AlertRef {
    const map = new WeakMap();
    map.set(AlertConfig, config);

    const alertRef = new AlertRef();
    map.set(AlertRef, alertRef);

    const sub = alertRef.afterClosed.subscribe(() => {
      // close the dialog
      this.removeDialogComponentFromBody();
      sub.unsubscribe();
    });

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(TulAlertComponent);
    const componentRef = componentFactory.create(new AlertInjector(this.injector, map));
    //inyect data to alert
    componentRef.instance.data = config;
    //attachView to appRef
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    //Remvoe previous alert
    this.removeElementsByClass();
    //Add new alert
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    this.dialogComponentRef.instance.onClose.subscribe((evt) => {
      this.removeDialogComponentFromBody();
    });

    return alertRef;
  }

  private removeElementsByClass() {
    const elements = document.getElementsByClassName('overlay');

    while (elements.length > 0) elements[0].remove();
  }

  private removeDialogComponentFromBody(): void {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
}
