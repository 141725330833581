<div
  class="overlay"
  [ngStyle]="{
    top: toastInit.top + 'px',
    left: toastInit.left + 'px',
    right: toastInit.right + 'px',
    width: toastInit.width
  }"
  (click)="onOverlayClicked($event)"
>
  <tul-static-alert
    [message]="data.message"
    [description]="data.description ?? null"
    [tulType]="data.tulType"
    [tulColor]="data.tulColor"
    [isBanner]="data.isBanner"
    [isClosable]="data.isClosable"
    [withIcon]="data.withIcon"
  ></tul-static-alert>
</div>
