import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ToastService } from '../../../tul-toast';
import { SubTypeSelectorsColumnForms, TypeColumnForms } from '../../enums';
import { TypeVisible } from '../../enums/typeVisible';
import { ColumnForms } from '../../interfaces/columns/column';
import { Data } from '../../interfaces/data';
import {
  CustomColumnForms,
  DocumentColumnForms,
  SelectorsColumnForms,
  TypeSelectsForms,
} from '../../interfaces';
import { Form, Group } from '../../interfaces';
import { Visible } from '../../interfaces/visible';
import { FormsService } from '../../services/forms/forms.service';

/**
 *
 */
@Component({
  selector: 'app-modification',
  templateUrl: './modification.component.html',
  styleUrls: ['./modification.component.scss'],
})
export class ModificationComponent implements OnInit {
  /** */
  @Input() groups: Array<Group> = [];

  /** */
  @Input() form!: Form;

  /** */
  @Input() data: Data = {};

  /** */
  @Output() finishOption: EventEmitter<any> = new EventEmitter<any>();

  /** */
  loading: boolean = false;

  /** */
  changesMap: Map<string, any> = new Map<string, any>();

  /** */
  validatingFlag: boolean = false;

  /** */
  requiredColumns: Array<ColumnForms> = [];

  /** */
  validateColumns: Array<ColumnForms> = [];

  /** */
  activeRoute: string = '';

  /**
   *
   * @param formsService
   * @param router
   * @param translocoService
   * @param toastService
   */
  constructor(
    private formsService: FormsService,
    private router: Router,
    private translocoService: TranslocoService,
    public toastService: ToastService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    this.rehydrateForm();
  }

  rehydrateForm() {
    this.calculateRequiredColumns();
  }

  getGroup(groupColumns: any) {
    return groupColumns.value;
  }

  validateVisible(visible: Visible | undefined) {
    // if visible is undefined allways return true
    if (visible) {
      return Object.prototype.hasOwnProperty.call(visible, this.getRoute())
        ? visible[this.getRoute()]
        : true;
    }
    return true;
  }

  /**
   *
   * @returns
   */
  getRoute() {
    // eslint-disable-next-line prefer-destructuring
    this.activeRoute = this.router.url?.split('?')[0];
    if (this.activeRoute.match(/(\/edit\/)/)) {
      return TypeVisible.INEDIT;
    }
    return TypeVisible.INADD;
  }

  /**
   *
   */
  calculateRequiredColumns() {
    this.groups.forEach((group) => {
      group.sections.forEach((section) => {
        const allColumns = section.columns.filter((column) =>
          column?.visible ? this.validateVisible(column?.visible) : true
        );
        this.validateColumns = [...this.validateColumns, ...allColumns];
        const columns = allColumns.filter((column) => {
          // set default value if is set on object column
          this.setDefaultColumnValue(column);
          // Cast column to Selectors column
          if (column.type === TypeColumnForms.SELECTORS) {
            const selectorsColumn = column as SelectorsColumnForms;
            // if item is a switch required is disbled because the default value always gonna be false
            if (selectorsColumn.subType === SubTypeSelectorsColumnForms.SWITCH) {
              return false;
            }
            return column.required === true;
          }
          return column.required === true;
        });
        this.requiredColumns = [...this.requiredColumns, ...columns];
      });
    });
  }

  /**
   * set defaultValue to column
   * @param column
   */
  setDefaultColumnValue(column: ColumnForms) {
    // Set defaultValue if is present
    if (column.defaultValue) {
      this.data[column.attribute] = column.defaultValue;
    }
  }

  /**
   *
   * @returns
   */
  onClickSaveChanges() {
    this.loading = true;
    this.rehydrateForm();
    this.formsService.validateFields.next({});
    if (this.formHasInvalidFields() || this.changesMap.size === 0) {
      this.loading = false;
      this.toastService.open({
        message: `${this.translocoService.translate('form')}: ${this.translocoService.translate(
          'youHaveInvalidFieldsOrNoChangesToSubmit'
        )}`,
        tulType: 'default',
        tulColor: 'warning',
        isBanner: true,
        isClosable: true,
        withIcon: true,
      });

      return;
    }
    if (this.form.submitForm) {
      this.form
        .submitForm({
          data: this.formsService.createFormData(this.groups, this.changesMap),
          groups: this.groups,
        })
        .subscribe({
          next: (response) => {
            this.loading = false;
            this.form.callbackSubmitForm({
              data: this.formsService.createFormData(this.groups, this.changesMap),
              groups: this.groups,
              response,
            });
          },
          error: (error) => {
            this.loading = false;
            this.form.callbackSubmitForm({
              data: this.formsService.createFormData(this.groups, this.changesMap),
              groups: this.groups,
              error,
            });
          },
        });

      return;
    }
    this.form.callbackSubmitForm({
      data: this.formsService.createFormData(this.groups, this.changesMap),
      groups: this.groups,
    });
  }

  /**
   *
   * @returns
   */
  formHasInvalidFields() {
    let fieldsWithErrors = 0;
    // Check if fields match criteria

    this.validateColumns.forEach((column: any) => {
      const validatorResponse = this.formsService.validateField(
        this.data[column.attribute],
        column
      );
      if (validatorResponse.invalidField) {
        fieldsWithErrors += 1;
        validatorResponse.errors.forEach((error) => {
          this.toastService.open({
            message: `${this.translocoService.translate(
              column.attribute
            )}: ${this.translocoService.translate(error)}`,
            tulType: 'default',
            tulColor: 'success',
            isBanner: true,
            isClosable: true,
            withIcon: true,
          });
        });
      }
    });

    // Check if required fields are filled
    this.requiredColumns.forEach((column) => {
      column.error = '';
      const currentValue = this.data[column.attribute];
      switch (column.type) {
        case TypeColumnForms.NUMERIC:
        case TypeColumnForms.DATE:
          if (currentValue === undefined) {
            fieldsWithErrors += 1;
            column.error = 'requiredData';
          }
          break;
        case TypeColumnForms.SELECT:
          if (!currentValue) {
            fieldsWithErrors += 1;
            column.error = 'requiredData';
          }
          break;
        case TypeColumnForms.FILES:
        case TypeColumnForms.IMAGE:
          // check if file was modified and has at least one file to send
          if (currentValue !== undefined && !currentValue[0]) {
            fieldsWithErrors += 1;
            column.error = 'requiredData';
          }
          break;
        default:
          if (!currentValue || `${currentValue}`.trim() === '') {
            fieldsWithErrors += 1;
            column.error = 'requiredData';
          }
          break;
      }
    });

    return fieldsWithErrors > 0;
  }

  /**
   *
   * @param column
   * @param value
   */
  onChangeField(column: ColumnForms, value: any) {
    this.data[column.attribute] = value;
  }

  /**
   *
   * @param value
   * @param column
   */
  changeField(value: any, column: ColumnForms) {
    this.changesMap.set(column.attribute, value);
    if (column.changeField) {
      column.changeField({ data: this.changesMap, column, columns: this.validateColumns });
    }
  }

  castDocumentColumnForms(column: ColumnForms) {
    return column as DocumentColumnForms;
  }

  castCustomColumnForms(column: ColumnForms) {
    return column as CustomColumnForms;
  }

  castSelectColumnForms(column: ColumnForms) {
    return column as TypeSelectsForms;
  }
}
