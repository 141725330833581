import { Injectable } from '@angular/core';

/**
 * Edit multiple edit services
 */
@Injectable({
  providedIn: 'root',
})
export class EditMultipleService {
  /**
   * list of element check multi edit
   */
  listOfSelection = [
    {
      text: 'Seleccionar toda la fila',
      onSelect: () => {
        this.onAllChecked(true);
      },
    },
  ];
  /**
   * show/hidden multicheck
   */
  showChecked: boolean = false;
  /**
   *  checked all elements column
   */
  checked = false;
  /**
   * indeterminate checked
   */
  indeterminate = false;
  /**
   *  check data in current page
   */
  listOfCurrentPageData: ReadonlyArray<any> = [];
  /**
   * all ids checked
   */
  setOfCheckedId = new Set<number>();
  /**
   * all data checked
   */
  setOfCheckedData = new Map<number, any>();

  /**
   * change the value of the checked value
   */
  changeShowChecked() {
    this.showChecked = !this.showChecked;
    this.onAllChecked(false);
  }
  /**
   * check/uncheck all row
   * @param value true/false value to check/uncheck
   */
  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) => this.updateCheckedSet(item.uuid, value, item));
    this.refreshCheckedStatus();
  }

  /**
   * update status value check row
   */
  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every((item) => this.setOfCheckedId.has(item.uuid));
    this.indeterminate =
      this.listOfCurrentPageData.some((item) => this.setOfCheckedId.has(item.uuid)) &&
      !this.checked;
  }
  /**
   * add data or delete in map
   * @param id row
   * @param checked value (checked/unchecked)
   * @param data data to update
   */
  updateCheckedSet(id: number, checked: boolean, data?: any): void {
    if (checked) {
      this.setOfCheckedId.add(id);
      this.setOfCheckedData.set(id, data);
    } else {
      this.setOfCheckedId.delete(id);
      this.setOfCheckedData.delete(id);
    }
  }
  /**
   * open checked
   */
  openChecks() {
    this.showChecked = true;
  }
  /**
   * close checked
   */
  closeChecks() {
    this.showChecked = false;
    this.listOfCurrentPageData.forEach((item) => this.updateCheckedSet(item.uuid, false, item));
    this.refreshCheckedStatus();
  }
  /**
   * clean map
   */
  cleanChecks() {
    this.listOfCurrentPageData.forEach((item) => this.updateCheckedSet(item.uuid, false, item));
    this.refreshCheckedStatus();
  }
  /**
   * checked change emitter
   * @param id row
   * @param checked value (checked/unchecked)
   * @param data data to update
   */
  onItemChecked(id: number, checked: boolean, data: any): void {
    this.updateCheckedSet(id, checked, data);
    this.refreshCheckedStatus();
  }
  /**
   * Current page data change
   * @param $event values in array
   */
  onCurrentPageDataChange(event: any) {
    this.listOfCurrentPageData = event;
    this.refreshCheckedStatus();
  }
}
