import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { State, TulState } from '../../../../shared/types/state.type';
import { InputType, TulInputType } from '../../../types/input-type.type';

/**
 * noop
 */
function noop() {
  //noop ref
}

/**
 * Interface for communication with ngModel
 */
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TulInputCurrencyComponent),
  multi: true,
};

/**
 * Component for custom input
 */
@Component({
  selector: 'tul-input-currency',
  templateUrl: './tul-input-currency.component.html',
  styleUrls: ['./tul-input-currency.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TulInputCurrencyComponent implements ControlValueAccessor, OnInit {
  /**
   * Placeholder
   */
  @Input() tulPlaceholder: string = 'Placeholder';

  /**
   * Text in label
   */
  @Input() tulLabel: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Text under dropdown
   */
  @Input() tulAdditionalInfo: string = '';

  /**
   * Enum State for design
   */
  @Input() tulState: TulState = State.DEFAULT;

  /**
   * Icon left name
   */
  @Input() tulLeftIconName: string = '';

  /**
   * Icon right name
   */
  @Input() tulRightIconName: string = '';

  /**
   * Name
   */
  @Input() tulName: string = '';

  /**
   * Step
   */
  @Input() tulStep: Number = 1;

  /**
   * currency
   */
  @Input() tulCurrency: string = 'COP';

  /** */
  @Input() tulTooltipconfig!: any;
  /**
   * Inner Value
   */
  private innerValue: any = '';

  /**
   * Response on touched
   */
  private onTouchedCallback: () => void = noop;

  /**
   * Response on change value
   */
  private onChangeCallback: (_: any) => void = noop;

  /**
   * Get value ngModel
   */
  get value(): any {
    return this.innerValue;
  }

  /**
   * Set value ngModel
   */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  /**
   * Write value
   * @param value ngModel
   */
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  /**
   * Register on change
   * @param fn function
   */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /**
   * Register on touched
   * @param fn function
   */
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  castStringToNumber(s: any) {
    return Number(String(s).replace(/[^0-9.,-]+/g, ''));
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.onBlur({
        target: {
          value: this.value,
        },
      });
    }, 100);
  }

  convertToFloat(number: number, locale = 'en', currency = 'AUD') {
    const instance = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    });
    const roundedValue = instance
      .formatToParts(number)
      .filter((part) => !['currency', 'group'].includes(part.type))
      .reduce((acc, part) => `${acc}${part.value}`, '')
      .replace(/,/g, '.');
    // then just parse it as a float
    return [instance.format(number), '->', parseFloat(roundedValue)];
  }

  onFocus(e: { target: { value: string | number } }) {
    e.target.value = this.removeCharacters(e.target.value.toString());
  }

  onBlur(e: { target: { value: string } }) {
    const value = e.target.value;

    const options = {
      maximumFractionDigits: 3,
      currency: this.tulCurrency,
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
    };

    const newVal = value ? this.castStringToNumber(value).toLocaleString(undefined, options) : '';
    e.target.value = newVal;

    this.innerValue = newVal;
  }

  /**
   * Remove mask on input for edit
   * @param text
   * @returns
   */
  removeCharacters(text: string): string {
    return text.replace('$', '').replace(',', '').replace('.00', '');
  }

  handleKeyDown(evt: any) {
    if (['e', 'E', '+', '-'].includes(evt.key)) {
      evt.preventDefault();
    }
  }
}
