<tul-input-date-time
  [tulLabel]="(column.required ? '* ' : '') + column.header"
  [tulState]="column.error ? 'error' : 'default'"
  [tulPlaceholder]="column.placeholder ? column.placeholder : ''"
  [tulAdditionalInfo]="column.error ?? ''"
  [tulDisabled]="column.disabled ?? false"
  [(ngModel)]="dateModel"
  [tulMin]="minDate"
  [tulMax]="maxDate"
  (ngModelChange)="onChange.emit($event)"
  [tulTooltipconfig]="column.tooltip"
>
</tul-input-date-time>
