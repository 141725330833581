import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DecimalColumnForms } from '../../../../../interfaces/columns/numeric';

/**
 *
 */
@Component({
  selector: 'decimal-controller',
  templateUrl: './decimal-controller.component.html',
  styleUrls: ['./decimal-controller.component.scss'],
})
export class DecimalControllerComponent {
  /** value input */
  @Input() data: any;

  /** column */
  @Input() column!: DecimalColumnForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Method that use transformData and emit the changed data
   */
  changeData(value: any) {
    this.data = this.column.transformData
      ? this.column.transformData({ data: value, column: this.column })
      : value;

    this.onChange.emit(this.data);
  }
}
