<div class="tul-popup-container" [@enterAnimation] *ngIf="tulVisible">
  <div class="tul-popup-container__block" [ngStyle]="{ width: tulWidth }">
    <div class="tul-popup-container__close-icon-container">
      <i
        class="soytul soytul-close_outline tul-popup-container__close-icon"
        (click)="sendCancelResponse(2)"
      ></i>
    </div>
    <div class="tul-popup-container__content">
      <img
        [src]="staticFilesUrl + 'icons/svg/success.svg'"
        alt=""
        *ngIf="tulPopupType === 'success'"
      />
      <img [src]="staticFilesUrl + 'icons/svg/error.svg'" alt="" *ngIf="tulPopupType === 'error'" />
      <img
        [src]="staticFilesUrl + 'icons/svg/warning.svg'"
        alt=""
        *ngIf="tulPopupType === 'warning'"
      />
      <img [src]="staticFilesUrl + 'icons/svg/info.svg'" alt="" *ngIf="tulPopupType === 'info'" />
      <h4 class="tul-popup-container__title">{{ tulTitle }}</h4>
      <p class="tul-popup-container__description">{{ tulDescription }}</p>
      <div
        class="tul-popup-container__grid"
        [ngStyle]="{
          'grid-template-columns': tulVerticalButtons
            ? 'auto'
            : tulBtnCancelText
            ? 'repeat(2, minmax(0, 1fr))'
            : 'auto'
        }"
      >
        <div
          class="tul-popup-container__grid-item tul-popup-container--grid-left"
          *ngIf="tulBtnCancelText"
        >
          <small class="tul-popup-container__cancel-link" (click)="sendCancelResponse(0)">{{
            tulBtnCancelText | transloco
          }}</small>
        </div>
        <div class="tul-popup-container__grid-item">
          <tul-btn [tulText]="tulBtnOkText" (click)="sendCancelResponse(1)"></tul-btn>
        </div>
      </div>
    </div>
  </div>
</div>
