import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RadioColumn } from '../../../../../interfaces/columns/selectors';

@Component({
  selector: 'lib-radio',
  templateUrl: './radio.component.html',
})
export class RadioComponent {
  @Input() data: boolean | string = false;

  @Input() column!: RadioColumn;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  modelChange(valueSelected: string) {
    this.data = valueSelected;
    this.onChange.emit(this.data);
  }
}
