import { Component, Inject, Input } from '@angular/core';

/**
 * Component for custom loader
 */
@Component({
  selector: 'tul-loader',
  templateUrl: './tul-loader.component.html',
  styleUrls: ['./tul-loader.component.scss'],
})
export class TulLoaderComponent {
  /**
   * Environments for static style url
   */
  environment: any;

  /**
   * Show loader
   */
  @Input() isLoading: boolean = false;

  constructor(@Inject('ENVIRONMENT') ENVIRONMENT: any) {
    this.environment = ENVIRONMENT;
  }
}
