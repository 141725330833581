import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeDatesForms } from '../../../../interfaces/columns/date';
import { Data } from '../../../../interfaces/data';

/**
 *
 */
@Component({
  selector: 'date-controller',
  templateUrl: './date-controller.component.html',
})
export class DateControllerComponent {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TypeDatesForms;

  /** */
  @Input() validateFieldFlag: boolean = false;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
}
