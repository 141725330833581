<div class="tul-input-container">
  <label
    for="tul-switch"
    class="tul-input-container__label"
    *ngIf="tulLabel"
    [ngClass]="{
      'tul-input-container__label--alert-error': tulState === 'error',
      'tul-input-container__label--alert-success': tulState === 'success'
    }"
    >{{ tulLabel | transloco }}
    &nbsp;
    <i
      *ngIf="tulTooltipconfig"
      class="tul-input-container__hint soytul soytul-info_filled"
      [tulTooltip]="tulTooltipconfig.title"
      [tulTooltipConfig]="tulTooltipconfig"
    ></i>
  </label>

  <div class="tul-upload">
    <div class="file-list" *ngIf="tulFileList.length > 0">Archivos:</div>
    <div class="file-list" *ngFor="let file of tulFileList">{{ file.name }}</div>
    <br *ngIf="tulFileList.length > 0" />
    <input
      type="file"
      class="file-input"
      (change)="onFileSelected($event)"
      #fileUpload
      [multiple]="tulMultiple"
    />

    <div class="file-upload" (click)="fileUpload.click()">
      <i class="soytul soytul-data_sheet_filled file-upload__icon"></i>
      <div class="file-upload__text">{{ "upload" | transloco }}</div>
    </div>
  </div>

  <small
    class="tul-input-container__small"
    *ngIf="tulAdditionalInfo"
    [ngClass]="{
      'tul-input-container__small--alert-error': tulState === 'error',
      'tul-input-container__small--alert-success': tulState === 'success'
    }"
    >{{ tulAdditionalInfo | transloco }}
  </small>
</div>
