import { Component, Input } from '@angular/core';
import { TypeNumericsForms } from '../../../../interfaces/columns/numeric';
import { Data } from '../../../../interfaces/data';

/**
 *
 */
@Component({
  selector: 'numeric-controller',
  templateUrl: './numeric-controller.component.html',
})
export class NumericControllerComponent {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TypeNumericsForms;
}
