import { Component, Input } from '@angular/core';
import { SubTypeSelectorsColumnTable } from '../../../../enums/columns/SubTypeSelectorsColumnTable.enum';
import { TypeSelector } from './../../../../interfaces/columns/selectors';

/**
 * Body option selectors
 */
@Component({
  selector: '[selectors]',
  templateUrl: './selectors.component.html',
})
export class SelectorsComponent {
  /**
   * subType Column with default
   */
  subType!: string;

  /**
   * subType Column
   */
  @Input('subType')

  /**
   * Set default column
   */
  set SubType(subType: string) {
    this.subType = subType || SubTypeSelectorsColumnTable.CHECK;
  }

  /**
   * Actual Column
   */
  @Input() column!: TypeSelector;
  /**
   * Data row
   */
  @Input() data: any;
}
