import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeDatesForms } from '../../../../../interfaces/columns/date';
import { Data } from '../../../../../interfaces/data';

/**
 *
 */
@Component({
  selector: 'year-controller',
  templateUrl: './year-controller.component.html',
  styleUrls: ['./year-controller.component.scss'],
})
export class YearControllerComponent implements OnInit {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TypeDatesForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   *
   * @param formsService
   */
  constructor() {}

  /**
   *
   */
  ngOnInit(): void {}

  showItemSelected($ev: any) {
    this.onChange.emit($ev.value);
  }
}
