export enum TypeColumnForms {
  TEXT = 'text',
  NUMERIC = 'numeric',
  DATE = 'date',
  SELECT = 'select',
  SELECTORS = 'selectors',
  COLOR = 'color',
  DOCUMENT = 'document',
  FILES = 'files',
  TEXT_EDITOR = 'text_editor',
  IMAGE = 'image',
  CUSTOM = 'custom',
}

export enum TypeState {
  ADD = 'add',
  EDIT = 'edit',
  DETAILS = 'details',
}
export enum TypeForm {
  GRAPHQL = 'graphql',
  MICROSERVICES = 'microservices',
  LIST = 'list',
}

export enum TypeLinkForms {
  INTERNAL,
  EXTERNAL,
}
