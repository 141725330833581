import { Component, Input } from '@angular/core';
import { SubTypeSelectColumnTable } from '../../../../enums/columns/SubTypeSelectColumnTable.enum';
import { TypeSelectsTable } from './../../../../interfaces/columns/select';

/**
 * Body option Select
 */
@Component({
  selector: '[select]',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  /**
   * subType Column with default
   */
  subType!: string;

  /**
   * subType Column
   */
  @Input('subType')

  /**
   * Set default column
   */
  set SubType(subType: string) {
    this.subType = subType || SubTypeSelectColumnTable.LIST;
  }

  /**
   * Actual Column
   */
  @Input() column!: TypeSelectsTable | any;

  /**
   * Data row
   */
  @Input() data: any;
}
