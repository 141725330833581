import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TulInputModule } from '../../../../../../tul-input/tul-input.module';
import { DefaultControllerComponent } from './default-controller.component';

@NgModule({
  declarations: [DefaultControllerComponent],
  imports: [CommonModule, FormsModule, TulInputModule],
  exports: [DefaultControllerComponent],
})
export class DefaultControllerModule {}
