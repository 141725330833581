<button
  nz-button
  class="button"
  nzType="default"
  [nzTooltipTitle]="option | tulGetToolTipPipe : data"
  nzTooltipPlacement="top"
  (click)="executeOption()"
  nz-tooltip
>
  <i
    *ngIf="!isOpen"
    class="button__icon"
    nz-icon
    nzType="{{ option.icon?.open ? option.icon?.open : 'expand' }}"
    nzTheme="outline"
  ></i>
  <i
    *ngIf="isOpen"
    class="button__icon"
    nz-icon
    nzType="{{ option.icon?.open ? option.icon?.close : 'expand' }}"
    nzTheme="outline"
  ></i>
</button>
