import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateComponent } from './date.component';
import { FormsModule } from '@angular/forms';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { TranslocoModule } from '@ngneat/transloco';
import { TulDatePipeModule } from '../../../../../pipes';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  exports: [DateComponent],
  imports: [
    CommonModule,
    FormsModule,
    TulDatePipeModule,
    NzPipesModule,
    TulDataTablePipeModule,
    TranslocoModule,
  ],
  declarations: [DateComponent],
})
export class DateModule {}
