import { Component } from '@angular/core';

/**
 * svg import
 */
@Component({
  selector: 'svg-import',
  templateUrl: './svg-import.component.html',
  styleUrls: ['./svg-import.component.scss'],
})
export class SvgImportComponent {}
