import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { TypeHeaderOptions, TypesMultiEdit } from '../enums/export';
import { TypeExecute } from '../enums/typeTable.enum';
import { ColumnTable } from '../interfaces/columns/column';
import { TypeTables } from '../interfaces/table';
import { EditMultipleService } from '../services/editMultiple.service';
import { SubjectsService } from '../services/subjects.service';
/**
 * table public component
 */
@Component({
  selector: 'lib-tul-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [SubjectsService],
})
export class TableComponent implements OnInit, OnDestroy {
  /**
   * Array of columns
   */
  @Input() columns: Array<ColumnTable> = [];
  /**
   * Array of data
   */
  @Input() data: Array<any> = [];
  /**
   * type of table
   */
  @Input() table!: TypeTables;
  /**
   * loader
   */
  @Input() loading!: boolean;

  /**
   * Emitter to execute option
   */
  @Output() executeOption = new EventEmitter<any>();
  /**
   *  Emitter of change data
   */
  @Output() changeData = new EventEmitter<any>();

  /**
   * Emitter of change filter
   */
  @Output() executeOptionFilter = new EventEmitter<any>();

  /**
   * Selected row when the same is clicked
   */
  @Output() rowClicked = new EventEmitter<any>();

  /**
   *  subscriptor external emitters
   */
  suscriptionAction = new Subscription();

  /**
   * constructor
   * @param subjectsService internal components emitters
   * @param editMultipleService edit multiple services
   */
  constructor(
    private subjectsService: SubjectsService,
    private editMultipleService: EditMultipleService
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    if (this.table?.actionSubscribe) {
      this.suscriptionAction = this.table.actionSubscribe().subscribe(({ option, tables = [] }) => {
        if (tables?.length === 0 || tables.includes(this.table.idTable)) {
          switch (option) {
            case TypeHeaderOptions.REFRESH:
              this.refresh();
              return;
            case TypesMultiEdit.CLEANCHECKS:
              this.resetDataMultiEdit();
          }
        }
      });
    }
  }

  /**
   * execute option
   * @param event  data emitters
   */
  executeOptionFunction(event: any) {
    this.subjectsService.emitExternalEvents(event);
    this.executeOption.emit(event);
  }

  /**
   * Execute filter emitter
   * @param event filter data column and event
   */
  executeFilter(event: any) {
    this.executeOptionFilter.emit(event);
  }

  /**
   * Execute when the row is clicked
   * @param event filter data column and event
   */
  executeRowClick(event: any) {
    this.rowClicked.emit(event);
  }

  /**
   * table events
   * @param event data emitters
   */
  tableEvents(event: any) {
    this.subjectsService.tableEmitterEvents(event);
    this.changeData.emit(event);
  }

  /**
   * Refresh table
   */
  refresh() {
    this.subjectsService.emitExternalEvents({
      option: TypeHeaderOptions.REFRESH,
      type: TypeExecute.EXTERNAL,
    });
  }

  /**
   * Reset multi edit
   */
  resetDataMultiEdit() {
    this.subjectsService.emitExternalEvents({
      option: TypesMultiEdit.CLEANCHECKS,
      type: TypeExecute.EXTERNAL,
    });
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.suscriptionAction.unsubscribe();
  }
}
