import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnTable } from '../../../../interfaces/columns/column';
import { TypeTextsTable } from '../../../../interfaces/columns/text';

/**
 * filter option selectors
 */
@Component({
  selector: 'text-filter',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent {
  /**
   * Actual Column
   */
  @Input() column!: TypeTextsTable;
  /**
   * Filters map
   */
  @Input() filter!: Map<string, any>;
  /**
   * Emitter change value filter
   */
  @Output() changeValueFilter = new EventEmitter<string>();
  /**
   * Value filter
   */
  valueFilterInput: any;

  /**
   * Change filter value
   * @param event value of filter
   */
  ngModelChange(event: any) {
    this.valueFilterInput = event;
    this.changeValueFilter.emit(event);
  }

  /**
   * get value filter
   * @param column selected column
   * @return value to selected column
   */
  getValueFilter(column: ColumnTable) {
    return this.filter.get(column?.filter?.attribute ?? column.attribute)?.value;
  }
}
