import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectControllerComponent } from './select-controller.component';
import { TulGetDataSelectPipeModule } from '../../../../../pipes/getDataSelect/tul-get-data-select-pipe.module';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';
import { TulLabelModule } from '../../../../../tul-label/tul-label.module';

@NgModule({
  imports: [CommonModule, TulGetDataSelectPipeModule, TulDataTablePipeModule, TulLabelModule],
  declarations: [SelectControllerComponent],
  exports: [SelectControllerComponent],
})
export class SelectControllerModule {}
