import { NgModule } from '@angular/core';
import { TulSteppersComponent } from './components/tul-steppers/tul-steppers.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TulSteppersComponent],
  imports: [SharedModule],
  exports: [TulSteppersComponent],
})
export class TulSteppersModule {}
