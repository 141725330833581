<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'datetime'">
      <date-time-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></date-time-controller>
    </ng-container>
    <ng-container *ngSwitchCase="'time'">
      <time-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></time-controller>
    </ng-container>
    <ng-container *ngSwitchCase="'month'">
      <month-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></month-controller>
    </ng-container>
    <ng-container *ngSwitchCase="'year'">
      <year-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></year-controller>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <date-default-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></date-default-controller>
    </ng-container>
  </ng-container>
</ng-container>
