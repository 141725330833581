import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { CustomModule } from './custom/custom.module';
import { CheckStatusModule } from './check-status/check-status.module';
import { DeleteModule } from './delete/delete.module';
import { DetailsModule } from './details/details.module';
import { EditModule } from './edit/edit.module';
import { OptionsComponent } from './options.component';
import { SelectStatusModule } from './select-status/select-status.module';
import { ExpandModule } from './expand/expand.module';
import { TulDataTablePipeModule } from '../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  exports: [OptionsComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzPopconfirmModule,
    TulDataTablePipeModule,
    SelectStatusModule,
    EditModule,
    ExpandModule,
    DetailsModule,
    DeleteModule,
    CheckStatusModule,
    CustomModule,
  ],
  declarations: [OptionsComponent],
})
export class OptionsModule {}
