import { Component } from '@angular/core';

/**
 * svg search
 */
@Component({
  selector: 'svg-search',
  templateUrl: './svg-search.component.html',
  styleUrls: ['./svg-search.component.scss'],
})
export class SvgSearchComponent {}
