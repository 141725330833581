import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { TulButtonComponent } from './components/tul-btn/tul-btn.component';
import { TulBtnQuickActionsComponent } from './components/tul-btn-quick-actions/tul-btn-quick-actions.component';
import { TulBtnCardComponent } from './components/tul-btn-card/tul-btn-card.component';

@NgModule({
  declarations: [TulButtonComponent, TulBtnQuickActionsComponent, TulBtnCardComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [TulButtonComponent, TulBtnQuickActionsComponent, TulBtnCardComponent],
})
export class TulButtonModule {
  public static forRoot(environment: any): ModuleWithProviders<TulButtonModule> {
    return {
      ngModule: TulButtonModule,
      providers: [
        {
          provide: 'ENVIRONMENT',
          useValue: environment,
        },
      ],
    };
  }
}
