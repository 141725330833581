export enum TulButtonColorEnum {
  PRIMARYA = 'primary-a',
  PRIMARYB = 'primary-b',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  CONTRAST = 'contrast',
  DANGER = 'danger',
  OUTLINEDANGER = 'outline-danger',
}

export type TulButtonColor = `${TulButtonColorEnum}`;
