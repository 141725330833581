import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateControllerComponent } from './date-controller.component';
import { DateDefaultControllerModule } from './date-default-controller/date-default-controller.module';
import { DateTimeControllerModule } from './date-time-controller/date-time-controller.module';
import { MonthControllerModule } from './month-controller/month-controller.module';
import { TimeControllerModule } from './time-controller/time-controller.module';
import { YearControllerModule } from './year-controller/year-controller.module';

@NgModule({
  declarations: [DateControllerComponent],
  imports: [
    CommonModule,
    FormsModule,
    DateDefaultControllerModule,
    DateTimeControllerModule,
    TimeControllerModule,
    MonthControllerModule,
    YearControllerModule,
  ],
  exports: [DateControllerComponent],
})
export class DateControllerModule {}
