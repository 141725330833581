import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { FormsModule } from '@angular/forms';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { TulGetDataSelectPipeModule } from '../../../../../pipes/getDataSelect/tul-get-data-select-pipe.module';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';
import { SelectEntityModule } from './select-entity/select-entity.module';
import { SelectTreeEntityModule } from './select-tree-entity/select-tree-entity.module';
import { SelectListModule } from './select-list/select-list.module';

@NgModule({
  exports: [SelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzPipesModule,
    TulGetDataSelectPipeModule,
    TulDataTablePipeModule,
    SelectEntityModule,
    SelectTreeEntityModule,
    SelectListModule,
  ],
  declarations: [SelectComponent],
})
export class SelectModule {}
