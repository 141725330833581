import { Inject, Injectable } from '@angular/core';

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class NumericService {
  /** */
  environment: any;

  /** decimalsSeparator variable */
  public decimalsSeparator: any;

  /** milesSeparator variable */
  public milesSeparator: any;

  /** milesSeparator variable */
  public numDecimals: number = 2;

  /**
   *
   * @param environment
   */
  constructor(@Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
    this.setSeparators();
  }

  /**
   * set the separators by country
   * @returns
   */
  setSeparators() {
    const countryCode = this.environment.country.code;

    switch (countryCode) {
      case 'MX':
        this.milesSeparator = ' ';
        this.decimalsSeparator = '.';
        break;
      case 'BR':
        this.milesSeparator = '.';
        this.decimalsSeparator = ',';
        break;
      case 'CO':
      default:
        this.milesSeparator = '.';
        this.decimalsSeparator = ',';
        break;
    }
  }

  /**
   * Clear the decimal value (accordig to separator of the country)
   * @param value
   * @returns
   */
  uniqueFix = (value: string | number, modelValue: string | number) => {
    const { decimalsSeparator } = this;

    let valueAux = `${value}`;
    const number = parseFloat(valueAux);
    let numberParts;

    // Check if is a number with decimals
    if (number) {
      numberParts = value.toString().split('.');
      valueAux =
        numberParts.length > 1
          ? `${numberParts[0]}${decimalsSeparator}${numberParts[1]}`
          : valueAux;
    }

    if (decimalsSeparator === ',')
      valueAux = valueAux.replace(/[^0-9,-]/g, '').replace(/,+/g, decimalsSeparator);
    else if (decimalsSeparator === '.')
      valueAux = valueAux.replace(/[^0-9.-]/g, '').replace(/\.+/g, decimalsSeparator);
    valueAux = valueAux.replace(/-+/g, '-');

    const decimalParts = valueAux.split(decimalsSeparator);

    if (decimalParts.length && decimalParts[1]) {
      valueAux = `${decimalParts[0]}.${decimalParts[1]}`;
    } else if (valueAux.includes(decimalsSeparator)) {
      modelValue = `${decimalParts[0]}${decimalsSeparator}`;
    }

    return { valueAux, modelValue };
  };

  /**
   * Format value (accordig to separators of the country)
   * @param value
   * @param inBlur
   * @returns
   */
  uniqueFormat = (value: string | number, inBlur: boolean = false) => {
    const { milesSeparator, decimalsSeparator } = this;

    const decimalParts = value.toString().split('.');

    if (inBlur) {
      const integerParts = [decimalParts[0]];
      const regexp = /(\d+)(\d{3})/;

      while (regexp.test(integerParts[0])) {
        integerParts[0] = integerParts[0].replace(regexp, `$1${milesSeparator}$2`);
      }
      decimalParts[0] = `${integerParts.join(milesSeparator)}`;
    }

    if (decimalParts.length && decimalParts[1])
      value = `${decimalParts[0]}${decimalsSeparator}${decimalParts[1]}`;
    else if (decimalParts.length > 1 && decimalsSeparator === '.')
      value = `${decimalParts[0]}${decimalsSeparator}`;
    else value = `${decimalParts[0]}`;

    return value;
  };
}
