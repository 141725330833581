export class TulTooltipConfig {
  title?: string;
  icon?: string;
  position?: TulTooltipPosition;
  type?: TulTooltipType;
}

export enum TulTooltipPosition {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}
export enum TulTooltipType {
  LIGHT = 'light',
  DARK = 'dark',
}
