import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { FormsComponent } from './forms.component';
import { DetailsModule } from './details/details.module';
import { HeaderModule } from './header/header.module';
import { ModificationModule } from './modification/modification.module';
import { HeaderComponent } from './header/header.component';

import es from '@angular/common/locales/es';
import pt from '@angular/common/locales/pt';
import { TranslocoRootModule } from '../../tul-table/components/transloco-root.module';

registerLocaleData(es);
registerLocaleData(pt);
@NgModule({
  declarations: [FormsComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HeaderModule,
    ModificationModule,
    DetailsModule,
    NzSpinModule,
    NzMessageModule,
    TranslocoRootModule,
  ],
  exports: [FormsComponent, HeaderComponent],
})
export class LibFormsModule {
  public static forRoot(environment: any): ModuleWithProviders<LibFormsModule> {
    return {
      ngModule: LibFormsModule,
      providers: [
        {
          provide: 'ENVIRONMENT',
          useValue: environment,
        },
      ],
    };
  }
}
