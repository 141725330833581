import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { StatuExecuteOption } from '../../../enums/export';
import { TypesMultiEdit } from '../../../enums/typeOption.enum';
import { MultiEditOption } from '../../../interfaces/export';

/**
 * Multiple edit option header
 */
@Component({
  selector: 'app-multiple-edit',
  templateUrl: './multiple-edit.component.html',
  styleUrls: ['./multiple-edit.component.scss'],
})
export class MultiEditComponent {
  /**
   * actual option (MULTIPLE EDIT)
   */
  @Input() option!: MultiEditOption | any;

  /**
   * Emitter to execute option
   */
  @Output() executeOption = new EventEmitter<any>();

  /**
   * Status of multiple edit
   */
  isMultiEdit = false;

  /**
   * Constructor
   */
  constructor(private translocoService: TranslocoService) {}

  /**
   * Close Checks and edit multiple option
   */
  closeChecks() {
    this.isMultiEdit = false;
    this.executeOption.emit({
      option: TypesMultiEdit.CLOSECHECKS,
      status: StatuExecuteOption.SUCCESS,
    });
  }

  /**
   * Open Checks and edit multiple option
   */
  OpenChecks() {
    this.isMultiEdit = true;
    this.executeOption.emit({
      option: TypesMultiEdit.OPENCHECKS,
      status: StatuExecuteOption.SUCCESS,
    });
  }

  /**
   * Execute multiple edit
   */
  sendDataMultiEdit() {
    this.executeOption.emit({
      option: TypesMultiEdit.DATACHECKS,
      status: StatuExecuteOption.SUCCESS,
    });
  }

  /**
   * Generate tooltip in multiple edit option
   * @param option: actual option
   * @return string tooltip
   */
  getToolTip(option: any) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return this.translocoService.translate('tulTable.options.multipleEditing.tooltip');
    }
    return typeof option?.tooltip?.title === 'string'
      ? option.tooltip.title
      : option?.tooltip?.title({ data: {}, option });
  }
}
