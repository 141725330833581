<ng-container *ngIf="column.link && getLink(); else contentTemplate">
  <ng-container [ngSwitch]="column.link.type">
    <ng-container *ngSwitchCase="'external'">
      <a [href]="getLink()" [target]="column.link.target ? column.link.target : '_self'">
        <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
      </a>
    </ng-container>

    <!--DEFAULT -->
    <ng-container *ngSwitchDefault>
      <a [routerLink]="getLink()" [target]="column.link.target ? column.link.target : '_self'">
        <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
      </a>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #contentTemplate>
  <!--CUSTOM VIEW DATA-->
  <ng-container *ngIf="column.customViewData; else contentTemplateType">
    <span>{{ customViewData() | nzSafeNull : (column.safeNull ? column.safeNull : "-") }}</span>
  </ng-container>
  <ng-template #contentTemplateType>
    <ng-container [ngSwitch]="column.type">
      <!-- DATE -->
      <ng-container *ngSwitchCase="'date'">
        <span date [data]="data" [column]="column" [subType]="getSubType(column)"></span>
      </ng-container>
      <!-- SELECT -->
      <ng-container *ngSwitchCase="'select'">
        <span select [data]="data" [column]="column" [subType]="getSubType(column)"></span>
      </ng-container>
      <!-- TEXT -->
      <ng-container *ngSwitchCase="'text'">
        <span text [data]="data" [column]="column" [subType]="getSubType(column)"></span>
      </ng-container>
      <!-- NUMERIC -->
      <ng-container *ngSwitchCase="'numeric'">
        <span
          numeric
          [data]="data"
          [column]="column"
          [subType]="getSubType(column)"
          [country]="country"
        ></span>
      </ng-container>
      <!-- NUMERIC MODIFIER -->
      <ng-container *ngSwitchCase="'numeric-modifier'">
        <span
          numeric-modifier
          [data]="data"
          [column]="column"
          [subType]="getSubType(column)"
          [country]="country"
        ></span>
      </ng-container>
      <!-- SELECTORS -->
      <ng-container *ngSwitchCase="'selectors'">
        <span selectors [data]="data" [column]="column" [subType]="getSubType(column)"></span>
      </ng-container>
      <!-- CUSTOM -->
      <ng-container *ngSwitchCase="'custom'">
        <span custom [data]="data" [column]="column"></span>
      </ng-container>
      <!-- COLOR -->
      <ng-container *ngSwitchCase="'color'">
        <span color [data]="data" [column]="column"></span>
      </ng-container>
      <!-- IMAGE -->
      <ng-container *ngSwitchCase="'image'">
        <span image [data]="data" [column]="column"></span>
      </ng-container>
      <!-- DOCUMENT -->
      <ng-container *ngSwitchCase="'document'">
        <span document [data]="data" [column]="column"></span>
      </ng-container>
      <!--DEFAULT -->
      <ng-container *ngSwitchDefault>
        <span text [data]="data" [column]="column"></span>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-template>
