import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RegexTextColumn, TypeTextsForms } from '../../../../interfaces/columns/text';

/**
 *
 */
@Component({
  selector: 'text-controller',
  templateUrl: './text-controller.component.html',
})
export class TextControllerComponent {
  /** */
  @Input() data = '';

  /** */
  @Input() column!: TypeTextsForms;

  /** */
  @Input() validateFieldFlag: boolean = false;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  getRegexColumn(column: any) {
    return column as RegexTextColumn;
  }
}
