import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgDeleteComponent } from './svg-delete.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  exports: [SvgDeleteComponent],
  imports: [CommonModule, FormsModule],
  declarations: [SvgDeleteComponent],
})
export class SvgDeleteModule {}
