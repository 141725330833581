<nz-spin [nzSpinning]="loading">
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'entity'">
      <tul-select-entity-select
        [tulLabel]="(column.required ? '* ' : '') + column.header"
        [tulState]="column.error ? 'error' : 'default'"
        [tulAdditionalInfo]="column.error ?? ''"
        [tulDisabled]="column.disabled ?? false"
        [(ngModel)]="currentValue"
        [tulPlaceholder]="column.placeholder ?? 'Selecciona una opción'"
        [tulHttpSubscribe]="currentData"
        (tulItemSelected)="showItemSelected($event)"
        [tulTooltipconfig]="column.tooltip"
      >
      </tul-select-entity-select>
    </ng-container>
    <ng-container *ngSwitchCase="'tree-entity'">
      <ng-container *ngIf="dataTree; else elseTemplateSelectTreeFilter">
        <nz-tree-select
          class="input-default"
          [nzNodes]="dataTree"
          [nzAsyncData]="true"
          nzShowSearch
          [nzDropdownStyle]="{ height: '30vh' }"
          [nzPlaceHolder]="
            column?.filter?.placeholder
              ? column.filter.placeholder
              : ('tulTable.filters.select.treeEntity.placeholder.default' | transloco)
          "
          [ngModel]="getValueFilter(column)"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="ngModelChange($event)"
        >
        </nz-tree-select>
      </ng-container>
      <ng-template #elseTemplateSelectTreeFilter>
        <nz-spin nzSimple style="text-align: center; margin: 14px 0px"></nz-spin>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <nz-select
        class="input-default"
        [ngModel]="getValueFilter(column)"
        nzAllowClear
        [nzPlaceHolder]="
          !column.filter
            ? ''
            : column.filter.placeholder
            ? column.filter.placeholder
            : ('tulTable.filters.select.default.placeholder.default' | transloco)
        "
        (ngModelChange)="ngModelChange($event)"
      >
        <nz-option
          *ngFor="let item of column.listValues"
          [nzValue]="item.value"
          [nzLabel]="item.text"
        ></nz-option>
      </nz-select>
    </ng-container>
  </ng-container>
</nz-spin>
