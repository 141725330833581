import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  ListColumnTable,
  SelectTag,
  TypeSelectsTable,
} from '../../../../../interfaces/columns/select';

@Component({
  selector: 'select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
})
export class SelectListComponent implements OnInit {
  /**
   * Environments for static style url
   */
  environment: any;

  /**
   * Actual Column
   */
  @Input() column!: TypeSelectsTable | any;

  /**
   * Data row
   */
  @Input() data: any;

  currentTag!: SelectTag | undefined;

  constructor(@Inject('ENVIRONMENT') ENVIRONMENT: any) {
    this.environment = ENVIRONMENT;
  }

  ngOnInit(): void {
    this.getCurrentTag();
  }

  getCurrentTag() {
    const index = this.column?.listValues?.findIndex(
      (item: any) => item.value === this.data[this.column.attribute]
    );
    if (index !== -1) {
      this.currentTag = (this.column as ListColumnTable)?.listValues[index].tag;
    }
  }

  /**
   * return color of item or null
   */
  getBackgroundColor(data: any) {
    const auxElement = (this.column as ListColumnTable).listValues.find(
      (element) => element.value === data
    );
    return (this.column as ListColumnTable).hasBackgroundColor && auxElement?.color
      ? auxElement.color
      : null;
  }
}
