export enum TypeColumnTable {
  TEXT = 'text',
  NUMERIC = 'numeric',
  DATE = 'date',
  SELECT = 'select',
  SELECTORS = 'selectors',
  CUSTOM = 'custom',
  IMAGE = 'image',
  COLOR = 'color',
  DOCUMENT = 'document',
  NUMERICMODIFIER = 'numeric-modifier',
}
