<ng-container *ngIf="option.template">
  <div [nzTooltipTitle]="getToolTip(option)" nzTooltipPlacement="top" nz-tooltip>
    <ng-container
      [ngTemplateOutlet]="getCustomTemplate(option)"
      [ngTemplateOutletContext]="{
        $implicit: { option: option, disabled: option.disabled | tulToAsyncPipe | async }
      }"
    >
      <!--  -->
    </ng-container>
  </div>
</ng-container>
