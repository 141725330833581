import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TulSelectEntitySelectComponent } from './components/select-entity/tul-select-entity-select/tul-select-entity-select.component';
import { TulSelectEntityMultiSelectComponent } from './components/select-entity/tul-select-entity-multi-select/tul-select-entity-multi-select.component';
import { TulSelectListSelectComponent } from './components/select-list/tul-select-list-select/tul-select-list-select.component';
import { TulSelectListMultiSelectComponent } from './components/select-list/tul-select-list-multi-select/tul-select-list-multi-select.component';
import { TulSelectTreeEntitySelectComponent } from './components/select-tree-entity/tul-select-tree-entity-select/tul-select-tree-entity-select.component';
import { TulSelectTreeEntityMultiSelectComponent } from './components/select-tree-entity/tul-select-tree-entity-multi-select/tul-select-tree-entity-multi-select.component';
import { TulSelectTreeListSelectComponent } from './components/select-tree-list/tul-select-tree-list-select/tul-select-tree-list-select.component';
import { TulSelectTreeListMultiSelectComponent } from './components/select-tree-list/tul-select-tree-list-multi-select/tul-select-tree-list-multi-select.component';
import { SharedModule } from '../shared/shared.module';
import { TulTagModule } from '../tul-tag/tul-tag.module';
import { TulSelectMonthComponent } from './components/select-list/tul-select-month/tul-select-month.component';
import { TulSelectYearComponent } from './components/select-list/tul-select-year/tul-select-year.component';

@NgModule({
  declarations: [
    TulSelectEntitySelectComponent,
    TulSelectEntityMultiSelectComponent,
    TulSelectListSelectComponent,
    TulSelectListMultiSelectComponent,
    TulSelectTreeEntitySelectComponent,
    TulSelectTreeEntityMultiSelectComponent,
    TulSelectTreeListSelectComponent,
    TulSelectTreeListMultiSelectComponent,
    TulSelectMonthComponent,
    TulSelectYearComponent,
  ],
  imports: [SharedModule, TulTagModule],
  exports: [
    TulSelectEntitySelectComponent,
    TulSelectEntityMultiSelectComponent,
    TulSelectListSelectComponent,
    TulSelectListMultiSelectComponent,
    TulSelectTreeEntitySelectComponent,
    TulSelectTreeEntityMultiSelectComponent,
    TulSelectTreeListSelectComponent,
    TulSelectTreeListMultiSelectComponent,
    TulSelectMonthComponent,
    TulSelectYearComponent,
  ],
})
export class TulSelectModule {}
