<div class="tul-tag" [ngClass]="['chip-' + tulTagColor]" *ngIf="!hideTag">
  <div #tulContainerItem class="tul-tag__item" *ngIf="showItemContainer">
    <ng-content select="[tul-item]"></ng-content>
  </div>
  <p class="tul-tag__font">{{ tulText | transloco }}</p>
  <i
    class="soytul soytul-close_outline tul-tag__close-icon"
    *ngIf="isClosable"
    (click)="closeTag()"
  ></i>
</div>
