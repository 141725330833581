<div class="container">
  <app-header
    [title]="table.title"
    [options]="table.optionsHeader"
    (executeOption)="executeOptionFunction($event)"
    [loading]="loading"
  ></app-header>
  <app-table
    [data]="data"
    [columns]="columns"
    [table]="table"
    (executeOption)="executeOptionFunction($event)"
    (executeOptionFilter)="executeFilter($event)"
    (tableEvents)="tableEvents($event)"
    (rowClicked)="executeRowClick($event)"
    [loading]="loading"
  ></app-table>
  <app-footer></app-footer>
</div>
