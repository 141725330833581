import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { DateModule } from './date/date.module';
import { FiltersComponent } from './filters.component';
import { NumericModule } from './numeric/numeric.module';
import { SelectModule } from './select/select.module';
import { SelectorsModule } from './selectors/selectors.module';
import { TextModule } from './text/text.module';

@NgModule({
  exports: [FiltersComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    TextModule,
    DateModule,
    NumericModule,
    SelectModule,
    SelectorsModule,
    NzSpinModule,
    TranslocoModule,
  ],
  declarations: [FiltersComponent],
})
export class FiltersModule {}
