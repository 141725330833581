<div class="details-groups" *ngFor="let group of groups">
  <nz-descriptions
    class="details-descriptions"
    [nzTitle]="group.name"
    nzBordered
    [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
  >
    <ng-container *ngFor="let groupColumns of mapGroups.get(group.number + '') | keyvalue">
      <ng-container *ngFor="let column of getGroup(groupColumns)">
        <nz-descriptions-item class="details-description" [nzTitle]="column.header">
          <ng-container
            *ngIf="column.link && !column.link.disabled && getLink(column); else contentTemplate"
          >
            <ng-container [ngSwitch]="column.link.type">
              <ng-container *ngSwitchCase="typeLink.EXTERNAL">
                <a [href]="getLink(column)" target="_blank" rel="noopener">
                  <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
                </a>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <a [href]="getLink(column)" target="_self">
                  <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
                </a>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #contentTemplate>
            <ng-container [ngSwitch]="column.type">
              <!-- DATE -->
              <ng-container *ngSwitchCase="'date'">
                <date-controller [data]="data" [column]="column"></date-controller>
              </ng-container>
              <!-- SELECT -->
              <ng-container *ngSwitchCase="'select'">
                <select-controller [data]="data" [column]="column"></select-controller>
              </ng-container>
              <!-- TEXT -->
              <ng-container *ngSwitchCase="'text'">
                <text-controller [data]="data" [column]="column"></text-controller>
              </ng-container>
              <!-- NUMERIC -->
              <ng-container *ngSwitchCase="'numeric'">
                <numeric-controller [data]="data" [column]="column"></numeric-controller>
              </ng-container>
              <!-- SELECTORS -->
              <ng-container *ngSwitchCase="'selectors'">
                <selectors-controller [data]="data" [column]="column"></selectors-controller>
              </ng-container>
              <!-- CUSTOM -->
              <ng-container *ngSwitchCase="'custom'">
                <custom-controller [data]="data" [column]="column"></custom-controller>
              </ng-container>
              <!-- COLOR -->
              <ng-container *ngSwitchCase="'color'">
                <color-controller [data]="data" [column]="column"></color-controller>
              </ng-container>
              <!-- DOCUMENT -->
              <ng-container *ngSwitchCase="'document'">
                <document-controller [data]="data" [column]="column"></document-controller>
              </ng-container>
              <!-- FILE -->
              <ng-container *ngSwitchCase="'files'">
                <files-controller [data]="data" [column]="column"></files-controller>
              </ng-container>
              <!--DEFAULT -->
              <ng-container *ngSwitchDefault>
                {{ data | tulDataTablePipe : column.attribute }}
              </ng-container>
            </ng-container>
          </ng-template>
        </nz-descriptions-item>
      </ng-container>
    </ng-container>
  </nz-descriptions>
</div>
