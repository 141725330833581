import { PipeTransform, Pipe } from '@angular/core';
import { formatDate } from '@angular/common';

/**
 * Date pipe format
 */
@Pipe({ name: 'tulDatePipe' })
export class TulDatePipe implements PipeTransform {
  transform(value: any, type = 'DATE') {
    try {
      if (value) {
        switch (type) {
          case 'DATE':
            return formatDate(value, 'dd/MM/yyy', 'es_ES');
          case 'DATETIME':
            return formatDate(value, 'dd/MM/yyy hh:mm aa', 'es_ES');
          case 'MONTH':
            return formatDate(value, 'MM', 'es_ES');
          case 'YEAR':
            return formatDate(value, 'yyy', 'es_ES');
          case 'TIME':
            return formatDate(new Date(value), 'h:mm:00 a', 'es_ES');
          case 'RANGE':
          case 'RANGETIME':
            return value;
        }
      }
      return value;
    } catch (error) {
      return value;
    }
  }
}
