import { Inject, Injectable } from '@angular/core';
import { SubTypeNumericColumnForms } from '../enums';
import { ValidField } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class NumericValidations {
  /** environment variable */
  environment: any;

  /**
   *
   * @param environment
   */
  constructor(@Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }

  /**
   *
   * @param data
   * @param col
   * @param response
   * @returns
   */
  validateNumericFields = (data: any, col: any, response: ValidField) => {
    const { subType, min, max, required } = col;
    const value = Number(data);

    switch (subType) {
      case SubTypeNumericColumnForms.CURRENCY:
        break;
      case SubTypeNumericColumnForms.PERCENTAGE: {
        response.invalidField = value < 0 || value > 100;
        break;
      }
      case SubTypeNumericColumnForms.PHONENUMBER: {
        const { digitsCellphone } = this.environment.country;
        if (required) {
          response.invalidField = `${value}`.length !== digitsCellphone;
        }
        break;
      }
      default: {
        const isMinError = min !== undefined && Number(min) > value;
        const isMaxError = max !== undefined && Number(max) < value;
        response.invalidField = isMinError || isMaxError;
        break;
      }
    }
    return response;
  };
}
