<div class="tul-dropdown-container">
  <div class="tul-dropdown-container__dropdown">
    <div class="tul-input-container">
      <label
        for="tul-input"
        class="tul-input-container__label"
        *ngIf="tulLabel"
        [ngClass]="{
          'tul-input-container__label--alert-error': tulState === 'error'
        }"
        >{{ tulLabel | transloco }}
      </label>
      <input
        type="text"
        [name]="tulName"
        class="tul-input tul-input--right-icon"
        [ngClass]="{
          'tul-input--disabled': tulDisabled,
          'tul-input--alert-error': tulState === 'error',
          'tul-input--alert-success': tulState === 'success',
          'tul-input--both-icon': icon?.name
        }"
        [placeholder]="tulPlaceholder | transloco"
        [disabled]="tulDisabled"
        [(ngModel)]="value"
        (ngModelChange)="filterData()"
        (click)="checkList()"
        ngDefaultControl
      />
      <span
        class="tul-input-container__icon tul-input-container__icon--position-left"
        [ngClass]="{
          'tul-input-container__icon--disabled': tulDisabled
        }"
        *ngIf="icon?.name"
      >
        <i
          class="soytul tul-input-container__icon--size"
          [ngClass]="['soytul-' + icon?.name]"
          *ngIf="icon?.type === 'font'"
        ></i>
      </span>
      <span
        class="tul-input-container__icon tul-input-container__icon--position-right"
        [ngClass]="{
          'tul-input-container__icon--disabled': tulDisabled
        }"
      >
        <i class="soytul" [ngClass]="['soytul-chevron_down_filled']"></i>
      </span>
      <small
        class="tul-input-container__small"
        *ngIf="tulAdditionalInfo"
        [ngClass]="{
          'tul-input-container__small--alert-error': tulState === 'error',
          'tul-input-container__small--alert-success': tulState === 'success'
        }"
        >{{ tulAdditionalInfo | transloco }}
      </small>
    </div>
    <div class="tul-dropdown-container__menu" *ngIf="showMenu">
      <div class="tul-dropdown-container__loading color-ufo-green" *ngIf="showLoading">
        <i class="soytul soytul-load_filled" *ngIf="value.length === 0 || value.length >= 3"></i>
        <small>{{ loadingText | transloco }}</small>
      </div>
      <ul
        class="tul-dropdown-container__menu-ul"
        [ngClass]="{ 'tul-dropdown-container__menu-ul--scroll': showScroll }"
      >
        <li *ngFor="let item of dataList; let i = index">
          <small
            class="tul-dropdown-container__links"
            [ngClass]="{ 'tul-dropdown-container--link-selected': item.selected }"
            (click)="selectItem(i)"
            >{{ item.text | transloco }}
            <div
              class="tul-dropdown-container__checkbox"
              [ngClass]="{ 'tul-dropdown-container--checkbox-selected': item.selected }"
            >
              <i class="soytul soytul-check_filled tul-dropdown-container__icon-check"></i>
            </div>
          </small>
        </li>
      </ul>
    </div>
  </div>
</div>
