import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgNotFoundComponent } from './svg-not-found.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  exports: [SvgNotFoundComponent],
  imports: [CommonModule, FormsModule],
  declarations: [SvgNotFoundComponent],
})
export class SvgNotFoundModule {}
