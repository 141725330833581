import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import {
  ClickOption,
  SelectStatusOption,
  TypeTableEntity,
  TypeTables,
} from '../../../../interfaces/export';
import { StateRequest, TypeRequest, TypeTable } from './../../../../enums/export';
import { TableMSService } from './../../../../services/tableMS.service';

/**
 * table select status option
 */
@Component({
  selector: 'app-select-status',
  templateUrl: './select-status.component.html',
  styleUrls: ['./select-status.component.scss'],
})
export class SelectStatusComponent implements OnInit, OnDestroy {
  /**
   * Check select status option
   */
  @Input() option!: SelectStatusOption;
  /**
   * Data row
   */
  @Input() data: any;
  /**
   * table
   */
  @Input() table!: TypeTables;
  /**
   * Type Table
   */
  @Input() typeTable!: TypeTable;
  /**
   * Emitter to execute option
   */
  @Output() clickOptionEmitter = new EventEmitter<ClickOption>();
  /**
   * Subscriptor set status
   */
  suscriptionSelectStatus = new Subscription();

  /**
   * option select body
   */
  selectOptions: any = [];

  /**
   * Constructor
   * @param tableMSService service that manages all communication with the api for MS
   */
  constructor(private tableMSService: TableMSService, private translocoService: TranslocoService) {}

  /**
   * NgOnInit
   */
  ngOnInit() {
    this.selectOptions = this.getValues(this.option);
  }

  /**
   *
   * Confirm change select
   * @param item to selected
   * @return void
   */
  confirm(item: any) {
    this.clickOptionEmitter.emit({
      option: this.option,
      loading: true,
      status: StateRequest.REQUESTING,
    });
    if (this.option.httpSubscribe) {
      this.suscriptionSelectStatus = this.option
        .httpSubscribe({ data: this.data, item })
        .pipe(
          map((response) => {
            return this.option.mapHttpSubscribe
              ? this.option.mapHttpSubscribe({ response, component: this })
              : response;
          })
        )
        .subscribe(
          (response) => {
            this.clickOptionEmitter.emit({
              option: this.option,
              loading: false,
              response,
              status: StateRequest.SUCCESS,
            });
          },
          (error) => {
            this.clickOptionEmitter.emit({
              option: this.option,
              loading: false,
              error,
              status: StateRequest.ERROR,
            });
          }
        );
      return;
    }
    // DEFAULT
    let formData: any;

    switch (this.option.typeRequest) {
      case TypeRequest.FORMDATA:
        formData = new FormData();
        formData.set([this.option.attribute], item.value);
        break;
      case TypeRequest.QUERYPARAMS:
      default:
        formData = {};
        formData[this.option.attribute] = item.value;
        break;
    }

    this.tableMSService
      .edit(formData, this.data.uuid, (this.table as TypeTableEntity).url)
      .subscribe(
        (response) => {
          this.clickOptionEmitter.emit({
            option: this.option,
            loading: false,
            response,
            status: StateRequest.SUCCESS,
          });
        },
        (error) => {
          this.clickOptionEmitter.emit({
            option: this.option,
            loading: false,
            error,
            status: StateRequest.ERROR,
          });
        }
      );
  }

  /**
   * getValues to select status
   * @param option Actual options
   * @return options
   */
  getValues(option: any) {
    return typeof option.options === 'function'
      ? option.options({ data: this.data, option })
      : option.options;
  }

  /**
   * Generate tooltip in button option
   * @param option: actual option
   * @return tooltip
   */
  getToolTip(option: any) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return this.translocoService.translate('tulTable.options.selectStatus.tooltip');
    }
    return typeof option.tooltip.title === 'boolean'
      ? option.tooltip.title
      : option.tooltip.title({ data: this.data, option });
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.suscriptionSelectStatus.unsubscribe();
  }
}
