import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TextControllerComponent } from './text-controller.component';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  declarations: [TextControllerComponent],
  imports: [CommonModule, FormsModule, TulDataTablePipeModule],
  exports: [TextControllerComponent],
})
export class TextControllerModule {}
