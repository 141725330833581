import {
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';
import { TabIndex } from '../../../shared/types/tab-index.type';
import { TulButtonColor, TulButtonColorEnum } from '../../types/btn-color.type';
import { TulButtonSize, TulButtonSizeEnum } from '../../types/btn-size.type';
import { TulButtonIconPosition, TulButtonIconPositionEnum } from '../../types/btn-icon-position';
import { TulButtonAction } from '../../types/btn-action.type';

@Component({
  selector: 'tul-btn',
  exportAs: 'tulButton',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './tul-btn.component.html',
  styleUrls: ['./tul-btn.component.scss'],
})
export class TulButtonComponent {
  /**
   * Text of button
   *
   * @required
   */
  @Input() tulText: string = '';

  /**
   * Emit text change
   */
  @Output() tulTextChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Button color from TulButtonColorEnum
   */
  @Input() tulColor: TulButtonColor = TulButtonColorEnum.PRIMARYA;

  /**
   * Button size from TulButtonColorEnum
   */
  @Input() tulSize: TulButtonSize = TulButtonSizeEnum.BIG;

  /**
   * Icon position from TulButtonIconPositionEnum
   */
  @Input() tulIconPosition: TulButtonIconPosition = TulButtonIconPositionEnum.LEFT;

  /**
   * Name of icon to show
   */
  @Input() tulIcon: string | null = null;

  /**
   * Flag that validates if is a only icon button
   */
  @Input() tulOnlyIcon: boolean = false;

  /**
   * Flag that validates if is a content width button
   */
  @Input() tulContentWidth: boolean = false;

  /**
   * Flag that validates if is disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Emit disabled change
   */
  @Output() tulDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Indicates order in navigation with keyboard
   */
  @Input() tulTabIndex: TabIndex = null;

  /**
   * Event that is fired when click in access button
   */
  @Output() callBack: EventEmitter<TulButtonAction> = new EventEmitter<TulButtonAction>();

  /**
   * Class of button container
   */
  @HostBinding('class') class = 'tul-btn-container';

  /**
   * Class of button container with content-width
   */
  @HostBinding('class.tul-btn-container--content-width') get isBtnContentWidth() {
    return !!this.tulContentWidth && !this.isOnlyIcon;
  }

  /**
   * Class of button container when is disabled
   */
  @HostBinding('class.tul-btn-container--disabled') get isBtnDisabled() {
    return this.getDisabled;
  }

  /**
   * Emit when there's any event on button
   * @param tulButtonAction
   */
  emitAction(tulButtonAction: TulButtonAction) {
    this.tulTextChange.emit(this.tulText);
    this.tulDisabledChange.emit(this.tulDisabled);
    this.callBack.emit(tulButtonAction);
  }

  get isBtnPrimaryA() {
    return this.tulColor === TulButtonColorEnum.PRIMARYA;
  }

  get isBtnPrimaryB() {
    return this.tulColor === TulButtonColorEnum.PRIMARYB;
  }

  get isBtnSecondary() {
    return this.tulColor === TulButtonColorEnum.SECONDARY;
  }

  get isBtnTertiary() {
    return this.tulColor === TulButtonColorEnum.TERTIARY;
  }

  get isBtnContrast() {
    return this.tulColor === TulButtonColorEnum.CONTRAST;
  }

  get isBtnDanger() {
    return this.tulColor === TulButtonColorEnum.DANGER;
  }

  get isBtnOutlineDanger() {
    return this.tulColor === TulButtonColorEnum.OUTLINEDANGER;
  }

  get isBtnSmall() {
    return this.tulSize === TulButtonSizeEnum.SMALL;
  }

  get hasIcon() {
    return !!this.tulIcon;
  }

  get hasLeftIcon() {
    return (
      this.hasIcon && !this.isOnlyIcon && this.tulIconPosition === TulButtonIconPositionEnum.LEFT
    );
  }

  get hasRightIcon() {
    return (
      this.hasIcon && !this.isOnlyIcon && this.tulIconPosition === TulButtonIconPositionEnum.RIGHT
    );
  }

  get isOnlyIcon() {
    return this.tulOnlyIcon && !!this.tulIcon;
  }

  get getTabIndex() {
    if (this.getDisabled) return -1;
    return this.tulTabIndex;
  }

  get getDisabled() {
    return this.tulDisabled || null;
  }
}
