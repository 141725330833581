import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SwitchColumn } from '../../../../../interfaces/columns/selectors';

@Component({
  selector: 'lib-switch',
  templateUrl: './switch.component.html',
})
export class SwitchComponent implements OnInit {
  @Input() data: boolean = false;

  switchData: boolean = false;

  sliderText = { textTrue: 'yes', textFalse: 'not' };

  @Input() column!: SwitchColumn;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.checkData();
  }

  modelChange(event: boolean) {
    this.onChange.emit(this.column?.selectorsType?.[`${event ?? false}`].value ?? event);
  }

  checkData() {
    this.sliderText.textFalse = this.column.selectorsType?.false.title ?? 'not';
    this.sliderText.textTrue = this.column.selectorsType?.true.title ?? 'yes';
    if (this.data === this.column.selectorsType?.false.value) {
      this.switchData = false;
      return;
    }
    if (this.data === this.column.selectorsType?.true.value) {
      this.switchData = true;
      return;
    }
    this.switchData = Boolean(this.data);
  }
}
