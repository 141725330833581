import { Component, Input } from '@angular/core';
import { DocumentColumnForms } from '../../../../interfaces/columns/document';

/**
 *
 */
@Component({
  selector: 'document-controller',
  templateUrl: './document-controller.component.html',
})
export class DocumentControllerComponent {
  /** */
  @Input() data!: any;

  /** */
  @Input() column!: DocumentColumnForms;
}
