import { Component, Input, OnInit } from '@angular/core';
import { NumericModifierOption } from '../../../../../enums/columns/subTypeNumericModifierColumn.enum';
import {
  NumberInputButtonsColumn,
  SetValueData,
} from '../../../../../interfaces/columns/numeric-modifier';
import { DataTable } from '../../../../../interfaces/data';
import { TulDataTablePipe } from '../../../../../../pipes/dataTable/tul-data-table.pipe';
/**
 * Body option numeric input button
 */
@Component({
  selector: 'numeric-input-button',
  templateUrl: './numeric-input-button.component.html',
  styleUrls: ['./numeric-input-button.component.scss'],
})
export class NumericInputButtonComponent implements OnInit {
  /**
   * Actual data
   */
  @Input() data!: DataTable<any>;
  /**
   * Actual Column
   */
  @Input() column!: NumberInputButtonsColumn;

  /**
   * model data
   */
  value: any;

  /**
   * minus option
   */
  MINUS = NumericModifierOption.MINUS;

  /**
   * plus option
   */
  PLUS = NumericModifierOption.PLUS;

  /**
   * max value
   */
  MAX: number = 999;

  /**
   * min value
   */
  MIN: number = -999;
  /**
   * Constructor
   */
  constructor(private tulDataTablePipe: TulDataTablePipe) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.value = this.tulDataTablePipe.transform(this.data, this.column.attribute) ?? 0;
    if (this.column.setValue) {
      const obs =
        typeof this.column.setValue === 'function' ? this.column.setValue() : this.column.setValue;
      obs.subscribe(({ attribute, uuid, value }: SetValueData) => {
        if (uuid && this.data[attribute] === uuid) {
          this.value = value;
        }
      });
    }
  }

  /**
   * onChange of model
   */
  onChange(event: any): void {
    this.value = event;
    if (this.column.changeField) {
      this.column.changeField({
        data: this.data,
        option: NumericModifierOption.CHANGE,
        value: event,
      });
    }
  }
  /**
   * disable button option
   */
  disableOption(option: any) {
    if (option === NumericModifierOption.PLUS) {
      const max = this.getMax();
      if (this.value + 1 <= max) {
        return false;
      }
    }
    if (option === NumericModifierOption.MINUS) {
      const min = this.getMin();
      if (this.value - 1 >= min) {
        return false;
      }
    }
    return true;
  }
  /**
   * listener of minus and plus buttons
   */
  onClick(event: any, option: any) {
    if (option === NumericModifierOption.PLUS) {
      const max = this.getMax();
      if (this.value + 1 <= max) {
        this.value += 1;
      }
    }
    if (option === NumericModifierOption.MINUS) {
      const min = this.getMin();
      if (this.value - 1 >= min) {
        this.value -= 1;
      }
    }
    if (this.column.changeField) {
      this.column.changeField({ data: this.data, option, value: this.value });
    }
  }
  /**
   * validateMaxNumber in keyUp event
   */
  validateMaxNumber(event: any) {
    const max = this.getMax();
    const valueInput = Number(event.target.value);
    if (valueInput > max) {
      event.target.blur();
      setTimeout(() => {
        event.target.focus();
      }, 0);
    }
  }
  /**
   * validateFormatNumber in keyPress
   */
  validateFormatNumber(event: any) {
    let key;
    // Handle paste
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      // Handle key press
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const REGEX = /[0-9]/;
    if (!REGEX.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }
  /**
   * get min value
   */
  getMin() {
    return typeof this.column.min === 'function'
      ? this.column.min({ data: this.data })
      : this.column.min ?? this.MIN;
  }
  /**
   * get max value
   */
  getMax() {
    return typeof this.column.max === 'function'
      ? this.column.max({ data: this.data })
      : this.column.max ?? this.MAX;
  }
  /**
   * get max value
   */
  disable = () => false;
}
