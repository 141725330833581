import { PipeTransform, Pipe, Inject } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';
@Pipe({ name: 'tulNumberPhonePipe' })
export class TulNumberPhonePipe implements PipeTransform {
  environment: any;
  constructor(@Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }
  transform(value: any) {
    let valueReturn = value;
    try {
      const phoneNumber = parsePhoneNumber(
        value + '',
        this.environment.country.code as CountryCode
      );
      valueReturn = phoneNumber
        .format('NATIONAL')
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '-');
    } catch (error) {
      valueReturn = value;
    }
    return valueReturn;
  }
}
