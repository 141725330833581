import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TulTabsComponent } from './components/tul-tabs/tul-tabs.component';

@NgModule({
  declarations: [TulTabsComponent],
  imports: [CommonModule],
  exports: [TulTabsComponent],
})
export class TulTabsModule {}
