import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NumericService } from '../../../../../services/numeric/numeric.service';
import { CurrencyColumnForms } from '../../../../../interfaces/columns/numeric';

/**
 *
 */
@Component({
  selector: 'currency-controller',
  templateUrl: './currency-controller.component.html',
  styleUrls: ['./currency-controller.component.scss'],
})
export class CurrencyControllerComponent {
  /** value input */
  @Input() data: any;

  /** column */
  @Input() column!: CurrencyColumnForms;

  /** sends the current value */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /** default num decimals */
  defaultNumDecimals: number;

  constructor(private numericService: NumericService) {
    this.defaultNumDecimals = this.numericService.numDecimals;
  }

  /**
   * Method that use transformData and emit the changed data
   */
  changeData(value: any) {
    this.data = this.column.transformData
      ? this.column.transformData({ data: value, column: this.column })
      : value;
    this.onChange.emit(this.data);
  }

  /**
   * calls the formatting function
   * @param value
   * @returns
   */
  formatterCurrency = (value: any) => {
    if (value === '' || value === undefined || value === null) return '';

    return this.formatter(value);
  };

  /**
   * decimal format with decimals attr / clear the decimal value
   * @param value
   * @returns
   */
  parserCurrency = (value: any) => {
    const { valueAux, modelValue } = this.numericService.uniqueFix(value, this.data);
    this.data = modelValue;
    return valueAux;
  };

  /**
   * Format the value of the input by separating the numbers 3 by 3
   * @param value
   * @returns
   */
  formatter = (value: any, inBlur: boolean = false) => {
    try {
      value = this.numericService.uniqueFormat(value, true);
      return `$ ${value}`;
    } catch (error) {
      return `${0}`;
    }
  };
}
