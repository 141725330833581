import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TagColor, TulTagColor } from '../../types/tag-color.type';

/**
 * Component for custom tag
 */
@Component({
  selector: 'tul-tag',
  templateUrl: './tul-tag.component.html',
  styleUrls: ['./tul-tag.component.scss'],
})
export class TulTagComponent implements AfterContentInit {
  /**
   * Show item container
   */
  showItemContainer = true;

  /**
   * Element reference container select item
   */
  @ViewChild('tulContainerItem') tulContainerItem!: ElementRef;

  /**
   * Text in tag
   */
  @Input() tulText: string = 'Tul tag';

  /**
   * Enum tag color ('YELLOW' | 'ORANGE' | 'RED' | 'BLUE' | 'GREEN' | 'GRAY')
   */
  @Input() tulTagColor: TulTagColor = TagColor.YELLOW;

  /**
   * The tag is closable
   */
  @Input() isClosable: boolean = true;

  /**
   * Hide tag
   */
  hideTag: boolean = false;

  /**
   * Emit response in actions on tag
   */
  @Output() tulActions: EventEmitter<string> = new EventEmitter();

  /**
   * Check if ng-content is empty
   */
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.showItemContainer =
        this.tulContainerItem.nativeElement.innerHTML.trim().length > 0 ? true : false;
    }, 5);
  }

  /**
   * Close tag and emit response
   */
  closeTag() {
    this.hideTag = true;
    this.tulActions.emit(`${this.tulText} was closed`);
  }
}
