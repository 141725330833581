import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnTable } from '../../../../interfaces/columns/column';
import { StateRequest, TypeTable } from '../../../../enums/typeTable.enum';
import { ClickOption, ExpandOption } from '../../../../interfaces/export';

/**
 * table expand option
 */
@Component({
  selector: 'app-expand',
  templateUrl: './expand.component.html',
  styleUrls: ['./expand.component.scss'],
})
export class ExpandComponent {
  /**
   * flag to indicate if the expand is open
   */
  isOpen: boolean = true;
  /**
   * Check status option
   */
  @Input() option!: ExpandOption;
  /**
   * Data row
   */
  @Input() data: any;
  /**
   * Emitter to execute option
   */
  @Output() clickOptionEmitter = new EventEmitter<ClickOption>();
  /**
   * actual column
   */
  @Input() column!: ColumnTable;
  /**
   * Type Table
   */
  @Input() typeTable!: TypeTable;

  /**
   * Execute expand emitter
   */
  executeOption() {
    this.isOpen = !this.isOpen;
    this.clickOptionEmitter.emit({
      option: this.option,
      loading: false,
      status: StateRequest.SUCCESS,
    });
  }
}
