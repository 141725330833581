import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from './text.component';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  exports: [TextComponent],
  imports: [CommonModule, FormsModule, NzInputModule, TranslocoModule],
  declarations: [TextComponent],
})
export class TextModule {}
