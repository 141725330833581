import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectStatusComponent } from './select-status.component';
import { FormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzElementPatchModule } from 'ng-zorro-antd/core/element-patch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';
import { TulToAsyncPipeModule } from '../../../../../pipes';

@NgModule({
  exports: [SelectStatusComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzDropDownModule,
    NzButtonModule,
    NzPopconfirmModule,
    TulDataTablePipeModule,
    NzToolTipModule,
    TranslocoModule,
    TulToAsyncPipeModule,
    NzElementPatchModule,
  ],
  declarations: [SelectStatusComponent],
})
export class SelectStatusModule {}
