import { NgModule } from '@angular/core';
import { TulTagComponent } from './components/tul-tag/tul-tag.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TulTagComponent],
  imports: [SharedModule],
  exports: [TulTagComponent],
})
export class TulTagModule {}
