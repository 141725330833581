import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { TableComponent } from './table.component';
import { TableModule as TableinsideModule } from './table/table.module';

@NgModule({
  declarations: [TableComponent],
  imports: [
    CommonModule,
    NzTableModule,
    HttpClientModule,
    FooterModule,
    HeaderModule,
    TableinsideModule,
  ],
  exports: [TableComponent],
})
export class TableModule {
  public static forRoot(environment: any): ModuleWithProviders<TableModule> {
    return {
      ngModule: TableModule,
      providers: [
        {
          provide: 'ENVIRONMENT',
          useValue: environment,
        },
      ],
    };
  }
}
