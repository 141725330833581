import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SvgExportComponent } from './svg-export.component';

@NgModule({
  exports: [SvgExportComponent],
  imports: [CommonModule, FormsModule],
  declarations: [SvgExportComponent],
})
export class SvgExportModule {}
