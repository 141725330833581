import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TulInputCurrencyComponent } from './components/number/tul-input-currency/tul-input-currency.component';
import { TulTooltipModule } from '../tul-tooltip/tul-tooltip.module';
import { TulInputDateComponent } from './components/date/tul-input-date/tul-input-date.component';
import { TulInputDateTimeComponent } from './components/date/tul-input-date-time/tul-input-date-time.component';
import { TulInputTimeComponent } from './components/date/tul-input-time/tul-input-time.component';
import { TulInputTextComponent } from './components/text/tul-input-text/tul-input-text.component';
import { TulInputTextAreaComponent } from './components/text/tul-input-text-area/tul-input-text-area.component';
import { TulInputNumberComponent } from './components/number/tul-input-number/tul-input-number.component';
import { TulInputDecimalComponent } from './components/number/tul-input-decimal/tul-input-decimal.component';
import { TulInputPercentageComponent } from './components/number/tul-input-percentage/tul-input-percentage.component';
import { TulInputPhoneComponent } from './components/number/tul-input-phone/tul-input-phone.component';
import { TulInputColorComponent } from './components/color/tul-input-color/tul-input-color.component';

@NgModule({
  declarations: [
    TulInputCurrencyComponent,
    TulInputDateComponent,
    TulInputDateTimeComponent,
    TulInputTimeComponent,
    TulInputTextComponent,
    TulInputTextAreaComponent,
    TulInputNumberComponent,
    TulInputDecimalComponent,
    TulInputPercentageComponent,
    TulInputPhoneComponent,
    TulInputColorComponent,
  ],
  imports: [SharedModule, TulTooltipModule],
  exports: [
    TulInputCurrencyComponent,
    TulInputDateComponent,
    TulInputDateTimeComponent,
    TulInputTimeComponent,
    TulInputTextComponent,
    TulInputTextAreaComponent,
    TulInputNumberComponent,
    TulInputDecimalComponent,
    TulInputPercentageComponent,
    TulInputPhoneComponent,
    TulInputColorComponent,
  ],
})
export class TulInputModule {}
