import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TypeFiles } from '../../../../interfaces/columns/files';

@Component({
  selector: 'files-controller',
  templateUrl: './files-controller.component.html',
  styleUrls: ['./files-controller.component.scss'],
})
export class FilesControllerComponent implements OnInit {
  /**
   * Is visible modal
   */
  isVisible: boolean = false;

  /**
   * Loading
   */
  loading = false;

  /**
   * Subscriptor Get Image
   */
  suscriptionImage = new Subscription();

  /** data */
  @Input() data: any = {};

  /** column */
  @Input() column!: TypeFiles;

  /**
   * File list
   */
  fileList: any[] = [];

  /**
   * currentDate
   */
  currentDate = Date.now();

  /**
   * NgOninit
   */
  ngOnInit(): void {
    this.loadFileImage();
  }

  /**
   * function to load column image - file if fetch an httpSubscribe
   */
  loadFileImage() {
    if (this.column && this.column.httpSubscribe) {
      this.loading = true;
      this.suscriptionImage = this.column
        .httpSubscribe({ data: this.data, column: this.column })
        .subscribe(
          ({ uuid, file_name, url }) => {
            this.loading = false;
            this.fileList.push({
              uid: uuid,
              name: file_name,
              status: 'done',
              url,
            });
          },
          () => {
            this.loading = false;
          }
        );
    } else {
      this.checkIfFileExistFromGraph();
      this.checkIfFileExist();
    }
  }

  /**
   * Check if exist image objects from graph for set @files
   */
  checkIfFileExistFromGraph() {
    if (this.data[this.column.attribute]) {
      this.fileList = this.data[this.column.attribute].map((item: any) => ({
        ...item,
        selected: false,
      }));
    }
  }

  /**
   * Check if exist image objects from fileList column attribute for set @files
   */
  checkIfFileExist() {
    if (this.column.fileList) {
      this.column.fileList.forEach((column) => this.fileList.push(column));
    } else {
      this.fileList = [...this.data[this.column.attribute]];
    }
  }

  /**
   * Go url file path
   * @param url path
   */
  goToFilePath(url: string | undefined) {
    window.location.href = url ? url : '';
  }
}
