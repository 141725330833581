import { NgModule } from '@angular/core';
import { TulModalComponent } from './components/tul-modal/tul-modal.component';
import { TulModalHeaderComponent } from './components/tul-modal-header/tul-modal-header.component';
import { TulModalBodyComponent } from './components/tul-modal-body/tul-modal-body.component';
import { TulModalFooterComponent } from './components/tul-modal-footer/tul-modal-footer.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    TulModalComponent,
    TulModalHeaderComponent,
    TulModalBodyComponent,
    TulModalFooterComponent,
  ],
  imports: [SharedModule],
  exports: [
    TulModalComponent,
    TulModalHeaderComponent,
    TulModalBodyComponent,
    TulModalFooterComponent,
  ],
})
export class TulModalModule {}
