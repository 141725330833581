import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgAddComponent } from './svg-add.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  exports: [SvgAddComponent],
  imports: [CommonModule, FormsModule],
  declarations: [SvgAddComponent],
})
export class SvgAddModule {}
