import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TulInputModule } from '../../../../../../tul-input/tul-input.module';
import { NumberControllerComponent } from './number-controller.component';

@NgModule({
  declarations: [NumberControllerComponent],
  imports: [CommonModule, FormsModule, TulInputModule],
  exports: [NumberControllerComponent],
})
export class NumberControllerModule {}
