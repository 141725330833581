<div class="tul-carousel" [ngClass]="{ 'box-shadow-l': tulIsModal }" *ngIf="tulVisible && tulData">
  <div class="tul-carousel__icon-container">
    <i
      class="soytul soytul-close_outline tul-carousel__icon"
      (click)="closeCarousel()"
      *ngIf="tulIsModal"
    ></i>
  </div>
  <div class="tul-carousel__header">
    <div class="tul-carousel__header-title">{{ tulData.header | transloco }}</div>
    <div class="tul-carousel__header-subtitle">{{ tulData.subHeader | transloco }}</div>
    <div>
      <tul-tag [tulText]="tulData.tag.text" [isClosable]="false" [tulTagColor]="'yellow'">
        <i tul-item class="soytul" [className]="'soytul-' + tulData.tag.iconName"></i>
      </tul-tag>
    </div>
  </div>
  <div
    class="tul-carousel__content"
    [ngClass]="{
      'tul-carousel__content--size-1-1': tulSize === '1:1',
      'tul-carousel__content--size-4-5': tulSize === '4:5'
    }"
    [ngStyle]="{
      'background-image': 'url(' + tulData.images[currentIndex].url + ')'
    }"
  >
    <div class="tul-carousel__indicator">{{ currentIndex + 1 }}/{{ tulData.images.length }}</div>
  </div>
  <div class="tul-caption">
    <div class="tul-caption__icon">
      <i class="soytul soytul-info_outline tul-icon__logo"></i>
    </div>
    <div class="tul-caption__text">
      <small class="tul-caption__text-headtext">{{
        tulData.images[currentIndex].caption.headText | transloco
      }}</small>
      <small class="tul-caption__text-subtext">{{
        tulData.images[currentIndex].caption.subText | transloco
      }}</small>
    </div>
  </div>
  <div class="tul-carousel__nav">
    <div
      class="tul-carousel__nav-container tul-carousel__nav-container-buttons"
      [ngClass]="{ 'tul-carousel__nav-container--disabled': currentIndex === 0 }"
      (click)="previousImage()"
    >
      <i class="soytul soytul-arrow_left_filled tul-icon__logo"></i>
      <small class="tul-carousel__nav-text">{{ "Anterior" | transloco }}</small>
    </div>
    <div class="tul-carousel__nav-container">
      <div
        class="tul-carousel__circle"
        *ngFor="let item of tulData.images; let i = index"
        [ngClass]="{ 'tul-carousel__circle--active': i === currentIndex }"
      ></div>
    </div>
    <div
      class="tul-carousel__nav-container tul-carousel__nav-container-buttons"
      [ngClass]="{
        'tul-carousel__nav-container--disabled': currentIndex === tulData.images.length - 1
      }"
      (click)="nextImage()"
    >
      <small class="tul-carousel__nav-text">{{ "Siguiente" | transloco }}</small>
      <i class="soytul soytul-arrow_right_filled tul-icon__logo"></i>
    </div>
  </div>
  <div class="tul-carousel__actions">
    <tul-btn
      [tulText]="tulData.leftButtonText | transloco"
      tulSize="small"
      tulType="outline"
      class="tul-carousel__actions--button-left"
      (click)="sendActions(1)"
    ></tul-btn>
    <tul-btn
      [tulText]="tulData.rightButtonText | transloco"
      tulSize="small"
      (click)="sendActions(2)"
    ></tul-btn>
  </div>
</div>
