export enum TagColor {
  YELLOW = 'yellow',
  ORANGE = 'orange',
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
  GRAY = 'gray',
}

export type TulTagColor = `${TagColor}`;
