import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DefaultTextColumn } from '../../../../interfaces';

/**
 *
 */
@Component({
  selector: 'color-controller',
  templateUrl: './color-controller.component.html',
})
export class ColorControllerComponent {
  /** */
  @Input() data!: any;

  /** */
  @Input() column!: DefaultTextColumn;

  /** */
  @Output() onChange = new EventEmitter();

  /**
   * Method that use transformData and emit the changed data
   */
  changeData(value: any) {
    this.data = this.column.transformData
      ? this.column.transformData({ data: value, column: this.column })
      : value;
    this.onChange.emit(this.data);
  }
}
