import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DefaultTextColumn, RegexTextColumn } from '../../../../../interfaces/columns/text';
import { TulTooltipConfig } from '../../../../../../tul-tooltip';

/**
 *
 */
@Component({
  selector: 'default-controller',
  templateUrl: './default-controller.component.html',
  styleUrls: ['./default-controller.component.scss'],
})
export class DefaultControllerComponent {
  /** */
  @Input() data!: any;

  /** */
  @Input() column!: DefaultTextColumn;

  /** */
  @Output() onChange = new EventEmitter();

  /**
   * Method that use transformData and emit the changed data
   */
  changeData(value: any) {
    //Check if has regex validation
    const columnRegex = this.castRegexcolumn(this.column);
    if (columnRegex.pattern) {
      //if existe check if pattern is valid
      this.validatePattern(columnRegex, value);
    }
    this.data = this.column.transformData
      ? this.column.transformData({ data: value, column: this.column })
      : value;
    this.onChange.emit(this.data);
  }

  castRegexcolumn(column: DefaultTextColumn) {
    return column as RegexTextColumn;
  }

  validatePattern(columnRegex: RegexTextColumn, value: any) {
    const regex = new RegExp(columnRegex.pattern);
    columnRegex.error = undefined;
    if (regex.test(value)) {
      columnRegex.error = undefined;
      return true;
    } else {
      columnRegex.error = columnRegex.invalidPatternMessage;
      return false;
    }
  }
}
