import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListColumnForms } from '../../../../../interfaces/columns/select';
import { Data } from '../../../../../interfaces/data';
import { TulSelectData } from '../../../../../../tul-select/models/select-data.model';

/**
 *
 */
@Component({
  selector: 'listed-controller',
  templateUrl: './listed-controller.component.html',
  styleUrls: ['./listed-controller.component.scss'],
})
export class ListedControllerComponent implements OnInit {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: ListColumnForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  currentData!: TulSelectData[];

  currentValue: string = '';

  ngOnInit(): void {
    // populate select with static data
    this.render();
    // load current item if selected
    this.setCurrentItem();
  }

  render() {
    this.currentData = this.column?.listValues;
  }

  setCurrentItem() {
    const currentValue = this.data[this.column.attribute];
    if (currentValue) {
      this.currentData.map((item) => {
        if (item.value === currentValue) {
          item.selected = true;
          this.currentValue = item.text;
        }
        return item;
      });
    }
  }

  showItemSelected($ev: any) {
    if ($ev[0]) {
      this.onChange.emit($ev);
    } else {
      this.onChange.emit($ev.value);
    }
  }
}
