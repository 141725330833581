import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Carousel } from '../../models/carousel.model';
import { CarouselAction } from '../../types/carousel-action.type';
import { CarouselSize, TulCarouselSize } from '../../types/carousel-size.type';

/**
 * Component for custom carousel
 */
@Component({
  selector: 'tul-carousel',
  templateUrl: './tul-carousel.component.html',
  styleUrls: ['./tul-carousel.component.scss'],
})
export class TulCarouselComponent implements OnInit {
  /**
   * Show carousel like a modal or not
   */
  @Input() tulIsModal: boolean = false;

  /**
   * Show or hide carousel
   */
  @Input() tulVisible: boolean = true;

  /**
   * Enum CarouselSize type ('1:1' | '4:5' | '5:4')
   */
  @Input() tulSize: TulCarouselSize = CarouselSize.FIVEFOUR;

  /**
   * Emit for change visibility
   */
  @Output() tulVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Content data
   */
  @Input() tulData!: Carousel;

  /**
   * Emit response in actions on buttons
   */
  @Output() tulActions: EventEmitter<string> = new EventEmitter();

  /**
   * Image array index in current preview
   */
  currentIndex: number = 0;

  /**
   * OnInit check if image array size it's bigger than 10(maximum)
   */
  ngOnInit(): void {
    if (this.tulData.images.length > 10) {
      this.tulData.images.splice(10, this.tulData.images.length - 10);
    }
  }

  /**
   * Close carousel if is in mode modal
   */
  closeCarousel() {
    this.tulVisible = false;
    this.tulVisibleChange.emit(this.tulVisible);
    this.sendActions(3);
  }

  /**
   * Change to next image
   */
  nextImage() {
    this.currentIndex =
      this.currentIndex < this.tulData.images.length - 1
        ? this.currentIndex + 1
        : this.currentIndex;
  }

  /**
   * Change to preview image
   */
  previousImage() {
    this.currentIndex = this.currentIndex > 0 ? this.currentIndex - 1 : this.currentIndex;
  }

  /**
   * Send action when any button is pressed
   * @param index indicator for text message
   */
  sendActions(index: number) {
    switch (index) {
      case 1:
        this.tulActions.emit(`${CarouselAction.LEFT} button was pressed!`);
        break;
      case 2:
        this.tulActions.emit(`${CarouselAction.RIGHT} button was pressed!`);
        break;
      case 3:
        this.tulActions.emit(`${CarouselAction.XCLICK} button was pressed!`);
        break;
      default:
        break;
    }
  }
}
