import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { HeaderFeedbackGroupOptions, HeaderGroupOptions } from '../../../../interfaces/group';

/**
 * Component for custom chip
 */
@Component({
  selector: 'header-feedback',
  templateUrl: './header-feedback.component.html',
  styleUrls: ['./header-feedback.component.scss'],
})
export class TulHeaderfeedbackGroupComponent {
  @Input() options!: HeaderFeedbackGroupOptions;
}
