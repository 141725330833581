<div class="tul-input-container">
  <label
    for="tul-input"
    class="tul-input-container__label"
    *ngIf="tulLabel"
    [ngClass]="{
      'tul-input-container__label--alert-error': tulState === 'error',
      'tul-input-container__label--alert-success': tulState === 'success'
    }"
    >{{ tulLabel | transloco }}
    &nbsp;
    <i
      *ngIf="tulTooltipconfig"
      class="tul-input-container__hint soytul soytul-info_filled"
      [tulTooltip]="tulTooltipconfig.title"
      [tulTooltipConfig]="tulTooltipconfig"
    ></i>
  </label>
  <input
    [type]="'text'"
    [name]="tulName"
    class="tul-input"
    [ngClass]="{
      'tul-input--disabled': tulDisabled,
      'tul-input--alert-error': tulState === 'error',
      'tul-input--alert-success': tulState === 'success',
      'tul-input--left-icon': tulLeftIconName,
      'tul-input--right-icon': tulRightIconName,
      'tul-input--both-icon': tulRightIconName && tulLeftIconName,
      'tul-input--both-icon-as-dropdown': tulRightIconName && tulLeftIconName
    }"
    [placeholder]="tulPlaceholder | transloco"
    [disabled]="tulDisabled"
    [(ngModel)]="value"
    (keydown)="handleKeyDown($event)"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    [step]="tulStep"
    ngDefaultControl
  />
  <span
    class="tul-input-container__icon"
    [ngClass]="{
      'tul-input-container__icon--position-left': tulLeftIconName,
      'tul-input-container__icon--position-right': tulRightIconName,
      'tul-input-container__icon--disabled': tulDisabled
    }"
    *ngIf="(!tulRightIconName && tulLeftIconName) || (tulRightIconName && !tulLeftIconName)"
  >
    <i
      class="soytul tul-input-container__icon--size"
      [ngClass]="
        tulRightIconName
          ? 'soytul-' + tulRightIconName + ' tul-input-container__icon--size'
          : 'soytul-' + tulLeftIconName
      "
    ></i>
  </span>
  <span
    class="tul-input-container__icon tul-input-container__icon--position-left"
    [ngClass]="{
      'tul-input-container__icon--disabled': tulDisabled
    }"
    *ngIf="tulRightIconName && tulLeftIconName"
  >
    <i class="soytul tul-input-container__icon--size" [ngClass]="['soytul-' + tulLeftIconName]"></i>
  </span>
  <span
    class="tul-input-container__icon tul-input-container__icon--position-right"
    [ngClass]="{
      'tul-input-container__icon--disabled': tulDisabled
    }"
    *ngIf="tulRightIconName && tulLeftIconName"
  >
    <i
      class="soytul tul-input-container__icon--size"
      [ngClass]="['soytul-' + tulRightIconName, 'tul-input-container__icon--size']"
    ></i>
  </span>
  <small
    class="tul-input-container__small"
    *ngIf="tulAdditionalInfo"
    [ngClass]="{
      'tul-input-container__small--alert-error': tulState === 'error',
      'tul-input-container__small--alert-success': tulState === 'success'
    }"
    >{{ tulAdditionalInfo | transloco }}
  </small>
</div>
