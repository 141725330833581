import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeDatesForms } from '../../../../../interfaces/columns/date';
import { Data } from '../../../../../interfaces/data';

/**
 *
 */
@Component({
  selector: 'month-controller',
  templateUrl: './month-controller.component.html',
  styleUrls: ['./month-controller.component.scss'],
})
export default class MonthControllerComponent {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TypeDatesForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  showItemSelected($ev: any) {
    this.onChange.emit($ev.value);
  }
}
