import { Inject, Component, Input } from '@angular/core';
import { ColumnTable } from '../../../interfaces/columns/column';
import { Country } from '../../../interfaces/country';

/**
 * Body options
 */
@Component({
  selector: '[content]',
  templateUrl: './content.component.html',
})
export class ContentComponent {
  /**
   * Data row
   */
  @Input() data: any;

  /**
   * Actual Column
   */
  @Input() column!: ColumnTable;

  /**
   * Country configurations
   */
  country: Country;

  /**
   * Constructor
   * @param environment actual environment
   */
  constructor(@Inject('ENVIRONMENT') environment: any) {
    this.country = environment.country;
  }

  /**
   * Generate link option
   * @return string link
   */
  getLink() {
    return typeof this.column.link?.url === 'string'
      ? this.column.link.url
      : this.column.link?.url({ data: this.data, column: this.column });
  }

  /**
   * Generate customViewData customize column data
   * @return string data
   */
  customViewData() {
    return this.column.customViewData
      ? this.column.customViewData({ data: this.data, column: this.column })
      : '-';
  }

  /**
   * Get subtype column
   * @param column actual column
   * @return subtype or null
   */
  getSubType(column: any) {
    return column.subType
      ? typeof column.subType === 'string'
        ? column.subType
        : column.subType({ data: this.data, column: this.column })
      : null;
  }
}
