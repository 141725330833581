import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubTypeReturnData } from '../../../../interfaces/columns/subTypeReturnData';
import { SubTypeSelectorsColumnForms } from '../../../../enums/columns/SubTypeSelectorsColumnForms.enum';
import { CheckBoxColumn, TypeSelectors } from '../../../../interfaces/columns/selectors';

/**
 *
 */
@Component({
  selector: 'selectors-controller',
  templateUrl: './selectors-controller.component.html',
  styleUrls: ['./selectors-controller.component.scss'],
})
export class SelectorsControllerComponent implements OnInit {
  /** data input */
  @Input() data = false;

  /** column input */
  @Input() column!: TypeSelectors;

  /** selectorsData */
  selectorsData!: boolean;

  /** onChange */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Switch type column
   */
  selectorType?:
    | SubTypeSelectorsColumnForms
    | (({ data, column }: SubTypeReturnData<TypeSelectors>) => SubTypeSelectorsColumnForms) =
    SubTypeSelectorsColumnForms.SWITCH;

  ngOnInit() {
    this.selectorType = this.column.subType;
    if (`${this.data}` === 'pending' || `${this.data}` === 'disabled') {
      this.selectorsData = this.column.subType === 'switch' ? this.data : false;
    } else {
      this.selectorsData = this.data;
    }
  }
}
