import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectEntityComponent } from './select-entity.component';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { TulGetDataSelectPipeModule } from '../../../../../../pipes/getDataSelect/tul-get-data-select-pipe.module';
import { TulDataTablePipeModule } from '../../../../../../pipes/dataTable/tul-data-table-pipe.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SelectEntityComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzPipesModule,
    TulGetDataSelectPipeModule,
    TulDataTablePipeModule,
  ],
  exports: [SelectEntityComponent],
})
export class SelectEntityModule {}
