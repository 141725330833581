import { TulAlertType } from '../../tul-static-alert/types/alert-type.type';
import { TulAlertColor } from '../../tul-static-alert/types/alert-color.type';
import { Observable } from 'rxjs';

export class AlertConfig {
  message: string = '';
  description?: string | null = null;
  tulType: TulAlertType = 'default';
  tulColor: TulAlertColor = 'success';
  isClosable: boolean = true;
  withIcon: boolean = true;
  button?: AlertButton;
}

export interface AlertButton {
  text: string;
  action: ({ buttonPressed }: any) => void;
}
