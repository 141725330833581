import { Component, Input } from '@angular/core';
import { TypeMaps } from '../../../../interfaces/columns/map';
import { Data } from '../../../../interfaces/data';

/**
 *
 */
@Component({
  selector: 'map-controller',
  templateUrl: './map-controller.component.html',
})
export class MapControllerComponent {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TypeMaps;
}
