<div class="{{ column.subType }}">
  <ng-container [ngSwitch]="column.subType">
    <!-- CHECK -->
    <ng-container *ngSwitchCase="'check'">
      <nz-radio-group [ngModel]="getValueFilter(column)" (ngModelChange)="ngModelChange($event)">
        <label nz-radio nzValue="true">{{
          column.checkValues?.true
            ? column.checkValues?.true
            : ("tulTable.filters.selectors.check.options.true" | transloco)
        }}</label>
        <label nz-radio nzValue="false">{{
          column.checkValues?.false
            ? column.checkValues?.false
            : ("tulTable.filters.selectors.check.options.false" | transloco)
        }}</label>
      </nz-radio-group>
    </ng-container>
  </ng-container>
</div>
