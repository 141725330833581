<div
  class="tul-alert"
  [class.tul-alert--default]="isTypeDefault"
  [class.tul-alert--light]="isTypeLight"
  [class.tul-alert--dark]="isTypeDark"
  [class.tul-alert--outlined]="isTypeOutlined"
  [class.tul-alert--success]="isColorSuccess"
  [class.tul-alert--info]="isColorInfo"
  [class.tul-alert--error]="isColorError"
  [class.tul-alert--warning]="isColorWarning"
  [class.tul-alert--with-description]="!!description"
  [class.tul-alert--with-icon]="!!withIcon && !!getIcon"
  [class.tul-alert--closable]="isClosable"
  [class.tul-alert--banner]="isBanner"
  [ngClass]="{ 'tul-alert--fullWidth': fullWidth }"
  *ngIf="!isClosed"
>
  <div class="tul-alert-icon" *ngIf="withIcon && !!getIcon">
    <i class="tul-alert-icon__icon soytul soytul-{{ getIcon }}"></i>
  </div>
  <div class="tul-alert-content">
    <span class="tul-alert-content__message">{{ message | transloco }}</span>
    <span class="tul-alert-content__description" *ngIf="!!description">{{
      description | transloco
    }}</span>
    <div class="tul-alert-btn-content" *ngIf="withButton">
      <button
        class="tul-alert-btn-content__btn"
        [ngClass]="['tul-alert-btn--' + tulColor]"
        (click)="buttonClick()"
      >
        {{ buttonText | transloco }}
      </button>
    </div>
  </div>
  <button
    type="button"
    tabindex="0"
    *ngIf="isClosable"
    class="tul-alert-close"
    (click)="closeAlert()"
  >
    <i class="tul-btn-close__icon soytul soytul-close_outline"></i>
  </button>
</div>
