import { Inject, Injectable } from '@angular/core';
import { TranslocoFallbackStrategy } from '@ngneat/transloco';

@Injectable({
  providedIn: 'any',
})
export class CustomStrategy implements TranslocoFallbackStrategy {
  environment: any;

  constructor(@Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }

  getNextLangs(failedLang: string) {
    return [
      this.environment.country.language,
      `${this.environment.country.language}_${this.environment.country.code}`,
      'en',
    ];
  }
}
