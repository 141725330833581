import { Injectable } from '@angular/core';
import { Apollo, gql, WatchQueryOptions } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { TypeSortDefault, TypeSort } from '../enums/typeOption.enum';
import { ColumnTable } from '../interfaces/columns/column';
import { ArgumentTable, SortTable, TableGraphQl } from '../interfaces/table';
import { TypeQueryFilter } from '../enums/typeQueryFilter.enum';
import { TableService } from './table.service';

/**
 * table graphql service
 */
@Injectable({
  providedIn: 'root',
})
export class TableGraphQlService {
  /**
   * constructor
   * @param apollo client graphql
   * @param tableServices table services
   */
  constructor(private apollo: Apollo, private tableServices: TableService) {}

  /**
   *  get data table
   * @param param0 dto content poll interval variables fetch policy attributesFunction params graphql
   * @param columns all columns
   * @param page actual page
   * @param size actual size page
   * @param filter array filters
   * @param defaultFilters array default filters
   * @param sort object sort
   * @param argument object argument
   * @return observable
   */
  get(
    { dto, content, pollInterval, variables, fetchPolicy, attributesFunction }: TableGraphQl,
    columns: Array<ColumnTable>,
    page: number,
    size: number,
    filter = new Map(),
    defaultFilters = [],
    typeQueryFilter: TypeQueryFilter,
    sort?: SortTable,
    argument?: Array<ArgumentTable>
  ): Observable<any> {
    let auxFilter = '';
    if (filter.size || defaultFilters.length) {
      let additionalFilter = new Map();
      filter.forEach((value, key) => {
        if (value.additionalSearch) {
          additionalFilter.set(key, value);
          filter.delete(key);
        }
      });
      auxFilter += ', search: "';
      auxFilter += this.getFiltersMsGraphql(filter, columns, typeQueryFilter);
      auxFilter += '", ';
      auxFilter += this.additionalSearch(additionalFilter, columns, typeQueryFilter);
    }
    const auxArgument = argument ? this.getArgumentsMsGraphql(argument, typeQueryFilter) : '';
    const auxSort = sort?.type ? `, sort: "${this.getSortMsGraphql(sort)}"` : '';
    const functionContent = attributesFunction
      ? `${
          typeof attributesFunction === 'string'
            ? attributesFunction
            : attributesFunction({
                page,
                size,
                filter,
                sort,
                defaultFilters,
                stringFilter: auxFilter,
                stringSort: auxSort,
              })
        }`
      : `page: ${page - 1}, size: ${size} ${auxFilter} ${auxSort} ${auxArgument}`;
    const options: WatchQueryOptions<any, any> = {
      query: gql`
    query {
      ${dto}(${functionContent} ) {
        content {
        ${content}
        }
        size
        totalElements
        page
      }
    }
  `,
      variables: {
        endpoint: variables.endpoint,
      },
    };

    if (pollInterval?.enable) {
      options.pollInterval = pollInterval.time ?? 10000;
    }

    (options as any).fetchPolicy = fetchPolicy ?? 'network-only';
    return this.apollo.watchQuery<any>(options).valueChanges.pipe(
      map(({ data: response }) => {
        const data = response[dto];
        return {
          content: data.content,
          size: data.size,
          total_elements: data.totalElements,
        };
      })
    );
  }

  /**
   * get sort
   * @param sort object sort
   * @return string sort
   */
  getSortMsGraphql(sort: any) {
    return sort?.type ? `${sort.attribute},${this.replaceSortByDefault(sort.type)}` : '';
  }

  /**
   * @param sort
   * @return a string with ASC or DESC sort
   */
  replaceSortByDefault(sort: string): string {
    return sort === TypeSortDefault.asc ? TypeSort.ASCEND : TypeSort.DESCEND;
  }

  /**
   * get filters
   * @param filters array object filter
   * @param columns all columns
   * @return string filters
   */
  getFiltersMsGraphql(
    filters: any,
    columns: Array<ColumnTable>,
    typeQueryFilter?: TypeQueryFilter
  ) {
    return this.tableServices.generateFilter(columns, filters, false, typeQueryFilter);
  }

  /**
   * get arguments
   * @param filters array argument objects
   * @return string arguments
   */
  getArgumentsMsGraphql(argums: any, typeQueryFilter?: TypeQueryFilter) {
    let res = '';
    argums.forEach((argument: any) => {
      res += `, ${argument.name}${this.tableServices.operatorTransform(':', typeQueryFilter)}"${
        argument.value
      }"`;
    });
    return res;
  }

  /**
   * get default filters
   * @param filters array filters
   * @return string filters
   */
  getDefaultFiltersMsGraphql(filters: any, typeQueryFilter?: TypeQueryFilter) {
    return this.tableServices.generateDefaultFilters(filters, false, typeQueryFilter);
  }

  /**
   * additional search customized
   * @param filters array filters
   * @param columns all columns
   * @return string auxFilter
   */
  additionalSearch(filter: any, columns: any, typeQueryFilter?: TypeQueryFilter) {
    let auxFilter = '';
    filter.forEach((value: any) => {
      auxFilter += ` ${value?.additionalSearch}${this.tableServices.operatorTransform(
        ':',
        typeQueryFilter
      )} "${this.getFiltersMsGraphql(filter, columns)}"`;
    });
    return auxFilter;
  }
}
