import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyControllerComponent } from './currency-controller.component';
import { TulInputModule } from '../../../../../../tul-input/tul-input.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CurrencyControllerComponent],
  imports: [CommonModule, TulInputModule, FormsModule],
  exports: [CurrencyControllerComponent],
})
export class CurrencyControllerModule {}
