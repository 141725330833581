import { Component } from '@angular/core';

/**
 * Component Tul Modal Footer for project content
 */
@Component({
  selector: 'tul-modal-footer',
  template: `<ng-content></ng-content>`,
})
export class TulModalFooterComponent {}
