import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TulSwitchIsValuePipe } from './tul-switch-is-value.pipe';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [TulSwitchIsValuePipe],
  exports: [TulSwitchIsValuePipe],
})
export class TulSwitchIsValuePipeModule {}
