import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { HeaderGroupOptions, HeaderGroupType } from '../../../../interfaces/group';

/**
 * Component for custom chip
 */
@Component({
  selector: 'header-group',
  templateUrl: './header-group.component.html',
  styleUrls: ['./header-group.component.scss'],
})
export class TulHeaderGroupComponent {
  @Input() options!: HeaderGroupOptions;

  headerTypes = HeaderGroupType;

  constructor() {
    this.headerTypes.VERTICAL;
  }
}
