<div class="tul-loader-container">
  <div class="tul-loader" *ngIf="isLoading">
    <img
      class="tul-loader__img"
      [src]="
        environment.staticStylesUrl + '/web/foundation/public/loading/gif/loader-secondary.gif'
      "
      alt="Load table"
    />
  </div>
  <ng-content></ng-content>
</div>
