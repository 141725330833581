<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'datetime'">
      {{ data | tulDataTablePipe : column.attribute | tulDatePipe : "DATETIME" }}
    </ng-container>
    <ng-container *ngSwitchCase="'time'">
      {{ data | tulDataTablePipe : column.attribute | tulDatePipe : "TIME" }}
    </ng-container>
    <ng-container *ngSwitchCase="'month'">
      {{ data | tulDataTablePipe : column.attribute | tulDatePipe : "MONTH" }}
    </ng-container>
    <ng-container *ngSwitchCase="'year'">
      {{ data | tulDataTablePipe : column.attribute | tulDatePipe : "YEAR" }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ data | tulDataTablePipe : column.attribute | tulDatePipe }}
    </ng-container>
  </ng-container>
</ng-container>
