<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'text_editor'">
      <div [innerHTML]="dataHtml"></div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ data[column.attribute] }}
    </ng-container>
  </ng-container>
</ng-container>
