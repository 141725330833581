import { Component, Input } from '@angular/core';
import { SubTypeDateColumnTable } from '../../../../enums/columns/subTypeDateColumnTable.enum';
import { TypeDatesTable } from './../../../../interfaces/columns/date';

/**
 * Body option Date
 */
@Component({
  selector: '[date]',
  templateUrl: './date.component.html',
})
export class DateComponent {
  /**
   * subType Column with default
   */
  subType!: string;

  /**
   * subType Column
   */
  @Input('subType')
  /**
   * Set default column
   */
  set SubType(subType: string) {
    this.subType = subType || SubTypeDateColumnTable.DATETIME;
  }

  /**
   * Actual Column
   */
  @Input() column!: TypeDatesTable;
  /**
   * Data row
   */
  @Input() data: any;
}
