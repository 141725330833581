import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ExternalExecutionsInterface, TableEvents } from '../interfaces/emiters';
/**
 * subject services (table and external)
 */
@Injectable()
export class SubjectsService {
  /**
   * external execution subject
   */
  externalExecutions: Subject<ExternalExecutionsInterface> = new Subject();
  /**
   * tabla emitter subject
   */
  tableEmitter: Subject<TableEvents> = new Subject();

  /**
   * emit external execution event
   * @param option data to emit
   */
  emitExternalEvents(option: ExternalExecutionsInterface) {
    this.externalExecutions.next(option);
  }

  /**
   * emit table event
   * @param option data to emit
   */
  tableEmitterEvents(option: TableEvents) {
    this.tableEmitter.next(option);
  }
}
