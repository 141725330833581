import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TulAccessButtonComponent } from './components/tul-access-btn/tul-access-btn.component';

@NgModule({
  declarations: [TulAccessButtonComponent],
  imports: [SharedModule],
  exports: [TulAccessButtonComponent],
})
export class TulAccessButtonModule {}
