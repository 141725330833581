import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AddModule } from './add/add.module';
import { HeaderComponent } from './header.component';
import { RefreshModule } from './refresh/refresh.module';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { CustomModule } from './custom/custom.module';
import { ImportModule } from './import/import.module';
import { MultiEditModule } from './multiple-edit/multiple-edit.module';
import { ExportModule } from './export/export.module';

@NgModule({
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    ImportModule,
    RefreshModule,
    AddModule,
    NzTagModule,
    CustomModule,
    MultiEditModule,
    ExportModule,
  ],
  declarations: [HeaderComponent],
})
export class HeaderModule {}
