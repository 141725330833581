import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeNumericsTable } from '../../../../interfaces/columns/numeric';
/**
 * filter option numeric
 */
@Component({
  selector: 'numeric-filter',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss'],
})
export class NumericComponent {
  /**
   * Actual Column
   */
  @Input() column!: TypeNumericsTable;

  /**
   * Filters map
   */
  @Input() filter!: Map<string, any>;

  /**
   * Emitter change value filter
   */
  @Output() changeValueFilter = new EventEmitter<string>();

  /**
   * Value filter
   */
  valueFilterInput: any;

  /**
   * Change filter value
   * @param event value of filter
   */
  ngModelChange(event: any) {
    this.valueFilterInput = event;
    this.changeValueFilter.emit(event);
  }

  /**
   * get value filter
   * @param column selected column
   * @return value to selected column
   */
  getValueFilter(column: TypeNumericsTable) {
    return this.filter.get(column?.filter?.attribute ?? column.attribute)?.value;
  }

  /**
   * parse currency
   * @param value value to parse
   * @return parser value
   */
  parserCurrency = (value: string) => value.replace('$ ', '');

  /**
   * Format currency
   * @param value value to format
   * @return formater value
   */
  formatterCurrency = (value: any) => {
    let numberFormat = 0;
    if (value === null || value === undefined) {
      numberFormat = 0;
    } else if (typeof value === 'string') {
      numberFormat = Number(parseFloat(value as string).toFixed(4));
    } else if (typeof value === 'number') {
      numberFormat = value;
    }
    if (isNaN(numberFormat)) {
      numberFormat = 0;
    }
    return ` ${new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
      numberFormat
    )}`;
  };
}
