import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextEditorControllerModule } from './text-editor-controller/text-editor-controller.module';
import { DefaultControllerModule } from './default-controller/default-controller.module';
import { TextControllerComponent } from './text-controller.component';
import { TextAreaControllerModule } from './textarea-controller/textarea-controller.module';

@NgModule({
  declarations: [TextControllerComponent],
  imports: [
    CommonModule,
    DefaultControllerModule,
    TextAreaControllerModule,
    TextEditorControllerModule,
  ],
  exports: [TextControllerComponent],
})
export class TextControllerModule {}
