import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EntityColumnForms } from '../../../../../interfaces/columns/select';
import { Data } from '../../../../../interfaces/data';
import { TulSelectData } from '../../../../../../tul-select/models/select-data.model';
/**
 *
 */
@Component({
  selector: 'entity-controller',
  templateUrl: './entity-controller.component.html',
  styleUrls: ['./entity-controller.component.scss'],
})
export class EntityControllerComponent implements OnInit {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: EntityColumnForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Current data for drop
   */
  currentData!: ({ data }: any) => Observable<TulSelectData[]>;

  /**
   * Cyrrent valor for show
   */
  currentValue: string = '';

  /** */
  page: number = 1;

  /**
   * OnInit
   */
  ngOnInit(): void {
    // populate select with static data
    this.render();
    this.checkValue();
  }

  /**
   * Assign values to the dropdown
   */
  render() {
    this.currentData = ({ data }) => {
      const filters = this.column.filters ? [...this.column.filters] : [];
      let auxKey = '';
      this.column.listAttributtes.forEach((element, index) => {
        auxKey += element + (index + 1 === this.column.listAttributtes.length ? '' : '*');
      });
      if (data) filters.push({ key: auxKey, value: [data] });
      return this.column
        .httpSubscribe({
          column: this.column,
          filters: filters,
          page: this.page,
          pageSize: 20,
          data: this.data,
        })
        .pipe(
          map((response) => {
            return response.data.map((res: any) => {
              return {
                text: this.concatViewText(res),
                value: res[this.column.attributeValue],
                selected: res.selected ?? false,
              };
            });
          })
        );
    };
  }

  /**
   * Generate view text in dropdown with specific list attributes
   * @param item
   * @returns
   */
  concatViewText(item: Data, flag?: boolean): string {
    let aux: any;
    if (!flag) {
      aux = '';
      this.column.listAttributtes.forEach((element, index) => {
        if (item[element]) {
          aux += item[element] + (this.column.listAttributtes.length - 1 !== index ? ' - ' : '');
        }
      });
    } else {
      aux = item;
    }
    return aux;
  }

  /**
   * Check value fi exist for change data model
   */
  checkValue() {
    this.currentValue = this.data[this.column.attribute]
      ? this.concatViewText(this.data[this.column.attribute])
      : '';
    this.data[this.column.attribute] = this.data[this.column.attribute]
      ? this.column.multiple
        ? this.concatViewText(this.data[this.column.attribute], true)
        : this.concatViewText(this.data[this.column.attribute])
      : '';
  }

  /**
   * Emit selected item
   * @param $ev
   */
  showItemSelected($ev: any) {
    if ($ev[0]) {
      this.onChange.emit($ev);
    } else {
      this.onChange.emit($ev.value);
    }
  }
}
