<a
  nz-button
  class="button tul-btn tul-btn--secondary"
  [routerLink]="[getLink()]"
  nzType="default"
  [nzTooltipTitle]="getToolTip(option)"
  nzTooltipPlacement="top"
  nz-tooltip
  [disabled]="option.disabled | tulToAsyncPipe | async"
>
  <svg-import class="button__icon"></svg-import>
  {{ "tulTable.options.import.title" | transloco }}
</a>
