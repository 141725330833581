<button
  nz-button
  class="tul-btn tul-btn--secondary"
  nzType="default"
  (click)="clickOption()"
  [nzTooltipTitle]="getToolTip(option)"
  nzTooltipPlacement="top"
  nz-tooltip
  [disabled]="option.disabled | tulToAsyncPipe | async"
>
  <svg-refresh class="button__icon"></svg-refresh>

  {{ "tulTable.options.refresh.title" | transloco }}
</button>
