<ng-container [ngSwitch]="table.typeTable">
  <!-- MICROSERVICES TABLE -->
  <!-- GRAPH QL TABLE -->
  <!-- MONOLITH TABLE -->
  <ng-container *ngSwitchDefault>
    <nz-table
      class="tul-table"
      #rowSelectionTable
      nzShowSizeChanger
      nzShowQuickJumper
      nzSize="small"
      nzTableLayout="fixed"
      [nzPaginationPosition]="table.paginationPosition ? table.paginationPosition : BOTTOM"
      (nzPageIndexChange)="nzPageIndexChange($event)"
      (nzPageSizeChange)="nzPageSizeChange($event)"
      (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
      [nzData]="data"
      [nzFrontPagination]="false"
      [nzTotal]="total"
      [nzPageSize]="size"
      [nzPageIndex]="page"
      [nzShowTotal]="totalTemplate"
      [nzPageSizeOptions]="table.pageSizeOptions ? table.pageSizeOptions : [10, 50, 100, 500, 1000]"
      [nzShowPagination]="table.showPagination === false ? table.showPagination : true"
      [nzLoading]="loading"
      [nzLoadingIndicator]="indicatorTemplate"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzNoResult]="noData"
      [nzScroll]="getScrollTable()"
    >
      <ng-container
        [ngTemplateOutlet]="contentTable"
        [ngTemplateOutletContext]="{
          $implicit: { table: table, rowSelectionTable: rowSelectionTable, columns: columns }
        }"
      >
      </ng-container>
    </nz-table>
  </ng-container>
  <!-- LIST TABLE -->
  <ng-container *ngSwitchCase="'list'">
    <nz-table
      class="tul-table"
      #rowSelectionTable
      nzShowSizeChanger
      nzShowQuickJumper
      nzSize="small"
      nzPaginationPosition="bottom"
      [nzData]="data"
      [nzShowTotal]="totalTemplate"
      [nzPageSize]="size"
      [nzPageSizeOptions]="table.pageSizeOptions ? table.pageSizeOptions : [10, 50, 100, 500, 1000]"
      [nzShowPagination]="table.showPagination === false ? table.showPagination : true"
      [nzLoading]="loading"
      [nzLoadingIndicator]="indicatorTemplate"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzNoResult]="noData"
      [nzScroll]="getScrollTable()"
    >
      <ng-container
        [ngTemplateOutlet]="contentTable"
        [ngTemplateOutletContext]="{
          $implicit: { table: table, rowSelectionTable: rowSelectionTable, columns: columns }
        }"
      >
      </ng-container>
    </nz-table>
  </ng-container>
</ng-container>

<ng-template #indicatorTemplate>
  <img
    class="tul-loader"
    [src]="environment.staticStylesUrl + '/web/foundation/public/loading/gif/loader-secondary.gif'"
    alt="Load table"
  />
</ng-template>

<!-- PAGINATION FOOTER -->
<ng-template #totalTemplate let-range="range" let-totalRange>
  {{ range[0] }}-{{ range[1] }}
  {{ "tulTable.pagination" | transloco : { totalRange: totalRange } }}
</ng-template>

<!-- CONTENT TABLE -->
<ng-template #contentTable let-contentData>
  <thead class="thead">
    <tr class="thead__tr" *ngIf="contentData.columns.length > 0">
      <th
        scope="col"
        class="checks-spacring"
        *ngIf="(showChecked || table.checksBox?.show) && table.typeTable != 'list'"
        [(nzChecked)]="checked"
        [nzIndeterminate]="indeterminate"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
      <th scope="col" thead__th *ngIf="table.dragSorting?.orderColumnShow | tulToAsyncPipe | async">
        {{ "global.order" | transloco }}
      </th>
      <ng-container *ngFor="let column of contentData.columns; let i = index">
        <th
          *ngIf="(column.isShow | tulToAsyncPipe | async) !== false"
          class="thead__th"
          [ngStyle]="column?.style?.th?.style"
          [nzEllipsis]="column?.style?.th?.ellipsis"
          [nz-tooltip]="column?.style?.th?.ellipsis ? column.header : ''"
          [nzBreakWord]="column?.style?.th?.breakWord"
          [nzWidth]="column?.style?.th?.width"
          [nzColumnKey]="column.attribute"
          [nzSortFn]="column.sort"
          [nzSortOrder]="sort?.type && sort.attribute === column.attribute ? sort.type : undefined"
          [nzFilterFn]="column.filter"
          [ngClass]="{ 'filter-active': column?.filter?._isActive }"
          (nzSortOrderChange)="actionOrder($event, column)"
          nzCustomFilter
          [nzLeft]="column?.fixed?.left ? true : false"
          [nzRight]="column?.fixed?.right ? true : false"
        >
          <nz-filter-trigger
            *ngIf="column?.filter?.filter"
            [nzActive]="isActiveFilter(column)"
            (nzVisibleChange)="nzVisibleChangeFilters($event, column)"
            [nzDropdownMenu]="menu"
          >
            <i nz-icon nzType="search"></i>
          </nz-filter-trigger>

          <!-- FILTER -->
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <app-filters
              (executeOptionFilter)="executeFilter($event)"
              [(filter)]="filter"
              [column]="column"
            >
            </app-filters>
          </nz-dropdown-menu>
          {{ column.header }}
        </th>
      </ng-container>
      <th
        class="thead__th thead__option_th"
        *ngIf="contentData.table.options && contentData.table.options.length > 0"
        [ngStyle]="
          !table.styles
            ? ''
            : !table.styles.options
            ? ''
            : table.styles.options.th
            ? table.styles.options.th
            : { minWidth: '100px' }
        "
        [nzRight]="table.scroll ? table.scroll : false"
      >
        {{ "tulTable.actions" | transloco }}
      </th>
    </tr>
  </thead>
  <tbody class="tbody" cdkDropList (cdkDropListDropped)="sortChange($event)">
    <ng-container *ngFor="let data of contentData.rowSelectionTable.data; let indexBody = index">
      <tr
        class="tbody__tr"
        cdkDrag
        [cdkDragDisabled]="table.dragSorting?.dragSorting ? false : true"
        [ngClass]="{ 'select-row': table.rowSelection }"
        (click)="table.rowSelection && selectRow(data)"
      >
        <td
          class="checks-spacring"
          *ngIf="(showChecked || table.checksBox?.show) && table.typeTable != 'list'"
          [nzChecked]="getRowChecked(data)"
          (nzCheckedChange)="
            onItemChecked(
              table.checksBox?.attribute
                ? getPropByString(data, table.checksBox?.attribute)
                : data.uuid,
              $event,
              data
            )
          "
        ></td>
        <td class="tbody__td" *ngIf="table.dragSorting?.orderColumnShow | tulToAsyncPipe | async">
          {{ indexBody + 1 }} <i nz-icon nzType="ordered-list" nzTheme="outline"></i>
        </td>
        <ng-container *ngFor="let column of contentData.columns">
          <td
            *ngIf="(column.isShow | tulToAsyncPipe | async) !== false"
            class="tbody__td {{ column.type }}"
            [ngStyle]="column?.style?.td?.style"
            [nzEllipsis]="column?.style?.td?.ellipsis"
            [nz-tooltip]="column?.style?.td?.ellipsis ? data[column.attribute] : ''"
            [nzBreakWord]="column?.style?.td?.breakWord"
            [column]="column"
            [data]="data"
            [nzLeft]="column?.fixed?.left ? true : false"
            [nzRight]="column?.fixed?.right ? true : false"
            [style]="column?.fixed?.left || column?.fixed?.right ? 'position: sticky' : ''"
            content
          ></td>
        </ng-container>
        <td
          class="tbody__option_td"
          *ngIf="contentData.table.options && contentData.table.options.length > 0"
          [nzRight]="table.scroll ? table.scroll : false"
          [options]="contentData.table.options"
          [table]="table"
          [data]="data"
          [ngStyle]="table.styles?.options?.td ? table.styles?.options?.td : {}"
          (executeOption)="clickOption($event, indexBody)"
          options
        ></td>
      </tr>
      <tr
        class="tbody__tr"
        [ngClass]="{ 'select-row': table.rowSelection }"
        *ngIf="expandSet.has(indexBody)"
      >
        <ng-container
          [ngTemplateOutlet]="getCustomTemplate(contentData.table.options)"
          [ngTemplateOutletContext]="{ $implicit: { data: data } }"
        >
        </ng-container>
      </tr>
    </ng-container>
  </tbody>
</ng-template>
<ng-container *ngIf="data?.length === 0 && !loading">
  <ng-container *ngIf="emptyState?.template; else noData">
    <div class="empty-state">
      <ng-container *ngTemplateOutlet="emptyState?.template"></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #noData>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'tulTable.noData' | transloco"></nz-empty>
</ng-template>
