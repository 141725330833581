import { Component, Input } from '@angular/core';
import { Country } from './../../../../interfaces/country';
import { TypeNumericsTable } from './../../../../interfaces/columns/numeric';
import { SubTypeNumericColumnTable } from '../../../../enums/columns/SubTypeNumericColumnTable.enum';

/**
 * Body option Numeric
 */
@Component({
  selector: '[numeric]',
  templateUrl: './numeric.component.html',
})
export class NumericComponent {
  /**
   * subType Column with default
   */
  subType!: string;

  /**
   * subType Column
   */
  @Input('subType')

  /**
   * Set default column
   */
  set SubType(subType: string) {
    this.subType = subType || SubTypeNumericColumnTable.NUMBER;
  }

  /**
   * Actual Column
   */
  @Input() column!: TypeNumericsTable;
  /**
   * Data row
   */
  @Input() data: any;

  /**
   * Country configurations
   */
  @Input() country!: Country;
}
