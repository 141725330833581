import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { SubTypeSelectColumnTable } from '../../../../enums/columns/SubTypeSelectColumnTable.enum';
import {
  EntityColumnTable,
  SelectColumnTable,
  TreeEntityColumn,
  TypeSelectsTable,
} from '../../../../interfaces/columns/select';
import { TableService } from '../../../../services/table.service';
import { TulSelectData } from '../../../../../tul-select/models/select-data.model';

/**
 * filter option select
 */
@Component({
  selector: 'select-filter',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnDestroy {
  /**
   * Actual Column
   */
  @Input() column!: TypeSelectsTable | any;
  /**
   * Filters map
   */
  @Input() filter!: Map<string, any>;
  /**
   * Emitter change value filter
   */
  @Output() changeValueFilter = new EventEmitter<string>();

  /**
   * Value filter
   */
  valueFilterInput: any;

  /**
   * Loading
   */
  loading = false;

  /**
   * suscriptor get data
   */
  suscriptionData = new Subscription();

  /**
   * Data tree
   */
  dataTree: any;

  /**
   * Current data for drop
   */
  currentData!: ({ data }: any) => Observable<TulSelectData[]>;

  /**
   * Cyrrent valor for show
   */
  currentValue: string = '';

  /** */
  page: number = 1;

  /**
   * Emit selected item
   * @param $ev
   */
  showItemSelected($ev: any) {
    if ($ev[0]) {
      this.changeValueFilter.emit($ev);
    } else {
      this.changeValueFilter.emit($ev.value);
    }
  }

  /**
   * Constructor
   * @param tableService service that handles external calls
   */
  constructor(private tableService: TableService) {}

  /**
   * NgOnInit
   */
  ngOnInit() {
    // populate select with static data
    this.render();
    if (this.column.subType === SubTypeSelectColumnTable.TREEENTITY) {
      this.loading = true;
      const auxColumn = this.column as TreeEntityColumn;
      const subscription = auxColumn.httpSubscribe
        ? auxColumn.httpSubscribe({
            column: this.column,
          })
        : this.tableService.get(auxColumn.url);

      this.suscriptionData.unsubscribe();
      this.suscriptionData = subscription
        .pipe(
          map((response) =>
            auxColumn.mapHttpSubscribe
              ? auxColumn.mapHttpSubscribe({ response, component: this })
              : response
          )
        )
        .subscribe((response) => {
          this.loading = false;
          this.dataTree = response.data;
          this.dataTree.forEach((elementTree: any) => {
            this.iterateTree(elementTree, 'root');
          });
        });
    }
  }

  /**
   * Assign values to the dropdown
   */
  render() {
    this.currentData = ({ data }) => {
      const filters = this.column.filters ? [...this.column.filters] : [];
      let auxKey = '';
      this.column.listAttributtes.forEach((element: string | number, index: number) => {
        auxKey += element + (index + 1 === this.column.listAttributtes.length ? '' : '*');
      });
      if (data) filters.push({ key: auxKey, value: [data] });
      return this.column
        .httpSubscribe({
          column: this.column,
          filters: filters,
          page: this.page,
          pageSize: 20,
        })
        .pipe(
          map((response: any) => {
            return response.data.map((res: any) => {
              return {
                text: this.concatViewText(res),
                value: res[this.column.attributeValue],
              };
            });
          })
        );
    };
  }

  /**
   * Generate view text in dropdown with specific list attributes
   * @param item
   * @returns
   */
  concatViewText(item: any): string {
    let aux = '';
    this.column.listAttributtes.forEach((element: string | number, index: number) => {
      if (item[element]) {
        aux += item[element] + (this.column.listAttributtes.length - 1 !== index ? ' - ' : '');
      }
    });
    return aux;
  }

  /**
   * iteate tree
   * @param subTree tree
   * @param father father
   */
  iterateTree(subTree: any, father: any) {
    const auxColumn = this.column as TreeEntityColumn;

    if (subTree.childrens.length > 0) {
      // Nodo
      subTree.padre = father;
      subTree.children = subTree.childrens;
      subTree.title = subTree.name;
      subTree.key = auxColumn?.attributeId ? subTree[auxColumn.attributeId] : subTree.uuid;
      subTree.isExpanded = true;
      subTree.isSelectable = true;
      subTree.expanded = false;
      for (const iterator of subTree.childrens) {
        this.iterateTree(iterator, subTree.id);
      }
    } else {
      // hoja
      subTree.padre = father;
      subTree.children = subTree.childrens;
      subTree.title = subTree.name;
      subTree.key = auxColumn?.attributeId ? subTree[auxColumn.attributeId] : subTree.uuid;
      subTree.isExpanded = false;
      subTree.isLeaf = true;
      subTree.isSelectable = false;
    }
  }

  /**
   * Change filter value
   * @param event value of filter
   */
  ngModelChange(event: any) {
    this.valueFilterInput = event;
    this.changeValueFilter.emit(event);
  }

  /**
   * get value filter
   * @param column selected column
   * @return value to selected column
   */
  getValueFilter(column: SelectColumnTable) {
    return this.filter.get(column?.filter?.attribute ?? column.attribute)?.value;
  }

  /**
   * get httpsubcribe in select entity
   * @param page number page
   * @param pageSize size to page
   * @param filters filters array
   * @return observable
   */
  getHttpSubscribe({ page, pageSize, filters }: { page: any; pageSize: any; filters: any }): any {
    const auxColumn = this.column as EntityColumnTable;
    if (auxColumn.httpSubscribe) {
      return auxColumn.httpSubscribe({ page, pageSize, filters, column: this.column }).pipe(
        map((response) =>
          auxColumn.mapHttpSubscribe
            ? auxColumn.mapHttpSubscribe({
                page,
                pageSize,
                filters,
                column: this.column,
                response,
              })
            : response
        )
      );
    } else {
      return null;
    }
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.suscriptionData.unsubscribe();
  }
}
