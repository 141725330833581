<div class="tul-stepper">
  <ng-container *ngFor="let step of tulStepList; let i = index">
    <div
      class="tul-stepper__circle-item"
      [ngClass]="{
        'tul-stepper--circle-item-inactive': step.state === 'inactive',
        'tul-stepper--circle-item-active': step.state === 'active',
        'tul-stepper--circle-item-finished': step.state === 'finished'
      }"
    >
      <div *ngIf="tulType === 'number'">{{ i + 1 }}</div>
      <i class="soytul soytul-check_filled tul-stepper__icon" *ngIf="tulType === 'icons'"></i>
      <small class="tul-stepper__step-text">{{ step.stepText | transloco }}</small>
    </div>
    <hr
      class="tul-stepper__line-item tul-stepper--line-item-inactive"
      [ngClass]="{
        'tul-stepper--line-item-active':
          (i < tulStepList.length - 1 && tulStepList[i + 1].state === 'active') ||
          tulStepList[i].state === 'finished'
            ? true
            : false
      }"
      [ngStyle]="{ display: i === tulStepList.length - 1 ? 'none' : 'block' }"
    />
  </ng-container>
</div>
