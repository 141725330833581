<div class="header-group-feedback">
  <tul-alert
    tulType="light"
    [fullWidth]="true"
    [isClosable]="true"
    tulColor="info"
    [message]="options.title ?? ''"
    [description]="options.description ?? ''"
    [withIcon]="true"
  >
  </tul-alert>
</div>
