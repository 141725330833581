export enum TypeLinkTable {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export enum Target {
  BLANK = '_blank',
  SELF = '_self',
  PARENT = '_parent',
  TOP = '_top',
}
