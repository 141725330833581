import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeDatesTable } from '../../../../interfaces/columns/date';

/**
 * filter option date
 */
@Component({
  selector: 'date-filter',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent {
  /**
   * Actual Column
   */
  @Input() column!: TypeDatesTable;
  /**
   * Filters map
   */
  @Input() filter!: Map<string, any>;
  /**
   * Emitter change value filter
   */
  @Output() changeValueFilter = new EventEmitter<string>();

  /**
   * Value filter
   */
  valueFilterInput: any;

  /**
   * Change filter value
   * @param event value of filter
   */
  ngModelChange(event: any) {
    this.valueFilterInput = event;
    this.changeValueFilter.emit(event);
  }

  /**
   * get value filter
   * @param column selected column
   * @return value to selected column
   */
  getValueFilter(column: TypeDatesTable) {
    return this.filter.get(column?.filter?.attribute ?? column.attribute)?.value;
  }
}
