import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { TulButtonCardTypeCondeced } from '../../models/btn-card-type-condesed.model';
import { TulButtonCardTypeLarge } from '../../models/btn-card-type-large.model';
import {
  TulButtonCardTypeCondencedEnum,
  TulButtonCardTypeLargeEnum,
} from '../../types/btn-card-type.type';
import { TulButtonCardSize, TulButtonCardSizeEnum } from '../../types/btn-card-size.type';
import { TulButtonAction } from '../../types/btn-action.type';
import { TulButtonIconTypeEnum } from '../../types/btn-icon-type.type';

@Component({
  selector: 'tul-btn-card',
  templateUrl: './tul-btn-card.component.html',
  styleUrls: ['./tul-btn-card.component.scss'],
})
export class TulBtnCardComponent {
  /**
   * Environments for static style url
   */
  environment: any;

  /**
   * Enum ('small', 'medium', 'large')
   */
  @Input() tulSize: TulButtonCardSize = TulButtonCardSizeEnum.SMALL;

  /**
   * Text on button
   */
  @Input() tulText: string = '';

  /**
   * Emit if text changed
   */
  @Output() tulTextChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Disable button
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Emit if button disabled changed
   */
  @Output() tulDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Emit any event on button
   */
  @Output() callBack: EventEmitter<TulButtonAction> = new EventEmitter<TulButtonAction>();

  /**
   * Config button
   */
  @Input() tulType: TulButtonCardTypeCondeced | TulButtonCardTypeLarge = {
    type: TulButtonCardTypeCondencedEnum.CONDENCED,
  };

  /**
   * Default img if icon daesn't exists
   */
  defaultImage: string = '';

  /**
   * Constructor
   * @param ENVIRONMENT
   */
  constructor(@Inject('ENVIRONMENT') ENVIRONMENT: any) {
    this.environment = ENVIRONMENT;
    this.defaultImage = `${this.environment.staticFilesUrl}icons/svg/chamo.svg`;
  }

  /**
   * Emit when there's any event on button
   * @param tulButtonAction
   */
  emitAction(tulButtonAction: TulButtonAction) {
    this.tulTextChange.emit(this.tulText);
    this.tulDisabledChange.emit(this.tulDisabled);
    this.callBack.emit(tulButtonAction);
  }

  get isDisabled(): boolean {
    return this.tulDisabled;
  }

  get isCondencedType(): boolean {
    return (
      this.tulType.type === TulButtonCardTypeCondencedEnum.CONDENCED ||
      this.tulType.type === TulButtonCardTypeCondencedEnum.CONDENCEDICON
    );
  }

  get isLargeType(): boolean {
    return (
      this.tulType.type === TulButtonCardTypeLargeEnum.LARGE ||
      this.tulType.type === TulButtonCardTypeLargeEnum.LARGEARROW ||
      this.tulType.type === TulButtonCardTypeLargeEnum.LARGEICON ||
      this.tulType.type === TulButtonCardTypeLargeEnum.LARGEICONARROW
    );
  }

  get isSmall(): boolean {
    return this.tulSize === TulButtonCardSizeEnum.SMALL;
  }

  get isMedium(): boolean {
    return this.tulSize === TulButtonCardSizeEnum.MEDIUM;
  }

  get isLarge(): boolean {
    return this.tulSize === TulButtonCardSizeEnum.LARGE;
  }

  get hasIcon(): boolean {
    return (
      this.tulType.type === TulButtonCardTypeCondencedEnum.CONDENCEDICON ||
      this.tulType.type === TulButtonCardTypeLargeEnum.LARGEICON ||
      this.tulType.type === TulButtonCardTypeLargeEnum.LARGEICONARROW
    );
  }

  get isVertical(): boolean {
    return (
      this.tulSize === TulButtonCardSizeEnum.LARGE &&
      this.tulType.type === TulButtonCardTypeCondencedEnum.CONDENCEDICON
    );
  }

  get hasArrow(): boolean {
    return (
      this.tulType.type === TulButtonCardTypeLargeEnum.LARGEICONARROW ||
      this.tulType.type === TulButtonCardTypeLargeEnum.LARGEARROW
    );
  }

  get hasDescription(): boolean {
    return this.tulType?.type?.includes('large') && this.tulSize === TulButtonCardSizeEnum.LARGE;
  }

  get description(): string | undefined {
    return this.hasDescription ? (this.tulType as TulButtonCardTypeLarge).description : '';
  }

  get isSvg(): boolean {
    return this.tulType?.icon?.type === TulButtonIconTypeEnum.SVG;
  }

  get isFont(): boolean {
    return this.tulType?.icon?.type === TulButtonIconTypeEnum.FONT;
  }
}
