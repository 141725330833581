<button
  class="option"
  nz-button
  nz-dropdown
  nzTrigger="click"
  [nzDropdownMenu]="menu"
  [nzPlacement]="'bottomCenter'"
  [nzTooltipTitle]="getToolTip(option)"
  nzTooltipPlacement="top"
  nz-tooltip
  [disabled]="option.disabled | tulToAsyncPipe | async"
  nz-element
  #button="nzElement"
>
  <svg
    viewBox="0 0 1024 1024"
    focusable="false"
    fill="currentColor"
    width="1em"
    height="1em"
    data-icon="retweet"
    aria-hidden="true"
  >
    <path
      d="M136 552h63.6c4.4 0 8-3.6 8-8V288.7h528.6v72.6c0 1.9.6 3.7 1.8 5.2a8.3 8.3 0 0011.7 1.4L893 255.4c4.3-5 3.6-10.3 0-13.2L749.7 129.8a8.22 8.22 0 00-5.2-1.8c-4.6 0-8.4 3.8-8.4 8.4V209H199.7c-39.5 0-71.7 32.2-71.7 71.8V544c0 4.4 3.6 8 8 8zm752-80h-63.6c-4.4 0-8 3.6-8 8v255.3H287.8v-72.6c0-1.9-.6-3.7-1.8-5.2a8.3 8.3 0 00-11.7-1.4L131 768.6c-4.3 5-3.6 10.3 0 13.2l143.3 112.4c1.5 1.2 3.3 1.8 5.2 1.8 4.6 0 8.4-3.8 8.4-8.4V815h536.6c39.5 0 71.7-32.2 71.7-71.8V480c-.2-4.4-3.8-8-8.2-8z"
    ></path>
  </svg>
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="content-options">
    <li nz-menu-item *ngFor="let item of selectOptions">
      <button
        [ngClass]="{
          'item-selected': (data | tulDataTablePipe : option.attribute) === item.value
        }"
        class="content-options__buttons"
        [nzCondition]="(data | tulDataTablePipe : option.attribute) === item.value"
        nz-popconfirm
        [nzPopconfirmOrigin]="button.elementRef"
        [nzPopconfirmTitle]="'tulTable.options.selectStatus.popConfirm.title' | transloco"
        (nzOnConfirm)="confirm(item)"
        [nzPopconfirmPlacement]="option.condition?.position || 'bottom'"
        nz-button
      >
        {{ item.text }}
      </button>
    </li>
  </ul>
</nz-dropdown-menu>
