import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { State, TulState } from '../../../../shared/types/state.type';
import {
  InputCustomUse,
  InputType,
  TulInputCustomUse,
  TulInputType,
} from '../../../types/input-type.type';

/**
 * noop
 */
function noop() {
  //noop ref
}

/**
 * Interface for communication with ngModel
 */
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TulInputTextComponent),
  multi: true,
};

@Component({
  selector: 'tul-input-text',
  templateUrl: './tul-input-text.component.html',
  styleUrls: ['./tul-input-text.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TulInputTextComponent implements ControlValueAccessor {
  /**
   * Placeholder
   */
  @Input() tulPlaceholder: string = 'Placeholder';

  /**
   * Text in label
   */
  @Input() tulLabel: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Text under dropdown
   */
  @Input() tulAdditionalInfo: string = '';

  /**
   * Enum State for design
   */
  @Input() tulState: TulState = State.DEFAULT;

  /**
   * Enum Input type
   */
  @Input() tulStep: Number = 1;

  /**
   * Icon left name
   */
  @Input() tulLeftIconName: string = '';

  /**
   * Icon right name
   */
  @Input() tulRightIconName: string = '';

  /**
   * Name
   */
  @Input() tulName: string = '';

  /** */
  @Input() tulTooltipconfig!: any;

  /**
   * Inner Value
   */
  private innerValue: any = '';

  /**
   * Response on touched
   */
  private onTouchedCallback: () => void = noop;

  /**
   * Response on change value
   */
  private onChangeCallback: (_: any) => void = noop;

  /**
   * Get value ngModel
   */
  get value(): any {
    return this.innerValue;
  }

  /**
   * Set value ngModel
   */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  /**
   * Write value
   * @param value ngModel
   */
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  /**
   * Register on change
   * @param fn function
   */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /**
   * Register on touched
   * @param fn function
   */
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
