import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumericInputButtonModule } from './numeric-input-button/numeric-input-button.module';
import { NumericModifierComponent } from './numeric-modifier.component';
@NgModule({
  declarations: [NumericModifierComponent],
  imports: [CommonModule, NumericInputButtonModule],
  exports: [NumericModifierComponent],
})
export class NumericModifierModule {}
