import { TranslocoService } from '@ngneat/transloco';

export class ListValuesSelect {
  getValues(enume: { [s: string]: unknown } | ArrayLike<unknown>) {
    const aux: { text: unknown; value: string }[] = [];
    const auxKeys = Object.keys(enume);
    const auxValues = Object.values(enume);
    auxKeys.forEach((element, index) => {
      aux.push({ text: auxValues[index], value: element });
    });
    return aux;
  }

  getValuesTranslate(enume: any, translateService: TranslocoService) {
    const aux: { text: any; value: string }[] = [];
    Object.keys(enume).forEach((element: any) => {
      aux.push({
        text: translateService.translate(enume[element]),
        value: element,
      });
    });
    return aux;
  }
}
