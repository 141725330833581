<ng-container [ngSwitch]="column.subType">
  <ng-container *ngSwitchCase="'switch'">
    <nz-badge [nzStatus]="badgeStatus" [nzText]="selectData | transloco"></nz-badge>
  </ng-container>
  <ng-container *ngSwitchCase="'radio'">
    {{ data[column.attribute] }}
  </ng-container>
  <ng-container *ngSwitchCase="'checkbox'">
    <div class="checkbox-container" *ngFor="let item of data[column.attribute]; let i = index">
      {{ item }}
    </div>
  </ng-container>
</ng-container>
