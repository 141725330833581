import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeNumericsForms } from '../../../../interfaces/columns/numeric';

/**
 *
 */
@Component({
  selector: 'numeric-controller',
  templateUrl: './numeric-controller.component.html',
})
export class NumericControllerComponent {
  /** */
  @Input() data!: any;

  /** */
  @Input() column!: TypeNumericsForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
}
