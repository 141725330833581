import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalSize, TulModalSize } from './../../types/modal-size.type';
import { ModalAction } from '../../types/modal-action.type';

/**
 * Component for custom modal
 */
@Component({
  selector: 'tul-modal',
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
    ]),
  ],
  templateUrl: './tul-modal.component.html',
  styleUrls: ['./tul-modal.component.scss'],
})
export class TulModalComponent {
  /**
   * Enum modal size ('XS' | 'S' | 'M' | 'L')
   */
  @Input() tulModalSize: TulModalSize = ModalSize.XS;

  /**
   * Show or hide modal
   */
  @Input() tulVisible: boolean = false;

  /**
   * Emit for change visibility
   */
  @Output() tulVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * X close modal is allowed
   */
  @Input() tulIsClosable: boolean = true;

  /**
   * Emit for modal close event
   */
  @Output() tulModalClosed: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();

  /**
   * Show or hide modal
   */
  showModal = false;

  /**
   * Close modal and emit type for action
   */
  closeModal() {
    this.tulVisible = false;
    this.tulVisibleChange.emit(this.tulVisible);
    this.tulModalClosed.emit(ModalAction.XCLICK);
  }
}
