import { Inject, Injectable } from '@angular/core';
import * as SentryAngular from '@sentry/angular';
import html2canvas from 'html2canvas';
import { BrowserTracing } from '@sentry/tracing';
import * as SentryBrowser from '@sentry/browser';

/**
 * sentry service
 */
@Injectable({ providedIn: 'root' })
export class SentryService {
  /** environment */
  public environment;
  /** transaction id */
  public transactionId!: string;

  /**
   * constructor
   * @param environment environments
   */
  public constructor(@Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }

  /**
   * init method
   */
  public init() {
    if (!this.environment.production) return;

    SentryAngular.init({
      dsn: this.environment.sentryDsn,
      environment: this.environment.production ? 'production' : 'develop',
      ignoreErrors: [
        'ChunkLoadError',
        'ApolloError',
        /Loading chunk [\d]+ failed/,
        /Uncaught (in promise): FirebaseError: Installations: Could not process request/,
        /Http failure response for [\d]+ 401 Unauthorized/,
        /feign.FeignException$NotFound: [404 Not Found]/,
      ],
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  /**
   *
   * @param email An alternative, or addition, to the username. Sentry is aware of email addresses and can display things such as Gravatars and unlock messaging capabilities.
   * @param id Your internal identifier for the user.
   * @param ip_address The user's IP address
   * @param segment The user segment, for apps that divide users in user segments.
   * @param username  The username. Typically used as a better label than the internal id.
   * @param owner actual owner route
   */
  public setUser(
    email: string = '',
    id: string = '',
    ip_address: string = '',
    segment: string = '',
    username: string = '',
    owner: string = ''
  ) {
    SentryBrowser.setUser({
      email,
      id,
      ip_address,
      segment,
      username,
      owner,
    });
  }

  /**
   * generate transaction id
   */
  public generateTransactionId(): void {
    this.transactionId = Math.random().toString(36).substring(2, 9);
    this.setTagInSentry();
  }

  /**
   * set tag in sentry
   */
  private setTagInSentry(): void {
    SentryAngular.configureScope((scope) => {
      scope.setTag('transaction_id', this.transactionId);
    });
  }

  /**
   * method that take a screenshot and send to sentry
   */
  public takeScreenshotAndSendSentry(): void {
    SentryAngular.configureScope((scope) => {
      scope.addEventProcessor(async (event, _) => {
        const screenshot = await this.takeScreenshot();
        return {
          ...event,
          extra: {
            ...event.extra,
            screenshot,
          },
        };
      });
    });
  }

  /**
   * method that take a screenshot with html2canvas
   */
  private async takeScreenshot(selector = 'body'): Promise<string> {
    return new Promise((resolve, _) => {
      let node = document.querySelector(selector) as HTMLElement;

      html2canvas(node).then(function (canvas) {
        let pngUrl = canvas.toDataURL();
        resolve(pngUrl);
      });
    });
  }
}
