import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectListComponent } from './select-list.component';
import { TulGetDataSelectPipeModule } from '../../../../../../pipes/getDataSelect/tul-get-data-select-pipe.module';
import { TulDataTablePipeModule } from '../../../../../../pipes/dataTable/tul-data-table-pipe.module';
import { TulTagModule } from '../../../../../../tul-tag/tul-tag.module';

@NgModule({
  declarations: [SelectListComponent],
  imports: [CommonModule, TulGetDataSelectPipeModule, TulDataTablePipeModule, TulTagModule],
  exports: [SelectListComponent],
})
export class SelectListModule {}
