import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { TypeTableEntity, TypeTables } from '../../../../interfaces/table';
import { StateRequest, TypeTable } from './../../../../enums/typeTable.enum';
import { TableMSService } from './../../../../services/tableMS.service';
import { ClickOption, CheckStatusOption } from '../../../../interfaces/export';
import { TranslocoService } from '@ngneat/transloco';

/**
 * table check status option
 */
@Component({
  selector: 'app-check-status',
  templateUrl: './check-status.component.html',
  styleUrls: ['./check-status.component.scss'],
})
export class CheckStatusComponent implements OnDestroy {
  /**
   * Check status option
   */
  @Input() option!: CheckStatusOption;
  /**
   * Data row
   */
  @Input() data: any;
  /**
   * Table
   */
  @Input() table!: TypeTables;
  /**
   * typeTable
   */
  @Input() typeTable!: TypeTable;
  /**
   * Emitter to execute option
   */
  @Output() clickOptionEmitter = new EventEmitter<ClickOption>();

  /**
   * Subscriptor get Document
   */
  suscriptionCheck = new Subscription();

  /**
   * Constructor
   * @param tableMSService service that manages all communication with the api for MS
   */
  constructor(private tableMSService: TableMSService, private translocoService: TranslocoService) {}

  /**
   * Returns the color given a state
   * @param option actual option (check status)
   * @return state color
   */
  getColorCheckStatus(option: CheckStatusOption) {
    return option.attribute
      ? this.data[option.attribute]
      : option?.functionCondition({ data: this.data });
  }

  /**
   * Execute check status
   * @param item value to change in request
   * @return void
   */
  executeOption(item: any) {
    this.clickOptionEmitter.emit({
      option: this.option,
      loading: true,
      status: StateRequest.REQUESTING,
    });
    if (this.option.httpSubscribe) {
      this.suscriptionCheck = this.option
        .httpSubscribe({ data: this.data, item })
        .pipe(
          map((response) => {
            return this.option.mapHttpSubscribe
              ? this.option.mapHttpSubscribe({ response, component: this })
              : response;
          })
        )
        .subscribe(
          (response) => {
            this.clickOptionEmitter.emit({
              option: this.option,
              loading: false,
              response,
              status: StateRequest.SUCCESS,
            });
          },
          (error) => {
            this.clickOptionEmitter.emit({
              option: this.option,
              loading: false,
              error,
              status: StateRequest.ERROR,
            });
          }
        );
      return;
    }
    // DEFAULT

    const table = this.table as TypeTableEntity;
    const formData: any = {};
    formData[this.option.attribute] = !this.data[this.option.attribute];
    this.tableMSService.edit(formData, this.data.uuid, table.url).subscribe(
      (response) => {
        this.clickOptionEmitter.emit({
          option: this.option,
          loading: false,
          response,
          status: StateRequest.SUCCESS,
        });
      },
      (error) => {
        this.clickOptionEmitter.emit({
          option: this.option,
          loading: false,
          error,
          status: StateRequest.ERROR,
        });
      }
    );
  }

  /**
   * cancel pop
   */
  cancelPop() {}

  /**
   * get confirm title
   * @param option actual option (check status)
   * @return title
   */
  getPopConfirmTitle(option: any) {
    if (!Boolean(option.condition?.title)) {
      return this.translocoService.translate('tulTable.options.checkStatus.popConfirm.title');
    }
    return typeof option.condition.title === 'string'
      ? option.condition.title
      : option.condition.title({ data: this.data, option });
  }

  /**
   * get tooltip
   * @param option actual option (check status)
   * @return tooltip
   */
  getToolTip(option: any) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return this.translocoService.translate('tulTable.options.checkStatus.tooltip');
    }
    return typeof option.tooltip.tooltip === 'boolean'
      ? option.tooltip.title
      : option.tooltip.title({ data: this.data, option });
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.suscriptionCheck.unsubscribe();
  }
}
