<input
  class="input-default"
  type="text"
  nz-input
  [placeholder]="
    column.filter?.placeholder
      ? column.filter?.placeholder
      : ('tulTable.filters.text.placeholder' | transloco)
  "
  [ngModel]="getValueFilter(column)"
  (ngModelChange)="ngModelChange($event)"
/>
