import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './components/toast.component';
import { TulStaticAlertModule } from '../tul-static-alert/tul-static-alert.module';
import { ToastInit } from './types/toast-init';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [CommonModule, BrowserModule, BrowserAnimationsModule, TulStaticAlertModule],
  declarations: [ToastComponent],
  exports: [ToastComponent],
})
export class TulToastModule {
  public static forRoot(config: ToastInit): ModuleWithProviders<TulToastModule> {
    return {
      ngModule: TulToastModule,
      providers: [
        {
          provide: 'TOASTINIT',
          useValue: config,
        },
      ],
    };
  }
}
