<nz-input-group
  class="input-group"
  nzSearch
  [nzAddOnAfter]="suffixIconButton"
  [nzAddOnBefore]="prefixIconButton"
>
  <nz-input-number
    class="input-group__input"
    [ngModel]="value"
    [nzMin]="getMin()"
    [nzMax]="getMax()"
    [nzStep]="0"
    (ngModelChange)="onChange($event)"
    (keyup)="validateMaxNumber($event)"
    (keypress)="validateFormatNumber($event)"
  ></nz-input-number>
</nz-input-group>

<ng-template #prefixIconButton>
  <button
    class="input-group__prefix-button"
    [disabled]="disableOption(MINUS)"
    (click)="onClick($event, MINUS)"
    nz-button
    nzType="primary"
    nzSearch
  >
    <i nz-icon nzType="minus"></i>
  </button>
</ng-template>
<ng-template #suffixIconButton>
  <button
    class="input-group__suffix-button"
    [disabled]="disableOption(PLUS)"
    (click)="onClick($event, PLUS)"
    nz-button
    nzType="primary"
    nzSearch
  >
    <i nz-icon nzType="plus"></i>
  </button>
</ng-template>
