import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TextEditorControllerComponent } from './text-editor-controller.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TulTooltipModule } from '../../../../../../tul-tooltip/tul-tooltip.module';

@NgModule({
  declarations: [TextEditorControllerComponent],
  imports: [CommonModule, AngularEditorModule, FormsModule, TranslocoModule, TulTooltipModule],
  exports: [TextEditorControllerComponent],
})
export class TextEditorControllerModule {}
