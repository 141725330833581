export enum SubTypeNumericModifierColumn {
  INPUT = 'input',
  INPUTBUTTONS = 'input-buttons',
}

export enum NumericModifierOption {
  PLUS = 'plus',
  MINUS = 'minus',
  CHANGE = 'change',
}
