import { Component, Input, OnInit } from '@angular/core';
import { TypeSelectors, SwitchColumn } from '../../../../interfaces';
import { Data } from '../../../../interfaces/data';
import { TulDataTablePipe } from '../../../../../pipes/dataTable/tul-data-table.pipe';

/**
 *
 */
@Component({
  selector: 'selectors-controller',
  templateUrl: './selectors-controller.component.html',
  styleUrls: ['./selectors-controller.component.scss'],
  providers: [TulDataTablePipe],
})
export class SelectorsControllerComponent implements OnInit {
  /** data */
  @Input() data!: Data;

  /** column */
  @Input() column!: TypeSelectors;

  /** selectData */
  selectData!: string | undefined;

  badgeStatus: string = '';

  constructor(private tulDataTablePipe: TulDataTablePipe) {}

  /** init method */
  ngOnInit(): void {
    this.selectData = this.tulDataTablePipe.transform(this.data, this.column.attribute);
    this.badgeStatus = this.checkSelectData();
  }

  /**
   * Check select data text for preview
   */
  checkSelectData(): string {
    if ((this.column as SwitchColumn).selectorsType) {
      if ((this.column as SwitchColumn).selectorsType?.true.value === this.selectData) {
        this.selectData = (this.column as SwitchColumn).selectorsType?.true.title;
        return 'success';
      }
      this.selectData = (this.column as SwitchColumn).selectorsType?.false.title;
      return 'error';
    }
    if (this.selectData === 'true' || Boolean(this.selectData) === true) {
      this.selectData = 'yes';
      return 'success';
    }
    this.selectData = 'not';
    return 'error';
  }
}
