import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TulInputModule } from '../../../../../../tul-input/tul-input.module';
import { PhoneNumberControllerComponent } from './phone-number-controller.component';

@NgModule({
  declarations: [PhoneNumberControllerComponent],
  imports: [CommonModule, FormsModule, TulInputModule],
  exports: [PhoneNumberControllerComponent],
})
export class PhoneNumberControllerModule {}
