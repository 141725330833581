import {
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  HostBinding,
  Inject,
} from '@angular/core';
import { TulButtonSize, TulButtonSizeEnum } from '../../../tul-btn/types/btn-size.type';
import { TabIndex } from '../../../shared/types/tab-index.type';
import { TulButtonAction } from '../../../tul-btn/types/btn-action.type';

@Component({
  selector: 'tul-access-btn',
  exportAs: 'tulAccessButton',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './tul-access-btn.component.html',
  styleUrls: ['./tul-access-btn.component.scss'],
})
export class TulAccessButtonComponent {
  /**
   * Environments for static style url
   */
  environment: any;

  /**
   * Text of access button
   *
   * @required
   */
  @Input() tulText: string = '';

  /**
   * Emit text events
   */
  @Output() tulTextChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Access button size from TulAccessButtonSizeEnum
   */
  @Input() tulSize: TulButtonSize = TulButtonSizeEnum.BIG;

  /**
   * Name of icon to show
   */
  @Input() tulIcon: string | null = null;

  /**
   * Flag that validates if is a only icon button
   */
  @Input() tulOnlyIcon: boolean = false;

  /**
   * Flag that validates if is a content width button
   */
  @Input() tulContentWidth: boolean = false;

  /**
   * Flag that validates if is disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Emit disabled change
   */
  @Output() tulDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Indicates order in navigation with keyboard
   */
  @Input() tulTabIndex: TabIndex = null;

  /**
   * Event that is fired when click in access button
   */
  @Output() callBack: EventEmitter<TulButtonAction> = new EventEmitter<TulButtonAction>();

  /**
   * Class of access button container
   */
  @HostBinding('class') class = 'tul-access-btn-container';

  /**
   * Class of access button container with content-width
   */
  @HostBinding('class.tul-access-btn--content-width') get isBtnContentWidth() {
    return !!this.tulContentWidth && !this.isOnlyIcon;
  }

  /**
   * Default img if icon daesn't exists
   */
  defaultImage: string = '';

  /**
   * Constructor
   * @param ENVIRONMENT
   */
  constructor(@Inject('ENVIRONMENT') ENVIRONMENT: any) {
    this.environment = ENVIRONMENT;
    this.defaultImage = `${this.environment.staticFilesUrl}icons/svg/chamo.svg`;
  }

  /**
   * Method that return the url of a global svg icon
   */
  public getIconSvg() {
    return `${this.environment.staticFilesUrl}icons/svg/${this.tulIcon}.svg`;
  }

  /**
   * Emit when there's any event on button
   * @param tulButtonAction
   */
  emitAction(tulButtonAction: TulButtonAction) {
    this.tulTextChange.emit(this.tulText);
    this.tulDisabledChange.emit(this.tulDisabled);
    this.callBack.emit(tulButtonAction);
  }

  get isBtnSmall() {
    return this.tulSize === TulButtonSizeEnum.SMALL;
  }

  get isOnlyIcon() {
    return this.tulOnlyIcon && !!this.tulIcon;
  }

  get getTabIndex() {
    if (this.getDisabled) return -1;
    return this.tulTabIndex;
  }

  get getDisabled() {
    return this.tulDisabled || null;
  }
}
