import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ImageComponent } from './image.component';
import { TulFileUploadModule } from '../../../../../../tul-file-upload/tul-file-upload.module';
import { NzUploadModule } from 'ng-zorro-antd/upload';

@NgModule({
  declarations: [ImageComponent],
  imports: [CommonModule, FormsModule, TranslocoModule, TulFileUploadModule, NzUploadModule],
  exports: [ImageComponent],
})
export class ImageModule {}
