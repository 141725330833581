import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';

/**
 * Resolves the input value and generates an Observable with this value
 */
@Pipe({
  name: 'tulToAsyncPipe',
})
export class TulToAsyncPipe implements PipeTransform {
  /**
   * Resolves the input value and generates an Observable with this value
   * @param {T | unknown} value actual option
   * @return {Observable<T | unknown>} Observable with input value
   */
  transform<T = unknown>(value: T): Observable<T> | any {
    const newValue: T = typeof value === 'function' ? value() : value;
    return isObservable(newValue) ? newValue : of(newValue);
  }
}
