import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TulTooltipConfig } from '../../../../../../tul-tooltip';
import { TextEditorColumnForms, TooltipForms } from '../../../../../interfaces';
import { TypeTextsForms } from '../../../../../interfaces';
import { TulDataTablePipe } from '../../../../../../pipes/dataTable/tul-data-table.pipe';

/**
 *
 */
@Component({
  selector: 'text-editor-controller',
  templateUrl: './text-editor-controller.component.html',
  styleUrls: ['./text-editor-controller.component.scss'],
  providers: [TulDataTablePipe],
})
export class TextEditorControllerComponent implements OnInit {
  /** */
  @Input() data = '';

  /** */
  @Input() column!: TextEditorColumnForms;

  /** */
  @Input() validateFieldFlag: boolean = false;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  tulTooltipConfig: TulTooltipConfig = {};

  editorConfig: AngularEditorConfig = {
    spellcheck: true,
    height: '300px',
    minHeight: '0',
    maxHeight: '',
    editable: true,
    width: 'auto',
    minWidth: '0',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
  };

  ngOnInit(): void {
    if (this.column.customClasses) {
      this.editorConfig.customClasses = this.column.customClasses;
    }
  }

  /**
   * Method that use transformData and emit the changed data
   */
  changeData(value: any) {
    this.data = this.column.transformData
      ? this.column.transformData({ data: value, column: this.column })
      : value;
    this.onChange.emit(this.data);
  }

  transformTooltip(tooltip: TooltipForms) {
    return (tooltip as TulTooltipConfig) ?? {};
  }
}
