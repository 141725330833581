import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TulInputModule } from '../../../../../../tul-input/tul-input.module';
import { TimeControllerComponent } from './time-controller.component';

@NgModule({
  declarations: [TimeControllerComponent],
  imports: [CommonModule, FormsModule, TulInputModule],
  exports: [TimeControllerComponent],
})
export class TimeControllerModule {}
