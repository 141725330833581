import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorControllerComponent } from './color-controller.component';
import { TulInputModule } from '../../../../../tul-input/tul-input.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, TulInputModule, FormsModule],
  declarations: [ColorControllerComponent],
  exports: [ColorControllerComponent],
})
export class ColorControllerModule {}
