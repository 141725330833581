import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { PaginationPosition } from '../../enums/paginationPosition.enum';
import { TypeHeaderOptions, TypeOption, TypesMultiEdit } from '../../enums/typeOption.enum';
import {
  StateRequest,
  StatuExecuteOption,
  TypeExecuteTable,
  TypeTable,
} from '../../enums/typeTable.enum';
import { ColumnTable } from '../../interfaces/columns/column';
import { TableEvents } from '../../interfaces/emiters';
import { ExpandOption } from '../../interfaces/export';
import {
  ArgumentTable,
  EmptyState,
  TableGraphQl,
  TypeTableEntity,
  TypeTables,
} from '../../interfaces/table';
import { SubjectsService } from '../../services/subjects.service';
import { TableGraphQlService } from '../../services/tableGraphQl.service';
import { TableMonolithService } from '../../services/tableMonolith.service';
import { TableMSService } from '../../services/tableMS.service';
import { TypeQueryFilter } from '../../enums/typeQueryFilter.enum';
import { DataTable } from '../../interfaces/data';
import { SortTable } from '../../interfaces/table';

/**
 * Table component
 */
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  /**
   * Array data
   */
  @Input() data: Array<DataTable<any>> = [];
  /**
   * Table
   */
  @Input() table!: TypeTables;
  /**
   * Array columns
   */
  @Input() columns!: Array<ColumnTable>;
  /**
   * Loader
   */
  @Input() loading!: boolean;

  /**
   * Emitter to execute option
   */
  @Output() executeOption = new EventEmitter<any>();
  /**
   * Emitter to table
   */
  @Output() tableEvents = new EventEmitter<TableEvents>();

  /**
   * Emitter of change filter
   */
  @Output() executeOptionFilter = new EventEmitter<any>();

  /**
   * Selected row when the same is clicked
   */
  @Output() rowClicked = new EventEmitter<any>();

  /**
   * total elements in table
   */
  total!: number;

  /**
   * size page in table
   */
  size: number = 10;

  /**
   * number of page in table
   */
  page = 1;

  /**
   * subscription request get data
   */
  subscriptionData = new Subscription();

  /**
   * actual sort data
   */
  sort!: SortTable;
  /**
   * actual argument to filter data
   */
  argument!: Array<ArgumentTable> | undefined;

  /**
   * Actual filters
   */
  filter: Map<string, any> = new Map<string, any>();

  /**
   * subscription external component communication
   */
  subscriptionExternal = new Subscription();
  /**
   * show/hidden multi-check
   */
  showChecked: boolean = false;
  /**
   *  checked all elements column
   */
  checked = false;
  /**
   * indeterminate checked
   */
  indeterminate = false;
  /**
   *  check data in current page
   */
  listOfCurrentPageData: ReadonlyArray<any> = [];
  /**
   * all data checked
   */
  setOfCheckedData = new Map<string, any>();

  /**
   * pagination position table
   */
  BOTTOM = PaginationPosition.BOTTOM;
  /**
   * set a custom empty state
   */
  emptyState: EmptyState = { template: undefined };

  /**
   * all ids to expand info
   */
  expandSet = new Set<number>();

  /**
   * environment
   */
  environment: any;

  /**
   * constructor
   * @param ENVIRONMENT
   * @param tableMSService service that manages all communication with the api for MS
   * @param subjectsService externalExecutions intra components
   * @param tableGraphQlService service that manages all communication with the api for GraphQl
   * @param tableMonolithService service that manages all communication with the api for Monolith
   */
  constructor(
    @Inject('ENVIRONMENT') ENVIRONMENT: any,
    private tableMSService: TableMSService,
    private subjectsService: SubjectsService,
    private tableGraphQlService: TableGraphQlService,
    private tableMonolithService: TableMonolithService
  ) {
    this.environment = ENVIRONMENT;
  }

  /**
   * NgOnInit
   */
  ngOnInit() {
    this.subscriptionExternal = this.subjectsService.externalExecutions.subscribe(
      ({ option, data, status, type }) => {
        switch (option) {
          case TypeHeaderOptions.REFRESH:
            return this.initTables();
          case TypesMultiEdit.OPENCHECKS:
            this.showChecked = true;
            break;
          case TypesMultiEdit.CLOSECHECKS:
            this.showChecked = false;
            this.listOfCurrentPageData.forEach((item) =>
              this.updateCheckedSet(this.getIdChecksBox(item), false, item)
            );
            this.refreshCheckedStatus();
            break;
          case TypesMultiEdit.CLEANCHECKS:
            this.listOfCurrentPageData.forEach((item) =>
              this.updateCheckedSet(this.getIdChecksBox(item), false, item)
            );
            this.refreshCheckedStatus();
            break;
          case TypesMultiEdit.DATACHECKS:
            this.executeOption.emit({
              option: TypeHeaderOptions.MULTIEDIT,
              data: Array.from(this.setOfCheckedData.values()),
            });
            break;
          case TypeHeaderOptions.EXPORT:
            this.loading = status === StatuExecuteOption.LOADING;
            return;
          default:
            break;
        }
      }
    );

    this.initTables(true);
    if (this.table?.checksBox?.defaultCheckedRows) {
      this.setOfCheckedData = this.table?.checksBox?.defaultCheckedRows;
    }
  }

  /**
   * change the value of the checked value
   */
  changeShowChecked() {
    this.showChecked = !this.showChecked;
    this.onAllChecked(false);
    const checkedIds = Array.from(this.setOfCheckedData.keys());
    this.executeOption.emit({
      all: this.showChecked,
      data: this.setOfCheckedData,
      ids: checkedIds,
    });
  }

  /**
   * returns the value of the selected attribute or by default the uuid
   * @param item object value
   */
  getIdChecksBox(item: any) {
    const attributeValue = this.table.checksBox?.attribute
      ? this.getPropByString(item, this.table.checksBox?.attribute)
      : item.uuid;
    return attributeValue;
  }

  /**
   * return if the column is checked or not (can use a personalize function)
   * @param data
   */
  getRowChecked(data: any): boolean {
    const attributeValue = this.table.checksBox?.attribute
      ? this.getPropByString(data, this.table.checksBox?.attribute)
      : data.uuid;
    return this.setOfCheckedData.has(attributeValue);
  }

  /**
   * obtain children value from object and string param
   * @param obj
   * @param path path to search in object
   */
  getPropByString(obj: any, path: any): any {
    if (!path) return obj;
    const properties = path.split('.');
    return this.getPropByString(obj[properties.shift()], properties.join('.'));
  }

  /**
   * check/uncheck all row
   * @param value true/false value to check/uncheck
   */
  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) =>
      this.updateCheckedSet(this.getIdChecksBox(item), value, item)
    );
    this.refreshCheckedStatus();
  }

  /**
   * update status value check row
   */
  refreshCheckedStatus(): void {
    const checkedIds = Array.from(this.setOfCheckedData.keys());
    this.checked = this.listOfCurrentPageData.length
      ? this.listOfCurrentPageData.every((item) => checkedIds.includes(this.getIdChecksBox(item)))
      : false;
    this.indeterminate =
      this.listOfCurrentPageData.some((item) => checkedIds.includes(this.getIdChecksBox(item))) &&
      !this.checked;
  }

  /**
   * add data or delete in map
   * @param id row
   * @param checked value (checked/unchecked)
   * @param data data to update
   */
  updateCheckedSet(id: string, checked: boolean, data?: any): void {
    if (checked) {
      this.setOfCheckedData.set(id, data);
    } else {
      this.setOfCheckedData.delete(id);
    }
    this.executeOption.emit({
      all: this.showChecked,
      data: this.setOfCheckedData,
      selected: { id, checked },
    });
  }

  /**
   * checked change emitter
   * @param id row
   * @param checked value (checked/unchecked)
   * @param data data to update
   */
  onItemChecked(id: string, checked: boolean, data: any): void {
    this.updateCheckedSet(id, checked, data);
    this.refreshCheckedStatus();
  }

  /**
   * Current page data change
   * @param $event values in array
   */
  onCurrentPageDataChange($event: ReadonlyArray<any>): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  /**
   * init table
   */
  initTables(init = false) {
    switch (this.table.typeTable) {
      case TypeTable.LIST:
        this.initTableList();
        break;
      default:
        this.initTableEntity(init);
    }
  }

  /**
   * init table list
   */
  initTableList() {
    this.size = this.table.size ? this.table.size : 10;
  }
  /**
   * init table entity
   */
  initTableEntity(init = false) {
    let auxDefaultFilters: any;
    if (this.table.filter?.default) {
      auxDefaultFilters = this.table?.filter?.default
        ? typeof this.table.filter?.default === 'object'
          ? this.table.filter?.default
          : this.table.filter?.default()
        : [];
    } else {
      auxDefaultFilters = this.table?.filters
        ? typeof this.table.filters === 'object'
          ? this.table.filters
          : this.table.filters()
        : [];
    }
    this.filter = new Map<string, string>();
    if (init) {
      auxDefaultFilters.forEach((element: any) => {
        this.filter.set(element.attribute, { ...element });
      });
    } else {
      auxDefaultFilters
        .filter((fil: any) => !fil.dynamic)
        .forEach((element: any) => {
          this.filter.set(element.attribute, { ...element });
        });
    }
    this.sort = {
      type: null,
      attribute: '',
    };
    this.addDefaultSort();
    const tableGraphql = this.table as TableGraphQl;
    this.argument =
      typeof tableGraphql.argument === 'function' ? tableGraphql.argument() : tableGraphql.argument;
    this.getData(1, this.table.size, TypeExecuteTable.INIT);
    this.expandSet = new Set<number>();
  }
  /**
   * Add default sort
   */
  addDefaultSort() {
    this.sort = this.table.sort
      ? { type: this.table.sort.type, attribute: this.table.sort.attribute }
      : { type: null, attribute: '' };
  }

  /**
   * Get data table
   * @param page actual page
   * @param size size of table for page
   * @param type type of table
   */
  getData(page: number, size: number | undefined, type: TypeExecuteTable) {
    const tableEntity = this.table as TypeTableEntity;
    this.responseData(this.data, true, page, type, StateRequest.LOADING, this.total, {});
    this.size = size !== null && size !== undefined ? size : this.size;
    const subscription = tableEntity.httpSubscribe
      ? tableEntity.httpSubscribe({
          page,
          size: this.size,
          sort: this.sort,
          filter: this.filter,
          component: this,
          type,
        })
      : this.getTableService(page, tableEntity);

    this.subscriptionData.unsubscribe();
    if (subscription) {
      this.subscriptionData = subscription
        .pipe(
          map((response) =>
            tableEntity.mapHttpSubscribe
              ? tableEntity.mapHttpSubscribe({ response, component: this })
              : response
          )
        )
        .subscribe(
          ({ content, total_elements: totalElements }: { content: any; total_elements: any }) => {
            this.responseData(
              [...content],
              false,
              page,
              type,
              StateRequest.SUCCESS,
              totalElements,
              {
                content,
                totalElements,
              }
            );
          },
          (error: any) => {
            this.responseData([], false, page, type, StateRequest.ERROR, 0, error);
          }
        );
    }
  }

  /**
   * Get data from the type table
   * @param page actual page
   * @param table type of table
   * @return observable
   */
  getTableService(page: any, table: any): any {
    let typeQueryFilter: TypeQueryFilter = this.table.filter?.type
      ? this.table.filter.type
      : TypeQueryFilter.SPRINGSEARCH;
    let auxDefaultFilters: any;
    if (this.table.filter?.default) {
      auxDefaultFilters = this.table?.filter?.default
        ? typeof this.table.filter?.default === 'object'
          ? this.table.filter?.default
          : this.table.filter?.default()
        : [];
    } else {
      auxDefaultFilters = this.table?.filters
        ? typeof this.table.filters === 'object'
          ? this.table.filters
          : this.table.filters()
        : [];
    }
    switch (this.table.typeTable) {
      case TypeTable.GRAPHQL:
        return this.tableGraphQlService.get(
          table as TableGraphQl,
          this.columns,
          page,
          this.size,
          this.filter,
          auxDefaultFilters,
          typeQueryFilter,
          this.sort,
          this.argument
        );
      case TypeTable.MONOLITH:
        return this.tableMonolithService.get(
          table.url,
          this.columns,
          page,
          this.size,
          this.filter,
          auxDefaultFilters,
          this.sort,
          table
        );
      case TypeTable.LIST:
        break;
      default:
        return this.tableMSService.get(
          table.url,
          this.columns,
          page,
          this.size,
          this.filter,
          auxDefaultFilters,
          typeQueryFilter,
          this.sort,
          table
        );
    }
  }

  /**
   * response of observable get data
   * @param data response data
   * @param loading value of loading
   * @param page actual page
   * @param type type of table
   * @param state state of observable
   * @param totalElements total elements table
   * @param response all response
   */
  responseData(
    data: any,
    loading: any,
    page: any,
    type: any,
    state: any,
    totalElements: any,
    response: any
  ) {
    this.data = data;
    this.loading = loading;
    this.total = totalElements;
    this.page = page;
    this.tableEvents.emit({
      page,
      size: this.size,
      sort: this.sort,
      filter: this.filter,
      component: this,
      type,
      state,
      response,
      columns: this.columns,
    });
    this.getEmptyState();
  }

  /**
   * emitter of click events
   * @param event click event
   * @param index
   * @return void
   */
  clickOption(event: any, index: number) {
    this.executeOption.emit(event);
    switch (event.option.type) {
      case TypeOption.DELETE:
      case TypeOption.SELECTSTATUS:
      case TypeOption.CHECKSTATUS:
        this.loading = event.loading;
        switch (event.status) {
          case StateRequest.ERROR:
            this.tableEvents.emit({
              page: this.page,
              size: this.size,
              sort: this.sort,
              filter: this.filter,
              component: this,
              type: event.option.type,
              state: StateRequest.ERROR,
              response: event.error,
              columns: this.columns,
            });
            break;
          case StateRequest.SUCCESS:
            switch (this.table.typeTable) {
              case TypeTable.LIST:
                this.initTableList();
                break;
              default:
                this.getData(this.page, this.size, event.option.type);
            }
            break;
        }
        break;
      case TypeHeaderOptions.REFRESH:
        this.initTableEntity();
        return;
      case TypeOption.EXPAND:
        this.onExpandChange(index);
        return;
    }
    if (event?.option?.responseOption) {
      event?.option?.responseOption(event);
    }
  }

  /**
   * Page index change
   * @param page page
   */
  nzPageIndexChange(page: any) {
    this.getData(page, this.size, TypeExecuteTable.CHANGEPAGE);
  }

  /**
   * Page size change
   * @param size size
   */
  nzPageSizeChange(size: any) {
    this.getData(1, size, TypeExecuteTable.CHANGESIZE);
  }

  /**
   * Query params change
   * @param params object page size sort filter
   */
  onQueryParamsChange(params: NzTableQueryParams) {}

  /**
   * Sort order change
   * @param event type sort
   * @param column actual column
   */
  actionOrder(event: any, column: ColumnTable) {
    if (event) {
      this.sort = { type: event, attribute: column?.sort?.attribute ?? column.attribute };
    } else {
      this.sort = { type: event, attribute: '' };
    }
    this.getData(1, this.size, TypeExecuteTable.SORT);
  }

  /**
   * Emit column data when the row is clicked
   * @param event
   */
  selectRow(event: any) {
    this.rowClicked.emit(event);
  }

  /**
   * execute filters (delete/action)
   * @param param type: type of filter  data: all data column: actual column
   */
  executeFilter({ type, data, column }: { type: any; data: any; column: any }) {
    this.executeOptionFilter.emit({ type, data, column });
    switch (type) {
      case 'actionFilter':
        this.getData(1, this.size, TypeExecuteTable.FILTER);
        break;
      case 'deletesFilter':
        (column.filter as any)._isActive = false;
        this.getData(this.page, this.size, TypeExecuteTable.FILTER);
        break;
      default:
        break;
    }
  }

  /**
   * is visible filter
   * @param event value
   * @param column actual column
   */
  nzVisibleChangeFilters(event: any, column: ColumnTable) {
    (column.filter as any)._isActive = true;
    this.executeOptionFilter.emit({ type: 'clickFilter', data: {}, column });
  }

  /**
   * validates if the filter is active
   * @param column actual column
   * @return true or false
   */
  isActiveFilter(column: ColumnTable) {
    return this.filter.has(column.filter?.attribute ?? column.attribute);
  }

  /**
   * get empty state
   */
  getEmptyState() {
    this.emptyState.template =
      typeof this.table.emptyState?.template === 'function'
        ? this.table.emptyState?.template()
        : this.table.emptyState?.template;
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptionData.unsubscribe();
    this.subscriptionExternal.unsubscribe();
  }
  /**
   * Validate if the scroll is active and set parameters
   */
  getScrollTable() {
    if (this.table.scroll?.scroll) {
      if (this.data?.length > 0)
        return { x: this.table.scroll?.x ?? '2500px', y: this.table.scroll?.y ?? '70vh' };
      return { x: '2500px' };
    }
    return { x: '' };
  }
  /**
   * function to execute when drag and drop emit
   */
  sortChange(event: any) {
    if (event.previousIndex === event.currentIndex) return;
    this.loading = true;
    const arrayForSort = [...this.data];
    moveItemInArray(arrayForSort, event.previousIndex, event.currentIndex);
    this.table.dragSorting?.sortChange({ data: arrayForSort, option: undefined }).subscribe(
      (response) => {
        this.data = [...arrayForSort];
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  /**
   * Set and delete rows to expand
   * @param id value row to expand
   */
  onExpandChange(id: number): void {
    if (!this.expandSet.has(id)) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  /**
   * get template
   * @param options actual options
   * @return template expand
   */
  getCustomTemplate(options: Array<ExpandOption>): any {
    const option = options.find((op: ExpandOption) => op.type === TypeOption.EXPAND);
    return typeof option?.template === 'function'
      ? option.template({ data: this.data })
      : option?.template;
  }
}
