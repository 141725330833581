import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TulAlertColor, TulAlertColorEnum } from '../../types/alert-color.type';
import { TulAlertType, TulAlertTypeEnum } from '../../types/alert-type.type';

@Component({
  selector: 'tul-static-alert',
  templateUrl: './tul-static-alert.component.html',
  styleUrls: ['./tul-static-alert.component.scss'],
})
export class TulStaticAlertComponent {
  /**
   * Flag that validates if alert is closed
   */
  isClosed: boolean = false;

  /**
   * Alert type from TulAlertTypeEnum
   */
  @Input() tulType: TulAlertType = TulAlertTypeEnum.DEFAULT;

  /**
   * Alert color from TulAlertColorEnum
   */
  @Input() tulColor: TulAlertColor = TulAlertColorEnum.SUCCESS;

  /**
   * Principal message of alert
   *
   * @required
   */
  @Input() message: string = '';

  /**
   * Aditional description
   */
  @Input() description: string | null | undefined = null;

  /**
   * Flag that validates if is a banner
   */
  @Input() isBanner: boolean = false;

  /**
   * Flag that validates if is closable
   */
  @Input() isClosable: boolean = false;

  /**
   * Flag that validates if is a alert with icon
   */
  @Input() withIcon: boolean = false;

  /**
   * Flag that validates if is a alert with button
   */
  @Input() withButton: boolean = false;

  /**
   * Text on button
   */
  @Input() buttonText: string = '';

  /**
   * Event out when button was clicked
   */
  @Output() buttonPressed: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Flag that validates if is a alert with icon
   */
  @Input() fullWidth: boolean = false;

  /**
   * Event that is fired when closing the alert
   */
  @Output() readonly alertClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  get isTypeDefault() {
    return this.tulType === TulAlertTypeEnum.DEFAULT;
  }

  get isTypeLight() {
    return this.tulType === TulAlertTypeEnum.LIGHT;
  }

  get isTypeDark() {
    return this.tulType === TulAlertTypeEnum.DARK;
  }

  get isTypeOutlined() {
    return this.tulType === TulAlertTypeEnum.OUTLINED;
  }

  get isColorSuccess() {
    return this.tulColor === TulAlertColorEnum.SUCCESS;
  }

  get isColorInfo() {
    return this.tulColor === TulAlertColorEnum.INFO;
  }

  get isColorError() {
    return this.tulColor === TulAlertColorEnum.ERROR;
  }

  get isColorWarning() {
    return this.tulColor === TulAlertColorEnum.WARNING;
  }

  get getIcon() {
    let icon = '';
    if (this.isColorSuccess) icon = 'circle_check_filled';
    if (this.isColorInfo) icon = 'info_filled';
    if (this.isColorError) icon = 'circle_error_filled';
    if (this.isColorWarning) icon = 'warning_filled';
    if (this.isTypeOutlined) icon = `${icon.substring(0, icon.length - 6)}outline`;
    return icon;
  }

  /**
   * Method that remove alert (close button action)
   */
  public closeAlert() {
    this.isClosed = true;
    this.alertClosed.emit(true);
  }

  /**
   * Alert button action
   */
  buttonClick() {
    this.buttonPressed.emit(true);
  }
}
