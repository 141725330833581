<button
  nz-button
  class="button tul-btn tul-btn--secondary"
  nzType="default"
  [nzTooltipTitle]="getToolTip(option)"
  nzTooltipPlacement="top"
  nz-tooltip
  nz-dropdown
  [nzDropdownMenu]="exportOptions"
  [disabled]="option.disabled | tulToAsyncPipe | async"
>
  <svg-export class="button__icon"></svg-export>
  {{ "tulTable.options.export.title" | transloco }}
</button>

<nz-dropdown-menu #exportOptions="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let itemExport of option.listFormats" (click)="export(itemExport)">
      {{ itemExport.name }}
    </li>
  </ul>
</nz-dropdown-menu>
