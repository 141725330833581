<div class="document-container">
  <tul-select-list-select
    [tulLabel]="(column.required ? '* ' : '') + column.header"
    [tulState]="column.error ? 'error' : 'default'"
    [tulAdditionalInfo]="column.error ?? ''"
    [disabled]="column.disabled ?? false"
    [(ngModel)]="data[column.attributeDocumentType]"
    [tulPlaceholder]="column.placeholder ?? 'Selecciona una opción'"
    [tulData]="currentData"
    (ngModelChange)="changeData($event)"
  >
  </tul-select-list-select>

  <tul-input-text
    [tulLabel]="' '"
    [tulAdditionalInfo]="column.error ?? ''"
    [tulState]="column.error ? 'error' : 'default'"
    [tulDisabled]="column.disabled ?? false"
    [tulPlaceholder]="column.placeholder ? column.placeholder : ''"
    [(ngModel)]="data[column.attributeDocumentNumber]"
    (ngModelChange)="changeData($event)"
  >
  </tul-input-text>
</div>
