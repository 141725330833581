<tul-radio
  [tulLabel]="(column.required ? '* ' : '') + column.header"
  [tulAdditionalInfo]="column.error ?? ''"
  [tulState]="column.error ? 'error' : 'default'"
  [tulDisabled]="column.disabled ?? false"
  [tulValues]="column.values ?? []"
  [(ngModel)]="data"
  [tulTooltipconfig]="column.tooltip"
  [tulState]="column.error ? 'error' : 'default'"
  (ngModelChange)="modelChange($event)"
></tul-radio>
