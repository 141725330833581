import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnTable } from '../../../../interfaces/columns/column';
import { TypeTable } from './../../../../enums/typeTable.enum';
import { ClickOption, CustomOption } from '../../../../interfaces/export';

/**
 * table custom option
 */
@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
})
export class CustomComponent {
  /**
   * Check status option
   */
  @Input() option!: CustomOption;
  /**
   * Data row
   */
  @Input() data: any;
  /**
   * Emitter to execute option
   */
  @Output() clickOptionEmitter = new EventEmitter<ClickOption>();
  /**
   * actual column
   */
  @Input() column!: ColumnTable;
  /**
   * Type Table
   */
  @Input() typeTable!: TypeTable;

  /**
   * get template
   * @param option actual option (custom)
   * @return template
   */
  getCustomTemplate(option: CustomOption) {
    return typeof option.template === 'function'
      ? option.template({ data: this.data, column: this.option })
      : option.template;
  }

  /**
   * get tooltip
   * @param option actual option (custom)
   * @return tooltip
   */
  getToolTip(option: any) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return null;
    }
    const isBoolean = typeof option.tooltip.tooltip === 'boolean';
    const title = isBoolean
      ? option.tooltip.title
      : option.tooltip.title({ data: this.data, option });
    return title;
  }
}
