import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { TulButtonModule } from '../../../tul-btn';
import { TulAlertModule } from '../../../tul-alert';
import { TulHeaderfeedbackGroupComponent } from './components/header-feedback/header-feedback.component';
import { TulHeaderGroupComponent } from './components/header-group/header-group.component';
import { TulHeaderSectionComponent } from './components/header-section/header-section.component';
import { TulHeaderSubGroupComponent } from './components/header-sub-group/header-sub-group.component';
import { ColorControllerModule } from './controllers/color-controller/color-controller.module';
import { CustomControllerModule } from './controllers/custom-controller/custom-controller.module';
import { DateControllerModule } from './controllers/date-controller/date-controller.module';
import { DocumentControllerModule } from './controllers/document-controller/document-controller.module';
import { FilesControllerModule } from './controllers/files-controller/files-controller.module';
import { MapControllerModule } from './controllers/map-controller/map-controller.module';
import { NumericControllerModule } from './controllers/numeric-controller/numeric-controller.module';
import { SelectControllerModule } from './controllers/select-controller/select-controller.module';
import { SelectorsControllerModule } from './controllers/selectors-controller/selectors-controller.module';
import { TextControllerModule } from './controllers/text-controller/text-controller.module';
import { TextEditorControllerModule } from './controllers/text-controller/text-editor-controller/text-editor-controller.module';
import { ModificationComponent } from './modification.component';

@NgModule({
  declarations: [
    ModificationComponent,
    TulHeaderGroupComponent,
    TulHeaderSubGroupComponent,
    TulHeaderfeedbackGroupComponent,
    TulHeaderSectionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    TextControllerModule,
    ColorControllerModule,
    TulButtonModule,
    TulAlertModule,
    CustomControllerModule,
    DocumentControllerModule,
    FilesControllerModule,
    SelectorsControllerModule,
    SelectControllerModule,
    NumericControllerModule,
    DateControllerModule,
    SelectControllerModule,
    DocumentControllerModule,
    MapControllerModule,
    TextEditorControllerModule,
  ],
  exports: [ModificationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModificationModule {}
