import { Component, Input } from '@angular/core';
import { TypeSelectsTable } from '../../../../../interfaces/columns/select';

@Component({
  selector: 'select-entity',
  templateUrl: './select-entity.component.html',
  styleUrls: ['./select-entity.component.scss'],
})
export class SelectEntityComponent {
  /**
   * Actual Column
   */
  @Input() column!: TypeSelectsTable | any;

  /**
   * Data row
   */
  @Input() data: any;
}
