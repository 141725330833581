<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'image'">
      <lib-image
        [data]="filesData"
        [column]="getImageColumn(column)"
        (onChange)="onChange.emit($event)"
      ></lib-image>
    </ng-container>
    <ng-container *ngSwitchCase="'file'">
      <lib-file
        [data]="filesData"
        [column]="getFileColumn(column)"
        (onChange)="onChange.emit($event)"
      ></lib-file>
    </ng-container>
  </ng-container>
</ng-container>
