<div class="container-options-header">
  <h5 nz-typography class="container-options-header__title">{{ title }}</h5>
  <div class="container-options container-options-header__container-options">
    <div class="search-container"></div>
    <ng-container *ngFor="let option of options">
      <ng-container *ngIf="validateVisibility(option)">
        <ng-container [ngSwitch]="option.type">
          <!-- ADD -->
          <ng-container *ngSwitchCase="'add'">
            <app-add [option]="option" (executeOption)="clickOption($event)"></app-add>
          </ng-container>
          <!-- REFRESH -->
          <ng-container *ngSwitchCase="'refresh'">
            <app-refresh [option]="option" (executeOption)="clickOption($event)"></app-refresh>
          </ng-container>
          <!-- IMPORT -->
          <ng-container *ngSwitchCase="'import'">
            <app-import [option]="option" (executeOption)="clickOption($event)"></app-import>
          </ng-container>
          <!-- CUSTOM -->
          <ng-container *ngSwitchCase="'custom'">
            <app-custom [option]="option"></app-custom>
          </ng-container>
          <!-- MULTIEDIT -->
          <ng-container *ngSwitchCase="'multi-edit'">
            <app-multiple-edit
              [option]="option"
              (executeOption)="clickOption($event)"
            ></app-multiple-edit>
          </ng-container>
          <!-- Export -->
          <ng-container *ngSwitchCase="'export'">
            <app-export [option]="option" (executeOption)="clickOption($event)"></app-export>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
