import { Component, Input, OnInit } from '@angular/core';
import { TypeVisible } from '../../enums/typeVisible';
import { TypeLinkForms } from '../../enums';
import { ColumnForms, ExternalLink, InternalLink } from '../../interfaces';
import { Data, Group } from '../../interfaces/group';
import { Visible } from '../../interfaces/visible';

/**
 *
 */
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  /** */
  @Input() groups: Array<Group> = [];

  /** */
  @Input() data: Data<any> = {};

  typeLink = TypeLinkForms;

  /** */
  mapGroups: Map<string, any> = new Map<string, any>();

  /**
   *
   */
  ngOnInit(): void {
    this.calculateGroupMaps();
  }

  getGroup(groupColumns: any) {
    return groupColumns.value;
  }

  /**
   *
   */
  calculateGroupMaps() {
    this.groups.forEach((group) => {
      const map = new Map();
      group.sections.forEach((section) => {
        section.columns.forEach((column) => {
          const cols = section.columns.filter(
            (elem) =>
              Number(elem.number) === Number(column.number) &&
              (elem?.visible ? this.validateVisible(elem?.visible) : true)
          );
          if (!map.has(String(column.number))) {
            map.set(String(column.number), cols);
          }
        });
      });
      this.mapGroups.set(String(group.number), map);
    });
  }

  /**
   * validateVisible
   * @param visible
   * @returns
   */
  validateVisible(visible: Visible) {
    return Object.prototype.hasOwnProperty.call(visible, TypeVisible.INDETAIL)
      ? visible[TypeVisible.INDETAIL]
      : true;
  }

  /**
   * Generate link url
   * @return string link
   */
  getLink(column: ColumnForms) {
    const linkInternal = column.link as InternalLink;
    const externalInternal = column.link as ExternalLink;

    if (externalInternal.url) {
      return typeof externalInternal.url === 'string'
        ? externalInternal.url
        : externalInternal.url && externalInternal.url({ data: this.data, column });
    }

    if (linkInternal.uuid) {
      return linkInternal.uuid;
    }
    return '';
  }
}
