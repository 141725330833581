import { AlertConfig } from './alert-config';

export const defaultAlertConfig: AlertConfig = {
  message: '',
  description: '',
  tulType: 'default',
  tulColor: 'success',
  isClosable: true,
  withIcon: true,
};
