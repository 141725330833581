import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateControllerComponent } from './date-controller.component';
import { TulDatePipeModule } from '../../../../../pipes';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  declarations: [DateControllerComponent],
  imports: [CommonModule, FormsModule, TulDatePipeModule, TulDataTablePipeModule],
  exports: [DateControllerComponent],
})
export class DateControllerModule {}
