import { Component, Input } from '@angular/core';
import { CustomColumnTable } from './../../../../interfaces/columns/custom';

/**
 * Body option custom
 */
@Component({
  selector: '[custom]',
  templateUrl: './custom.component.html',
})
export class CustomComponent {
  /**
   * Actual Column
   */
  @Input() column!: CustomColumnTable;
  /**
   * Data row
   */
  @Input() data: any;

  /**
   * Generate template in custom option
   * @param option actual option (custom)
   * @return custom template
   */
  getCustomTemplate(column: any) {
    return typeof column.template === 'function'
      ? column.template({ data: this.data, column: this.column })
      : column.template;
  }
}
