import { Pipe, PipeTransform } from '@angular/core';

/**
 * get data interface to list values
 */
@Pipe({
  name: 'tulGetDataSelectPipe',
})
export class TulGetDataSelectPipe implements PipeTransform {
  transform(value: any, values?: Array<{ text: string; value: string }>): any {
    let indexValue;
    if (values) {
      indexValue = values?.findIndex((element) => element.value === value);
      if (indexValue !== -1) {
        return values[indexValue].text;
      }
      return value;
    } else {
      return value;
    }
  }
}
