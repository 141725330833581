import { Component, Input } from '@angular/core';
import { ColorColumn } from '../../../../interfaces/columns/color';
import { TulDataTablePipe } from '../../../../../pipes/dataTable/tul-data-table.pipe';

/**
 * Body option color
 */
@Component({
  selector: '[color]',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss'],
  providers: [TulDataTablePipe],
})
export class ColorComponent {
  /**
   * Actual Column
   */
  @Input() column!: ColorColumn;
  /**
   * Data row
   */
  @Input() data: any;

  /**
   * Constructor
   * @param tulDataTablePipe pipe transform to tul structure to data
   */
  constructor(private tulDataTablePipe: TulDataTablePipe) {}

  /**
   * get the color to paint it
   * @param data object data
   * @return string tag css style in line
   */
  getcolor(data: any) {
    return 'background-color: ' + this.tulDataTablePipe.transform(data, this.column.attribute);
  }
}
