import { ModuleWithProviders, NgModule } from '@angular/core';
import { TulNumberPhonePipe } from './tul-number-phone.pipe';

@NgModule({
  exports: [TulNumberPhonePipe],
  imports: [],
  declarations: [TulNumberPhonePipe],
})
export class TulNumberPhonePipeModule {
  public static forRoot(environment: any): ModuleWithProviders<TulNumberPhonePipeModule> {
    return {
      ngModule: TulNumberPhonePipeModule,
      providers: [
        {
          provide: 'ENVIRONMENT',
          useValue: environment,
        },
      ],
    };
  }
}
