import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { ColorControllerModule } from './controllers/color-controller/color-controller.module';
import { CustomControllerModule } from './controllers/custom-controller/custom-controller.module';
import { DateControllerModule } from './controllers/date-controller/date-controller.module';
import { DocumentControllerModule } from './controllers/document-controller/document-controller.module';
import { FilesControllerModule } from './controllers/files-controller/files-controller.module';
import { NumericControllerModule } from './controllers/numeric-controller/numeric-controller.module';
import { SelectControllerModule } from './controllers/select-controller/select-controller.module';
import { SelectorsControllerModule } from './controllers/selectors-controller/selectors-controller.module';
import { TextControllerModule } from './controllers/text-controller/text-controller.module';
import { DetailsComponent } from './details.component';
import { TulDataTablePipeModule } from '../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  declarations: [DetailsComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ColorControllerModule,
    CustomControllerModule,
    DateControllerModule,
    DocumentControllerModule,
    FilesControllerModule,
    NumericControllerModule,
    SelectControllerModule,
    SelectorsControllerModule,
    TextControllerModule,
    NzDescriptionsModule,
    TulDataTablePipeModule,
  ],
  exports: [DetailsComponent],
})
export class DetailsModule {}
