import { Component } from '@angular/core';

/**
 * Component Tul Modal Header for project content
 */
@Component({
  selector: 'tul-modal-header',
  template: `<ng-content></ng-content>`,
})
export class TulModalHeaderComponent {}
