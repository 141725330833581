import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TextAreaControllerComponent } from './textarea-controller.component';
import { TulTooltipModule } from '../../../../../../tul-tooltip/tul-tooltip.module';
import { TulInputModule } from '../../../../../../tul-input/tul-input.module';
@NgModule({
  declarations: [TextAreaControllerComponent],
  imports: [CommonModule, FormsModule, TulInputModule, TulTooltipModule],
  exports: [TextAreaControllerComponent],
})
export class TextAreaControllerModule {}
