import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MapControllerComponent } from './map-controller.component';
import { MapDefaultControllerModule } from './map-default-controller/map-default-controller.module';

@NgModule({
  declarations: [MapControllerComponent],
  imports: [CommonModule, FormsModule, MapDefaultControllerModule],
  exports: [MapControllerComponent],
})
export class MapControllerModule {}
