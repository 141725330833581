import { NgModule } from '@angular/core';
import { TulCarouselComponent } from './components/tul-carousel/tul-carousel.component';
import { TulTagModule } from './../tul-tag/tul-tag.module';
import { TulButtonModule } from './../tul-btn/tul-btn.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TulCarouselComponent],
  imports: [SharedModule, TulTagModule, TulButtonModule],
  exports: [TulCarouselComponent],
})
export class TulCarouselModule {}
