import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from './text.component';
import { FormsModule } from '@angular/forms';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { RouterModule } from '@angular/router';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  exports: [TextComponent],
  imports: [
    CommonModule,
    FormsModule,
    TulDataTablePipeModule,
    NzCollapseModule,
    NzPipesModule,
    RouterModule,
  ],
  declarations: [TextComponent],
})
export class TextModule {}
