<svg
  viewBox="64 64 896 896"
  focusable="false"
  fill="currentColor"
  width="1em"
  height="1em"
  data-icon="plus"
  aria-hidden="true"
>
  <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
  <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
</svg>
