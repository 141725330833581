import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { TranslocoModule } from '@ngneat/transloco';
import { SelectorsControllerComponent } from './selectors-controller.component';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  imports: [CommonModule, TulDataTablePipeModule, NzBadgeModule, TranslocoModule],
  declarations: [SelectorsControllerComponent],
  exports: [SelectorsControllerComponent],
})
export class SelectorsControllerModule {}
