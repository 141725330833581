import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiEditComponent } from './multiple-edit.component';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { TulToAsyncPipeModule } from '../../../../pipes';

@NgModule({
  exports: [MultiEditComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzToolTipModule,
    NzButtonModule,
    TranslocoModule,
    TulToAsyncPipeModule,
  ],
  declarations: [MultiEditComponent],
})
export class MultiEditModule {}
