import { Component, ElementRef, HostListener, Input, OnInit, forwardRef } from '@angular/core';
import { State, TulState } from '../../../../shared/types/state.type';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TulSelectDataTree } from '../../../models/select-data-tree.model';

/**
 * noop
 */
function noop() {
  //noop ref
}

/**
 * Interface for communication with ngModel
 */
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TulSelectTreeListSelectComponent),
  multi: true,
};

@Component({
  selector: 'tul-select-tree-list-select',
  templateUrl: './tul-select-tree-list-select.component.html',
  styleUrls: ['./tul-select-tree-list-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TulSelectTreeListSelectComponent implements ControlValueAccessor, OnInit {
  /**
   * Placeholder
   */
  @Input() tulPlaceholder: string = 'Placeholder';

  /**
   * Text in label
   */
  @Input() tulLabel: string = 'Text input label';

  /**
   * Enum State for design
   */
  @Input() tulState: TulState = State.DEFAULT;

  /**
   * Name
   */
  @Input() tulName: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Text under dropdown
   */
  @Input() tulAdditionalInfo: string = 'Additional info';

  /**
   * Data for tree
   */
  @Input() tulData: TulSelectDataTree[] = [];

  /**
   * Backup data filter
   */
  tulDataBackup: string = '';

  /**
   * Data setted after filter
   */
  finalData: TulSelectDataTree[] = [];

  /**
   * Loading text
   */
  loadingText: string = '';

  /**
   * Show loading
   */
  showLoading: boolean = false;

  /**
   * Focus on div container search
   */
  searchOnFocus: boolean = false;

  /**
   * Show menu
   */
  showMenu: boolean = false;

  /**
   * General id
   */
  id: number = 0;

  /**
   * Inner Value
   */
  private innerValue: any = '';

  /**
   * Response on touched
   */
  private onTouchedCallback: () => void = noop;

  /**
   * Response on change value
   */
  private onChangeCallback: (_: any) => void = noop;

  /**
   * Check click out of the element
   * @param event
   */
  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showMenu = false;
      this.searchOnFocus = false;
    }
  }

  /**
   * Constructo
   * @param eRef
   */
  constructor(private eRef: ElementRef) {}

  /**
   * OnInit
   */
  ngOnInit(): void {
    this.setDataId(this.tulData, 0, -1);
    this.tulDataBackup = JSON.stringify(this.tulData);
  }

  /**
   * Get if tul state is error
   */
  get isError() {
    return this.tulState === 'error';
  }

  /**
   * Get if tul state is error
   */
  get isSuccess() {
    return this.tulState === 'success';
  }

  /**
   * Get disabled
   */
  get isDisabled() {
    return this.tulDisabled;
  }

  /**
   * Get value ngModel
   */
  get value(): any {
    return this.innerValue;
  }

  /**
   * Set value ngModel
   */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  /**
   * Write value
   * @param value ngModel
   */
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  /**
   * Register on change
   * @param fn function
   */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /**
   * Register on touched
   * @param fn function
   */
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /**
   * Filter data search
   */
  filterData() {
    this.loadingText = 'minimumThreeCharactersForFilter';
    this.showLoading = true;
    setTimeout(() => {
      if (this.value.length >= 3) {
        this.loadingText = 'loadingInformation';
        this.tulData = [...JSON.parse(this.tulDataBackup)];
        let data: any = [];
        this.finalData = [];
        this.setFalseAttr(this.tulData, 'show');
        for (let i = 0; i < this.tulData.length; i++) {
          this.filterElements(this.tulData[i], data);
        }
      }
    }, 1000);
    if (this.value.length === 0) {
      this.tulData = [...JSON.parse(this.tulDataBackup)];
      this.showLoading = false;
    }
  }

  /**
   * Set show item when user clicked
   * @param parent
   */
  showChildren(parent: TulSelectDataTree) {
    if (parent.children.length === 0) {
      this.setFalseAttr(this.tulData, 'selected');
      parent.selected = parent.selected ? !parent.selected : true;
      this.value = parent.value;
    }
    parent.show = parent.children.length !== 0 ? !parent.show : false;
  }

  /**
   * Set false attr in data
   * @param array
   */
  setFalseAttr(array: TulSelectDataTree[], type: string) {
    array.forEach((item: any) => {
      item[type] = false;
      if (item.children.length !== 0) {
        this.setFalseAttr(item.children, type);
      }
    });
  }

  /**
   * Show menu when user clicked on input search
   */
  onSearch() {
    this.searchOnFocus = true;
    this.showMenu = true;
  }

  /**
   * Recursive filter elements
   * @param item
   * @param data
   */
  filterElements(item: TulSelectDataTree, data: any) {
    if (item.children.length === 0) {
      if (item.text.toLowerCase().includes(this.value.toLowerCase())) {
        data.push(item);
        this.finalData.push(...data);
        this.searchInTree(this.finalData);
        setTimeout(() => {
          this.removeFalseObjects(this.tulData);
          this.showLoading = false;
        }, 1000);
      }
      data.length = 0;
      setTimeout(() => {
        if (this.finalData.length === 0) {
          this.tulData = [];
          this.showLoading = false;
        }
      }, 1100);
    } else {
      for (let i = 0; i < item.children.length; i++) {
        this.filterElements(item.children[i], data);
      }
    }
  }

  /**
   * Recursive set id for items data
   * @param array
   * @param parentId
   */
  setDataId(array: TulSelectDataTree[], level: number, parentId?: number) {
    for (let i = 0; i < array.length; i++) {
      this.id += 1;
      array[i].id = this.id;
      array[i].parentId = parentId;
      array[i].level = level;
      if (array[i].children.length !== 0) {
        this.setDataId(array[i].children, level + 1, array[i].id);
      }
    }
  }

  /**
   * Search in tree
   * @param array
   */
  searchInTree(array: TulSelectDataTree[]) {
    for (let i = 0; i < array.length; i++) {
      this.searchByParentId(this.tulData, array[i].parentId ?? 0);
      this.searchByParentId(this.tulData, array[i].id ?? 0);
    }
  }

  /**
   * Search in data by parent id
   * @param array
   * @param id
   */
  searchByParentId(array: TulSelectDataTree[], id: number) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].show = true;
        if (array[i].children.length !== 0) {
          this.searchByParentId(this.tulData, array[i].parentId ?? 0);
        }
      } else {
        if (array[i].children.length !== 0) {
          this.searchByParentId(array[i].children, id);
        }
      }
    }
  }

  /**
   * Remove objects with show in false
   * @param array
   */
  removeFalseObjects(array: TulSelectDataTree[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] && !array[i].show) {
        array.splice(i, 1);
      } else {
        if (array[i] && array[i].children.length !== 0) {
          this.removeFalseObjects(array[i].children);
        }
      }
    }
  }

  /**
   * Set padding for identation
   * @param level
   * @returns
   */
  setPadding(level: number): string {
    switch (level) {
      case 0:
        return '22px';
      case 1:
        return '66px';
      default:
        return level * 44 + 22 + 'px';
    }
  }
}
