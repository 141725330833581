import { NgModule } from '@angular/core';
import { TulPopupsComponent } from './components/tul-popups/tul-popups.component';
import { SharedModule } from '../shared/shared.module';
import { TulButtonModule } from './../tul-btn/tul-btn.module';

@NgModule({
  declarations: [TulPopupsComponent],
  imports: [SharedModule, TulButtonModule],
  exports: [TulPopupsComponent],
})
export class TulPopupsModule {}
