<tul-switch
  [tulLabel]="(column.required ? '* ' : '') + column.header"
  [tulAdditionalInfo]="column.error ?? ''"
  [tulState]="column.error ? 'error' : 'default'"
  [tulDisabled]="column.disabled ?? false"
  [(ngModel)]="switchData"
  [tulTooltipconfig]="column.tooltip"
  [tulSliderText]="sliderText"
  (ngModelChange)="modelChange($event)"
></tul-switch>
