import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { YearControllerComponent } from './year-controller.component';
import { TulTooltipModule } from '../../../../../../tul-tooltip/tul-tooltip.module';
import { TulSelectModule } from '../../../../../../tul-select/tul-select.module';

@NgModule({
  declarations: [YearControllerComponent],
  imports: [CommonModule, FormsModule, TulSelectModule, TulTooltipModule],
  exports: [YearControllerComponent],
})
export class YearControllerModule {}
