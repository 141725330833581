import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubTypeFilesColumn } from '../../../../enums/columns/SubTypeFilesColumn.enum';
import { FileColumn, ImageColumnForms, TypeFiles } from '../../../../interfaces/columns/files';
import { SubTypeReturnData } from '../../../../interfaces/columns/subTypeReturnData';

@Component({
  selector: 'files-controller',
  templateUrl: './files-controller.component.html',
})
export class FilesControllerComponent implements OnInit {
  /** data input */
  @Input() data: any = {};

  /** column input */
  @Input() column!: TypeFiles;

  /** FilesData */
  filesData!: string | boolean;

  /** onChange */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Switch type column
   */
  fileType?:
    | SubTypeFilesColumn
    | (({ data, column }: SubTypeReturnData<TypeFiles>) => SubTypeFilesColumn) =
    SubTypeFilesColumn.IMAGE;

  ngOnInit() {
    this.fileType = this.column.subType;
    if (`${this.data}` === 'pending' || `${this.data}` === 'disabled') {
      this.filesData = false;
    } else {
      this.filesData = this.data;
    }
  }

  getFileColumn(column: any) {
    return column as FileColumn;
  }
  getImageColumn(column: any) {
    return column as ImageColumnForms;
  }
}
