import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubjectsService } from '../../../../services/subjects.service';
import { Subscription } from 'rxjs';
import { ImageColumnTable } from './../../../../interfaces/columns/image';
import { TypeHeaderOptions } from '../../../../enums/export';

/**
 * Body option Image
 */
@Component({
  selector: '[image]',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit, OnDestroy {
  /**
   * Actual Column
   */
  @Input() column!: ImageColumnTable;
  /**
   * Data row
   */
  @Input() data: any;

  /**
   * Date today
   */
  date = new Date().toISOString();

  /**
   * Is visible modal
   */
  isVisible: any;

  /**
   * Loading
   */
  loading = false;

  /**
   * Subscriptor Get Image
   */
  suscriptionImage = new Subscription();

  /**
   * image error flag
   */
  hasError = false;

  /**
   * subscriptor external component comunication
   */
  suscriptionExternal = new Subscription();

  /**
   * Constructor Generate request
   */
  constructor(private subjectsService: SubjectsService) {}

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.suscriptionExternal = this.subjectsService.externalExecutions.subscribe(
      ({ option, data, status, type }) => {
        switch (option) {
          case TypeHeaderOptions.REFRESH:
            return this.loadImage();
          default:
            break;
        }
      }
    );
    this.loadImage();
  }

  /**
   * function to load column image if fetch an httpSubscribe
   */
  loadImage() {
    if (this.column.httpSubscribe) {
      this.loading = true;
      this.suscriptionImage = this.column
        .httpSubscribe({ data: this.data, column: this.column })
        .subscribe(
          ({ uuid, file_name: { fileName }, url }) => {
            this.loading = false;
            this.data = {
              ...this.data,
              [this.column.attribute]: {
                uuid,
                file_name: fileName,
                url,
              },
            };
          },
          (error) => {
            this.loading = false;
          }
        );
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.suscriptionImage.unsubscribe();
    this.suscriptionExternal.unsubscribe();
  }
}
