import { Component, EventEmitter, Input, Output } from '@angular/core';
import { map } from 'rxjs';
import {
  EntityColumnForms,
  ListColumnForms,
  SelectColumnForms,
  TypeSelectsForms,
} from '../../../../interfaces/columns/select';
import { Data } from '../../../../interfaces/data';

/**
 *
 */
@Component({
  selector: 'select-controller',
  templateUrl: './select-controller.component.html',
})
export class SelectControllerComponent {
  /** */
  @Input() data: Data = {};

  /** */
  @Input() column!: TypeSelectsForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Filters map
   */
  @Input() filter?: Map<string, any>;
  /**
   * Emitter change value filter
   */
  @Output() changeValueFilter = new EventEmitter<string>();

  /**
   * Value filter
   */
  valueFilterInput = undefined;

  /**
   * get value filter
   * @param column selected column
   * @return value to selected column
   */
  getValueFilter(column: SelectColumnForms) {
    return this.filter?.get(column?.filter?.attribute ?? column.attribute)?.value;
  }

  getEntityColumn() {
    return this.column as EntityColumnForms;
  }
  getListColumn() {
    return this.column as ListColumnForms;
  }

  /**
   * Change filter value
   * @param event value of filter
   */
  ngModelChange(event: any) {
    this.valueFilterInput = event;
    this.changeValueFilter.emit(event);
  }

  /**
   * get httpsubcribe in select entity
   * @param page number page
   * @param pageSize size to page
   * @param filters filters array
   * @return observable
   */
  getHttpSubscribe({ page, pageSize, filters }: any) {
    const auxColumn = this.column as EntityColumnForms;
    return auxColumn
      .httpSubscribe({ page, pageSize, filters, column: this.column, data: this.data })
      .pipe(
        map((response) =>
          auxColumn.mapHttpSubscribe
            ? auxColumn.mapHttpSubscribe({ page, pageSize, filters, column: this.column, response })
            : response
        )
      );
  }
}
