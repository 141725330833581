<button
  nz-button
  nzType="default"
  nz-popconfirm
  [nzPopconfirmTitle]="getPopConfirmTitle(option)"
  [nzPopconfirmPlacement]="option.condition?.position || 'bottom'"
  (nzOnConfirm)="executeOption()"
  (nzOnCancel)="cancelPop()"
  [nzTooltipTitle]="getToolTip(option)"
  nzTooltipPlacement="top"
  nz-tooltip
  [disabled]="option.disabled | tulToAsyncPipe | async"
>
  <svg-delete></svg-delete>
</button>
