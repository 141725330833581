import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  /**   */
  environment: any;

  /**
   *
   * @param environment
   * @param translocoService
   */
  constructor(@Inject('ENVIRONMENT') environment: any, private translocoService: TranslocoService) {
    this.environment = environment;
  }

  translateByCountry(object: any) {
    return this.translate(object[this.environment.country.code], {});
  }

  /**
   *
   * @param key
   * @param params
   * @param lang an specific lang
   * @returns
   */
  translate(key: string, params: any = {}, lang?: string) {
    return this.translocoService.translate(key, params, lang);
  }

  /**
   * load i18n
   */
  async load() {
    await lastValueFrom(this.translocoService.load(this.environment.country.language));
    await lastValueFrom(
      this.translocoService.load(
        `${this.environment.country.language}_${this.environment.country.code}`
      )
    );
  }
}
