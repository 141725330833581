import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeTable, TypeOption } from '../../../enums/export';
import { OptionsTable, TypeTables, ColumnTable } from '../../../interfaces/export';

/**
 * options table
 */
@Component({
  selector: '[options]',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class OptionsComponent {
  /**
   * Array options
   */
  @Input() options!: Array<OptionsTable<TypeOption>> | any;
  /**
   * table
   */
  @Input() table!: TypeTables;
  /**
   * Data row
   */
  @Input() data: any;
  /**
   * Emitter to execute option
   */
  @Output() executeOption = new EventEmitter<any>();
  /**
   * Type Table
   */
  @Input() typeTable!: TypeTable;
  /**
   * actual column
   */
  @Input() column!: ColumnTable | any;

  /**
   * Generate click option
   * @param option click option
   */
  clickOption(event: any) {
    this.executeOption.emit({ ...event, type: 'table', data: this.data });
  }

  /**
   * whether it is visible or not
   * @param option actual option
   * @return boolean whether it is visible or not
   */
  validateVisibility(option: any) {
    return option.visible ? option.visible({ data: this.data, option }) : true;
  }
}
