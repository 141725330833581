import { Component } from '@angular/core';
import { TulTooltipPosition } from '../../models/tul-tooltip-model';

/**
 * Component for custom chip
 */
@Component({
  selector: 'tul-tooltip',
  templateUrl: './tul-tooltip.component.html',
  styleUrls: ['./tul-tooltip.component.scss'],
})
export class TulTooltipComponent {
  icon: string = '';
  dark: boolean = false;
  tooltip: string = '';
  left: number = 0;
  right: number = 0;
  top: number = 0;
  positionType = TulTooltipPosition;
  position: TulTooltipPosition = TulTooltipPosition.TOP;
}
