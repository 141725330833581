<div class="tul-input-container">
  <label
    *ngIf="tulLabel"
    class="tul-input-container__label"
    [ngClass]="{
      'tul-input-container__label--alert-error': tulState === 'error',
      'tul-input-container__label--alert-success': tulState === 'success'
    }"
    >{{ tulLabel | transloco }}
    &nbsp;
    <i
      *ngIf="tulTooltipconfig"
      class="tul-input-container__hint soytul soytul-info_filled"
      [tulTooltip]="tulTooltipconfig.title"
      [tulTooltipConfig]="tulTooltipconfig"
    ></i>
  </label>
  <nz-upload
    [nzAccept]="nzAccept ?? ''"
    [nzDirectory]="nzDirectory"
    [nzBeforeUpload]="nzBeforeUpload"
    [nzData]="nzData"
    [nzDisabled]="nzDisabled"
    [nzLimit]="nzLimit"
    [nzSize]="nzSize"
    [(nzFileList)]="nzFileList"
    [nzFileType]="nzFileType ?? ''"
    [nzFilter]="nzFilter"
    [nzHeaders]="nzHeaders"
    [nzListType]="nzListType"
    [nzMultiple]="nzMultiple"
    [nzName]="nzName"
    [nzShowUploadList]="nzShowUploadList"
    [nzShowButton]="nzShowButton"
    [nzWithCredentials]="nzWithCredentials"
    [nzOpenFileDialogOnClick]="nzOpenFileDialogOnClick"
    [nzRemove]="nzRemove"
    [nzPreview]="nzPreview"
    [nzPreviewFile]="nzPreviewFile"
    [nzPreviewIsImage]="nzPreviewIsImage"
    [nzTransformFile]="nzTransformFile"
    [nzDownload]="nzDownload"
    (nzChange)="nzFileCharged($event)"
  >
    <div class="file-upload">
      <i class="soytul soytul-data_sheet_filled file-upload__icon"></i>
      <div class="file-upload__text">{{ "upload" | transloco }}</div>
    </div>
  </nz-upload>
</div>
