import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tulSwitchIsValuePipe',
})
export class TulSwitchIsValuePipe implements PipeTransform {
  transform(value: unknown, ...args: any): unknown {
    let newValue = value;
    if (value === 'enable' || value) newValue = true;
    return args[0].selectorsType ? value === args[0].selectorsType.true.value : newValue === true;
  }
}
