import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorsControllerComponent } from './selectors-controller.component';
import { SwitchModule } from './switch-controller/switch.module';
import { RadioModule } from './radio/radio.module';
import { CheckboxModule } from './checkbox/checkbox.module';

@NgModule({
  imports: [CommonModule, SwitchModule, RadioModule, CheckboxModule],
  declarations: [SelectorsControllerComponent],
  exports: [SelectorsControllerComponent],
})
export class SelectorsControllerModule {}
