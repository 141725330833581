import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportComponent } from './import.component';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterModule } from '@angular/router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SvgImportModule } from '../../../svg/svg-import/svg-import.module';
import { TranslocoModule } from '@ngneat/transloco';
import { TulToAsyncPipeModule } from '../../../../pipes';

@NgModule({
  exports: [ImportComponent],
  imports: [
    CommonModule,
    FormsModule,
    SvgImportModule,
    NzButtonModule,
    RouterModule,
    NzToolTipModule,
    TranslocoModule,
    TulToAsyncPipeModule,
  ],
  declarations: [ImportComponent],
})
export class ImportModule {}
