<div class="options-container">
  <ng-container *ngFor="let option of options">
    <span *ngIf="validateVisibility(option)" class="option-{{ option.condition }}">
      <ng-container [ngSwitch]="option.type">
        <ng-container *ngSwitchCase="'custom'">
          <app-custom
            [data]="data"
            [option]="option"
            [column]="column"
            [typeTable]="typeTable"
            (clickOptionEmitter)="clickOption($event)"
          ></app-custom>
        </ng-container>
        <ng-container *ngSwitchCase="'edit'">
          <app-edit
            [data]="data"
            [option]="option"
            [typeTable]="typeTable"
            (clickOptionEmitter)="clickOption($event)"
          ></app-edit>
        </ng-container>
        <ng-container *ngSwitchCase="'delete'">
          <app-delete
            [data]="data"
            [option]="option"
            [table]="table"
            [typeTable]="typeTable"
            (clickOptionEmitter)="clickOption($event)"
          ></app-delete>
        </ng-container>
        <ng-container *ngSwitchCase="'details'">
          <app-details
            [data]="data"
            [option]="option"
            [typeTable]="typeTable"
            (clickOptionEmitter)="clickOption($event)"
          ></app-details>
        </ng-container>
        <ng-container *ngSwitchCase="'check-status'">
          <app-check-status
            [data]="data"
            [option]="option"
            [table]="table"
            [typeTable]="typeTable"
            (clickOptionEmitter)="clickOption($event)"
          ></app-check-status>
        </ng-container>
        <ng-container *ngSwitchCase="'select-status'">
          <app-select-status
            [data]="data"
            [option]="option"
            [table]="table"
            [typeTable]="typeTable"
            (clickOptionEmitter)="clickOption($event)"
          ></app-select-status>
        </ng-container>
        <ng-container *ngSwitchCase="'expand'">
          <app-expand
            [data]="data"
            [option]="option"
            [column]="column"
            [typeTable]="typeTable"
            (clickOptionEmitter)="clickOption($event)"
          ></app-expand>
        </ng-container>
      </ng-container>
    </span>
  </ng-container>
</div>
