import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TulInputModule } from '../../../../../../tul-input/tul-input.module';
import { DateTimeControllerComponent } from './date-time-controller.component';

@NgModule({
  declarations: [DateTimeControllerComponent],
  imports: [CommonModule, FormsModule, TulInputModule],
  exports: [DateTimeControllerComponent],
})
export class DateTimeControllerModule {}
