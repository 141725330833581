import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DocumentColumnTable } from './../../../../interfaces/columns/document';

/**
 * Body option Document
 */
@Component({
  selector: '[document]',
  templateUrl: './document.component.html',
})
export class DocumentComponent implements OnInit, OnDestroy {
  /**
   * Actual Column
   */
  @Input() column!: DocumentColumnTable;
  /**
   * Data row
   */
  @Input() data: any;

  /**
   * Loading
   */
  loading = false;

  /**
   * Subscriptor get Document
   */
  suscriptionDocument = new Subscription();

  /**
   * ngOnInit Generate request
   */
  ngOnInit() {
    if (this.column.httpSubscribe) {
      this.loading = true;
      this.suscriptionDocument = this.column
        .httpSubscribe({ data: this.data, column: this.column })
        .subscribe(
          ({ uuid, key, mime_type: { mimeType }, name, url }) => {
            this.loading = false;
            this.data = {
              ...this.data,
              [this.column.attribute]: {
                uuid,
                key,
                mime_type: mimeType,
                name,
                url,
              },
            };
          },
          (error) => {
            this.loading = false;
          }
        );
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.suscriptionDocument.unsubscribe();
  }
}
