import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EntityControllerModule } from './entity-controller/entity-controller.module';
import { ListedControllerModule } from './listed-controller/listed-controller.module';
import { SelectControllerComponent } from './select-controller.component';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  declarations: [SelectControllerComponent],
  imports: [CommonModule, EntityControllerModule, ListedControllerModule, TulDataTablePipeModule],
  exports: [SelectControllerComponent],
})
export class SelectControllerModule {}
