import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckStatusComponent } from './check-status.component';
import { FormsModule } from '@angular/forms';
import { SvgShrinkModule } from '../../../../svg/svg-shrink/svg-shrink.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TulToAsyncPipeModule } from '../../../../../pipes';
@NgModule({
  exports: [CheckStatusComponent],
  imports: [
    CommonModule,
    FormsModule,
    SvgShrinkModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzToolTipModule,
    TulToAsyncPipeModule,
  ],
  declarations: [CheckStatusComponent],
})
export class CheckStatusModule {}
