import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ContentModule } from './content/content.module';
import { FiltersModule } from './filters/filters.module';
import { OptionsModule } from './options/options.module';
import { TableComponent } from './table.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TulToAsyncPipeModule } from '../../../pipes';
import { TulLoaderModule } from '../../../tul-loader';

@NgModule({
  exports: [TableComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzTableModule,
    OptionsModule,
    ContentModule,
    NzEmptyModule,
    NzDropDownModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
    FiltersModule,
    TranslocoModule,
    DragDropModule,
    TulToAsyncPipeModule,
    TulLoaderModule,
  ],
  declarations: [TableComponent],
})
export class TableModule {}
