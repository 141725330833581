<p
  [ngClass]="column.hasBackgroundColor ? 'select-background' : ''"
  [ngStyle]="{
    'background-color': getBackgroundColor(data | tulDataTablePipe : column.attribute)
      ? getBackgroundColor(data | tulDataTablePipe : column.attribute)
      : '',
    color: getBackgroundColor(data | tulDataTablePipe : column.attribute) ? 'white' : '#303030'
  }"
>
  <ng-container *ngIf="!currentTag">
    {{ data | tulDataTablePipe : column.attribute | tulGetDataSelectPipe : column?.listValues }}
  </ng-container>
  <tul-tag
    tulText="{{
      data | tulDataTablePipe : column.attribute | tulGetDataSelectPipe : column?.listValues
    }}"
    [isClosable]="false"
    [tulTagColor]="currentTag.color ?? 'yellow'"
    *ngIf="currentTag"
  >
    <i
      *ngIf="currentTag?.icon?.type === 'filledoutline'"
      tul-item
      class="soytul soytul-{{ currentTag.icon?.name }}"
    ></i>
    <img
      *ngIf="currentTag?.icon?.type === 'compounds'"
      tul-item
      [src]="environment.staticFilesUrl + 'icons/svg/' + currentTag.icon?.name + '.svg'"
      alt=""
    />
  </tul-tag>
</p>
