import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsService } from '../../services/forms/forms.service';
import { HeaderOptions } from '../../interfaces/headerOptions';
import { TypeActionsHeaderOptions } from '../../enums/typeActionsHeaderOptions';
import { Icon } from '../../interfaces/icon';

@Component({
  selector: 'tul-form-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  /**
   * Header options object
   */
  @Input() headerOptions?: HeaderOptions;

  /** uuid */
  readonly uuid = this.activatedroute.snapshot.params?.['id'];

  /** base mfe path */
  readonly basePath = this.formsService.getBasePath(this.router.routerState.snapshot.url);

  /** current op */
  readonly activeRoute = this.formsService.getActiveRoute();

  /**
   * render header for forms inputs
   * @param formsService
   * @param router
   * @param activatedroute
   */
  constructor(
    private formsService: FormsService,
    private router: Router,
    private activatedroute: ActivatedRoute
  ) {}

  /**
   * Redirect url
   * @param link
   * @param type
   */
  goTo(
    link: string | ((data: any) => string),
    callBack: any,
    type?: TypeActionsHeaderOptions | ((data: any) => TypeActionsHeaderOptions),
    hasLink?: string | ((data: any) => string)
  ) {
    let auxLink = typeof link === 'string' ? link : link({ data: this.headerOptions });
    let auxType = '';
    if (type) {
      auxType = typeof type === 'string' ? type : type({ data: this.headerOptions });
    }
    if (callBack) {
      return callBack({ data: this.headerOptions });
    }
    window.location.href = `${auxLink}${
      hasLink ? '' : this.checkTypeActionsHeaderOptions(auxType)
    }`;
  }

  goBack() {
    if (this.headerOptions?.backLink) {
      let auxLink =
        typeof this.headerOptions?.backLink === 'string'
          ? this.headerOptions?.backLink
          : this.headerOptions?.backLink({ data: this.headerOptions });
      window.location.href = `${auxLink}`;
    }
  }

  /**
   * Get title by string or function
   * @param title
   * @returns
   */
  getTitle(title: string | ((data: any) => string)): string {
    return typeof title === 'string' ? title : title({ data: this.headerOptions });
  }

  /**
   * Get disabled by string or function
   * @param disabled
   * @returns
   */
  getDisabled(disabled: boolean | ((data: any) => boolean)) {
    return typeof disabled === 'boolean' ? disabled : disabled({ data: this.headerOptions });
  }

  /**
   * Check if icon exist
   * @param icon
   * @returns
   */
  getIcon(icon: Icon | undefined): string {
    if (!icon?.name) {
      return '';
    }
    return typeof icon.name === 'string' ? icon.name : icon.name({ data: this.headerOptions });
  }

  /**
   * Check type actions for url
   * @param type
   * @returns
   */
  checkTypeActionsHeaderOptions(type: string): string {
    switch (type) {
      case 'add':
        return '/add';
      case 'edit':
        return `/edit/${this.uuid}`;
      case 'details':
        return `/show/${this.uuid}`;
      default:
        return '';
    }
  }
}
