import {
  Input,
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';
import { TulColorEnum, TulColorType } from '../../../shared/types/colors.type';
import { TulBadgeStatus, TulBadgeStatusEnum } from '../../types/badge-status.type';
import { TulBadgeType, TulBadgeTypeEnum } from '../../types/badge-type.type';

/** Tul badge component */
@Component({
  selector: 'tul-badge',
  exportAs: 'tulBadge',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './tul-badge.component.html',
  styleUrls: ['./tul-badge.component.scss'],
})
export class TulBadgeComponent {
  /**
   * Badge type from TulBadgeTypeEnum
   */
  @Input() tulType: TulBadgeType = TulBadgeTypeEnum.SIMPLE;

  /**
   * Badge color from TulBadgeStatusEnum
   */
  @Input() tulStatus: TulBadgeStatus = TulBadgeStatusEnum.ERROR;

  /**
   * Badge color from TulBadgeStatusEnum
   */
  @Input() tulColor: TulColorType = TulColorEnum['BOSTON-UNIVERSITY-RED'];

  /**
   * Principal content of badge
   */
  @Input() content: string | number = '';

  get isTypeSimple() {
    return this.tulType === TulBadgeTypeEnum.SIMPLE;
  }

  get isTypeText() {
    return this.tulType === TulBadgeTypeEnum.TEXT;
  }

  get isTypeNumeric() {
    return this.tulType === TulBadgeTypeEnum.NUMERIC;
  }

  get hasContent() {
    if (!this.isTypeText) {
      if (this.isTypeNumeric && !Number.isNaN(this.content?.toString())) {
        return true;
      }
      this.tulType = TulBadgeTypeEnum.SIMPLE;
      return false;
    }

    if (this.content === null || this.content === undefined || this.content === '') {
      this.tulType = TulBadgeTypeEnum.SIMPLE;
      return false;
    }
    return true;
  }

  get getColorClass() {
    if (this.isTypeText) return 'tul-badge--' + this.tulStatus;
    return 'tul-badge--color-' + this.tulColor;
  }

  /**
   * Class of button container
   */
  @HostBinding('class') class = 'tul-badge-container';
}
