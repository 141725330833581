import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsComponent } from './details.component';
import { FormsModule } from '@angular/forms';
import { SvgSearchModule } from '../../../../svg/svg-search/svg-search.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterModule } from '@angular/router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TulToAsyncPipeModule } from '../../../../../pipes';

@NgModule({
  exports: [DetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    SvgSearchModule,
    NzButtonModule,
    RouterModule,
    NzToolTipModule,
    TulToAsyncPipeModule,
  ],
  declarations: [DetailsComponent],
})
export class DetailsModule {}
