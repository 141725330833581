import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddComponent } from './add.component';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SvgAddModule } from '../../../svg/svg-add/svg-add.module';
import { RouterModule } from '@angular/router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { TulToAsyncPipeModule } from '../../../../pipes';

@NgModule({
  exports: [AddComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    SvgAddModule,
    RouterModule,
    NzToolTipModule,
    TranslocoModule,
    TulToAsyncPipeModule,
  ],
  declarations: [AddComponent],
})
export class AddModule {}
