import { Component } from '@angular/core';

/**
 * svg add
 */
@Component({
  selector: 'svg-add',
  templateUrl: './svg-add.component.html',
  styleUrls: ['./svg-add.component.scss'],
})
export class SvgAddComponent {}
