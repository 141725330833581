import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PopupType, TulPopupType } from '../../types/popup-state.type';
import { PopupAction } from '../../types/popup-action.type';

/**
 * Component for custom popup
 */
@Component({
  selector: 'tul-popups',
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
    ]),
  ],
  templateUrl: './tul-popups.component.html',
  styleUrls: ['./tul-popups.component.scss'],
})
export class TulPopupsComponent {
  /**
   * Emit response in actions on popup
   */
  @Output() tulActions: EventEmitter<string> = new EventEmitter();

  /**
   * Show or hide popup
   */
  @Input() tulVisible: boolean = false;

  /**
   * Emit for change visibility
   */
  @Output() tulVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Popup title
   */
  @Input() tulTitle: string = '';

  /**
   * Popup description
   */
  @Input() tulDescription: string = '';

  /**
   * Text on OK right button
   */
  @Input() tulBtnOkText: string = '';

  /**
   * Optional Text on Cancel left button
   */
  @Input() tulBtnCancelText?: string;

  /**
   * Optional buttons vertical aligned
   */
  @Input() tulVerticalButtons?: boolean;

  /**
   * Popup width
   */
  @Input() tulWidth: string = '358px';

  /**
   * Enum Popup type ('success' | 'error' | 'warning' | 'info')
   */
  @Input() tulPopupType: TulPopupType = PopupType.INFO;

  /**
   * Enviroment url https://style.global.dev.soytul.com/web/public/
   */
  staticFilesUrl = environment.staticFilesUrl;

  /**
   * Get action (0 === btnCancel was pressed) (1 === btnOK was pressed)
   * (other === Popup was closed)
   * @param index Id for action
   */
  sendCancelResponse(index: number) {
    this.tulVisible = false;
    this.tulVisibleChange.emit(this.tulVisible);
    switch (index) {
      case 0:
        this.tulActions.emit(PopupAction.CANCEL);
        break;
      case 1:
        this.tulActions.emit(PopupAction.ACCEPT);
        break;
      default:
        this.tulActions.emit(PopupAction.XCLICK);
        break;
    }
  }
}
