import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumericComponent } from './numeric.component';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  exports: [NumericComponent],
  imports: [CommonModule, FormsModule, NzInputModule, NzInputNumberModule, TranslocoModule],
  declarations: [NumericComponent],
})
export class NumericModule {}
