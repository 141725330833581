<nz-spin [nzSpinning]="loading">
  <ng-container *ngIf="loading; else documentTemplate">
    <div>...{{ "tulTable.content.document.loading" | transloco }}</div>
  </ng-container>
</nz-spin>

<ng-template #documentTemplate>
  <ng-container
    *ngIf="
      (data | tulDataTablePipe : column.attribute)?.url &&
        (data | tulDataTablePipe : column.attribute)?.url.length > 0;
      else elseTemplateUrl
    "
  >
    <a
      nz-button
      rel="noopener"
      nzType="primary"
      [href]="(data | tulDataTablePipe : column.attribute)?.url"
      target="_blank"
    >
      <i nz-icon nzType="file-markdown" nzTheme="outline"></i>
    </a>
  </ng-container>
  <ng-template #elseTemplateUrl>
    {{ null | nzSafeNull : (column.safeNull ? column.safeNull : "-") }}
  </ng-template>
</ng-template>
