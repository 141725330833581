import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NumberColumnForms } from '../../../../../interfaces/columns/numeric';

/**
 *
 */
@Component({
  selector: 'number-controller',
  templateUrl: './number-controller.component.html',
  styleUrls: ['./number-controller.component.scss'],
})
export class NumberControllerComponent {
  /** */
  @Input() data = 0;

  /** */
  @Input() column!: NumberColumnForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Method that use transformData and emit the changed data
   */
  changeData(value: any) {
    this.data = this.column.transformData
      ? this.column.transformData({ data: value, column: this.column })
      : value;

    this.onChange.emit(this.data);
  }
}
