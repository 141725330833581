<div class="tul-input-container">
  <label
    [for]="'tul-checkbox' + tulLabel"
    class="tul-input-container__label"
    *ngIf="tulLabel"
    [ngClass]="{
      'tul-input-container__label--alert-error': tulState === 'error'
    }"
    >{{ tulLabel | transloco }}

    &nbsp;
    <i
      *ngIf="tulTooltipconfig"
      class="tul-checkbox__hint soytul soytul-info_filled"
      [tulTooltip]="tulTooltipconfig.title"
      [tulTooltipConfig]="tulTooltipconfig"
    ></i>
  </label>
  <div class="tul-checkbox-container">
    <div *ngFor="let checkbox of tulValues; let i = index">
      <input
        type="checkbox"
        [id]="checkbox.title + i"
        [name]="'tul-checkbox' + tulLabel"
        [ngClass]="{ 'tul-input--disabled': tulDisabled }"
        [disabled]="tulDisabled"
        [(ngModel)]="checkbox.checked"
        (ngModelChange)="updateChanges()"
      />
      <span class="tul-checkbox-container--label"> &nbsp;{{ checkbox.title }}</span>
    </div>
  </div>
  <small
    class="tul-input-container__small"
    *ngIf="tulAdditionalInfo"
    [ngClass]="{
      'tul-input-container__small--alert-error': tulState === 'error',
      'tul-input-container__small--alert-success': tulState === 'success'
    }"
    >{{ tulAdditionalInfo | transloco }}
  </small>
</div>
