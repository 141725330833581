import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TulStep, StepState, StepType, TulStepType } from '../../../models/step.model';
import { TulStepperService } from '../../services/tul-stepper.service';

/**
 * Component for custom stepper
 */
@Component({
  selector: 'tul-steppers',
  templateUrl: './tul-steppers.component.html',
  styleUrls: ['./tul-steppers.component.scss'],
})
export class TulSteppersComponent implements OnInit {
  /**
   * Data for stepper content
   */
  @Input() tulStepList: TulStep[] = [];

  /**
   * Enum Stepper type
   */
  @Input() tulType: TulStepType = StepType.NUMBER;

  /**
   * Current ID
   */
  stepCurrentId: number = 0;

  /**
   * Get events on modal
   */
  display$: Observable<'initial' | 'next' | 'previous'> = new Observable();

  /**
   * Constructor
   * @param stepperService service for actions in stepper
   */
  constructor(private stepperService: TulStepperService) {}

  /**
   * Subscribe to service observable
   */
  ngOnInit(): void {
    this.display$ = this.stepperService.watch();
    this.display$.subscribe({
      next: (res) => {
        this.checkListSize();
        if (res === 'initial') {
          this.initialConfig();
          this.setCurrentId('initial');
        } else {
          this.setStepList(res);
        }
      },
    });
  }

  /**
   * Set stepper states
   */
  initialConfig() {
    this.tulStepList.forEach((item) => (item.state = item.state ?? StepState.INACTIVE));
  }

  /**
   * Set state in @tulStepList for ACTIVE(actual), FINISHED(previous), INACTIVE(next)
   * @param state for actions on stepper
   */
  setStepList(state: string) {
    switch (state) {
      case 'initial':
        for (let i = 0; i < this.tulStepList.length; i++) {
          if (i === 0) {
            this.tulStepList[i].state = StepState.ACTIVE;
            this.stepCurrentId = i;
          } else {
            this.tulStepList[i].state = StepState.INACTIVE;
          }
        }
        break;
      case 'next':
      case 'previous':
        this.setCurrentId(state);
        for (let i = 0; i < this.tulStepList.length; i++) {
          if (i === this.stepCurrentId) {
            this.tulStepList[i].state = StepState.ACTIVE;
          } else if (i < this.stepCurrentId) {
            this.tulStepList[i].state = StepState.FINISHED;
          } else if (i > this.stepCurrentId) {
            this.tulStepList[i].state = StepState.INACTIVE;
          }
          if (i === this.tulStepList.length - 1 && this.stepCurrentId > i) {
            this.tulStepList[i].state = StepState.FINISHED;
          }
        }
        break;
      default:
        break;
    }
  }

  /**
   * Increases or decreases @tulCurrentId for functionality
   * @param state 'next' | 'previous'
   */
  setCurrentId(state: string) {
    let arraySize = this.tulStepList.length;
    switch (true) {
      case state === 'initial':
        this.stepCurrentId = this.tulStepList.findIndex((step) => step.state === StepState.ACTIVE);
        break;
      case state === 'next' && this.stepCurrentId < arraySize:
        this.stepCurrentId++;
        break;
      case state === 'previous' && this.stepCurrentId > 0:
        this.stepCurrentId--;
    }
  }

  /**
   * Check if @tulStepList is greater than five(permitted)
   */
  checkListSize() {
    if (this.tulStepList.length > 5) {
      this.tulStepList.splice(5, this.tulStepList.length);
    }
  }

  /**
   * Check if @tulStepList has an ACTIVE state
   * @returns boolean if exists any state
   */
  checkCurrentState(): boolean {
    let exists = false;
    for (let i = 0; i < this.tulStepList.length; i++) {
      if (this.tulStepList[i].state === StepState.ACTIVE) {
        this.stepCurrentId = i === 0 ? i : i - 1;
        exists = true;
        return exists;
      }
    }
    return exists;
  }
}
