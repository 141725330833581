import { Component } from '@angular/core';

/**
 * svg export
 */
@Component({
  selector: 'svg-export',
  templateUrl: './svg-export.component.html',
  styleUrls: ['./svg-export.component.scss'],
})
export class SvgExportComponent {}
