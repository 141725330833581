/**
 * Interface for data content
 */
export interface TulStep {
  /**
   * Text under step
   */
  stepText: string;

  /**
   * Optional actual state
   */
  state?: StepState;

  /**
   * Optional id
   */
  id?: number;
}

export enum StepType {
  NUMBER = 'number',
  ICONS = 'icons',
}

export enum StepState {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  FINISHED = 'finished',
}

export type TulStepType = `${StepType}`;
export type TulStepState = `${StepState}`;
