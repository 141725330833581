<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'switch'">
      <lib-switch
        [data]="selectorsData"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></lib-switch>
    </ng-container>
    <ng-container *ngSwitchCase="'radio'">
      <lib-radio
        [data]="selectorsData"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></lib-radio>
    </ng-container>
    <ng-container *ngSwitchCase="'checkbox'">
      <lib-checkbox
        [data]="selectorsData"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></lib-checkbox>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <lib-switch
        [data]="selectorsData"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></lib-switch>
    </ng-container>
  </ng-container>
</ng-container>
