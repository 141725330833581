import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorComponent } from './color.component';
import { FormsModule } from '@angular/forms';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  exports: [ColorComponent],
  imports: [CommonModule, FormsModule, NzPipesModule, TulDataTablePipeModule],
  declarations: [ColorComponent],
})
export class ColorModule {}
