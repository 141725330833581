import { NgModule } from '@angular/core';
import { TulCheckboxComponent } from './components/tul-checkbox/tul-checkbox.component';
import { SharedModule } from '../shared/shared.module';
import { TulTooltipModule } from '../tul-tooltip';
import { TulSwitchTitlePipeModule } from '../pipes/switchTitle/tul-switch-title-pipe.module';

@NgModule({
  declarations: [TulCheckboxComponent],
  imports: [SharedModule, TulSwitchTitlePipeModule, TulTooltipModule],
  exports: [TulCheckboxComponent],
})
export class TulCheckboxModule {}
