import { Component, Input } from '@angular/core';
import { SubTypeNumericModifierColumn } from '../../../../enums/columns/subTypeNumericModifierColumn.enum';
import { TypeNumericsModifier } from '../../../../interfaces/columns/numeric-modifier';
import { Country } from '../../../../interfaces/country';
/**
 * Body option numeric modifier
 */
@Component({
  selector: '[numeric-modifier]',
  templateUrl: './numeric-modifier.component.html',
})
export class NumericModifierComponent {
  /**
   * subType Column with default
   */
  subType!: string;

  /**
   * subType Column
   */
  @Input('subType')

  /**
   * Set default column
   */
  set SubType(subType: string) {
    this.subType = subType || SubTypeNumericModifierColumn.INPUT;
  }

  /**
   * Actual Column
   */
  @Input() column!: TypeNumericsModifier;

  /**
   * Data row
   */
  @Input() data: any;

  /**
   * Country configurations
   */
  @Input() country!: Country;
}
