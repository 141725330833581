import { Component, Input } from '@angular/core';
import { ColumnForms } from '../../../../interfaces/columns/column';
import { Data } from '../../../../interfaces/data';
import { CustomColumnForms } from '../../../../interfaces';
/**
 *
 */
@Component({
  selector: 'custom-controller',
  templateUrl: './custom-controller.component.html',
})
export class CustomControllerComponent {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: ColumnForms;

  /**
   * Constructor
   */
  constructor() {}

  /**
   * ngOnInit
   */
  ngOnInit() {}

  /**
   * Generate template in custom option
   * @param option actual option (custom)
   * @return custom template
   */
  getCustomTemplate(column: ColumnForms) {
    const customColumn = column as CustomColumnForms;
    return typeof customColumn.template === 'function'
      ? customColumn.template({ data: this.data, column: this.column })
      : customColumn.template;
  }
}
