import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ListedControllerComponent } from './listed-controller.component';
import { TulSelectModule } from '../../../../../../tul-select/tul-select.module';

@NgModule({
  declarations: [ListedControllerComponent],
  imports: [CommonModule, FormsModule, TulSelectModule],
  exports: [ListedControllerComponent],
})
export class ListedControllerModule {}
