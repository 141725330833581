import {
  Component,
  Type,
  OnDestroy,
  AfterViewInit,
  ComponentRef,
  ChangeDetectorRef,
  Inject,
  ElementRef,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ToastConfig } from '../services/toast-config';
import { defaultToastConfig } from '../services/toast-default-config';
import { ToastInit } from '../types/toast-init';

@Component({
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements AfterViewInit, OnDestroy {
  /**
   * Component reference
   */
  componentRef!: ComponentRef<any>;

  /**
   * Subject for event close
   */
  private readonly _onClose = new Subject<any>();

  /**
   * Observable for close alert
   */
  public onClose = this._onClose.asObservable();

  /**
   * Data object for alert structure
   */
  data: ToastConfig = defaultToastConfig;

  toastInit: ToastInit;

  /**
   * Constructor
   * @param cd
   * @param toastRef
   */
  constructor(private cd: ChangeDetectorRef, @Inject('TOASTINIT') TOASTINIT: any) {
    this.toastInit = TOASTINIT;
  }

  /**
   * Close toast when time finalice
   */
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  /**
   * Destroy component reference
   */
  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  /**
   * Close alert when user clicked on
   * @param evt
   */
  onOverlayClicked(evt: MouseEvent): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  /**
   * Open alert
   * @param evt
   */
  onDialogClicked(evt: MouseEvent): void {
    evt.stopPropagation();
  }

  /**
   * Close alert
   */
  close(): void {
    this._onClose.next(true);
  }
}
