import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { State, TulState } from '../../../shared/types/state.type';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilesTypes } from '../../../tul-form/enums/typeFile';
import { ImagesTypes } from '../../../tul-form/enums/typeImage';
import { TulFile } from './types/tul-upload.type';
import { Size, UploadErrors } from './types/tul-upload.enum';

@Component({
  selector: 'tul-upload',
  templateUrl: './tul-upload.component.html',
  styleUrls: ['./tul-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TulUploadComponent),
      multi: true,
    },
  ],
})
export class TulUploadComponent implements ControlValueAccessor, OnInit {
  tulFileListDefault!: Array<TulFile>;

  /**
   * Text in label
   */
  @Input() tulLabel: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Enum State for design
   */
  @Input() tulState: TulState = State.DEFAULT;

  /**
   * Text under dropdown
   */
  @Input() tulAdditionalInfo: string = '';
  /**
   * Text under dropdown
   */
  @Input() tulMultiple: boolean = false;
  /**
   * Text under dropdown
   */
  @Input() tulSize: number = 1024;
  /**
   * Text under dropdown
   */
  @Input() tulFileList!: Array<TulFile>;
  /**
   * Text under dropdown
   */
  @Input() tulAllowedExtensions: ImagesTypes[] | FilesTypes[] = [];

  /** errors on upload */
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();

  /** */
  @Input() tulTooltipconfig!: any;

  /**
   * Holds the current value of the slider
   */
  value: any;

  ngOnInit(): void {
    this.tulFileListDefault = this.tulFileList;
  }
  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  fileName = '';

  onFileSelected(event: { target: { files: File[] } }) {
    const files: File[] = event.target.files;

    if (files) {
      // check if file size is allowed
      const allowedSizeFiles = Array.from(files).filter((file) => {
        if (!this.checkFileSize(file)) {
          this.onError.emit({ file, error: UploadErrors.WRONG_SIZE });
          return false;
        }
        return true;
      });

      // check if extensions is right
      const allowedFiles = Array.from(allowedSizeFiles).filter((file) => {
        if (!this.checkFileExtension(file)) {
          this.onError.emit({ file, error: UploadErrors.WRONG_EXTENSION });
          return false;
        }
        return true;
      });

      this.tulFileList = [];
      allowedFiles.forEach((file_done, index) => {
        this.tulFileList.push({
          name: file_done.name,
          uid: index + '',
          url: '',
        });
      });
      this.tulFileList = [...this.tulFileListDefault, ...this.tulFileList];
      this.writeValue(allowedFiles);
    }
  }

  //////////////////////////////
  // VALIDATE TUL UPLOAD      //
  //////////////////////////////

  /**
   *
   * @param size Image size
   * @returns true if image size is valid, else false
   */

  checkFileSize(file: File) {
    const fileSize = file.size;
    if (this.tulSize >= Math.round(fileSize / Size.kbSize)) return true;
    return false;
  }
  /**
   *
   * @param size Image size
   * @returns true if image size is valid, else false
   */

  checkFileExtension(file: File) {
    const extFile = this.getFileExtension(file.name);
    const allowedExtensions = [...(this.tulAllowedExtensions as any)];
    if (allowedExtensions.indexOf(extFile) !== -1) {
      return true;
    }
    return false;
  }

  /**
   * get file extension
   */

  getFileExtension(filename: string = ''): string {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: File[]): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
