import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MapDefaultControllerComponent } from './map-default-controller.component';

@NgModule({
  declarations: [MapDefaultControllerComponent],
  imports: [CommonModule, FormsModule],
  exports: [MapDefaultControllerComponent],
})
export class MapDefaultControllerModule {}
