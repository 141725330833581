import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickOption, CustomOption } from '../../../interfaces/export';

/**
 * Custom option header
 */
@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
})
export class CustomComponent {
  /**
   * actual option (CUSTOM)
   */
  @Input() option!: CustomOption | any;
  /**
   * Emitter to execute option
   */
  @Output() clickOptionEmitter = new EventEmitter<ClickOption>();

  /**
   * Generate template in custom option
   * @param option actual option (custom)
   * @return custom template
   */
  getCustomTemplate(option: any) {
    return typeof option.template === 'function'
      ? option.template({ option: this.option })
      : option.template;
  }

  /**
   * Generate tooltip in custom option
   * @param option actual option (custom)
   * @return string tooltip
   */
  getToolTip(option: any) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return '';
    }
    return typeof option.tooltip.title === 'string'
      ? option.tooltip.title
      : option.tooltip.title({ data: {}, option });
  }
}
