import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TulInputModule } from '../../../../../../tul-input/tul-input.module';
import { DecimalControllerComponent } from './decimal-controller.component';

@NgModule({
  declarations: [DecimalControllerComponent],
  imports: [CommonModule, FormsModule, TulInputModule],
  exports: [DecimalControllerComponent],
})
export class DecimalControllerModule {}
