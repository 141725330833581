import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateComponent } from './date.component';
import { FormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  exports: [DateComponent],
  imports: [CommonModule, FormsModule, NzDatePickerModule, TranslocoModule],
  declarations: [DateComponent],
})
export class DateModule {}
