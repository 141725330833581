<ng-container [ngSwitch]="column.subType">
  <ng-container *ngSwitchCase="'image'">
    <div class="image-container" *ngFor="let item of fileList">
      <img
        class="image-container__img"
        [src]="item.url + '?timestamp=' + currentDate"
        alt=""
        (click)="isVisible = true"
      />
      {{ column.enableFilename ? item.name : "" }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'file'">
    <div class="image-container" *ngFor="let item of fileList" (click)="goToFilePath(item.url)">
      {{ item.name }}
    </div>
  </ng-container>
</ng-container>
