import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { ColorModule } from './color/color.module';
import { ContentComponent } from './content.component';
import { CustomModule } from './custom/custom.module';
import { DateModule } from './date/date.module';
import { DocumentModule } from './document/document.module';
import { ImageModule } from './image/image.module';
import { NumericModifierModule } from './numeric-modifier/numeric-modifier.module';
import { NumericModule } from './numeric/numeric.module';
import { SelectModule } from './select/select.module';
import { SelectorsModule } from './selectors/selectors.module';
import { TextModule } from './text/text.module';

@NgModule({
  exports: [ContentComponent],
  imports: [
    CommonModule,
    FormsModule,
    CustomModule,
    DateModule,
    NumericModule,
    SelectModule,
    SelectorsModule,
    TextModule,
    ColorModule,
    ImageModule,
    RouterModule,
    DocumentModule,
    NumericModifierModule,
    NzPipesModule,
  ],
  declarations: [ContentComponent],
})
export class ContentModule {}
