import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  exports: [CommonModule, FormsModule, RouterModule, TranslocoModule],
})
export class SharedModule {}
