import { Component } from '@angular/core';

/**
 * Component Tul Modal Body for project content
 */
@Component({
  selector: 'tul-modal-body',
  template: `<ng-content></ng-content>`,
})
export class TulModalBodyComponent {}
