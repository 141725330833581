<button
  *ngIf="!isMultiEdit"
  nz-button
  (click)="OpenChecks()"
  class="button button-edit"
  nzType="default"
  [nzTooltipTitle]="getToolTip(option)"
  nzTooltipPlacement="top"
  nz-tooltip
  [disabled]="option.disabled | tulToAsyncPipe | async"
>
  <span class="button-edit__text">
    {{ "tulTable.options.multipleEditing.title" | transloco }}
  </span>
</button>

<div class="options-edit-multiple" *ngIf="isMultiEdit">
  <button nz-button (click)="sendDataMultiEdit()" class="button button-assign" nzType="default">
    <span class="button-assign__text"> {{ option.label }} </span>
  </button>
  <button nz-button (click)="closeChecks()" class="button button-cancel" nzType="default">
    <span class="button-cancel__text">
      {{ "tulTable.options.multipleEditing.cancel" | transloco }}
    </span>
  </button>
</div>
