export enum LabelColor {
  YELLOW = 'yellow',
  LIGHT_YELLOW = 'light-yellow',
  ORANGE = 'orange',
  RED = 'red',
  LIGHT_RED = 'light-red',
  BLUE = 'blue',
  GREEN = 'green',
  LIGHT_GREEN = 'light-green',
  GRAY = 'gray',
}

export type TulLabelColor = `${LabelColor}`;
