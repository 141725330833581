import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TulTooltipDirective } from './directives/tul-tooltip.directive';
import { TulTooltipComponent } from './components/tul-tooltip/tul-tooltip.component';

@NgModule({
  declarations: [TulTooltipDirective, TulTooltipComponent],
  imports: [SharedModule],
  exports: [TulTooltipDirective, TulTooltipComponent],
})
export class TulTooltipModule {}
