import { Inject, Injectable } from '@angular/core';
import { TypeValidatorError } from '../enums/errors/genericColumnErrors.enum';
import { SubTypeTextColumnForms } from '../enums';
import { ValidField } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class TextValidations {
  /** environment variable */
  environment: any;

  /**
   *
   * @param environment
   */
  constructor(@Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }

  /**
   *
   * @param data
   * @param col
   * @param response
   * @returns
   */
  validateTextFields(data: any, col: any, response: ValidField) {
    const { subType, minLength, maxLength, pattern, invalidPatternMessage } = col;
    const valueLength = `${data}`.trim().length;
    const isMinError = minLength !== undefined && Number(minLength) > valueLength;
    const isMaxError = maxLength !== undefined && Number(maxLength) < valueLength;
    switch (subType) {
      case SubTypeTextColumnForms.TEXTAREA:
      case SubTypeTextColumnForms.TEXT:
        response.invalidField = isMinError || isMaxError;

        if (isMaxError) {
          response.errors.push(TypeValidatorError.INVALID_MAX_LENGTH);
          break;
        }

        if (isMinError) {
          response.errors.push(TypeValidatorError.INVALID_MIN_LENGTH);
          break;
        }

        break;
      case SubTypeTextColumnForms.REGEX:
        const re = new RegExp(pattern);
        if (re.exec(data)) {
          response.invalidField = false;
        } else {
          response.invalidField = true;
          response.errors.push(invalidPatternMessage ?? TypeValidatorError.INVALID_REGEX);
        }
        break;
      default:
        break;
    }
    return response;
  }
}
