import { ToastConfig } from './toast-config';

export const defaultToastConfig: ToastConfig = {
  message: '',
  description: '',
  tulType: 'default',
  tulColor: 'success',
  isBanner: true,
  isClosable: true,
  withIcon: true,
};
