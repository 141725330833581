import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { FileColumn } from '../../../../../interfaces/columns/files';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { TranslateService } from '../../../../../../services/translate/translate.service';
import { Size } from '../../../../../enums/size';
import { ToastService } from '../../../../../../tul-toast';

@Component({
  selector: 'lib-file',
  templateUrl: './file.component.html',
})
export class FileComponent implements OnInit {
  /** */
  @Input() data: any = {};

  /** */
  @Input() column!: FileColumn;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Image list
   */
  fileList: NzUploadFile[] = [];

  /**
   * Url or preview b64 data
   */
  previewImage: string | undefined = '';

  /**
   * Show modal preview
   */
  previewVisible = false;

  /**
   * Subscriptor Get File
   */
  suscriptionFile = new Subscription();

  /**
   * loading state
   */
  loading: boolean = false;

  /**
   * Constructor
   * @param msg Message for notifications
   * @param translocoAppService Translate language
   */
  constructor(
    private translocoAppService: TranslateService,

    public toastService: ToastService
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.checkIfFileExistFromGraph();
    this.checkIfFileExist();
  }

  /**
   * Check if exist image objects from graph for set @fileList
   */
  checkIfFileExistFromGraph() {
    if (this.data[this.column.attribute]) {
      const images = this.data[this.column.attribute].map((item: any) => ({
        ...item,
        selected: false,
      }));
      this.fileList = images ?? [];
    }
  }

  /**
   * Check if exist image objects from fileList column attribute for set @fileList
   */
  checkIfFileExist() {
    if (this.column.fileList) {
      this.fileList.push(...this.column.fileList);
    }
    // grab files from httpSuscribe

    if (this.column.httpSubscribe) {
      this.suscriptionFile = this.column
        .httpSubscribe({ data: this.data, column: this.column })
        .subscribe({
          next: ({ uuid, file_name, url }) => {
            this.fileList = [
              {
                uid: uuid,
                name: file_name,
                url,
              },
              ...this.fileList,
            ];
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  /**
   * Check if file size is in range allowed
   * @param file Image
   * @returns Boolean promise
   */
  beforeUpload = (file: any): Observable<boolean> => {
    if (file.size && !this.checkFileSize(file.size)) {
      this.toastService.open({
        message: `${this.translocoAppService.translate(
          'El archivo sobrepasa el límite del tamaño'
        )} ${this.column.size} KB`,
        tulType: 'default',
        tulColor: 'error',
        isBanner: true,
        isClosable: true,
        withIcon: true,
      });
    } else {
      this.fileList = this.fileList.concat(file);
      this.onChange.emit(this.fileList);
    }
    return of(false);
  };

  /**
   *
   * @param size Image size
   * @returns true if image size is valid, else false
   */
  checkFileSize(size: number): boolean {
    if (this.column.size && this.column.size >= Math.round(size / Size.kbSize)) return true;
    return false;
  }

  /**
   * Register all changes in nz upload
   * @param event Data complete wit current file and file list
   */
  change(event: any) {
    console.warn('change');
    if (!this.checkFileSize(event.file.size)) {
      this.fileList.pop();
    }
    this.onChange.emit(event.fileList);
  }
}
