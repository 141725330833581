import { Component, Input } from '@angular/core';
import { Data } from '../../../../interfaces/data';
import { ColumnForms } from '../../../../interfaces';
/**
 *
 */
@Component({
  selector: 'color-controller',
  templateUrl: './color-controller.component.html',
})
export class ColorControllerComponent {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: ColumnForms;
}
