import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandComponent } from './expand.component';
import { FormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TulGetToolTipPipeModule } from '../../../../../pipes/getTooltip/tul-get-tool-tip-pipe.module';

@NgModule({
  exports: [ExpandComponent],
  imports: [CommonModule, FormsModule, NzToolTipModule, NzIconModule, TulGetToolTipPipeModule],
  declarations: [ExpandComponent],
})
export class ExpandModule {}
