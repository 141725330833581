import { Pipe, PipeTransform } from '@angular/core';

/**
 * to fixed decimal number
 */
@Pipe({ name: 'tulToFixedDecimalPipe' })
export class TulToFixedDecimalPipe implements PipeTransform {
  transform(value: any, column: any): any {
    if (column.type !== 'numeric') return value;
    if (column.subType !== 'number') return value;
    if (!column.decimal || column.decimal === 0) return value;
    return value.toFixed(column.decimal);
  }
}
