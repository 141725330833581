import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '../../services/translate/translate.service';

/**
 * Provider that use the tulTranslateProvider custom for Tul MFE
 */

export const TulTranslateProvider = {
  provide: APP_INITIALIZER,
  useFactory: (ds: TranslateService) => () => {
    return ds.load();
  },
  deps: [TranslateService],
  multi: true,
};
