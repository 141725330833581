import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FontType, TulFontType } from '../../types/font-type.type';

/**
 * Component for custom chip
 */
@Component({
  selector: 'tul-chip',
  templateUrl: './tul-chip.component.html',
  styleUrls: ['./tul-chip.component.scss'],
})
export class TulChipComponent implements AfterContentInit {
  /**
   * Show item container
   */
  showItemContainer = true;

  /**
   * Element reference container select item
   */
  @ViewChild('tulContainerItem') tulContainerItem!: ElementRef;

  /**
   * Hide chip
   */
  hideChip: boolean = false;

  /**
   * Text in chip
   */
  @Input() tulText: string = 'Tul Chip';

  /**
   * Enum font type ('REGULAR' | 'MEDIUM' | 'SEMIBOLD')
   */
  @Input() TulFontType: TulFontType = FontType.REGULAR;

  /**
   * Emit response in actions on chip
   */
  @Output() tulActions: EventEmitter<string> = new EventEmitter();

  /**
   * Check if ng-content is empty
   */
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.showItemContainer =
        this.tulContainerItem.nativeElement.innerHTML.trim().length > 0 ? true : false;
    }, 5);
  }

  /**
   * Close chip and emit response
   */
  closeChip() {
    this.hideChip = true;
    this.tulActions.emit(`${this.tulText} was closed`);
  }
}
