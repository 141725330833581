<div class="ant-table-filter-dropdown" class="{{ column.type }}">
  <div class="search-box">
    <ng-container [ngSwitch]="column.type">
      <!-- TEXT -->
      <ng-container *ngSwitchCase="'text'">
        <text-filter
          [column]="column"
          [filter]="filter"
          (changeValueFilter)="changeValue($event, column); valueFilterInput = $event"
        ></text-filter>
      </ng-container>
      <!-- NUMBERS -->
      <ng-container *ngSwitchCase="'numeric'">
        <numeric-filter
          [column]="column"
          [filter]="filter"
          (changeValueFilter)="changeValue($event, column); valueFilterInput = $event"
        ></numeric-filter>
      </ng-container>
      <!-- SELECTORS -->
      <ng-container *ngSwitchCase="'selectors'">
        <selectors-filter
          [column]="column"
          [filter]="filter"
          (changeValueFilter)="changeValue($event, column); valueFilterInput = $event"
        ></selectors-filter>
      </ng-container>
      <!-- DATE -->
      <ng-container *ngSwitchCase="'date'">
        <date-filter
          [column]="column"
          [filter]="filter"
          (changeValueFilter)="changeValue($event, column); valueFilterInput = $event"
        ></date-filter>
      </ng-container>
      <!-- SELECT -->
      <ng-container *ngSwitchCase="'select'">
        <select-filter
          [column]="column"
          [filter]="filter"
          (changeValueFilter)="changeValue($event, column); valueFilterInput = $event"
        ></select-filter>
      </ng-container>
      <!-- DEFAULT -->
      <ng-container *ngSwitchDefault>
        <text-filter
          [column]="column"
          [filter]="filter"
          (changeValueFilter)="changeValue($event, column); valueFilterInput = $event"
        ></text-filter>
      </ng-container>
    </ng-container>
    <div class="buttons-content">
      <button
        class="search-button"
        nz-button
        nzSize="small"
        nzType="default"
        nzDanger
        [disabled]="!getValueFilter(column)"
        (click)="deleteFilter(column)"
      >
        {{ "tulTable.filters.options.clean" | transloco }}
      </button>
      <button
        class="search-button"
        nz-button
        nzSize="small"
        nzType="primary"
        [disabled]="!valueFilterInput && !getValueFilter(column)"
        (click)="actionFilter(column)"
      >
        {{ "tulTable.filters.options.search" | transloco }}
      </button>
    </div>
  </div>
</div>
