import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeDocument } from '../../../../interfaces/columns/document';
import { Data } from '../../../../interfaces/data';
import { TulSelectData } from '../../../../../tul-select/models/select-data.model';

/**
 *
 */
@Component({
  selector: 'document-controller',
  templateUrl: './document-controller.component.html',
  styleUrls: ['./document-controller.component.scss'],
})
export class DocumentControllerComponent implements OnInit {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TypeDocument;

  /** sends the current value */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  currentData!: TulSelectData[];

  ngOnInit(): void {
    this.render();
  }

  render() {
    this.currentData = this.column?.listValuesType;
  }

  changeData(value: any) {
    this.onChange.emit(value);
  }
}
