<div
  class="header-group"
  *ngIf="options.type === headerTypes.VERTICAL || options.type === headerTypes.VERTICAL_REVERSE"
>
  <div class="header-group__container">
    <div class="group-icon" *ngIf="options.icon">
      <i class="group-icon__asset soytul" [ngClass]="[options.icon ? options.icon : '']"></i>
    </div>
    <div class="group__title">
      {{ options.type === headerTypes.VERTICAL ? options.title : options.description }}
    </div>
    <div class="group__subtitle">
      {{ options.type === headerTypes.VERTICAL ? options.description : options.title }}
    </div>
  </div>
</div>

<div
  class="header-group"
  *ngIf="options.type === headerTypes.HORIZONTAL || options.type === headerTypes.HORIZONTAL_REVERSE"
>
  <div class="header-group-horizontal__container">
    <div class="group-container">
      <div class="group-icon" *ngIf="options.icon">
        <i class="group-icon__asset soytul" [ngClass]="[options.icon ? options.icon : '']"></i>
      </div>
      <div class="group-text">
        <div class="group-text__title">
          {{ options.type === headerTypes.HORIZONTAL ? options.title : options.description }}
        </div>
        <div class="group-text__subtitle">
          {{
            options.type === headerTypes.HORIZONTAL_REVERSE ? options.description : options.title
          }}
        </div>
      </div>
    </div>
  </div>
</div>
