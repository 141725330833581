import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorControllerComponent } from './color-controller.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ColorControllerComponent],
  exports: [ColorControllerComponent],
})
export class ColorControllerModule {}
