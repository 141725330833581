import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { TulState, State } from '../../../../shared/types/state.type';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

/**
 * noop
 */
function noop() {
  //noop ref
}

/**
 * Interface for communication with ngModel
 */
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TulInputDateComponent),
  multi: true,
};

@Component({
  selector: 'tul-input-date',
  templateUrl: './tul-input-date.component.html',
  styleUrls: ['./tul-input-date.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TulInputDateComponent implements ControlValueAccessor, OnInit {
  /**
   * Placeholder
   */
  @Input() tulPlaceholder: string = 'Placeholder';

  /**
   * Text in label
   */
  @Input() tulLabel: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Text under dropdown
   */
  @Input() tulAdditionalInfo: string = '';

  /**
   * Enum State for design
   */
  @Input() tulState: TulState = State.DEFAULT;

  /**
   * Name
   */
  @Input() tulName: string = '';

  /**
   * Tooltip config
   */
  @Input() tulTooltipconfig!: any;

  /**
   * Minimum date allowed
   */
  @Input() tulMin: string = '';

  /**
   * Maximum date allowed
   */
  @Input() tulMax: string = '';

  /**
   * Inner Value
   */
  private innerValue: any = '';

  /**
   * Response on touched
   */
  private onTouchedCallback: () => void = noop;

  /**
   * Response on change value
   */
  private onChangeCallback: (_: any) => void = noop;

  /**
   * Get value ngModel
   */
  get value(): any {
    return this.innerValue;
  }

  /**
   * Set value ngModel
   */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    this.setMinDate();
    this.setMaxDate();
  }

  /**
   * Write value
   * @param value ngModel
   */
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  /**
   * Register on change
   * @param fn function
   */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /**
   * Register on touched
   * @param fn function
   */
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /**
   * Set minimum date
   */
  setMinDate() {
    this.tulMin = this.tulMin ? `${new Date(this.tulMin).toISOString().slice(0, 10)}` : '';
  }

  /**
   * Set maximum date
   */
  setMaxDate() {
    this.tulMax = this.tulMax ? `${new Date(this.tulMax).toISOString().slice(0, 10)}` : '';
  }
}
