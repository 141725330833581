<ng-container [ngSwitch]="subType">
  <!-- TEXT EDITOR -->
  <ng-container *ngSwitchCase="'text-editor'">
    <nz-collapse nzGhost>
      <nz-collapse-panel [nzHeader]="refHeaderCollapse">
        <iframe
          title="text-editor-iframe"
          class="iframe"
          [srcdoc]="data | tulDataTablePipe : column.attribute"
        ></iframe>
      </nz-collapse-panel>
    </nz-collapse>

    <ng-template #refHeaderCollapse>
      <p class="ellipsis-header">{{ getNameTextEditor(column) }}</p>
    </ng-template>
  </ng-container>

  <!-- LINK -->
  <ng-container *ngSwitchCase="'link'">
    <ng-container *ngIf="data | tulDataTablePipe : column.attribute; else attributeNull">
      <a [routerLink]="data | tulDataTablePipe : column.attribute">
        <ng-container *ngTemplateOutlet="default"></ng-container>
      </a>
    </ng-container>
    <ng-template #attributeNull>
      <ng-container *ngTemplateOutlet="default"></ng-container>
    </ng-template>
  </ng-container>

  <!-- TEXT -->
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>

  <!-- DEFAULT -->
  <ng-template #default>
    {{
      data
        | tulDataTablePipe : column.attribute
        | nzSafeNull : (column.safeNull ? column.safeNull : "-")
    }}
  </ng-template>
</ng-container>
