<nz-range-picker
  class="input-date"
  [nzPlaceHolder]="
    !column.filter
      ? ''
      : column.filter.placeholder
      ? column.filter.placeholder
      : ('tulTable.filters.date.placeholder.default' | transloco)
  "
  [ngModel]="getValueFilter(column)"
  (ngModelChange)="ngModelChange($event)"
>
</nz-range-picker>
