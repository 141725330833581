import { Component } from '@angular/core';

/**
 * svg delete
 */
@Component({
  selector: 'svg-delete',
  templateUrl: './svg-delete.component.html',
  styleUrls: ['./svg-delete.component.scss'],
})
export class SvgDeleteComponent {}
