import { Component, ElementRef, HostListener, Input, OnInit, forwardRef } from '@angular/core';
import { State, TulState } from '../../../../shared/types/state.type';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TulSelectDataTree } from '../../../models/select-data-tree.model';
import { TulSelectAttrType, TulSelectAttrTypeEnum } from '../../../types/select-attr-type.type';
import {
  TulSelectTreeInputType,
  TulSelectTreeInputTypeEnum,
} from '../../../types/select-tree-input-type';

/**
 * noop
 */
function noop() {
  //noop ref
}

/**
 * Interface for communication with ngModel
 */
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TulSelectTreeListMultiSelectComponent),
  multi: true,
};

@Component({
  selector: 'tul-select-tree-list-multi-select',
  templateUrl: './tul-select-tree-list-multi-select.component.html',
  styleUrls: ['./tul-select-tree-list-multi-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TulSelectTreeListMultiSelectComponent implements ControlValueAccessor, OnInit {
  /**
   * Placeholder
   */
  @Input() tulPlaceholder: string = 'Placeholder';

  /**
   * Text in label
   */
  @Input() tulLabel: string = 'Text input label';

  /**
   * Enum State for design
   */
  @Input() tulState: TulState = State.DEFAULT;

  /**
   * Name
   */
  @Input() tulName: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Text under dropdown
   */
  @Input() tulAdditionalInfo: string = 'Additional info';

  /**
   * Data for tree
   */
  @Input() tulData: TulSelectDataTree[] = [];

  /**
   * Tul items selected
   */
  @Input() tulItemsSelected: TulSelectDataTree[] = [];

  /**
   * Enum ('multi-tag', 'multi-text')
   */
  @Input() tulInputType: TulSelectTreeInputType = TulSelectTreeInputTypeEnum.MULTITEXT;

  /**
   * Backup data filter
   */
  tulDataBackup: string = '';

  /**
   * Data setted after filter
   */
  finalData: TulSelectDataTree[] = [];

  /**
   * Loading text
   */
  loadingText: string = '';

  /**
   * Show loading
   */
  showLoading: boolean = false;

  /**
   * Focus on div container search
   */
  searchOnFocus: boolean = false;

  /**
   * Show menu
   */
  showMenu: boolean = false;

  /**
   * General id
   */
  id: number = 0;

  /**
   * Inner Value
   */
  private innerValue: any = '';

  /**
   * Response on touched
   */
  private onTouchedCallback: () => void = noop;

  /**
   * Response on change value
   */
  private onChangeCallback: (_: any) => void = noop;

  /**
   * Check click out of the element
   * @param event
   */
  @HostListener('document:click', ['$event'])
  clickOut(event: any | Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showMenu = false;
      this.searchOnFocus = false;
    }
  }

  /**
   * Constructo
   * @param eRef
   */
  constructor(private eRef: ElementRef) {}

  /**
   * OnInit
   */
  ngOnInit(): void {
    this.setDataId(this.tulData, 0, -1);
    this.tulDataBackup = JSON.stringify(this.tulData);
  }

  /**
   * Get if tul state is error
   */
  get isError() {
    return this.tulState === 'error';
  }

  /**
   * Get if tul state is error
   */
  get isSuccess() {
    return this.tulState === 'success';
  }

  /**
   * Get disabled
   */
  get isDisabled() {
    return this.tulDisabled;
  }

  /**
   * Get value ngModel
   */
  get value(): any {
    return this.innerValue;
  }

  /**
   * Set value ngModel
   */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  /**
   * Write value
   * @param value ngModel
   */
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  /**
   * Register on change
   * @param fn function
   */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /**
   * Register on touched
   * @param fn function
   */
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /**
   * Filter data search
   */
  filterData() {
    this.loadingText = 'minimumThreeCharactersForFilter';
    this.showLoading = true;
    setTimeout(() => {
      if (this.value.length >= 3) {
        this.loadingText = 'loadingInformation';
        this.tulData = [...JSON.parse(this.tulDataBackup)];
        let data: any = [];
        this.finalData = [];
        this.setAttrInData(this.tulData, 'show', false);
        for (let i = 0; i < this.tulData.length; i++) {
          this.filterElements(this.tulData[i], data);
        }
      }
    }, 1000);
    if (this.value.length === 0) {
      this.tulData = [...JSON.parse(this.tulDataBackup)];
      this.showLoading = false;
    }
  }

  /**
   * Set show item when user clicked
   * @param parent
   */
  showChildren(parent: TulSelectDataTree, type: string) {
    this.tulItemsSelected = [];
    switch (type) {
      case 'checked':
        parent.checked = parent.checked ? !parent.checked : true;
        if (parent.children.length === 0 && parent.checked) {
          parent.selected = parent.selected ? false : true;
        } else {
          parent.selected = false;
        }
        if (parent.children.length !== 0) {
          this.setAttrInData(parent.children, TulSelectAttrTypeEnum.CHECKED, parent.checked);
          this.setAttrInData(parent.children, TulSelectAttrTypeEnum.SELECTED, parent.checked);
        }
        this.searchByParentId(
          this.tulData,
          parent.parentId ?? 0,
          TulSelectAttrTypeEnum.CHECKED,
          true
        );
        break;
      case 'show':
        parent.show = !parent.show;
        break;
      default:
        break;
    }
    this.getItems(this.tulData);
  }

  getItems(array: TulSelectDataTree[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].children.length === 0 && array[i].checked) {
        this.tulItemsSelected.push(array[i]);
      } else {
        this.getItems(array[i].children);
      }
    }
  }

  /**
   * Check if one children item has false in checked attr
   * @param array
   * @returns
   */
  getChildrenCheckedFalse(array: TulSelectDataTree[]) {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].checked) return true;
      if (i === array.length - 1) return false;
    }
    return false;
  }

  /**
   * Set false attr in data
   * @param array
   */
  setAttrInData(array: TulSelectDataTree[], type: TulSelectAttrType, mode: boolean) {
    array.forEach((item: any) => {
      item[type] = mode;
      if (item.children.length !== 0) {
        this.setAttrInData(item.children, type, mode);
      }
    });
  }

  /**
   * Show menu when user clicked on input search
   */
  onSearch() {
    this.searchOnFocus = true;
    this.showMenu = true;
  }

  /**
   * Recursive filter elements
   * @param item
   * @param data
   */
  filterElements(item: TulSelectDataTree, data: any) {
    if (item.children.length === 0) {
      if (item.text.toLowerCase().includes(this.value.toLowerCase())) {
        data.push(item);
        this.finalData.push(...data);
        this.searchInTree(this.finalData);
        setTimeout(() => {
          this.removeFalseObjects(this.tulData);
          this.showLoading = false;
        }, 1000);
      }
      data.length = 0;
      setTimeout(() => {
        if (this.finalData.length === 0) {
          this.tulData = [];
          this.showLoading = false;
        }
      }, 1100);
    } else {
      for (let i = 0; i < item.children.length; i++) {
        this.filterElements(item.children[i], data);
      }
    }
  }

  /**
   * Recursive set id for items data
   * @param array
   * @param parentId
   */
  setDataId(array: TulSelectDataTree[], level: number, parentId?: number) {
    for (let i = 0; i < array.length; i++) {
      this.id += 1;
      array[i].id = this.id;
      array[i].parentId = parentId;
      array[i].level = level;
      if (array[i].children.length !== 0) {
        this.setDataId(array[i].children, level + 1, array[i].id);
      }
    }
  }

  /**
   * Search in tree
   * @param array
   */
  searchInTree(array: TulSelectDataTree[]) {
    for (let i = 0; i < array.length; i++) {
      this.searchByParentId(this.tulData, array[i].parentId ?? 0, TulSelectAttrTypeEnum.SHOW, true);
      this.searchByParentId(this.tulData, array[i].id ?? 0, TulSelectAttrTypeEnum.SHOW, true);
    }
  }

  /**
   * Search in data by parent id
   * @param array
   * @param id
   */
  searchByParentId(
    array: TulSelectDataTree[],
    parentId: number,
    type: TulSelectAttrType,
    mode: boolean
  ) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === parentId) {
        if (type === TulSelectAttrTypeEnum.CHECKED && array[i].children.length !== 0) {
          array[i][type] = this.getChildrenCheckedTrue(array[i].children);
        } else {
          array[i][type] = mode;
        }
        if (array[i].children.length !== 0) {
          this.searchByParentId(this.tulData, array[i].parentId ?? 0, type, mode);
        }
      } else {
        if (array[i].children.length !== 0) {
          this.searchByParentId(array[i].children, parentId, type, mode);
        }
      }
    }
  }

  /**
   * Remove objects with show in false
   * @param array
   */
  removeFalseObjects(array: TulSelectDataTree[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] && !array[i].show) {
        array.splice(i, 1);
      } else {
        if (array[i] && array[i].children.length !== 0) {
          this.removeFalseObjects(array[i].children);
        }
      }
    }
  }

  /**
   * Remove item in array items selected
   * @param index
   * @param id
   */
  removeItem(index: number, id: number, parentId: number) {
    this.tulItemsSelected.splice(index, 1);
    this.searchByParentId(this.tulData, id, TulSelectAttrTypeEnum.CHECKED, false);
    this.searchByParentId(this.tulData, id, TulSelectAttrTypeEnum.SELECTED, false);
    this.searchByParentId(this.tulData, parentId, TulSelectAttrTypeEnum.CHECKED, false);
    this.refreshChecks(this.tulData);
  }

  /**
   * Refresh check parents
   * @param array
   */
  refreshChecks(array: TulSelectDataTree[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].children.length !== 0 && array[i].checked) {
        array[i].checked = this.getChildrenCheckedTrue(array[i].children);
      } else {
        this.refreshChecks(array[i].children);
      }
    }
  }

  /**
   * Get if children data has true checks
   * @param array
   * @returns
   */
  getChildrenCheckedTrue(array: TulSelectDataTree[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].checked) {
        return true;
      }
      if (i === array.length - 1) {
        return false;
      }
    }
    return false;
  }

  /**
   * Set padding for identation
   * @param level
   * @returns
   */
  setPadding(level: number): string {
    switch (level) {
      case 0:
        return '22px';
      case 1:
        return '66px';
      default:
        return level * 44 + 22 + 'px';
    }
  }

  /**
   * Create string text for multi-text type
   * @returns
   */
  createMultiTextInput(): string {
    let text: string = '';
    for (let i = 0; i < this.tulItemsSelected.length; i++) {
      if (i === 3) {
        text += `, ${this.tulItemsSelected.length - i} item más`;
        return text;
      } else {
        if (i === 0) {
          text += `${this.tulItemsSelected[i].text}`;
        } else {
          text += `, ${this.tulItemsSelected[i].text}`;
        }
      }
    }
    return text;
  }
}
