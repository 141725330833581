import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageComponent } from './image.component';
import { FormsModule } from '@angular/forms';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { SvgNotFoundModule } from '../../../../svg/svg-not-found/svg-not-found.module';
import { TranslocoModule } from '@ngneat/transloco';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  exports: [ImageComponent],
  imports: [
    CommonModule,
    FormsModule,
    TulDataTablePipeModule,
    NzSpinModule,
    NzModalModule,
    SvgNotFoundModule,
    TranslocoModule,
  ],
  declarations: [ImageComponent],
})
export class ImageModule {}
