<button
  class="tul-btn"
  [class.tul-btn--primary-a]="isBtnPrimaryA"
  [class.tul-btn--primary-b]="isBtnPrimaryB"
  [class.tul-btn--secondary]="isBtnSecondary"
  [class.tul-btn--tertiary]="isBtnTertiary"
  [class.tul-btn--contrast]="isBtnContrast"
  [class.tul-btn--danger]="isBtnDanger"
  [class.tul-btn--outline-danger]="isBtnOutlineDanger"
  [class.tul-btn--small]="isBtnSmall"
  [class.tul-btn--content-width]="isBtnContentWidth"
  [class.tul-btn--with-icon]="hasIcon"
  [class.tul-btn--icon-left]="hasLeftIcon"
  [class.tul-btn--icon-right]="hasRightIcon"
  [class.tul-btn--only-icon]="isOnlyIcon"
  [class.tul-btn--disabled]="getDisabled"
  [attr.tabindex]="getTabIndex"
  (mouseenter)="emitAction('hover')"
  (focus)="emitAction('focus')"
  (click)="emitAction('press')"
>
  <ng-template [ngIf]="hasLeftIcon">
    <ng-container *ngTemplateOutlet="showIcon"> </ng-container>
  </ng-template>

  <ng-template [ngIf]="isOnlyIcon">
    <ng-container *ngTemplateOutlet="showIcon"> </ng-container>
  </ng-template>
  <ng-template [ngIf]="!isOnlyIcon">
    {{ tulText | transloco }}
  </ng-template>

  <ng-template [ngIf]="hasRightIcon">
    <ng-container *ngTemplateOutlet="showIcon"> </ng-container>
  </ng-template>

  <ng-template #showIcon>
    <i class="tul-btn__icon soytul soytul-{{ tulIcon }}"></i>
  </ng-template>
</button>
