import { NgModule } from '@angular/core';
import { TulLabelComponent } from './components/tul-label/tul-label.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TulLabelComponent],
  imports: [SharedModule],
  exports: [TulLabelComponent],
})
export class TulLabelModule {}
