<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'decimal'">
      <decimal-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></decimal-controller>
    </ng-container>
    <ng-container *ngSwitchCase="'percentage'">
      <decimal-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></decimal-controller>
    </ng-container>
    <ng-container *ngSwitchCase="'currency'">
      <currency-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></currency-controller>
    </ng-container>
    <ng-container *ngSwitchCase="'phonenumber'">
      <phone-number-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></phone-number-controller>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <number-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></number-controller>
    </ng-container>
  </ng-container>
</ng-container>
