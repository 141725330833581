import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { StatuExecuteOption, TypeHeaderOptions } from '../../../enums/export';
import { ExecuteOption, RefreshOption } from '../../../interfaces/export';

/**
 * Refresh option header
 */
@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss'],
})
export class RefreshComponent {
  /**
   * actual option (REFRESH)
   */
  @Input() option!: RefreshOption;
  /**
   * Emitter to execute option
   */
  @Output() executeOption = new EventEmitter<ExecuteOption>();

  /**
   * Constructor
   */
  constructor(private translocoService: TranslocoService) {}

  /**
   * Execute click emitter
   */
  clickOption() {
    this.executeOption.emit({
      option: TypeHeaderOptions.REFRESH,
      status: StatuExecuteOption.SUCCESS,
    });
    if (this.option.function) {
      this.option.function({ option: this.option });
    }
  }

  /**
   * Generate tooltip in refresh option
   * @param option: actual option
   * @return string tooltip
   */
  getToolTip(option: any) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return this.translocoService.translate('tulTable.options.refresh.tooltip');
    }
    return typeof option?.tooltip?.title === 'string'
      ? option.tooltip.title
      : option?.tooltip?.title({ data: {}, option });
  }
}
