import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectTreeEntityComponent } from './select-tree-entity.component';
import { TulDataTablePipeModule } from '../../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  declarations: [SelectTreeEntityComponent],
  imports: [CommonModule, TulDataTablePipeModule],
  exports: [SelectTreeEntityComponent],
})
export class SelectTreeEntityModule {}
