export enum TypeExecuteTable {
  SORT = 'sort',
  FILTER = 'filter',
  CHANGEPAGE = 'change-page',
  CHANGESIZE = 'change-size',
  INIT = 'init',
  CHANGESELECTSTATUS = 'change-select-status',
}

export enum StateRequest {
  REQUESTING = 'requesting',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'succees',
}

export enum TypeRequest {
  FORMDATA = 'formdata',
  QUERYPARAMS = 'queryparams',
}

export enum TypeExecute {
  HEADER = 'header',
  INTABLE = 'in-table',
  EXTERNAL = 'external',
}

export enum TypeTable {
  GRAPHQL = 'graphql',
  MICROSERVICES = 'microservices',
  MONOLITH = 'monolith',
  LIST = 'list',
}

export enum StatuExecuteOption {
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}
