import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { FormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { TranslocoModule } from '@ngneat/transloco';
import { TulSelectModule } from '../../../../../tul-select/tul-select.module';

@NgModule({
  exports: [SelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzSelectModule,
    NzTreeSelectModule,
    NzSpinModule,
    TranslocoModule,
    TulSelectModule,
  ],
  declarations: [SelectComponent],
})
export class SelectModule {}
