<ng-content></ng-content>
<span *ngIf="hasContent && isTypeText" class="tul-badge-content">
  <ng-container *ngTemplateOutlet="tulBadgeT"> </ng-container>
  <span class="tul-badge-content__text">
    {{ content.toString() | transloco }}
  </span>
</span>
<ng-template [ngIf]="!(hasContent && isTypeText)">
  <ng-container *ngTemplateOutlet="tulBadgeT"> </ng-container>
</ng-template>

<ng-template #tulBadgeT>
  <span
    class="tul-badge"
    [class]="getColorClass"
    [class.tul-badge--simple]="isTypeSimple"
    [class.tul-badge--text]="isTypeText"
    [class.tul-badge--numeric]="isTypeNumeric"
  >
    <ng-template [ngIf]="hasContent && isTypeNumeric">
      {{ content }}
    </ng-template>
  </span>
</ng-template>
