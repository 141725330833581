<ng-container [ngSwitch]="subType">
  <!-- ENTITY -->
  <ng-container *ngSwitchCase="'entity'">
    <select-entity [data]="data" [column]="column"></select-entity>
  </ng-container>

  <!-- TREE ENTITY -->
  <ng-container *ngSwitchCase="'tree-entity'">
    <select-tree-entity [data]="data" [column]="column"></select-tree-entity>
  </ng-container>

  <!-- LIST -->
  <ng-container *ngSwitchDefault>
    <select-list [data]="data" [column]="column"></select-list>
  </ng-container>
</ng-container>
