import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeSelector } from '../../../../interfaces/columns/selectors';

/**
 * filter option selectors
 */
@Component({
  selector: 'selectors-filter',
  templateUrl: './selectors.component.html',
  styleUrls: ['./selectors.component.scss'],
})
export class SelectorsComponent {
  /**
   * Actual Column
   */
  @Input() column!: TypeSelector;
  /**
   * Filters map
   */
  @Input() filter!: Map<string, any>;
  /**
   * Emitter change value filter
   */
  @Output() changeValueFilter = new EventEmitter<string>();
  /**
   * Value filter
   */
  valueFilterInput: any;

  /**
   * Change filter value
   * @param event value of filter
   */
  ngModelChange(event: any) {
    this.valueFilterInput = event;
    this.changeValueFilter.emit(event);
  }

  /**
   * get value filter
   * @param column selected column
   * @return value to selected column
   */
  getValueFilter(column: TypeSelector) {
    return this.filter.get(column?.filter?.attribute ?? column.attribute)?.value;
  }
}
