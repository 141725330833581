import { Injectable, Inject } from '@angular/core';
import { ToastComponent } from '../components/toast.component';
import { ToastConfig } from './toast-config';
import { ToastInit } from '../types/toast-init';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

const TOAST_OVERLAY = 'toast-overlay';
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastInit: ToastInit;

  overlayRef!: OverlayRef;
  constructor(private overlay: Overlay, @Inject('TOASTINIT') TOASTINIT: any) {
    this.toastInit = TOASTINIT;
  }

  private removeElementsByClass() {
    const elements = document.getElementsByClassName(TOAST_OVERLAY);

    while (elements.length > 0) elements[0].remove();
  }
  /**
   * open toast on screen
   * @param config config for toast
   * @returns
   */
  public open(config: ToastConfig) {
    //clear board for new toast
    this.removeElementsByClass();
    // We create the overlay
    this.overlayRef = this.overlay.create();
    //Then we create a portal to render a component
    const componentPortal = new ComponentPortal(ToastComponent);
    // We add a custom CSS class to our overlay
    this.overlayRef.addPanelClass(TOAST_OVERLAY);
    //We render the portal in the overlay
    const componentRef = this.overlayRef.attach(componentPortal);

    // set data to toast
    componentRef.instance['data'] = config;

    // remove toast after x milliseconds
    setTimeout(() => {
      if (this.overlayRef) {
        this.overlayRef.removePanelClass('toast-overlay');
        this.overlayRef.detach();
        this.overlayRef.dispose();
      }
    }, this.toastInit.closeAfter);
  }
}
