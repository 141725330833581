import { TulAlertType } from '../../tul-static-alert/types/alert-type.type';
import { TulAlertColor } from '../../tul-static-alert/types/alert-color.type';

export class ToastConfig {
  message: string = '';
  description?: string | null = null;
  tulType: TulAlertType = 'default';
  tulColor: TulAlertColor = 'success';
  isBanner: boolean = true;
  isClosable: boolean = true;
  withIcon: boolean = true;
}
