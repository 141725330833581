import { NgModule } from '@angular/core';
import { TulStaticAlertComponent } from './components/tul-static-alert/tul-static-alert.component';
import { SharedModule } from '../shared/shared.module';
import { TulButtonModule } from '../tul-btn/tul-btn.module';

@NgModule({
  declarations: [TulStaticAlertComponent],
  imports: [SharedModule, TulButtonModule],
  exports: [TulStaticAlertComponent],
})
export class TulStaticAlertModule {}
