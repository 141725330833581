import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NumericControllerComponent } from './numeric-controller.component';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';
import { TulCurrencyPipeModule } from '../../../../../pipes/currency/tul-currency-pipe.module';
import { TulToFixedDecimalPipeModule } from '../../../../../pipes';
@NgModule({
  declarations: [NumericControllerComponent],
  imports: [
    CommonModule,
    FormsModule,
    TulDataTablePipeModule,
    TulCurrencyPipeModule,
    TulToFixedDecimalPipeModule,
  ],
  exports: [NumericControllerComponent],
})
export class NumericControllerModule {}
