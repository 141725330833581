import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ClickOption, DetailsOption } from '../../../../interfaces/export';
import { StateRequest, TypeTable } from './../../../../enums/typeTable.enum';

/**
 * table details option
 */
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent {
  /**
   * Check deleteOption option
   */
  @Input() option!: DetailsOption;
  /**
   * Data row
   */
  @Input() data: any;
  /**
   * Type Table
   */
  @Input() typeTable!: TypeTable;
  /**
   * Emitter to execute option
   */
  @Output() clickOptionEmitter = new EventEmitter<ClickOption>();

  /**
   * Constructor
   * @param router A service that provides navigation among views and URL manipulation capabilities.
   */
  constructor(private router: Router, private translocoService: TranslocoService) {}

  /**
   * Execute details emitter
   */
  executeOption() {
    this.clickOptionEmitter.emit({
      option: this.option,
      loading: true,
      status: StateRequest.SUCCESS,
    });
  }

  /**
   * Generate link option
   * @return link
   */
  getLink() {
    if (this.option.url) {
      const url =
        typeof this.option.url === 'string'
          ? this.option.url
          : this.option.url({ type: 'table', option: this.option, data: this.data });
      return url;
    }
    return this.router.url + '/show/' + this.data.uuid;
  }

  /**
   * Generate tooltip in button option
   * @param option: actual option
   * @return tooltip
   */
  getToolTip(option: any) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return this.translocoService.translate('tulTable.options.details.tooltip');
    }
    return typeof option.tooltip.title === 'string'
      ? option.tooltip.title
      : option.tooltip.title({ data: this.data, option });
  }
}
