<div class="tul-input-container">
  <label
    for="tul-input"
    class="tul-input-container__label"
    *ngIf="tulLabel"
    [ngClass]="{
      'tul-input-container__label--alert-error': tulState === 'error',
      'tul-input-container__label--alert-success': tulState === 'success'
    }"
    >{{ tulLabel | transloco }}
    &nbsp;
    <i
      *ngIf="tulTooltipconfig"
      class="tul-input-container__hint soytul soytul-info_filled"
      [tulTooltip]="tulTooltipconfig.title"
      [tulTooltipConfig]="tulTooltipconfig"
    ></i>
  </label>
  <input
    type="datetime-local"
    [name]="tulName"
    class="tul-input"
    [ngClass]="{
      'tul-input--disabled': tulDisabled,
      'tul-input--alert-error': tulState === 'error',
      'tul-input--alert-success': tulState === 'success'
    }"
    [placeholder]="tulPlaceholder | transloco"
    [disabled]="tulDisabled"
    [(ngModel)]="value"
    [min]="tulMin"
    [max]="tulMax"
    ngDefaultControl
  />
  <small
    class="tul-input-container__small"
    *ngIf="tulAdditionalInfo"
    [ngClass]="{
      'tul-input-container__small--alert-error': tulState === 'error',
      'tul-input-container__small--alert-success': tulState === 'success'
    }"
    >{{ tulAdditionalInfo | transloco }}
  </small>
</div>
