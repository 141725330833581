import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { StatuExecuteOption } from '../../../enums/export';
import { TypeHeaderOptions } from '../../../enums/typeOption.enum';
import { ExecuteOption, ImportOption } from '../../../interfaces/export';

/**
 * Import option header
 */
@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent {
  /**
   * actual option (IMPORT)
   */
  @Input() option!: ImportOption;
  /**
   * Emitter to execute option
   */
  @Output() executeOption = new EventEmitter<ExecuteOption>();

  /**
   * Constructor
   * @param router A service that provides navigation among views and URL manipulation capabilities.
   */
  constructor(private router: Router, private translocoService: TranslocoService) {}

  /**
   * Generate link option
   * @return string url
   */
  getLink() {
    this.executeOption.emit({
      option: TypeHeaderOptions.IMPORT,
      status: StatuExecuteOption.SUCCESS,
    });
    if (this.option.url) {
      const url =
        typeof this.option.url === 'string'
          ? this.option.url
          : this.option.url({ type: 'header', option: this.option });
      return url;
    }
    return this.router.url + '/import';
  }

  /**
   * Generate tooltip in import option
   * @param option: actual option
   * @return string tooltip
   */
  getToolTip(option: any) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return this.translocoService.translate('tulTable.options.import.tooltip');
    }
    return typeof option?.tooltip?.title === 'string'
      ? option.tooltip.title
      : option?.tooltip?.title({ data: {}, option });
  }
}
