import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { State, TulState } from '../../../../shared/types/state.type';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TulSelectData } from '../../../models/select-data.model';
import { TulSelectIcon } from '../../../models/select-icon.model';
import { TranslocoService } from '@ngneat/transloco';

/**
 * noop
 */
function noop() {
  //noop ref
}

/**
 * Interface for communication with ngModel
 */
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TulSelectYearComponent),
  multi: true,
};

@Component({
  selector: 'tul-select-year',
  templateUrl: './tul-select-year.component.html',
  styleUrls: ['./tul-select-year.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TulSelectYearComponent implements ControlValueAccessor, OnInit {
  /**
   * Placeholder
   */
  @Input() tulPlaceholder: string = 'chooseAnOption';

  placeholderBackup: string = '';

  /**
   * Text in input label
   */
  @Input() tulLabel: string = '';

  /**
   * Icon name on input
   */
  @Input() tulInputIconName: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Text under input
   */
  @Input() tulAdditionalInfo: string = '';

  /**
   * Enum State { 'error' | 'success' | 'default' }
   */
  @Input() tulState: TulState = State.DEFAULT;

  @Input() icon?: TulSelectIcon;

  /**
   * Data for content
   */
  tulData: TulSelectData[] = this.getYears().map((year: string) => {
    return { value: year, text: year };
  });

  /**
   * Backup @tulData for filter data
   */
  dataBackup: TulSelectData[] = [];

  /**
   * Item selected emit
   */
  @Output() tulItemSelected: EventEmitter<TulSelectData | TulSelectData[]> = new EventEmitter();

  /**
   * Item search emit
   */
  @Output() tulItemSearch: EventEmitter<string> = new EventEmitter();

  /**
   * Name for input
   */
  @Input() tulName: string = '';
  /** */
  @Input() tulTooltipconfig!: any;

  /**
   * Show or close menu
   */
  showMenu: boolean = false;

  /**
   * Search text
   */

  /**
   * Menu scrollable
   */
  showScroll: boolean = false;

  /**
   * Items for dropdown
   */
  dataList: TulSelectData[] = [];

  /**
   * Id timeout
   */
  timeOutId!: any;

  /**
   * Show spinner in menu
   */
  showLoading: boolean = false;

  /**
   * Text in loading
   */
  loadingText: string = '';

  /**
   * Inner Value
   */
  private innerValue: any = '';

  /**
   * Response on touched
   */
  private onTouchedCallback: () => void = noop;

  /**
   * Response on change value
   */
  private onChangeCallback: (_: any) => void = noop;

  /**
   * Get value ngModel
   */
  get value(): any {
    return this.innerValue;
  }

  /**
   * Set value ngModel
   */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  /**
   * Write value
   * @param value ngModel
   */
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  /**
   * Register on change
   * @param fn function
   */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /**
   * Register on touched
   * @param fn function
   */
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /**
   * constructor
   * @param eRef element of DOM
   */
  constructor(private eRef: ElementRef, private translocoAppService: TranslocoService) {}

  ngOnInit(): void {}

  /**
   * Check if the data is list or entity
   */
  checkList() {
    if (!this.tulDisabled) {
      this.dataList = [...this.tulData];
      this.dataBackup = [...this.tulData];
      this.checkSelectedForDefault();
      this.checkDataLength();
      this.showDropdownMenu();
    }
  }

  /**
   * Check click out of the element
   * @param event
   */
  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showMenu = false;
    }
  }

  /**
   * Emmit the value selected
   * @param item the option selected
   * @param index array position
   */
  selectItem(index: number) {
    this.clearData(true);
    this.dataList[index].selected = this.dataList[index].selected
      ? !this.dataList[index].selected
      : true;
    this.tulItemSelected.emit({
      text: this.dataList[index].text,
      value: this.dataList[index].value,
    });
    this.value = this.dataList[index].text;
    this.showMenu = false;
  }

  /**
   * Filter data fot list or entity
   * @param event string search
   */
  filterData() {
    this.clearData(true);
    this.tulPlaceholder = this.placeholderBackup;
    this.loadingText = 'minimumThreeCharactersForFilter';
    this.showMenu = true;
    this.showLoading = true;
    this.tulItemSearch.emit(this.value);
    this.dataList = [];
    this.dataList = [...this.dataBackup];
    this.dataList = this.dataList.filter((item) =>
      item.text.toLowerCase().includes(this.value.toLowerCase())
    );
    this.checkDataLength();
    this.showLoading = false;
  }

  /**
   * Show ul menu
   */
  showDropdownMenu() {
    if (!this.tulDisabled) {
      this.dataList.length !== 0 ? (this.showMenu = !this.showMenu) : this.showMenu;
    }
  }

  /**
   * Clear items selected
   */
  clearData(allFalse?: boolean) {
    this.dataList.forEach((item) => {
      if (allFalse) {
        item.selected = false;
      } else if (!item.selected) item.selected = false;
    });
  }

  /**
   * Check @tulData length for customize scroll and search input
   */
  checkDataLength() {
    if (this.dataList.length > 20) {
      this.showScroll = true;
    } else if (this.dataList.length > 10 && this.dataList.length <= 20) {
      this.showScroll = true;
    } else {
      this.showScroll = false;
    }
  }

  checkSelectedForDefault() {
    this.dataList.forEach((item) => {
      if (item.selected) {
        this.value = item.text;
      }
    });
  }

  getYears(): string[] {
    return Array.from(Array(new Date().getFullYear() + 20 - 1499), (_, i) => (i + 1500).toString());
  }
}
