import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { PhoneNumberColumn } from '../../../../../interfaces/columns/numeric';

/**
 *
 */
@Component({
  selector: 'phone-number-controller',
  templateUrl: './phone-number-controller.component.html',
  styleUrls: ['./phone-number-controller.component.scss'],
})
export class PhoneNumberControllerComponent {
  /** value input */
  @Input() data: any;

  /** column */
  @Input() column!: PhoneNumberColumn;

  /** sends the current value */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /** environment variables */
  environment: any;

  constructor(@Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }

  /**
   * Method that use transformData and emit the changed data
   */
  changeData(value: any) {
    this.data = this.column.transformData
      ? this.column.transformData({ data: value, column: this.column })
      : value;
    this.onChange.emit(this.data);
  }

  /**
   * Show the prefix phone number depending on the country
   * @returns
   */
  prefixPhoneNumber = () => `+${this.environment.country.prefixNumber}`;

  /**
   * format the entered value to display it in the input when it is focused
   * @param value
   * @returns
   */
  parserPhoneNumber = (value: any) => {
    let fixValueAux = this.fixValue(value).substring(0, this.environment.country.digitsCellphone);
    fixValueAux = fixValueAux.length ? fixValueAux : '';
    this.changeData(fixValueAux);
    return fixValueAux;
  };

  /**
   * calls the formatting function
   * @param value
   * @returns
   */
  formatterPhoneNumber = (value: any) => this.formatter(value);

  /**
   * when the value is not yet defined show the input placeholder
   * @param value
   * @returns
   */
  formatterPhoneNumberInit = (value: any) => value;

  /**
   * Format the value of the input with '%' character
   * @param value
   * @returns
   */
  formatter = (value: any) => {
    try {
      return this.fixValue(value);
    } catch (error) {
      return `${0}`;
    }
  };

  /**
   * Clear the value
   * @param value
   * @returns
   */
  fixValue = (value: any) => {
    let valueAux = `${value}`; // in case they pass a number instead of a string
    valueAux = valueAux.replace(/[^0-9]/g, ''); // removes anything that is not a number
    return valueAux;
  };
}
