import { NgModule } from '@angular/core';
import { TulRadioComponent } from './components/tul-radio/tul-radio.component';
import { SharedModule } from '../shared/shared.module';
import { TulTooltipModule } from '../tul-tooltip';
import { TulSwitchTitlePipeModule } from '../pipes/switchTitle/tul-switch-title-pipe.module';

@NgModule({
  declarations: [TulRadioComponent],
  imports: [SharedModule, TulSwitchTitlePipeModule, TulTooltipModule],
  exports: [TulRadioComponent],
})
export class TulRadioModule {}
