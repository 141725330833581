<tul-select-entity-select
  [tulLabel]="(column.required ? '* ' : '') + column.header"
  [tulState]="column.error ? 'error' : 'default'"
  [tulAdditionalInfo]="column.error ?? ''"
  [tulDisabled]="column.disabled ?? false"
  [(ngModel)]="currentValue"
  [tulPlaceholder]="column.placeholder ?? 'Selecciona una opción'"
  [tulHttpSubscribe]="currentData"
  (tulItemSelected)="showItemSelected($event)"
  [tulTooltipconfig]="column.tooltip"
  *ngIf="!column?.multiple"
>
</tul-select-entity-select>

<tul-select-entity-multi-select
  [tulLabel]="(column.required ? '* ' : '') + column.header"
  [tulState]="column.error ? 'error' : 'default'"
  [tulAdditionalInfo]="column.error ?? ''"
  [tulDisabled]="column.disabled ?? false"
  [(ngModel)]="currentValue"
  [tulPlaceholder]="column.placeholder ?? 'Selecciona una opción'"
  [tulHttpSubscribe]="currentData"
  (tulItemSelected)="showItemSelected($event)"
  [tulTooltipconfig]="column.tooltip"
  *ngIf="column?.multiple"
>
</tul-select-entity-multi-select>
