<tul-select-month
  [tulLabel]="(column.required ? '* ' : '') + column.header"
  [tulState]="column.error ? 'error' : 'default'"
  [tulAdditionalInfo]="column.error ?? ''"
  [disabled]="column.disabled ?? false"
  [(ngModel)]="data[column.attribute]"
  [tulPlaceholder]="'selectMonth'"
  (tulItemSelected)="showItemSelected($event)"
  [tulTooltipconfig]="column.tooltip"
>
</tul-select-month>
