import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteComponent } from './delete.component';
import { FormsModule } from '@angular/forms';
import { SvgDeleteModule } from '../../../../svg/svg-delete/svg-delete.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TulToAsyncPipeModule } from '../../../../../pipes';
@NgModule({
  exports: [DeleteComponent],
  imports: [
    CommonModule,
    FormsModule,
    SvgDeleteModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzToolTipModule,
    TulToAsyncPipeModule,
  ],
  declarations: [DeleteComponent],
})
export class DeleteModule {}
