import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckBoxColumn } from '../../../../../interfaces/columns/selectors';

@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent {
  @Input() data: boolean | string = false;

  @Input() column!: CheckBoxColumn;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  modelChange(item: any) {
    this.onChange.emit(item);
  }
}
