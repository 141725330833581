import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesControllerComponent } from './files-controller.component';
import { ImageModule } from './image/image.module';
import { FileModule } from './file/file.module';

@NgModule({
  declarations: [FilesControllerComponent],
  imports: [CommonModule, ImageModule, FileModule],
  exports: [FilesControllerComponent],
})
export class FilesControllerModule {}
