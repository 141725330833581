import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { TypeDatesForms } from '../../../../../interfaces/columns/date';
import { Data } from '../../../../../interfaces/data';
import { FormsService } from '../../../../../services/forms/forms.service';

/**
 *
 */
@Component({
  selector: 'date-default-controller',
  templateUrl: './date-default-controller.component.html',
  styleUrls: ['./date-default-controller.component.scss'],
})
export class DateDefaultControllerComponent implements OnInit {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TypeDatesForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  dateModel = '';

  minDate: string = '';

  maxDate: string = '';

  /**
   *
   */
  ngOnInit(): void {
    this.dateModel = this.getDate();
    this.setMinDate();
    this.setMaxDate();
  }

  getDate() {
    if (this.data[this.column.attribute]) {
      return new Date(this.data[this.column.attribute]).toISOString().slice(0, 10);
    }
    return '';
  }

  setMinDate() {
    this.minDate = this.column.min?.toISOString() ?? '';
  }

  setMaxDate() {
    this.maxDate = this.column.max?.toISOString() ?? '';
  }
}
