import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NumericInputButtonComponent } from './numeric-input-button.component';
import { TulDataTablePipe } from '../../../../../../pipes/dataTable/tul-data-table.pipe';

@NgModule({
  declarations: [NumericInputButtonComponent],
  imports: [
    CommonModule,
    NzInputModule,
    NzInputNumberModule,
    NzButtonModule,
    NzIconModule,
    FormsModule,
  ],
  providers: [TulDataTablePipe],
  exports: [NumericInputButtonComponent],
})
export class NumericInputButtonModule {}
