import { NgModule } from '@angular/core';
import { TulChipComponent } from './components/tul-chip/tul-chip.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TulChipComponent],
  imports: [SharedModule],
  exports: [TulChipComponent],
})
export class TulChipModule {}
