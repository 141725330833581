import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomControllerComponent } from './custom-controller.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CustomControllerComponent],
  exports: [CustomControllerComponent],
})
export class CustomControllerModule {}
