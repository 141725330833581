import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'tulDataInputPipe' })
export class TulDataInputPipe implements PipeTransform {
  transform(value: any, input: any) {
    let valor = '';
    if (input.value !== '') {
      const columnas = input.extraInfo.get('columns');
      columnas.forEach((element: any) => {
        if (
          input.value[element.attribute] !== null ||
          input.value[element.attribute] !== undefined
        ) {
          valor += input.value[element.attribute] + ' ';
        }
      });
    }
    return valor;
  }
}
