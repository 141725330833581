<svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="14.4" viewBox="0 0 9.75 12">
  <defs>
    <style>
      .cls-1 {
        fill: #ff4040;
      }
    </style>
  </defs>
  <g id="_001-trash" transform="translate(-48)">
    <path
      id="Trazado_43814"
      d="M56.813 1.5H54.75v-.375A1.126 1.126 0 0 0 53.625 0h-1.5A1.126 1.126 0 0 0 51 1.125V1.5h-2.062a.939.939 0 0 0-.938.938V3.75a.375.375 0 0 0 .375.375h.2l.324 6.8A1.124 1.124 0 0 0 50.028 12h5.695a1.124 1.124 0 0 0 1.124-1.071l.324-6.8h.2a.375.375 0 0 0 .375-.375V2.438a.939.939 0 0 0-.933-.938zm-5.063-.375a.375.375 0 0 1 .375-.375h1.5a.375.375 0 0 1 .375.375V1.5h-2.25zm-3 1.313a.188.188 0 0 1 .187-.187h7.875a.188.188 0 0 1 .188.187v.938h-8.25zm7.35 8.455a.375.375 0 0 1-.375.357h-5.697a.375.375 0 0 1-.375-.357l-.322-6.768h7.088z"
      class="cls-1"
    />
    <path
      id="Trazado_43815"
      d="M240.375 213.625a.375.375 0 0 0 .375-.375v-4.875a.375.375 0 0 0-.75 0v4.875a.375.375 0 0 0 .375.375z"
      class="cls-1"
      transform="translate(-187.5 -203.125)"
    />
    <path
      id="Trazado_43816"
      d="M320.375 213.625a.375.375 0 0 0 .375-.375v-4.875a.375.375 0 0 0-.75 0v4.875a.375.375 0 0 0 .375.375z"
      class="cls-1"
      transform="translate(-265.625 -203.125)"
    />
    <path
      id="Trazado_43817"
      d="M160.375 213.625a.375.375 0 0 0 .375-.375v-4.875a.375.375 0 1 0-.75 0v4.875a.375.375 0 0 0 .375.375z"
      class="cls-1"
      transform="translate(-109.375 -203.125)"
    />
  </g>
</svg>
