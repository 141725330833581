import { Observable } from 'rxjs';
import { TypeForm } from '../enums';
import { ColumnForms } from './columns/column';

export interface Group {
  number: number;
  name: string;
  feedbackOptions?: HeaderFeedbackGroupOptions;
  headerOptions?: HeaderGroupOptions;
  subHeaderOptions?: SubHeaderGroupOptions;
  sections: Array<Section>;
}

export interface Section {
  header?: HeaderSectionOptions;
  columns: Array<ColumnForms>;
  numberColumns?: number;
}
export enum HeaderGroupType {
  VERTICAL,
  HORIZONTAL,
  VERTICAL_REVERSE,
  HORIZONTAL_REVERSE,
}
export interface HeaderGroupOptions {
  disabled?: boolean;
  type: HeaderGroupType;
  icon?: string | null;
  title: string | null;
  description: string | null;
}
export interface SubHeaderGroupOptions {
  disabled?: boolean;
  icon?: string;
  title: string | null;
}
export interface HeaderSectionOptions {
  disabled?: boolean;
  icon?: string;
  title: string | null;
}
export interface HeaderFeedbackGroupOptions {
  disabled?: boolean;
  title: string | null;
  description: string | null;
}

export interface FilterTableForms {
  value: any | Array<any>;
  /**
   * attribute name
   */
  attribute: string;
}

/**
 * poll interval
 */
export interface PollIntervalForms {
  /**
   * enable flag
   */
  enable: boolean;
  /**
   * time to retry
   */
  time: number;
}

export interface ArgumentForms {
  attribute: string;
  value?: string | (() => string);
}

export interface Data<T> {
  /**
   * key and value type
   */
  [key: string]: T;
}

/**
 * Form
 */
export interface Form {
  id: string;
  title: string;
  url: string | (() => string);
  filters?: Array<FilterTableForms> | (() => Array<FilterTableForms>);
  mapHttpSubscribe?: ({ response, component }: any) => any;
  type?: TypeForm;
  submitForm?: ({ data, groups }: RequestData) => Observable<any>;
  callbackSubmitForm: ({ data, groups, error }: RequestData) => void;
}

export interface ErrorFormResponse {
  field: string;
  message: string;
}

export interface RequestData {
  data: Data<any>;
  groups: Array<Group>;
  response?: Data<any>;
  error?: Data<any>;
}
export interface FormGraphQl extends Form {
  dto: string | (() => string);
  content: string | (() => string);
  arguments: Array<ArgumentForms>;
  pollInterval?: PollIntervalForms;
  fetchPolicy?: string;
  mapHttpSubscribe?: ({ response, component }: any) => any;
}

export interface FormMs extends Form {
  httpSubscribe?: (event: any) => Observable<any>;
  mapHttpSubscribe?: ({ response, component }: any) => any;
}
export type TypeForms = Form | FormGraphQl | FormMs;
