<div class="tul-header-container" *ngIf="!getDisabled(headerOptions?.disabled ?? false)">
  <div class="tul-header-container__description">
    <i
      class="soytul soytul-chevron_left_filled tul-header-container__icon"
      (click)="goBack()"
      *ngIf="!getDisabled(headerOptions?.backDisabled ?? false)"
    ></i>
    <span class="tul-header-container--title">{{
      getTitle(headerOptions?.title ?? activeRoute) | transloco
    }}</span>
    <span class="tul-header-container--tag" *ngIf="uuid">
      <tul-tag
        tulText="{{ 'id' | transloco }}: {{ uuid }}"
        tulTagColor="blue"
        [isClosable]="false"
        i
      >
      </tul-tag>
    </span>
  </div>
  <div class="tul-header-container__actions">
    <div class="tul-header-buttons">
      <tul-btn
        class="tul-header-buttons--button"
        tulSize="small"
        *ngFor="let button of headerOptions?.actions"
        [tulText]="getTitle(button.title)"
        [tulColor]="button.color"
        [tulDisabled]="getDisabled(button.disabled ?? false)"
        (click)="goTo(button.link ?? basePath, button.callBack, button.type, button.link)"
        [tulIcon]="getIcon(button.icon)"
      ></tul-btn>
    </div>
  </div>
</div>
