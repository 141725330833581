import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { TulBadgeComponent } from './components/tul-badge/tul-badge.component';

@NgModule({
  declarations: [TulBadgeComponent],
  imports: [SharedModule],
  exports: [TulBadgeComponent],
})
export class TulBadgeModule {}
