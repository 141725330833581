import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';

@Injectable({
  providedIn: 'any',
})
export class CustomLoader implements TranslocoLoader {
  environment: any;

  /**
   * constructor
   * @param http
   * @param environment
   */
  constructor(private http: HttpClient, @Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }

  /**
   * getTranslations
   * @param lang
   * @returns
   */
  getTranslation(lang: string) {
    return this.http.get<Translation>(`${this.environment.urlTranslate}${lang}.json`);
  }
}
