<div [nzTooltipTitle]="getToolTip(option)" nzTooltipPlacement="top" nz-tooltip>
  <ng-container
    [ngTemplateOutlet]="getCustomTemplate(option)"
    [ngTemplateOutletContext]="{
      $implicit: { data: data, column: column, disabled: option.disabled | tulToAsyncPipe | async }
    }"
  >
    <!--  -->
  </ng-container>
</div>
