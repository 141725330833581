import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { Inject, Injectable, InjectionToken, NgModule } from '@angular/core';

/**
 * transloco module
 */
@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  /**
   * environment
   * @private environment
   */
  public environment: any;

  /**
   * constructor
   * @param http httpclient
   * @param environment environment
   */
  constructor(private http: HttpClient, @Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }

  /**
   * get translation¡
   * @param lang active lang
   * @returns observable
   */
  getTranslation(lang: string) {
    return this.http.get<Translation>(`${this.environment.i18nUrl}i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['es'],
        defaultLang: 'es',
        fallbackLang: 'es',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: false,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
