import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeColumnForms } from '../../../../../interfaces/columns/date';
import { Data } from '../../../../../interfaces/data';

/**
 *
 */
@Component({
  selector: 'time-controller',
  templateUrl: './time-controller.component.html',
  styleUrls: ['./time-controller.component.scss'],
})
export class TimeControllerComponent implements OnInit {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TimeColumnForms;

  /** */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  dateModel = '';

  /**
   *
   */
  ngOnInit(): void {
    this.dateModel = this.getDate();
  }

  getDate() {
    if (this.data[this.column.attribute]) {
      return this.data[this.column.attribute].slice(11, 19);
    }
    return '';
  }
}
