import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { TranslocoModule } from '@ngneat/transloco';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import { ArrowLeftOutline, PictureTwoTone } from '@ant-design/icons-angular/icons';
import { HeaderComponent } from './header.component';
import { TulButtonModule } from '../../../tul-btn/tul-btn.module';
import { TulTagModule } from '../../../tul-tag/tul-tag.module';

/**
 * Load icons for global usage
 */
const icons: IconDefinition[] = [ArrowLeftOutline, PictureTwoTone];

@NgModule({
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzPageHeaderModule,
    NzTagModule,
    NzSpaceModule,
    NzButtonModule,
    TranslocoModule,
    RouterModule,
    NzIconModule.forChild(icons),
    TulButtonModule,
    TulTagModule,
  ],
  declarations: [HeaderComponent],
})
export class HeaderModule {}
