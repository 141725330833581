import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

/**
 * getToolTip
 */
@Pipe({
  name: 'tulGetToolTipPipe',
})
export class TulGetToolTipPipe implements PipeTransform {
  /**
   * constructor
   */
  constructor(public translocoService: TranslocoService) {}

  /**
   * getToolTip
   */
  transform(option: any, data: any): string {
    if (!Boolean(option?.tooltip?.tooltip)) {
      return this.translocoService.translate('tulTable.options.expand.tooltip');
    }
    return typeof option?.tooltip?.title === 'string'
      ? option.tooltip.title
      : !option.tooltip
      ? ''
      : option.tooltip.title({ data, option });
  }
}
