<a
  class="button"
  [routerLink]="[getLink()]"
  (click)="executeOption()"
  [nzTooltipTitle]="getToolTip(option)"
  nzTooltipPlacement="top"
  nz-tooltip
  [class.button--disabled]="option.disabled | tulToAsyncPipe | async"
>
  <svg-search></svg-search>
</a>
