export enum TulColorEnum {
  'CASTLETON-GREEN' = 'castleton-green',
  'UFO-GREEN' = 'ufo-green',
  'GOLDEN-POPPY' = 'golden-poppy',
  'FLAVESCENT' = 'flavescent',
  'COSMIC-LATTE' = 'cosmic-latte',
  'PEACH-ORANGE' = 'peach-orange',
  'BRUNSWICK-GREEN' = 'brunswick-green',
  'OXLEY' = 'oxley',
  'MAGIC-MINT' = 'magic-mint',
  'MAGIC-MINT-DARK' = 'magic-mint-dark',
  'LIGHT-CYAN' = 'light-cyan',
  'TROPICAL-RAIN-FOREST' = 'tropical-rain-forest',
  'RAISIN-BLACK' = 'raisin-black',
  'OUTER-SPACE' = 'outer-space',
  'SONIC-SILVER' = 'sonic-silver',
  'CHINESE-SILVER' = 'chinese-silver',
  'ANTI-FLASH-WHITE' = 'anti-flash-white',
  'WHITE' = 'white',
  'ORANGE' = 'orange',
  'BOSTON-UNIVERSITY-RED' = 'boston-university-red',
  'AMERICAN-GREEN' = 'american-green',
  'BLUEBERRY' = 'blueberry',
  'MIDDLE-YELLOW-RED' = 'middle-yellow-red',
  'LIGHT-CORAL' = 'light-coral',
  'TEAL-DEER' = 'light-coral',
  'FRENCH-SKY-BLUE' = 'light-coral',
  'BANGLADESH-GREEN' = 'bangladesh-green',
  'SACRAMENTO-STATE-GREEN' = 'sacramento-state-green',
  'DEEP-JUNGLE-GREEN' = 'deep-jungle-green',
  'MYRTLE-GREEN' = 'myrtle-green',
  'PEWTER-BLUE' = 'pewter-blue',
  'JET-STREAM' = 'jet-stream',
  'COLUMBIA-BLUE' = 'columbia-blue',
  'CARIBBEAN-GREEN' = 'caribbean-green',
  'PERSIAN-GREEN' = 'persian-green',
  'EUCALYPTUS' = 'eucalyptus',
  'MEDIUM-AQUAMARINE' = 'medium-aquamarine',
  'PALE-ROBIN-EGG-BLUE' = 'pale-robin-egg-blue',
  'AERO-BLUE' = 'aero-blue',
  'BUBBLES' = 'pale-robin-egg-blue',
  'PHILIPPINE-YELLOW' = 'philippine-yellow',
  'DANDELION' = 'dandelion',
  'COSMIC-LATE' = 'cosmic-late',
  'LIGHT-WARNING' = 'light-warning',
  'LIGHT-ERROR' = 'light-error',
  'LIGHT-SUCCESS' = 'light-success',
  'LIGHT-INFO' = 'light-info',
  'DARK-WARNING' = 'dark-warning',
  'DARK-ERROR' = 'dark-error',
  'DARK-SUCCESS' = 'dark-success',
  'DARK-INFO' = 'dark-info',
}

export type TulColorType = `${TulColorEnum}`;
