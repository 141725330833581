import { Component } from '@angular/core';

/**
 * svg not found
 */
@Component({
  selector: 'svg-not-found',
  templateUrl: './svg-not-found.component.html',
  styleUrls: ['./svg-not-found.component.scss'],
})
export class SvgNotFoundComponent {}
