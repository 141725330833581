import { Component, Input } from '@angular/core';
import { LabelColor, TulLabelColor } from '../../types/label-color.type';

/**
 * Component for custom label
 */
@Component({
  selector: 'tul-label',
  templateUrl: './tul-label.component.html',
  styleUrls: ['./tul-label.component.scss'],
})
export class TulLabelComponent {
  /**
   * Text in label
   */
  @Input() tulText: string = 'Tul LABEL';

  /**
   * Enum label color ('YELLOW' | 'LIGHT_YELLOW' | 'ORANGE' | 'RED' | 'LIGHT_RED' | 'BLUE' | 'GREEN' | 'LIGHT_GREEN' | 'GRAY')
   */
  @Input() tulLabelColor: TulLabelColor = LabelColor.LIGHT_GREEN;
}
