import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { TulAlertComponent } from './components/tul-alert/tul-alert.component';
import { TulStaticAlertModule } from '../tul-static-alert/tul-static-alert.module';

@NgModule({
  declarations: [TulAlertComponent],
  imports: [SharedModule, TulStaticAlertModule],
  exports: [TulAlertComponent],
})
export class TulAlertModule {}
