<label
  class="tul-label"
  *ngIf="column.header"
  [ngClass]="{
    'tul-label--alert-error': column.error
  }"
  >{{ column.required ? "* " : "" }} {{ column.header | transloco }}
  &nbsp;
  <i
    *ngIf="column.tooltip"
    class="tul-label__hint soytul soytul-info_filled"
    [tulTooltip]="column.tooltip.title ?? ''"
    [tulTooltipConfig]="transformTooltip(column.tooltip)"
  ></i>
</label>
<angular-editor
  [placeholder]="''"
  [(ngModel)]="data"
  (ngModelChange)="changeData($event)"
  [config]="editorConfig"
></angular-editor>
<small
  class="tul-additional-info"
  *ngIf="column.error"
  [ngClass]="{
    'tul-additional-info--alert-error': column.error
  }"
  >{{ column.error | transloco }}
</small>
