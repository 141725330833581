import { Component } from '@angular/core';

/**
 * svg refresh
 */
@Component({
  selector: 'svg-refresh',
  templateUrl: './svg-refresh.component.html',
  styleUrls: ['./svg-refresh.component.scss'],
})
export class SvgRefreshComponent {}
