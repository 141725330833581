<button
  class="tul-access-btn"
  [class.tul-access-btn--small]="isBtnSmall"
  [class.tul-access-btn--content-width]="isBtnContentWidth"
  [class.tul-access-btn--only-icon]="isOnlyIcon"
  [attr.tabindex]="getTabIndex"
  [disabled]="getDisabled"
  (mouseenter)="emitAction('hover')"
  (focus)="emitAction('focus')"
  (click)="emitAction('press')"
>
  <img
    class="tul-access-btn__icon"
    [src]="getIconSvg()"
    alt="icon {{ tulIcon }}"
    (error)="$event.target.src = defaultImage"
  />
  <ng-template [ngIf]="!isOnlyIcon">
    {{ tulText | transloco }}
  </ng-template>
</button>
