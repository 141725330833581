<tul-input-currency
  [tulLabel]="(column.required ? '* ' : '') + column.header"
  [tulAdditionalInfo]="column.error ?? ''"
  [tulState]="column.error ? 'error' : 'default'"
  [tulDisabled]="column.disabled ?? false"
  [tulPlaceholder]="column.placeholder ? column.placeholder : ''"
  [(ngModel)]="data"
  [tulCurrency]="'COP'"
  [tulTooltipconfig]="column.tooltip"
  (ngModelChange)="changeData($event)"
>
</tul-input-currency>
