import { NgModule } from '@angular/core';
import { TulSwitchComponent } from './components/tul-switch/tul-switch.component';
import { SharedModule } from '../shared/shared.module';
import { TulTooltipModule } from '../tul-tooltip';
import { TulSwitchTitlePipeModule } from '../pipes/switchTitle/tul-switch-title-pipe.module';

@NgModule({
  declarations: [TulSwitchComponent],
  imports: [SharedModule, TulSwitchTitlePipeModule, TulTooltipModule],
  exports: [TulSwitchComponent],
})
export class TulSwitchModule {}
