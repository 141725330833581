import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { HeaderSectionOptions, SubHeaderGroupOptions } from '../../../../interfaces/group';

/**
 * Component for custom chip
 */
@Component({
  selector: 'header-section',
  templateUrl: './header-section.component.html',
  styleUrls: ['./header-section.component.scss'],
})
export class TulHeaderSectionComponent {
  @Input() options!: HeaderSectionOptions;
}
