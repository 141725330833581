import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TulLoaderComponent } from './components/tul-loader/tul-loader.component';

@NgModule({
  declarations: [TulLoaderComponent],
  imports: [SharedModule],
  exports: [TulLoaderComponent],
})
export class TulLoaderModule {}
