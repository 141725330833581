import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorsComponent } from './selectors.component';
import { FormsModule } from '@angular/forms';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  exports: [SelectorsComponent],
  imports: [CommonModule, FormsModule, NzRadioModule, TranslocoModule],
  declarations: [SelectorsComponent],
})
export class SelectorsModule {}
