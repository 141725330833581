<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'entity'">
      {{ concatEntityAttributes }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{
        data | tulDataTablePipe : column.attribute | tulGetDataSelectPipe : castToPipeData(column)
      }}
    </ng-container>
  </ng-container>
</ng-container>
