import { Input, NgModule } from '@angular/core';
import { TulFileUploadComponent } from './components/tul-file-upload/tul-file-upload.component';
import { SharedModule } from '../shared/shared.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { TulTooltipModule } from '../tul-tooltip';

@NgModule({
  declarations: [TulFileUploadComponent],
  imports: [SharedModule, NzSpinModule, NzUploadModule, TulTooltipModule],
  exports: [TulFileUploadComponent],
})
export class TulFileUploadModule {}
