import { Component } from '@angular/core';

/**
 * svg edit
 */
@Component({
  selector: 'svg-edit',
  templateUrl: './svg-edit.component.html',
  styleUrls: ['./svg-edit.component.scss'],
})
export class SvgEditComponent {}
