import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tulSwitchTitlePipe',
})
export class TulSwitchTitlePipe implements PipeTransform {
  transform(value: boolean | string, ...args: any): unknown {
    return args[0].selectorsType ? args[0].selectorsType[`${value}`].title : '';
  }
}
