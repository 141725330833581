import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import {
  ClickOption,
  DeleteOption,
  TypeTableEntity,
  TypeTables,
} from '../../../../interfaces/export';
import { StateRequest, TypeTable } from './../../../../enums/typeTable.enum';
import { TableMSService } from './../../../../services/tableMS.service';

/**
 * table delete option
 */
@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent implements OnDestroy {
  /**
   * Check deleteOption option
   */
  @Input() option!: DeleteOption;
  /**
   * Data row
   */
  @Input() data: any;
  /**
   * table
   */
  @Input() table!: TypeTables;
  /**
   * Type Table
   */
  @Input() typeTable!: TypeTable;

  /**
   * Emitter to execute option
   */
  @Output() clickOptionEmitter = new EventEmitter<ClickOption>();

  /**
   * Subscriptor delete
   */
  suscriptionDelete = new Subscription();

  /**
   * Constructor
   * @param tableMSService service that manages all communication with the api for MS
   */
  constructor(private tableMSService: TableMSService, private translocoService: TranslocoService) {}

  /**
   * Execute option
   * @return void
   */
  executeOption() {
    this.clickOptionEmitter.emit({
      option: this.option,
      loading: true,
      status: StateRequest.REQUESTING,
    });
    if (this.option.httpSubscribe) {
      this.suscriptionDelete = this.option
        .httpSubscribe({ data: this.data })
        .pipe(
          map((response) => {
            return this.option.mapHttpSubscribe
              ? this.option.mapHttpSubscribe({ response, component: this })
              : response;
          })
        )
        .subscribe(
          (response) => {
            this.clickOptionEmitter.emit({
              option: this.option,
              loading: false,
              response,
              status: StateRequest.SUCCESS,
            });
          },
          (error) => {
            this.clickOptionEmitter.emit({
              option: this.option,
              loading: false,
              error,
              status: StateRequest.ERROR,
            });
          }
        );
      return;
    }

    // DEFAULT DELETE
    const table = this.table as TypeTableEntity;
    const url =
      typeof table.url === 'string' ? table.url : !table.url ? '' : table.url({ data: this.data });
    this.tableMSService.delete(this.data.uuid, url).subscribe(
      (response) => {
        this.clickOptionEmitter.emit({
          option: this.option,
          loading: false,
          response,
          status: StateRequest.SUCCESS,
        });
      },
      (error) => {
        this.clickOptionEmitter.emit({
          option: this.option,
          loading: false,
          error,
          status: StateRequest.ERROR,
        });
      }
    );
  }

  /**
   * cancel confirmation
   */
  cancelPop() {}

  /**
   * Get title to confirm pop up
   * @param option actual option (check status)
   * @return title
   */
  getPopConfirmTitle(option: DeleteOption) {
    if (!Boolean(option.confirm?.title)) {
      return this.translocoService.translate('tulTable.options.delete.popConfirm.tooltip');
    }
    return typeof option?.confirm?.title === 'string'
      ? option.confirm.title
      : option?.confirm?.title({ data: this.data, option });
  }

  /**
   * Generate tooltip in button option
   * @param option: actual option
   * @return string tooltip
   */
  getToolTip(option: DeleteOption) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return this.translocoService.translate('tulTable.options.delete.tooltip');
    }
    return typeof option?.tooltip?.title === 'string'
      ? option.tooltip.title
      : option?.tooltip?.title({ data: this.data, option });
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.suscriptionDelete.unsubscribe();
  }
}
