<div class="tul-textarea-container">
  <label
    for="tul-text-area"
    class="tul-textarea-container__label"
    *ngIf="tulLabel"
    [ngClass]="{
      'tul-textarea-container__label--alert-error': tulState === 'error',
      'tul-textarea-container__label--alert-success': tulState === 'success'
    }"
    >{{ tulLabel | transloco }}
    &nbsp;
    <i
      *ngIf="tulTooltipconfig"
      class="tul-textarea-container__hint soytul soytul-info_filled"
      [tulTooltip]="tulTooltipconfig.title"
      [tulTooltipConfig]="tulTooltipconfig"
    ></i>
  </label>
  <textarea
    [rows]="tulRows"
    [cols]="tulCols"
    [name]="tulName"
    class="tul-textarea"
    [ngClass]="{
      'tul-textarea--disabled': tulDisabled,
      'tul-textarea--alert-error': tulState === 'error',
      'tul-textarea--alert-success': tulState === 'success',
      'tul-textarea--left-icon': tulLeftIconName,
      'tul-textarea--right-icon': tulRightIconName,
      'tul-textarea--both-icon': tulLeftIconName && tulRightIconName
    }"
    [placeholder]="tulPlaceholder | transloco"
    [disabled]="tulDisabled"
    [(ngModel)]="value"
    [maxlength]="tulLimit"
    ngDefaultControl
  ></textarea>
  <span
    class="tul-textarea-container__icon"
    [ngClass]="{
      'tul-textarea-container__icon--position-left': tulLeftIconName,
      'tul-textarea-container__icon--position-right': tulRightIconName,
      'tul-textarea-container__icon--disabled': tulDisabled
    }"
    *ngIf="(!tulRightIconName && tulLeftIconName) || (tulRightIconName && !tulLeftIconName)"
  >
    <i
      class="soytul tul-textarea-container__icon--size"
      [ngClass]="tulRightIconName ? 'soytul-' + tulRightIconName : 'soytul-' + tulLeftIconName"
    ></i>
  </span>
  <span
    class="tul-textarea-container__icon tul-textarea-container__icon--position-left"
    [ngClass]="{
      'tul-textarea-container__icon--disabled': tulDisabled
    }"
    *ngIf="tulLeftIconName && tulRightIconName"
  >
    <i
      class="soytul tul-textarea-container__icon--size"
      [ngClass]="['soytul-' + tulLeftIconName]"
    ></i>
  </span>
  <span
    class="tul-textarea-container__icon tul-textarea-container__icon--position-right"
    [ngClass]="{
      'tul-textarea-container__icon--disabled': tulDisabled
    }"
    *ngIf="tulLeftIconName && tulRightIconName"
  >
    <i
      class="soytul tul-textarea-container__icon--size"
      [ngClass]="['soytul-' + tulRightIconName]"
    ></i>
  </span>
  <small
    class="tul-textarea-container__small"
    *ngIf="tulAdditionalInfo"
    [ngClass]="{
      'tul-textarea-container__small--alert-error': tulState === 'error',
      'tul-textarea-container__small--alert-success': tulState === 'success'
    }"
    >{{ tulAdditionalInfo | transloco }}
  </small>
  <small class="tul-textarea-container__count"
    >{{ value ? value.length : 0 }} / {{ tulLimit }}</small
  >
</div>
