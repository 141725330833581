<ng-container [ngSwitch]="column.subType">
  <ng-container *ngSwitchCase="'currency'">
    <nz-input-number
      class="input-default"
      [ngModel]="getValueFilter(column)"
      [nzPlaceHolder]="
        !column.filter
          ? ''
          : column.filter.placeholder
          ? column.filter.placeholder
          : ('tulTable.filters.numeric.currency.placeholder.default' | transloco)
      "
      autocomplete="off"
      [nzStep]="1"
      [nzFormatter]="formatterCurrency"
      [nzParser]="parserCurrency"
      (ngModelChange)="ngModelChange($event)"
    >
    </nz-input-number>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <input
      class="input-default"
      type="number"
      nz-input
      [placeholder]="
        !column.filter
          ? ''
          : column.filter.placeholder
          ? column.filter.placeholder
          : ('tulTable.filters.numeric.number.placeholder.default' | transloco)
      "
      [ngModel]="getValueFilter(column)"
      (ngModelChange)="ngModelChange($event)"
    />
  </ng-container>
</ng-container>
