import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { StatuExecuteOption } from '../../../enums/export';
import { TypeHeaderOptions } from '../../../enums/typeOption.enum';
import { TableService } from '../../../services/table.service';
import { ExecuteOption, ExportFormat, ExportOption } from '../../../interfaces/export';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Export option header
 */
@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent implements OnDestroy {
  /**
   * actual option (EXPORT)
   */
  @Input() option!: ExportOption | any;
  /**
   * Emitter to execute option
   */
  @Output() executeOption = new EventEmitter<ExecuteOption>();

  /**
   * subscriptor to call api endpoint
   */
  suscriptionExport = new Subscription();

  /**
   * Constructor
   * @param tableService service that handles external calls
   */
  constructor(private tableService: TableService, private translocoService: TranslocoService) {}

  /**
   * generates the exportable
   * @param option actual option
   */
  export(option: ExportFormat) {
    this.executeOption.emit({
      data: { option },
      option: TypeHeaderOptions.EXPORT,
      status: StatuExecuteOption.LOADING,
    });
    const subscription = option.httpSubscribe
      ? option.httpSubscribe({ option })
      : this.tableService.export(
          typeof option?.url === 'string'
            ? option?.url
            : !option.url
            ? ''
            : option.url({ type: 'header', option })
        );

    this.suscriptionExport = subscription.subscribe(
      (response) => {
        const link: any = document.createElement('a');
        link.href = window.URL.createObjectURL(response.body);
        link.download = option.exportableFileName;
        link.click();
        this.executeOption.emit({
          data: { option, ...response },
          option: TypeHeaderOptions.EXPORT,
          status: StatuExecuteOption.SUCCESS,
        });
      },
      (error) => {
        this.executeOption.emit({
          data: { option, ...error },
          option: TypeHeaderOptions.EXPORT,
          status: StatuExecuteOption.ERROR,
        });
      }
    );
  }

  /**
   * Generate tooltip in export option
   * @param option: actual option
   * @return string tooltip
   */
  getToolTip(option: any) {
    if (!Boolean(option.tooltip?.tooltip)) {
      return this.translocoService.translate('tulTable.options.export.tooltip');
    }
    return typeof option?.tooltip?.title === 'string'
      ? option.tooltip.title
      : option?.tooltip?.title({ data: {}, option });
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.suscriptionExport.unsubscribe();
  }
}
