<div class="tul-tree">
  <label
    for="tul-input"
    class="tul-tree__label"
    [class.tul-tree__label--alert-error]="isError"
    *ngIf="tulLabel"
    >{{ tulLabel | transloco }}
  </label>
  <div
    class="tul-tree__search"
    [class.tul-tree__search--focus]="searchOnFocus"
    [class.tul-tree__search--alert-error]="isError"
    [class.tul-tree__search--disabled]="isDisabled"
    tabindex="-1"
    id="tul-tree-search"
    (click)="onSearch()"
  >
    <div class="tul-tag-container" *ngIf="tulItemsSelected.length !== 0">
      <ng-container *ngIf="tulInputType === 'multi-tag'">
        <div class="tul-tag" *ngFor="let item of tulItemsSelected; let i = index">
          <tul-tag
            [tulText]="item.text"
            [isClosable]="true"
            [tulTagColor]="'gray'"
            (tulActions)="removeItem(i, item.id ?? 0, item.parentId ?? 0)"
            *ngIf="i < 3"
          >
          </tul-tag>
          <tul-tag
            [tulText]="'y ' + (tulItemsSelected.length - i) + ' más...'"
            [isClosable]="true"
            [tulTagColor]="'gray'"
            (tulActions)="removeItem(i, item.id ?? 0, item.parentId ?? 0)"
            *ngIf="i === 3"
          >
          </tul-tag>
        </div>
      </ng-container>
      <ng-container *ngIf="tulInputType === 'multi-text'">
        <div class="tul-text">
          {{ createMultiTextInput() }}
        </div>
      </ng-container>
    </div>
    <div class="tul-input-container" *ngIf="tulItemsSelected.length === 0">
      <input
        type="text"
        [name]="tulName"
        class="tul-input-container__input"
        [disabled]="tulDisabled"
        [(ngModel)]="value"
        [placeholder]="tulPlaceholder | transloco"
        (keyup)="filterData()"
      />
    </div>
    <div class="tul-icon-container">
      <i class="soytul soytul-chevron_down_filled tul-icon-container__icon"></i>
    </div>
  </div>

  <div class="tul-tree__menu" *ngIf="showMenu">
    <div class="tul-tree__loading color-ufo-green" *ngIf="showLoading">
      <i class="soytul soytul-load_filled" *ngIf="value.length >= 3"></i>
      <small>{{ loadingText | transloco }}</small>
    </div>
    <ng-template #recursiveList let-data>
      <ng-container *ngFor="let item of data; let i = index">
        <div class="tul-tree-node" *ngIf="!showLoading">
          <div
            class="tul-tree-node__item"
            [class.tul-tree-node__item--selected]="item.children.length === 0 && item.selected"
            [ngStyle]="{
              'padding-left':
                item.children.length === 0 ? item.level * 44 + 44 + 'px' : setPadding(item.level)
            }"
          >
            <i
              class="soytul soytul-chevron_down_filled tul-tree-node__icon"
              [hidden]="item.children.length === 0 || (!item.show && item.children.length !== 0)"
              (click)="showChildren(item, 'show')"
            ></i>
            <i
              class="soytul soytul-chevron_right_filled tul-tree-node__icon"
              [hidden]="item.children.length === 0 || (item.show && item.children.length !== 0)"
              (click)="showChildren(item, 'show')"
            ></i>
            <div
              class="tul-tree-node__checkbox"
              [ngClass]="{ 'tul-tree-node--checkbox-selected': item.checked }"
              (click)="showChildren(item, 'checked')"
            >
              <i
                class="soytul soytul-{{
                  getChildrenCheckedFalse(item.children) ? 'line' : 'check'
                }}_filled tul-tree-node__icon-check"
              ></i>
            </div>
            {{ item.text }}
          </div>
          <div class="tul-tree-level" *ngIf="item.children.length > 0 && item.show">
            <ng-container
              *ngTemplateOutlet="recursiveList; context: { $implicit: item.children }"
            ></ng-container>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: tulData }"></ng-container>
  </div>
  <small
    class="tul-tree__info"
    *ngIf="tulAdditionalInfo"
    [ngClass]="{
      'tul-tree__info--alert-error': tulState === 'error',
      'tul-tree__info--alert-success': tulState === 'success'
    }"
    >{{ tulAdditionalInfo | transloco }}
  </small>
</div>
