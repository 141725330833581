import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { SwitchComponent } from './switch.component';
import { TulSwitchModule } from '../../../../../../tul-switch';
import { TulSwitchTitlePipeModule } from '../../../../../../pipes/switchTitle/tul-switch-title-pipe.module';
import { TulSwitchIsValuePipeModule } from '../../../../../../pipes/switchIsValue/tul-switch-is-value-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TulSwitchTitlePipeModule,
    TulSwitchIsValuePipeModule,
    TulSwitchModule,
    TranslocoModule,
  ],
  declarations: [SwitchComponent],
  exports: [SwitchComponent],
})
export class SwitchModule {}
