<a
  nz-button
  [routerLink]="[getLink()]"
  class="tul-btn tul-btn--secondary"
  nzType="default"
  [nzTooltipTitle]="getToolTip(option)"
  nzTooltipPlacement="top"
  nz-tooltip
  (click)="click()"
  [disabled]="option.disabled | tulToAsyncPipe | async"
>
  <svg-add class="button__icon"></svg-add>

  {{ "tulTable.options.add.title" | transloco }}
</a>
