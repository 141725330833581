<div
  tabindex="-1"
  id="tul-btn-quick"
  class="tul-button-quick-actions"
  [class.tul-button-quick-actions--shadow]="hasShadow"
  [class.tul-button-quick-actions--disabled]="isDisabled"
  [class.tul-button-quick-actions--selected]="isSelected"
  [class.tul-button-quick-actions--primary-size]="isPrimary"
  [class.tul-button-quick-actions--secondary-size]="isSecondary"
  (mouseenter)="emitAction('hover')"
  (focus)="emitAction('focus')"
  (click)="emitAction('press')"
>
  <div class="tul-button-quick-actions-select" *ngIf="tulSelect">
    <i class="soytul soytul-check_filled tul-button-quick-actions-select__icon"></i>
  </div>

  <div
    class="tul-button-quick-actions-icon"
    [class.tul-button-quick-actions-icon--primary-size]="isPrimary"
    [class.tul-button-quick-actions-icon--secondary-size]="isSecondary"
  >
    <img
      class="tul-button-quick-actions-icon__icon"
      [class.tul-button-quick-actions-icon__icon--primary-size]="isPrimary"
      [class.tul-button-quick-actions-icon__icon--secondary-size]="isSecondary"
      [src]="environment.staticFilesUrl + 'icons/svg/' + tulIcon?.name + '.svg'"
      alt="Icon"
      *ngIf="isSvg"
      (error)="$event.target.src = defaultImage"
    />
    <i
      class="soytul soytul-{{ tulIcon?.name }} tul-button-quick-actions-icon__icon"
      *ngIf="isFont"
    ></i>
  </div>
  <div class="tul-button-quick-actions-text">
    <p class="tul-button-quick-actions-text__text">{{ tulText | transloco }}</p>
  </div>
</div>
