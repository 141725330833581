import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumericComponent } from './numeric.component';
import { FormsModule } from '@angular/forms';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';
import { TulToFixedDecimalPipeModule } from '../../../../../pipes';
import { TulCurrencyPipeModule } from '../../../../../pipes/currency/tul-currency-pipe.module';
import { TulDataInputPipeModule } from '../../../../../pipes/dataInput/tul-data-input-pipe.module';

@NgModule({
  exports: [NumericComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzPipesModule,
    TulDataTablePipeModule,
    TulToFixedDecimalPipeModule,
    TulCurrencyPipeModule,
    TulDataInputPipeModule,
  ],
  declarations: [NumericComponent],
})
export class NumericModule {}
