import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  ComponentRef,
  OnDestroy,
} from '@angular/core';
import { AlertConfig } from '../../services/alert-config';
import { defaultAlertConfig } from '../../services/alert-default-config';
import { Subject } from 'rxjs';
import { AlertRef } from '../../services/alert-ref';

/** Tul alert component */
@Component({
  selector: 'tul-alert',
  exportAs: 'tulAlert',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './tul-alert.component.html',
  styleUrls: ['./tul-alert.component.scss'],
})
export class TulAlertComponent implements OnDestroy {
  /**
   * Component reference
   */
  componentRef!: ComponentRef<any>;

  /**
   * Subject for event close
   */
  private readonly _onClose = new Subject<any>();

  /**
   * Observable for close alert
   */
  public onClose = this._onClose.asObservable();

  /**
   * Data object for alert structure
   */
  data: AlertConfig = defaultAlertConfig;

  /**
   * Constructor
   * @param alertRef
   */
  constructor(private alertRef: AlertRef) {}

  /**
   * Destroy component reference
   */
  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  /**
   * Close alert when user clicked on
   * @param evt
   */
  onOverlayClicked(evt: MouseEvent): void {
    this.alertRef.close();
  }

  /**
   * Open alert
   * @param evt
   */
  onDialogClicked(evt: MouseEvent): void {
    evt.stopPropagation();
  }

  /**
   * Close alert
   */
  close(): void {
    this._onClose.next(true);
  }

  /**
   * Alert button action
   */
  buttonPressed() {
    this.data.button?.action({ buttonPressed: true });
  }
}
