<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
  <g id="Grupo_20886" data-name="Grupo 20886" transform="translate(-81 -3451)">
    <rect
      id="Rectángulo_21827"
      data-name="Rectángulo 21827"
      width="60"
      height="60"
      transform="translate(81 3451)"
      fill="none"
    />
    <g id="Grupo_20932" data-name="Grupo 20932" transform="translate(6.385 -2391.402)">
      <path
        id="Trazado_44679"
        data-name="Trazado 44679"
        d="M280.991,10.391a.725.725,0,0,1-.209.623l-5.115,5.071a.756.756,0,0,1-.884.129L256.39,6.449a.733.733,0,0,1-.383-.538.714.714,0,0,1-.008-.1v0a.723.723,0,0,1,.219-.517L261.382.215a.754.754,0,0,1,.882-.126l18.344,9.766A.732.732,0,0,1,280.991,10.391Z"
        transform="translate(-151.384 5847.402)"
        fill="#d39c72"
      />
      <path
        id="Trazado_44678"
        data-name="Trazado 44678"
        d="M82.662,60.1a.731.731,0,0,0,.383-.538.757.757,0,0,0,.008-.105.724.724,0,0,0,.008.1.733.733,0,0,0,.383.538l18.394,9.766a.753.753,0,0,0,.355.088.757.757,0,0,0-.355.089L83.443,79.8a.732.732,0,0,0-.383.538.753.753,0,0,0-.008.105.76.76,0,0,0-.008-.105.732.732,0,0,0-.383-.538L64.268,70.039a.773.773,0,0,0-.309-.088.77.77,0,0,0,.309-.088Z"
        transform="translate(21.563 5793.754)"
        fill="#c48d69"
      />
      <path
        id="Trazado_44682"
        data-name="Trazado 44682"
        d="M29.56,5.814v0a.749.749,0,0,1-.008.105.731.731,0,0,1-.383.538L10.775,16.225a.77.77,0,0,1-.309.088H10.4a.747.747,0,0,1-.511-.216L4.777,11.025a.725.725,0,0,1-.209-.623.736.736,0,0,1,.383-.537L23.295.1a.756.756,0,0,1,.882.126L29.341,5.3A.729.729,0,0,1,29.56,5.814Z"
        transform="translate(75.055 5847.392)"
        fill="#e2ae83"
      />
      <g id="Grupo_17793" data-name="Grupo 17793" transform="translate(95.046 5853.194)">
        <path
          id="Trazado_535"
          data-name="Trazado 535"
          d="M1105.99,259.185v12.492q.677-.148,1.349-.365a6.821,6.821,0,0,0,4.721-4.654,13.2,13.2,0,0,0,.529-3.476c.023-1.332.031-2.665.034-4Z"
          transform="translate(-1093.485 -250.996)"
          fill="#005b56"
        />
        <path
          id="Trazado_536"
          data-name="Trazado 536"
          d="M1110.995,3.277a3.336,3.336,0,0,1-6.672,0,3.336,3.336,0,0,1,6.672,0"
          transform="translate(-1091.873 0)"
          fill="#83d8c7"
        />
        <path
          id="Trazado_537"
          data-name="Trazado 537"
          d="M724.186,3.277a3.337,3.337,0,0,1-6.672,0,3.337,3.337,0,0,1,6.672,0"
          transform="translate(-717.506 0)"
          fill="#ffdd17"
        />
        <path
          id="Trazado_538"
          data-name="Trazado 538"
          d="M727.59,265.672c-1.607.244-2.826-.22-3.31-1.4a5.116,5.116,0,0,1-.322-1.87q-.015-1.611-.016-3.222h-6.576v3.848q.02.694.049,1.387c.161,3.731,2.259,6.136,5.933,7.1a13.926,13.926,0,0,0,4.449.446v-6.344c-.069.016-.135.036-.208.047"
          transform="translate(-717.366 -250.994)"
          fill="#005b56"
        />
      </g>
      <path
        id="Trazado_44677"
        data-name="Trazado 44677"
        d="M88.9,269.646a.759.759,0,0,1-.882-.126l-5.164-5.072a.728.728,0,0,1-.219-.519.728.728,0,0,1-.219.519l-5.164,5.072a.759.759,0,0,1-.882.126L63.5,262.792v13.837a.711.711,0,0,0,0,.075c0,.011,0,.021,0,.031s0,.026.006.038.008.031.012.047,0,.014.006.022.011.031.017.046l.008.021c.006.014.012.027.019.04l.012.025.019.032.019.03.018.024.026.034.016.018.032.035.019.017.035.031.036.027.021.016a.765.765,0,0,0,.066.04l18.391,9.764a.757.757,0,0,0,.709,0l18.391-9.764q.034-.018.066-.04l.021-.016.036-.027c.012-.01.023-.02.035-.031l.018-.017.032-.035.016-.018c.009-.011.018-.022.026-.034l.017-.024.019-.03c.006-.011.013-.021.019-.032l.012-.025c.007-.013.013-.027.019-.04s.005-.014.008-.021.012-.031.017-.046,0-.014.006-.022.009-.031.012-.047,0-.025.006-.038,0-.021,0-.031a.7.7,0,0,0,0-.075V262.792Z"
        transform="translate(21.975 5610.273)"
        fill="#e2ae83"
      />
      <path
        id="Trazado_44680"
        data-name="Trazado 44680"
        d="M280.782,172.224a.725.725,0,0,1,.209.623.736.736,0,0,1-.383.537l-18.344,9.766a.758.758,0,0,1-.882-.126l-5.164-5.072a.728.728,0,0,1-.219-.519.75.75,0,0,1,.008-.105.732.732,0,0,1,.383-.538l18.394-9.766a.754.754,0,0,1,.884.128Z"
        transform="translate(-151.384 5696.769)"
        fill="#f2c397"
      />
      <path
        id="Trazado_44681"
        data-name="Trazado 44681"
        d="M433.337,172.224l-5.114-5.072a.754.754,0,0,0-.884-.128l-1.942,1.032a.753.753,0,0,1,.884.128l5.115,5.072a.725.725,0,0,1,.209.623.736.736,0,0,1-.383.537l1.942-1.032a.736.736,0,0,0,.383-.537A.725.725,0,0,0,433.337,172.224Z"
        transform="translate(-303.94 5696.769)"
        fill="#e2ae83"
      />
      <path
        id="Trazado_44683"
        data-name="Trazado 44683"
        d="M29.552,177.337a.755.755,0,0,1,.008.106.728.728,0,0,1-.219.519l-5.164,5.072a.758.758,0,0,1-.882.126L4.951,173.393a.736.736,0,0,1-.383-.537.725.725,0,0,1,.209-.623l5.114-5.072a.749.749,0,0,1,.511-.216h.064a.774.774,0,0,1,.309.088L29.169,176.8A.732.732,0,0,1,29.552,177.337Z"
        transform="translate(75.055 5696.76)"
        fill="#ffd2a6"
      />
      <path
        id="Trazado_44684"
        data-name="Trazado 44684"
        d="M63.383,166.938h0Z"
        transform="translate(22.138 5696.767)"
        fill="#e2ae83"
      />
      <path
        id="Trazado_44685"
        data-name="Trazado 44685"
        d="M418.167,262.907c-.01.018-.02.025-.03.016h0L415.4,264.38v13.836a.734.734,0,0,1,0,.075c0,.011,0,.021,0,.032s0,.025-.006.038-.008.032-.012.047,0,.014-.006.022-.011.031-.017.046,0,.014-.008.021-.013.027-.019.041l-.012.025c-.006.011-.013.022-.019.032l-.019.03-.017.024c-.009.012-.017.023-.026.034l-.016.018c-.011.012-.021.024-.033.035l-.018.017c-.011.01-.022.021-.034.031l-.036.027-.021.016-.015.009,2.932-1.557c.023-.012.045-.025.066-.039l.021-.016.036-.027.034-.031.019-.017c.011-.011.022-.023.032-.035l.016-.018c.009-.011.018-.022.026-.034s.012-.015.017-.024l.019-.03.019-.032.012-.025c.007-.013.013-.027.019-.04s.005-.014.008-.021.012-.03.017-.046,0-.014.006-.021.009-.031.012-.047,0-.025.006-.038,0-.021,0-.032a.682.682,0,0,0,0-.075V262.792Z"
        transform="translate(-294.628 5610.273)"
        fill="#d39c72"
      />
      <path
        id="Trazado_44686"
        data-name="Trazado 44686"
        d="M82.421,264.448l-5.164,5.072a.759.759,0,0,1-.882.126L63.5,262.792v13.837a.711.711,0,0,0,0,.075c0,.011,0,.021,0,.031s0,.026.006.038.008.031.012.047,0,.014.006.022.011.031.017.046l.008.021c.006.014.012.027.019.04l.012.025.019.032.019.03.018.024.026.034.016.018.032.035.019.017.035.031.036.027.021.016a.765.765,0,0,0,.066.04l18.391,9.764a.755.755,0,0,0,.355.088v-23.2A.728.728,0,0,1,82.421,264.448Z"
        transform="translate(21.975 5610.273)"
        fill="#f2c397"
      />
    </g>
  </g>
</svg>
