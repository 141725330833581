import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditComponent } from './edit.component';
import { FormsModule } from '@angular/forms';
import { SvgEditModule } from '../../../../svg/svg-edit/svg-edit.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterModule } from '@angular/router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TulToAsyncPipeModule } from '../../../../../pipes';

@NgModule({
  exports: [EditComponent],
  imports: [
    CommonModule,
    FormsModule,
    SvgEditModule,
    NzButtonModule,
    RouterModule,
    NzToolTipModule,
    TulToAsyncPipeModule,
  ],
  declarations: [EditComponent],
})
export class EditModule {}
