import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { map, Subscription } from 'rxjs';
import { TypeForm, TypeState } from '../enums';
import { Data, FormGraphQl, Group, TypeForms } from '../interfaces/group';
import { FormGraphQlService } from '../services/forms-graph-ql/formGraphQl.service';
import { ModificationComponent } from './modification/modification.component';
import { FormsService } from '../services/forms/forms.service';

/**
 *
 */
@Component({
  selector: 'tul-form',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit, OnDestroy {
  /** */
  @Input() data?: Data<any> = {};

  /** */
  @Input() form?: TypeForms;

  /** */
  @Input() groups: Array<Group> = [];

  /** */
  @Input() state?: TypeState;

  /** */
  private querySubscription: Subscription = new Subscription();

  /** */
  loading: boolean = false;

  /** */
  activeRoute: string = '';

  /** */
  mapGroups = new Map<string, any>();

  /** */
  @ViewChild('modificationComponent') modificationComponent!: TemplateRef<ModificationComponent>;

  /**
   *
   * @param router
   * @param formGraphQlService
   */
  constructor(
    private router: Router,
    private formGraphQlService: FormGraphQlService,
    private formService: FormsService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    this.initForms();
  }

  /**
   *
   */
  ngOnDestroy() {
    if (this.querySubscription.unsubscribe) {
      this.querySubscription.unsubscribe();
    }
  }

  /**
   *
   * @returns
   */
  initForms() {
    if (this.state === TypeState.ADD) {
      this.getData();
      return;
    }
    if (this.state === TypeState.DETAILS) {
      this.getData();
      return;
    }
    if (this.state) {
      if (this.activeRoute.match(/(\/add\/)/)) {
        return;
      }
      this.getData();
      return;
    }
    this.activeRoute = this.router.url.split('?')[0];
    if (this.activeRoute.match(/(\/show\/)/)) {
      this.getData();
      this.state = TypeState.DETAILS;
      return;
    }
    if (this.activeRoute.match(/(\/edit\/)/)) {
      this.getData();
      this.state = TypeState.EDIT;
      return;
    }
    if (this.activeRoute.match(/(\/add\/)/)) {
      this.state = TypeState.ADD;
    }
  }

  /**
   *
   */
  getData() {
    this.loading = true;
    switch (this.form?.type) {
      case TypeForm.LIST:
        this.loading = false;
        // STATIC SERVICE CALL
        break;
      case TypeForm.MICROSERVICES:
        // MS SERVICE CALL
        this.formService
          .getServiceMs(this.form.url as string)
          .pipe(
            map((response) =>
              this.form?.mapHttpSubscribe
                ? this.form.mapHttpSubscribe({ response, component: this })
                : response
            )
          )
          .subscribe({
            next: (response) => {
              this.data = response;
              this.loading = false;
            },
          });
        break;
      case TypeForm.GRAPHQL:
      default:
        // GRAPHQL SERVICE CALL
        this.querySubscription = this.formGraphQlService
          .get(this.form as FormGraphQl)
          .pipe(
            map((response) =>
              this.form && this.form.mapHttpSubscribe
                ? this.form.mapHttpSubscribe({ response, component: this })
                : response
            )
          )
          .subscribe((response) => {
            this.data = response;
            this.loading = false;
          });
        break;
    }
  }
}
