import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyControllerModule } from './currency-controller/currency-controller.module';
import { DecimalControllerModule } from './decimal-controller/decimal-controller.module';
import { NumberControllerModule } from './number-controller/number-controller.module';
import { PhoneNumberControllerModule } from './phone-number-controller/phone-number-controller.module';
import { NumericControllerComponent } from './numeric-controller.component';

@NgModule({
  declarations: [NumericControllerComponent],
  imports: [
    CommonModule,
    NumberControllerModule,
    DecimalControllerModule,
    CurrencyControllerModule,
    PhoneNumberControllerModule,
  ],
  exports: [NumericControllerComponent],
})
export class NumericControllerModule {}
