import { Component, Input, forwardRef } from '@angular/core';
import { State, TulState } from '../../../shared/types/state.type';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tul-switch',
  templateUrl: './tul-switch.component.html',
  styleUrls: ['./tul-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TulSwitchComponent),
      multi: true,
    },
  ],
})
export class TulSwitchComponent implements ControlValueAccessor {
  /**
   * Text in label
   */
  @Input() tulLabel: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Enum State for design
   */
  @Input() tulState: TulState = State.DEFAULT;

  /**
   * Text on slider
   */
  @Input() tulSliderText = { textTrue: 'yes', textFalse: 'not' };

  /**
   * Text under dropdown
   */
  @Input() tulAdditionalInfo: string = '';

  /** */
  @Input() tulTooltipconfig!: any;

  /**
   * Holds the current value of the slider
   */
  value: boolean = false;

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: boolean): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get sliderTextValue() {
    return this.value ? this.tulSliderText.textTrue : this.tulSliderText.textFalse;
  }
}
