import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgRefreshComponent } from './svg-refresh.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  exports: [SvgRefreshComponent],
  imports: [CommonModule, FormsModule],
  declarations: [SvgRefreshComponent],
})
export class SvgRefreshModule {}
