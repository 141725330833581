import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tulDataTablePipe' })
export class TulDataTablePipe implements PipeTransform {
  transform(value: any, column: any): any {
    let valor = '';
    try {
      if (column.includes('.')) {
        const separates = column.split('.');
        valor = value;
        separates.forEach((element: any) => {
          valor = valor[element];
        });
      } else {
        valor = value[column];
      }
    } catch (error) {
      return '';
    }

    return valor !== undefined && valor !== null ? valor : '';
  }
}
