<div class="tul-input-container">
  <label
    for="tul-switch"
    class="tul-input-container__label"
    *ngIf="tulLabel"
    [ngClass]="{
      'tul-input-container--alert-error': tulState === 'error'
    }"
    >{{ tulLabel | transloco }}
    &nbsp;
    <i
      *ngIf="tulTooltipconfig"
      class="tul-input-container__hint soytul soytul-info_filled"
      [tulTooltip]="tulTooltipconfig.title"
      [tulTooltipConfig]="tulTooltipconfig"
    ></i>
  </label>
  <label class="switch">
    <input
      class="input"
      type="checkbox"
      [ngClass]="{ 'tul-input--disabled': tulDisabled }"
      [(ngModel)]="value"
      (ngModelChange)="updateChanges()"
      [disabled]="tulDisabled"
    />
    <span class="slider" [ngClass]="{ 'slider--disabled': tulDisabled }">
      <span class="slider__circle" [ngClass]="{ 'slider__circle--checked': value }"></span>

      <!--if selectorsType is undefined defaults to yes/no labels-->
      <span class="slider__text" [ngClass]="{ 'slider__text--checked': value }">{{
        sliderTextValue | transloco
      }}</span>
    </span>
  </label>
  <small
    class="tul-input-container__small"
    *ngIf="tulAdditionalInfo"
    [ngClass]="{
      'tul-input-container__small--alert-error': tulState === 'error',
      'tul-input-container__small--alert-success': tulState === 'success'
    }"
    >{{ tulAdditionalInfo | transloco }}
  </small>
</div>
