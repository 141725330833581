export enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  DATE = 'date',
  COLOR = 'color',
  DATETIME = 'datetime-local',
  TIME = 'time',
  DEFAULT = 'default',
}

export enum InputCustomUse {
  DEFAULT = '',
  DROPDOWN = 'dropdown',
}

export type TulInputType = `${InputType}`;
export type TulInputCustomUse = `${InputCustomUse}`;
