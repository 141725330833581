import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { ColumnTable, FilterTable, TableMonolith } from '../interfaces/export';

/**
 * get table monolith services
 */
@Injectable({
  providedIn: 'root',
})
export class TableMonolithService {
  /**
   * environment
   */
  environment: any;

  /**
   * constructor
   * @param http Performs HTTP requests
   * @param environment environment
   */
  constructor(private http: HttpClient, @Inject('ENVIRONMENT') environment: any) {
    this.environment = environment;
  }

  /**
   * get data table
   * @param url url table
   * @param columns all columns
   * @param page actual page
   * @param size size of page
   * @param filters all filters
   * @param defaultFilters all default filters
   * @param sort object sort
   * @return observable
   */
  get(
    url: any,
    columns: Array<ColumnTable>,
    page: any,
    size: any,
    filters = new Map(),
    defaultFilters = [],
    sort?: any,
    table?: TableMonolith
  ): Observable<any> {
    let auxUrl = typeof url === 'string' ? url : url();
    auxUrl += `?page=${page}&perPage=${size}`;
    auxUrl += this.getSort(sort);
    auxUrl += this.getFilters(filters, defaultFilters);
    return this.http
      .get((table?.alternativeHost ? table?.alternativeHost : this.environment.url) + auxUrl)
      .pipe(
        map(({ data, meta }: any) => ({
          content: data.map((element: any) => ({ ...element, uuid: element?.id })),
          total_elements: meta.total,
        }))
      );
  }

  /**
   * get filter
   * @param filters filters table
   * @param defaultFilters defaults filters
   * @return string filter
   */
  getFilters(filters: any, defaultFilters: Array<FilterTable> = []) {
    if (!Boolean(filters.size)) {
      return '';
    }
    const auxKeys = [];
    const auxValues = [];
    for (const [key, { value }] of filters) {
      auxKeys.push(key);
      auxValues.push(value);
    }
    return ('&s_f=' + JSON.stringify(auxKeys) + '&s=' + JSON.stringify(auxValues)).replace(
      /"/g,
      ''
    );
  }

  /**
   * get sort
   * @param sort object sort
   * @return string sort
   */
  getSort(sort: any) {
    if (!Boolean(sort?.attribute) && !Boolean(sort?.type)) {
      return '';
    }
    return '&o_f=' + sort.attribute + '&o_b=' + (sort.type === 'ascend' ? 'ASC' : 'DESC');
  }
}
