import { Component, Input } from '@angular/core';

/**
 * svg shrink
 */
@Component({
  selector: 'svg-shrink',
  templateUrl: './svg-shrink.component.html',
  styleUrls: ['./svg-shrink.component.scss'],
})
export class SvgShrinkComponent {
  /**
   * is danger flag
   */
  @Input() danger = false;
}
