<tul-input-text-area
  [tulName]="'text-area'"
  [tulRows]="3"
  [tulCols]="10"
  [tulPlaceholder]="column.placeholder ? column.placeholder : ''"
  [tulLabel]="(column.required ? '* ' : '') + column.header"
  [tulAdditionalInfo]="column.error ?? ''"
  [tulState]="column.error ? 'error' : 'default'"
  [tulDisabled]="column.disabled ?? false"
  [tulLimit]="column.maxLength ?? 200"
  [(ngModel)]="data"
  (ngModelChange)="changeData($event)"
  [tulTooltipconfig]="column.tooltip ?? {}"
>
</tul-input-text-area>
