import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { SelectorsComponent } from './selectors.component';
import { TulDataTablePipeModule } from '../../../../../pipes/dataTable/tul-data-table-pipe.module';

@NgModule({
  exports: [SelectorsComponent],
  imports: [CommonModule, FormsModule, TulDataTablePipeModule, TranslocoModule],
  declarations: [SelectorsComponent],
})
export class SelectorsModule {}
