<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'textarea'">
      <textarea-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></textarea-controller>
    </ng-container>
    <ng-container *ngSwitchCase="'text_editor'">
      <text-editor-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
        [validateFieldFlag]="validateFieldFlag"
      ></text-editor-controller>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <default-controller
        [data]="data"
        [column]="column"
        (onChange)="onChange.emit($event)"
      ></default-controller>
    </ng-container>
  </ng-container>
</ng-container>
