<ng-container [ngSwitch]="subType">
  <!-- CURRENCY -->
  <ng-container *ngSwitchCase="'currency'">
    {{
      data
        | tulDataTablePipe : column.attribute
        | tulCurrencyPipe
        | nzSafeNull : (column.safeNull ? column.safeNull : "0")
    }}
  </ng-container>
  <!-- PERCENTAGE -->
  <ng-container *ngSwitchCase="'percentage'">
    {{
      data
        | tulDataTablePipe : column.attribute
        | nzSafeNull : (column.safeNull ? column.safeNull : "0")
    }}%
  </ng-container>
  <!-- NUMBER -->
  <ng-container *ngSwitchDefault>
    {{
      data
        | tulDataTablePipe : column.attribute
        | tulToFixedDecimalPipe : column
        | nzSafeNull : (column.safeNull ? column.safeNull : "0")
    }}
  </ng-container>
</ng-container>
