import { Injectable } from '@angular/core';
import { Apollo, gql, WatchQueryOptions } from 'apollo-angular';
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { FormGraphQl } from '../../interfaces';

/**
 * Service to graph ql connection with ms
 */
@Injectable({
  providedIn: 'root',
})
export class FormGraphQlService {
  /**
   *
   * @param apollo
   */
  constructor(private apollo: Apollo) {}

  /**
   *
   * @param argumentsParams
   * @returns
   */
  getArguments(argumentsParams: any[]): string {
    return argumentsParams.reduce(
      (accumulator, currentValue) =>
        `${accumulator} ${currentValue.attribute}:"${
          typeof currentValue.value === 'function' ? currentValue.value() : currentValue.value
        }" `,
      ''
    );
  }

  /**
   *
   * @param param0
   * @returns
   */
  get({
    dto,
    arguments: [...argumentsParams],
    content,
    url: endpoint,
    pollInterval,
    fetchPolicy,
  }: FormGraphQl) {
    const auxDto = typeof dto === 'string' ? dto : dto();
    if (dto === '') return EMPTY;
    /* istanbul ignore next */
    const options: WatchQueryOptions<any, any> = {
      query: gql`
      query {
        ${dto}(${this.getArguments(argumentsParams)}) {
          ${content}
        }
      }
      `,
      variables: {
        endpoint,
      },
    };

    /* istanbul ignore next */
    if (pollInterval?.enable) {
      options.pollInterval = pollInterval.time ?? 10000;
    }
    /* istanbul ignore next */
    (options as any).fetchPolicy = fetchPolicy ?? 'network-only';
    /* istanbul ignore next */
    return this.apollo.watchQuery<any>(options).valueChanges.pipe(
      map(({ data: response }) => ({ ...response[auxDto] })),
      catchError(() => EMPTY)
    );
  }
}
