<div class="tul-input-container">
  <label
    [for]="'tul-radio' + tulLabel"
    class="tul-input-container__label"
    *ngIf="tulLabel"
    [ngClass]="{
      'tul-input-container--alert-error': tulState === 'error'
    }"
    >{{ tulLabel | transloco }}
    &nbsp;
    <i
      *ngIf="tulTooltipconfig"
      class="tul-input-container__hint soytul soytul-info_filled"
      [tulTooltip]="tulTooltipconfig.title"
      [tulTooltipConfig]="tulTooltipconfig"
    ></i
  ></label>
  <div class="tul-radio-container">
    <div *ngFor="let radio of tulValues; let i = index">
      <input
        type="radio"
        [id]="radio.title + i"
        [ngClass]="{ 'tul-input--disabled': tulDisabled }"
        [name]="'tul-radio' + tulLabel"
        [value]="radio.value"
        [checked]="radio.value"
        [(ngModel)]="value"
        (ngModelChange)="updateChanges()"
        [disabled]="tulDisabled"
      />
      <label class="tul-radio-container--label" [for]="radio.title + i"
        >&nbsp;&nbsp;{{ radio.title }}</label
      >
    </div>
  </div>
  <small
    class="tul-input-container__small"
    *ngIf="tulAdditionalInfo"
    [ngClass]="{
      'tul-input-container__small--alert-error': tulState === 'error',
      'tul-input-container__small--alert-success': tulState === 'success'
    }"
    >{{ tulAdditionalInfo | transloco }}
  </small>
</div>
