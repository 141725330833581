import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextAreaColumn } from '../../../../../interfaces/columns/text';

/**
 *
 */
@Component({
  selector: 'textarea-controller',
  templateUrl: './textarea-controller.component.html',
  styleUrls: ['./textarea-controller.component.scss'],
})
export class TextAreaControllerComponent {
  /** value input */
  @Input() data!: any;

  /** column */
  @Input() column!: TextAreaColumn;

  /** */
  @Output() onChange = new EventEmitter();

  /** */
  status = '';

  /** */
  feedBackActivate: boolean = false;

  /**
   * Method that use transformData and emit the changed data
   */
  changeData(value: any) {
    this.data = this.column.transformData
      ? this.column.transformData({ data: value, column: this.column })
      : value;
    this.onChange.emit(this.data);
  }
}
