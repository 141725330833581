import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { TulTooltipComponent } from '../components/tul-tooltip/tul-tooltip.component';
import { TulTooltipConfig, TulTooltipPosition, TulTooltipType } from '../models/tul-tooltip-model';

@Directive({
  selector: '[tulTooltip]',
})
export class TulTooltipDirective {
  @Input() tulTooltip: string | (({ data, column }: any) => string) = '';
  @Input() tulTooltipConfig: TulTooltipConfig = {};

  private componentRef!: ComponentRef<any> | null;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  private setTooltipComponentProperties() {
    if (this.componentRef) {
      this.componentRef.instance.tooltip = this.tulTooltip;
      const { left, right, bottom, height, width, top, x } =
        this.elementRef.nativeElement.getBoundingClientRect();
      // default position TOP
      this.componentRef.instance.left = (right - left) / 2 + left;
      this.componentRef.instance.top = bottom - height - 40;

      //icon
      if (this.tulTooltipConfig.icon) this.componentRef.instance.icon = this.tulTooltipConfig.icon;
      // theme dark
      if (this.tulTooltipConfig.type === TulTooltipType.DARK) {
        this.componentRef.instance.dark = true;
      }
      // position
      if (this.tulTooltipConfig.position) {
        this.componentRef.instance.position = this.tulTooltipConfig.position;
        switch (this.tulTooltipConfig.position) {
          case TulTooltipPosition.TOP:
            this.componentRef.instance.left = (right - left) / 2 + left;
            this.componentRef.instance.top = bottom - height - 45;
            break;

          case TulTooltipPosition.BOTTOM:
            this.componentRef.instance.left = (right - left) / 2 + left;
            this.componentRef.instance.top = bottom;
            break;

          case TulTooltipPosition.RIGHT:
            this.componentRef.instance.right = null;
            this.componentRef.instance.left = right + 140;
            this.componentRef.instance.top = top - height / 2 - 2;
            break;

          case TulTooltipPosition.LEFT:
            this.componentRef.instance.right = null;
            this.componentRef.instance.left = left - 240 / 3;
            this.componentRef.instance.top = top - height / 2 - 5;
            break;
        }
      }
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (!this.componentRef) {
      this.componentRef = this.viewContainerRef.createComponent(TulTooltipComponent);
      if (this.componentRef.hostView) {
        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
          .rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
        this.setTooltipComponentProperties();
      }
    }
  }
  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef) {
      if (this.componentRef.hostView) {
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.componentRef = null;
      }
    }
  }
}
