import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeTab } from '../../types/type-tab.type';
import { TulTab, TulItemTab } from '../../models/tab.model';

/**
 * Component for custom tabs
 */
@Component({
  selector: 'tul-tabs',
  templateUrl: './tul-tabs.component.html',
  styleUrls: ['./tul-tabs.component.scss'],
})
export class TulTabsComponent {
  /**
   * Tab list
   */
  @Input() tulTabs: TulTab = { type: TypeTab.HORIZONTAL, items: [] };

  /**
   * Emit item selected by user
   */
  @Output() tulItemSelected: EventEmitter<TulItemTab> = new EventEmitter<TulItemTab>();

  /**
   * TrackBy for ngFor performance
   * @param index
   * @returns index
   */
  trackByItems(index: number): number {
    return index;
  }

  /**
   * Change status for isActive parameter when user press other tab
   * @param itemSelected item selected by user action
   */
  selectItem(itemSelected: TulItemTab) {
    this.tulTabs.items.forEach((tab) => (tab.isActive = false));
    itemSelected.isActive = true;
    this.tulItemSelected.emit(itemSelected);
  }
}
