import { CurrencyPipe as CurrencyPipeAngular } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tulCurrencyPipe' })
export class TulCurrencyPipe implements PipeTransform {
  environment: any;
  constructor(
    private currencyPipeAngular: CurrencyPipeAngular,
    @Inject('ENVIRONMENT') environment: any
  ) {
    this.environment = environment;
  }
  transform(value: any) {
    try {
      switch (this.environment.country['code']) {
        case 'CO':
          return this.currencyPipeAngular.transform(value, 'COP', '$ ', '1.0-0', 'pt_BR');
        case 'MX':
          return this.currencyPipeAngular.transform(value, 'MXN', '$ ', '1.2-2', 'en-US');
        case 'BR':
          return this.currencyPipeAngular.transform(
            value,
            'BRL',
            'symbol-narrow',
            '1.2-2',
            'pt_BR'
          );
        default:
          return this.currencyPipeAngular.transform(
            value,
            `${this.environment.country['code']}`,
            'symbol',
            '1.2-2',
            `${this.environment.country['language']}-${this.environment.country['code']}`
          );
      }
    } catch (error) {
      return null;
    }
  }
}
