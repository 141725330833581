<div
  tabindex="-1"
  id="tul-btn-card"
  class="btn-card"
  [class.btn-card--disabled]="isDisabled"
  [class.btn-card--condenced]="isCondencedType"
  [class.btn-card--large]="isLargeType"
  [class.btn-card--s-size]="isSmall"
  [class.btn-card--m-size]="isMedium"
  [class.btn-card--l-size]="isLarge"
  (mouseenter)="emitAction('hover')"
  (focus)="emitAction('focus')"
  (click)="emitAction('press')"
>
  <div
    class="btn-card-container"
    [class.btn-card-container--vertical]="isVertical"
    [class.btn-card-container--condenced]="isCondencedType"
    [class.btn-card-container--large]="isLargeType"
  >
    <img
      class="btn-card-container__icon"
      [src]="environment.staticFilesUrl + 'icons/svg/' + tulType?.icon?.name + '.svg'"
      alt="Icon"
      *ngIf="hasIcon && isSvg"
      (error)="$event.target.src = defaultImage"
    />
    <i
      class="soytul soytul-{{ tulType?.icon?.name }} btn-card-container__icon"
      [class.tul-card-container--m-right]="!isVertical"
      *ngIf="hasIcon && isFont"
    ></i>
    <div class="btn-card-container__text-container">
      <span
        class="btn-card-container__text"
        [class.btn-card-container__text--disabled]="isDisabled"
        [class.btn-card-container--m-bottom]="hasDescription"
        >{{ tulText | transloco }}</span
      >
      <span *ngIf="hasDescription">{{ description }}</span>
    </div>
    <i class="soytul soytul-arrow_right_filled btn-card-container__icon" *ngIf="hasArrow"></i>
  </div>
</div>
