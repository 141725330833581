<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'entity'">
      <entity-controller
        [data]="data!"
        [column]="getEntityColumn()"
        (onChange)="onChange.emit($event)"
      ></entity-controller>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <listed-controller
        [data]="data!"
        [column]="getListColumn()"
        (onChange)="onChange.emit($event)"
      ></listed-controller>
    </ng-container>
  </ng-container>
</ng-container>
