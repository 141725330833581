<nz-spin nzSimple [nzSize]="'large'" *ngIf="loading; else elseTemplate"></nz-spin>

<ng-template #elseTemplate>
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="'details'">
      <app-details [groups]="groups" [data]="data!"></app-details>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <app-modification
        #modificationComponent
        [form]="form!"
        [groups]="groups"
        [data]="data!"
      ></app-modification>
    </ng-container>
  </ng-container>
</ng-template>
