import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DocumentControllerComponent } from './document-controller.component';
import { TulInputModule } from '../../../../../tul-input/tul-input.module';
import { TulSelectModule } from '../../../../../tul-select/tul-select.module';
@NgModule({
  imports: [CommonModule, FormsModule, TulInputModule, TulSelectModule],
  declarations: [DocumentControllerComponent],
  exports: [DocumentControllerComponent],
})
export class DocumentControllerModule {}
