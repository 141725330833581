import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { State, TulState } from '../../../shared/types/state.type';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  ValueType,
  ValueTypeCheckbox,
} from '../../../../src/tul-form/interfaces/columns/selectors';

@Component({
  selector: 'tul-checkbox',
  templateUrl: './tul-checkbox.component.html',
  styleUrls: ['./tul-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TulCheckboxComponent),
      multi: true,
    },
  ],
})
export class TulCheckboxComponent implements ControlValueAccessor {
  /**
   * Text in label
   */
  @Input() tulLabel: string = '';

  /**
   * Disabled
   */
  @Input() tulDisabled: boolean = false;

  /**
   * Enum State for design
   */
  @Input() tulState: TulState = State.DEFAULT;

  @Input() data: boolean | string = false;

  /**
   * Text under dropdown
   */
  @Input() tulAdditionalInfo: string = '';
  /**
   * Text under dropdown
   */
  @Input() tulValues: ValueTypeCheckbox[] = [];

  /** */
  @Input() tulTooltipconfig!: any;

  /**
   * Holds the current value of the slider
   */
  value: any = {};

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    console.warn(this.result());
    this.onChange(this.result());
  }

  result() {
    return this.tulValues.filter((item) => item.checked);
  }
  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: any): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
