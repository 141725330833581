import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnTable } from '../../../interfaces/columns/column';

/**
 * filters component
 */
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  /**
   * Filters map
   */
  @Input() filter!: Map<string, any>;
  /**
   * Emitter two data binding
   */
  @Output() filterChange = new EventEmitter();
  /**
   * Actual Column
   */
  @Input() column!: ColumnTable;
  /**
   * Emitter change value filter
   */
  @Output() executeOptionFilter = new EventEmitter<{ type: any; data: any; column: any }>();
  /**
   * Value filter
   */
  valueFilterInput: any;

  /**
   * Execute filter
   * @param column selected column
   */
  actionFilter(column: ColumnTable) {
    if (this.valueFilterInput) {
      this.filter.set(column.filter?.attribute ?? column.attribute, {
        value: this.valueFilterInput,
        additionalSearch: column.filter?.additionalSearchFilter
          ? column.filter.additionalSearchFilter
          : null,
      });
    }
    this.executeOptionFilter.emit({
      type: 'actionFilter',
      data: this.getValueFilter(column),
      column,
    });
  }

  /**
   * Delete clicking filter
   * @param column selected column
   */
  deleteFilter(column: ColumnTable) {
    this.filter.delete(column.filter?.attribute ?? column.attribute);
    this.executeOptionFilter.emit({ type: 'deletesFilter', data: {}, column });
  }

  /**
   * Change value input
   * @param event value
   * @param column actual column
   */
  changeValue(event: any, column: ColumnTable) {
    this.executeOptionFilter.emit({ type: 'changeValue', data: event, column });
  }

  /**
   * get value filter
   * @param column selected column
   * @return value to selected column
   */
  getValueFilter(column: ColumnTable) {
    return this.filter.get(column?.filter?.attribute ?? column.attribute)?.value ?? {};
  }
}
