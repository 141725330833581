import { Component, Input, OnInit } from '@angular/core';
import { TypeTextsForms } from '../../../../interfaces';
import { Data } from '../../../../interfaces/data';
import { TulDataTablePipe } from '../../../../../pipes/dataTable/tul-data-table.pipe';

@Component({
  selector: 'text-controller',
  templateUrl: './text-controller.component.html',
  providers: [TulDataTablePipe],
})
export class TextControllerComponent implements OnInit {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TypeTextsForms;

  /** */
  dataHtml!: string;

  constructor(private tulDataTablePipe: TulDataTablePipe) {}

  /** */
  ngOnInit(): void {
    if (this.column.subType === 'text_editor')
      this.dataHtml = this.tulDataTablePipe.transform(this.data, this.column.attribute);
  }
}
