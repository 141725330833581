import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeHeaderOptions } from '../../enums/export';
import { OptionsTable } from '../../interfaces/export';
import { SubjectsService } from '../../services/subjects.service';

/**
 * Options header
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  /**
   * Title table
   */
  @Input() title: string | undefined = '';

  /**
   * Array options header
   */
  @Input() options!: Array<OptionsTable<TypeHeaderOptions>> | undefined;

  /**
   * loading options
   */
  @Input() loading!: boolean;

  /**
   * Emitter to execute option
   */
  @Output() executeOption = new EventEmitter<any>();

  /**
   * Constructor
   * @param subjectsService external connection between components
   */
  constructor(private subjectsService: SubjectsService) {}

  /**
   * Generate click option
   * @param option click option
   */
  clickOption(option: any) {
    this.executeOption.emit({ ...option, type: 'header' });
  }

  /**
   * whether it is visible or not
   * @param option actual option
   * @return boolean whether it is visible or not
   */
  validateVisibility(option: any) {
    return option.visible ? option.visible({ option }) : true;
  }
}
