import { Component, Input, OnInit } from '@angular/core';
import {
  EntityColumnForms,
  ListColumnForms,
  TypeSelectsForms,
} from '../../../../interfaces/columns/select';
import { Data } from '../../../../interfaces/data';

/**
 *
 */
@Component({
  selector: 'select-controller',
  templateUrl: './select-controller.component.html',
})
export class SelectControllerComponent implements OnInit {
  /** */
  @Input() data!: Data;

  /** */
  @Input() column!: TypeSelectsForms;

  concatEntityAttributes = '';
  /**
   * cast to pipe data
   * @param column
   */
  public castToPipeData(column: TypeSelectsForms) {
    return (column as ListColumnForms).listValues;
  }

  ngOnInit(): void {
    let column = this.column as EntityColumnForms;
    if (column.listAttributtes) {
      column.listAttributtes.forEach((element, index) => {
        if (this.data && this.data[column.attribute] && this.data[column.attribute][element]) {
          this.concatEntityAttributes += this.data[column.attribute][element] + ' ';
        }
      });
    }
  }
}
