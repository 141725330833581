import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

type displayType = 'initial' | 'next' | 'previous';
/**
 * Service for custom stepper
 */
@Injectable({
  providedIn: 'root',
})
export class TulStepperService {
  /**
   * Subject for actions ('initial' | 'next' | 'previous')
   */
  private display: BehaviorSubject<displayType> = new BehaviorSubject<
    'initial' | 'next' | 'previous'
  >('initial');

  /**
   * Build an observable
   * @returns Observable
   */
  watch(): Observable<'initial' | 'next' | 'previous'> {
    return this.display.asObservable();
  }

  /**
   * Select next step
   */
  next() {
    this.display.next('next');
  }

  /**
   * Select previous step
   */
  previous() {
    this.display.next('previous');
  }
}
