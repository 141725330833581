<ng-container>
  <ng-container [ngSwitch]="column.subType">
    <ng-container *ngSwitchCase="'currency'">
      {{ data | tulDataTablePipe : column.attribute | tulCurrencyPipe }}
    </ng-container>
    <ng-container *ngSwitchCase="'decimal'">
      {{
        data | tulDataTablePipe : column.attribute | tulToFixedDecimalPipe : column.decimals ?? 0
      }}
    </ng-container>
    <ng-container *ngSwitchCase="'percentage'">
      {{ data | tulDataTablePipe : column.attribute }} %
    </ng-container>
    <ng-container *ngSwitchCase="'phonenumber'">
      <a [href]="'tel: ' + (data | tulDataTablePipe : column.attribute)">{{
        data | tulDataTablePipe : column.attribute
      }}</a></ng-container
    >
    <ng-container *ngSwitchDefault>
      {{ data | tulDataTablePipe : column.attribute }}
    </ng-container>
  </ng-container>
</ng-container>
