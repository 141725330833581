<div
  class="tul-tabs"
  [ngClass]="{
    'tul-tabs--scroll': tulTabs.isScrollable
  }"
>
  <div
    class="tul-tab"
    *ngFor="let tab of tulTabs.items; trackBy: trackByItems"
    [ngClass]="{
      'tul-tab--active': tab.isActive,
      'tul-tab--is-vertical': tulTabs.type === 'vertical',
      'tul-tab--disabled': tab.disabled,
      'tul-tab--scroll': tulTabs.isScrollable
    }"
    [ngStyle]="{ width: tulTabs.widthTabsScroll }"
    (click)="selectItem(tab)"
  >
    <i
      class="soytul tul-tab__icon"
      [ngClass]="[
        'soytul-' + tab.icon,
        tulTabs.type === 'vertical' ? 'tul-tab__icon--is-vertical' : '',
        !tab.text && tulTabs.type !== 'vertical' ? 'tul-tab__icon--only-icon' : ''
      ]"
    ></i>
    <p class="tul-tab__text">{{ tab.text }}</p>
  </div>
</div>
